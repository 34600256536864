import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { sendOrderMail } from '../../api'
import { Button } from 'primereact/button'
import { useGlobalContext } from '../../context'
//TODO: to be optimized
export default function SendMailDialog({ mailData, setMailData }) {
  const { sendEmailDialogVisible, setSendEmailDialogVisible, toast } = useGlobalContext()

  const empty_sendEmail = {
    email: '',
    subject: '',
    body: '',
    relatedTo: '',
    refId: '',
  }
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)

  const hideMessageDialog = () => {
    setSendEmailDialogVisible(false)
    setMailData(empty_sendEmail)
    setLoading(false)
    setConfirm(false)
  }

  const sendMessage = async () => {
    setLoading(true)
    const { email, subject, body, relatedTo, refId } = mailData
    const res = await sendOrderMail(email, subject, body, relatedTo, refId)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: `Message Sent Successfully For ${refId}`,
          life: 3000,
        })
        hideMessageDialog()
      }
    }
  }

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => hideMessageDialog()}
          className="p-button-text"
        />
        <Button
          label={loading ? 'Sending' : 'Send'}
          disabled={!confirm || !mailData.subject || !mailData.body || loading}
          icon="pi pi-send"
          onClick={() => {
            sendMessage()
          }}
          loading={loading}
          autoFocus
        />
      </div>
    )
  }
  return (
    <Dialog
      header={`Compose Mail For ${mailData.refId}`}
      modal
      className="p-fluid"
      visible={
        sendEmailDialogVisible
        // true
      }
      style={{ width: '1000px' }}
      footer={renderFooter('messageDialog')}
      onHide={() => hideMessageDialog()}
    >
      <div className="p-my-2">
        <div className="p-field">
          <label htmlFor="subject" className="block p-text-bold">
            Subject
          </label>
          <InputText
            id="subject"
            value={mailData.subject}
            onChange={(e) => setMailData({ ...mailData, subject: e.target.value })}
            placeholder="Enter The Subject"
            aria-describedby="subject"
            className="block"
          />
        </div>
        <div className="p-field">
          <label htmlFor="body" className="block p-text-bold">
            Body
          </label>
          <ReactQuill
            value={mailData.body}
            onChange={(e) => setMailData({ ...mailData, body: e })}
          />
        </div>
        <div className="p-col-12">
          <Checkbox checked={confirm} onChange={(e) => setConfirm(e.checked)} />
          <label className="p-checkbox-label p-ml-2">
            I confirm sending this mail to the customer
          </label>
        </div>
      </div>
    </Dialog>
  )
}
