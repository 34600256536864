import { format } from 'date-fns'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toolbar } from 'primereact/toolbar'
import React, { useState } from 'react'
import { useGlobalContext } from '../../../../context'
import { deletePaymentPartnerMethod } from '../../../../api'
import { Toast } from 'primereact/toast'
import EditPaymentPartnerMethodDialog from './EditPaymentPartnerMethodDialog'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import AddPaymentPartnerMethodDialog from './AddPaymentPartnerMethodDialog'
import generateS3Path from '../../../../functions/generateS3Path'
import { Dropdown } from 'primereact/dropdown'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import ChangeHistorySidebar from '../../ChangeHistorySidebar'

export default function PaymentPartnerMethodsTable({
  paymentPartners,
  setActiveIndex,
  paymentPartnerMethodsTableLoading,
  setPaymentPartnerMethodsTableLoading,
  paymentPartnerMethods,
  setPaymentPartnerMethods,
  fetchPaymentPartnerMethods,
}) {
  const [addPaymentPartnerMethodDialog, setAddPaymentPartnerMethodDialog] = useState(false)
  const { toast } = useGlobalContext()
  const [paymentMethodTableFilter, setPaymentMethodTableFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    paymentPartner: { value: null, matchMode: FilterMatchMode.EQUALS },
  })
  const onChangeFilter = (setter, value, target) => {
    setter((ps) => ({ ...ps, [target]: { ...ps[target], value } }))
  }

  // global filter search onchange
  const onGlobalFilterChange = (event, filter, setFilter) => {
    const value = event.target.value
    let _filters = { ...filter }

    _filters['global'].value = value

    setFilter(_filters)
  }

  const deletePaymentPartnerMethodFn = async (id) => {
    if (id) {
      setPaymentPartnerMethodsTableLoading(true)
      const res = await deletePaymentPartnerMethod(id)
      if (res) {
        setPaymentPartnerMethodsTableLoading(false)
        if (res.status === 200) {
          setPaymentPartnerMethods((ps) => ps.filter((p) => p.id !== id))
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.data.message,
            life: 3000,
          })
        }
      }
    }
  }

  return (
    <div>
      <Toast ref={toast} />
      <Toolbar
        className="p-toolbar p-mb-3 p-flex-wrap gap-1mb-4"
        left={
          <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
            <h4 className="p-m-0">Payment Partner Methods</h4>
            <Button
              label="Add"
              icon="pi pi-plus"
              className="p-button-primary"
              onClick={() => setAddPaymentPartnerMethodDialog(true)}
            />
          </div>
        }
        right={
          <div>
            <div className="p-d-flex p-flex-wrap gap-1">
              <Button
                label="Refresh"
                icon="pi pi-refresh"
                onClick={async () => {
                  setPaymentPartnerMethodsTableLoading(true)
                  await fetchPaymentPartnerMethods()
                  setPaymentPartnerMethodsTableLoading(false)
                }}
              />
            </div>
          </div>
        }
      />
      <DataTable
        loading={paymentPartnerMethodsTableLoading}
        value={paymentPartnerMethods}
        removableSort
        showGridlines
        filters={paymentMethodTableFilter}
        header={() => (
          <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
            <div>
              <Dropdown
                optionLabel="name"
                optionValue="name"
                options={paymentPartners}
                value={paymentMethodTableFilter.paymentPartner.value}
                onChange={(e) =>
                  onChangeFilter(setPaymentMethodTableFilter, e.value, 'paymentPartner')
                }
                showClear
                filter
                filterBy="name"
                placeholder="Select Payment Partner"
              />
            </div>
            <div>
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  type="search"
                  value={
                    paymentMethodTableFilter['global']
                      ? paymentMethodTableFilter['global'].value
                      : '' || ''
                  }
                  onChange={(e) =>
                    onGlobalFilterChange(e, paymentMethodTableFilter, setPaymentMethodTableFilter)
                  }
                  placeholder="Global Search"
                />
              </span>
            </div>
          </div>
        )}
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
      >
        <Column field="id" header="ID" sortable />
        <Column
          field="appImage"
          header="App"
          body={(rd) => (
            <div className="p-d-flex p-jc-center p-ai-center">
              <img
                src={generateS3Path(rd.appImage)}
                alt={rd.appName}
                className="p-mr-2"
                style={{
                  width: '40px',
                }}
              />
            </div>
          )}
        />
        <Column field="paymentPartner" header="Payment Partner" sortable />
        <Column field="appName" header="Payment Method" sortable />
        <Column field="active" header="Status" body={(rd) => (rd.active ? 'Active' : 'Inactive')} />
        <Column
          field="createdAt"
          header="Created At"
          body={(rd) => format(new Date(rd.createdAt), 'PPp')}
          sortable
        />
        <Column
          field="Action"
          header="Action"
          style={{
            width: '1%',
          }}
          body={(rd) => {
            return (
              <div className="p-d-flex gap-1by2 p-jc-center">
                <Link to={`?partner_method_id=${rd?.id}`}>
                  <Button
                    className="p-button p-button-info"
                    icon="pi pi-pencil"
                    tooltip="Edit"
                    tooltipOptions={{
                      position: 'bottom',
                    }}
                  />
                </Link>
                <Button
                  icon="pi pi-trash"
                  className="p-button p-button-danger"
                  tooltip="Delete"
                  tooltipOptions={{
                    position: 'bottom',
                  }}
                  onClick={() => {
                    deletePaymentPartnerMethodFn(rd.id)
                  }}
                />
                <Link to={`?history=${rd.id}`}>
                  <Button
                    tooltip="History"
                    tooltipOptions={{ position: 'bottom' }}
                    icon="pi pi-clock"
                    className="p-button p-button-help"
                  />
                </Link>
              </div>
            )
          }}
        />
      </DataTable>
      <AddPaymentPartnerMethodDialog
        addPaymentPartnerMethodDialog={addPaymentPartnerMethodDialog}
        setAddPaymentPartnerMethodDialog={setAddPaymentPartnerMethodDialog}
        paymentPartners={paymentPartners}
        setPaymentPartnerMethods={setPaymentPartnerMethods}
        setPaymentPartnerMethodsTableLoading={setPaymentPartnerMethodsTableLoading}
      />
      {/* Edit dialog  */}
      <EditPaymentPartnerMethodDialog
        paymentPartners={paymentPartners}
        paymentPartnerMethods={paymentPartnerMethods}
        setPaymentPartnerMethods={setPaymentPartnerMethods}
        setPaymentPartnerMethodsTableLoading={setPaymentPartnerMethodsTableLoading}
        setActiveIndex={setActiveIndex}
      />
      <ChangeHistorySidebar
        header="Payment Partner Method Change History"
        historyType="im_paymentPartnerMethod"
      />
    </div>
  )
}
