import React, { useCallback, useEffect, useState } from 'react'
import useQuery from '../../../hooks/useQuery'
import { getFaqById, patchFaqRecord } from '../../../api'
import { Dialog } from 'primereact/dialog'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { Dropdown } from 'primereact/dropdown'
import { handleObjChange } from '../../../functions/setter'
import ConfirmDialogWithPassword from '../Dialog/ConfirmDialogWithPassword'
import { useGlobalContext } from '../../../context'
import { Toast } from 'primereact/toast'

const empty_Faq = {
  passwordDialogVisible: false,
  loading: false,
  visible: false,
  id: null,
  main_category_id: null,
  category_id: null,
  question: '',
  answer: '',
  updatedBy: '',
  updatedAt: '',
  createdBy: '',
  createdAt: '',
}

const emptyErr = {
  state: false,
  errors: [],
}

export default function EditFaq({ mainCategoryData, subCategoryData, faqs, setFaqs }) {
  const { toast } = useGlobalContext()
  const search = useQuery()
  const id = search.get('editFaqId')
  const history = useHistory()
  const [faqRecord, setFaqRecord] = useState(empty_Faq)
  const [password, setPassword] = useState('')
  const [editFaqRecordError, setEditFaqRecordError] = useState(emptyErr)
  const fetchFaqById = useCallback(async () => {
    if (!id) return
    const res = await getFaqById(id)
    if (res) {
      if (res.status === 200) {
        setFaqRecord((prev) => ({ ...prev, visible: true, ...res.data }))
      }
    }
  }, [id])
  useEffect(() => {
    fetchFaqById()
  }, [fetchFaqById])

  const validateUpdateRecord = () => {
    const { question, answer, main_category_id } = faqRecord
    const err = []
    if (!question.trim()) err.push('Question is required')
    if (!answer.trim()) err.push('Answer is required')
    if (!main_category_id) err.push('Main category is required')
    return err
  }

  const handleValidateUpdateRecord = () => {
    const errs = validateUpdateRecord()
    if (errs.length > 0) {
      setEditFaqRecordError({
        state: true,
        errors: errs,
      })
      return false
    }
    setEditFaqRecordError(emptyErr)
    return true
  }
  const handleEditFaqRecord = async () => {
    handleObjChange(setFaqRecord, 'loading', true)
    const { question, answer, main_category_id, category_id } = faqRecord
    const res = await patchFaqRecord(id, {
      question,
      answer,
      main_category_id,
      category_id,
      password,
    })
    if (res) {
      handleObjChange(setFaqRecord, 'loading', false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successfull',
          detail: res.data.message,
        })

        let oldFaqs = [...faqs]

        oldFaqs = oldFaqs.map((faq) => {
          if (faq.id === Number(id)) {
            return res.data.updatedRecord
          }
          return faq
        })
        setFaqs(oldFaqs)
        hideFaqRecord()
      }
      handleObjChange(setFaqRecord, 'passwordDialogVisible', false)
      handleObjChange(setFaqRecord, 'loading', false)
    }
  }

  const hideFaqRecord = () => {
    setFaqRecord(empty_Faq)
    setPassword('')
    history.push('/website/faqs')
  }
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={faqRecord.visible}
        header={`FAQ #${id}`}
        style={{
          width: '50%',
          minWidth: '400px',
        }}
        onHide={() => {
          hideFaqRecord()
        }}
        footer={
          <div className="p-d-flex p-jc-end gap-1by2">
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => {
                hideFaqRecord()
              }}
            />
            <Button
              label="Save"
              icon="pi pi-save"
              onClick={() => {
                const isValid = handleValidateUpdateRecord()
                isValid && handleObjChange(setFaqRecord, 'passwordDialogVisible', true)
              }}
            ></Button>
          </div>
        }
      >
        <div className="p-fluid p-formgrid p-grid p-mb-2">
          <div className="p-col-12 p-md-6">
            <div className="p-fluid p-field ">
              <label className="p-col-fixed">Question</label>
              <InputTextarea
                placeholder="Enter question"
                rows={7}
                value={faqRecord.question}
                onChange={(e) => handleObjChange(setFaqRecord, 'question', e.target.value)}
              />
            </div>
          </div>

          <div className="p-col-12 p-md-6">
            <div className="p-fluid p-field ">
              <label className="p-col-fixed">Answer</label>
              <InputTextarea
                placeholder="Enter answer"
                rows={10}
                value={faqRecord.answer}
                onChange={(e) => handleObjChange(setFaqRecord, 'answer', e.target.value)}
              />
            </div>
          </div>
          <div className="p-col-12 p-md-6">
            <div className="p-fluid p-field ">
              <label className="p-col-fixed">Main Category</label>
              <Dropdown
                placeholder="Select main category"
                options={
                  mainCategoryData
                    ? mainCategoryData.map((category) => ({
                        ...category,
                        main_category_id: category.id,
                      }))
                    : []
                }
                optionLabel="name"
                optionValue="main_category_id"
                value={faqRecord.main_category_id}
                onChange={(e) => handleObjChange(setFaqRecord, 'main_category_id', e.value)}
              />
            </div>
          </div>
          <div className="p-col-12 p-md-6">
            <div className="p-fluid p-field ">
              <label className="p-col-fixed">Sub Category</label>
              <Dropdown
                placeholder="Select sub category"
                value={faqRecord.category_id}
                options={
                  subCategoryData
                    ? subCategoryData.map((subCategory) => ({
                        ...subCategory,
                        category_id: subCategory.id,
                      }))
                    : []
                }
                optionLabel="name"
                optionValue="id"
                onChange={(e) => handleObjChange(setFaqRecord, 'category_id', e.value)}
              />
            </div>
          </div>

          {/* Showing errors that occur if the field isn't filled properly */}
        </div>
        {editFaqRecordError.state && (
          <>
            {editFaqRecordError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid  p-col-12 p-lg-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setEditFaqRecordError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
        <ConfirmDialogWithPassword
          headerText={`Confirm Updating Wallet Coupon`}
          loading={faqRecord.loading}
          visible={faqRecord.passwordDialogVisible}
          setPassword={setPassword}
          password={password}
          onHide={() => handleObjChange(setFaqRecord, 'passwordDialogVisible', false)}
          mainText={`Are you sure you want to update this record?`}
          yesText="Submit"
          loadingText={`Submitting`}
          onSubmit={handleEditFaqRecord}
        />
      </Dialog>
    </>
  )
}
