import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { format } from 'date-fns'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  getCustomerById,
  getCustomers,
  updateCustomerById,
  bulkActionCustomer,
  getCustomerWalletLogs,
} from '../../api/customer'
import ExportButton from '../../components/mini/ExportButton'
import { Calendar } from 'primereact/calendar'
import HideDetails from '../../components/mini/HideDetails'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import { useGlobalContext } from '../../context'
import { dtFilenameDate } from '../../functions/myDateFns'
import { FilterMatchMode } from 'primereact/api'
import { TabPanel, TabView } from 'primereact/tabview'
import moneyFormatter from '../../functions/moneyFormatter'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { handleObjChange, onChangeFilter } from '../../functions/setter'
import { Divider } from 'primereact/divider'
import { Slider } from 'primereact/slider'
import { SelectButton } from 'primereact/selectbutton'
import { ToggleButton } from 'primereact/togglebutton'
import { InputNumber } from 'primereact/inputnumber'
import { MdVerified } from 'react-icons/md'
import { getRedeemableCoupons, postRedeemableCoupon } from '../../api'

export default function CustomerPage() {
  const { user, agents } = useGlobalContext()
  const toast = useRef(null)
  const dt = useRef(null)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = parseInt(searchParams.get('id'))
  const emptyFilterObject = {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    isEmailVerified: { value: '', matchMode: FilterMatchMode.EQUALS },
    assignedUserId: { value: '', matchMode: FilterMatchMode.IN },
  }
  const emptyWalletFilterObject = {
    transactionType: { value: '', matchMode: FilterMatchMode.EQUALS },
  }
  const emptybackendFilters = {
    dateRange: [],
    orderCount: null,
    flag: true,
  }
  const emptyWalletDetails = {
    balance: 0,
    totalCredit: 0,
    totalDebit: 0,
  }
  const [selectedRecords, setSelectedRecords] = useState([])
  const emptyRecord = {
    id: null,
    fullName: null,
    email: null,
    lastPaidOrder: {
      orderid: null,
      refillDate: null,
    },
    agent: null,
    assignedUser: null,
    newAssignedUser: null,
    paidOrderStats: {
      totalOrders: 0,
      totalPurchase: 0,
      avgOrderValue: 0,
      totalDiscount: 0,
    },
    paidOrders: [],
    unpaidOrders: [],
    loading: false,
  }
  const emptyAddCredit = {
    loading: false,
    visible: false,
    amount: 0,
    customerId: 0,
    transactionType: 'credit',
    refId: null,
    refName: null,
    walletCouponCode: null,
    codeId: null,
  }

  const [filters, setFilters] = useState(emptyFilterObject)
  const [walletFilters, setWalletFilters] = useState(emptyWalletFilterObject)
  const [records, setRecords] = useState([])
  const [record, setRecord] = useState(emptyRecord)
  const [recordVisible, setRecordVisible] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [limit, setLimit] = useState(10)
  const [walletLogs, setWalletLogs] = useState([])
  const [walletDetails, setWalletDetails] = useState(emptyWalletDetails)
  const [redeemableCoupons, setRedeemableCoupons] = useState([])
  const [addCreditRecord, setAddCreditRecord] = useState(emptyAddCredit)
  const [queryFilters, setQueryFilters] = useState(emptybackendFilters)
  const hideRecord = () => {
    setRecord(emptyRecord)
    setRecordVisible(false)
    history.push({
      pathname: '/customers',
    })
  }

  const hideAddCreditRecord = () => {
    setAddCreditRecord(emptyAddCredit)
    setRedeemableCoupons([])
  }

  const fetchRecord = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getCustomerById(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 404) {
        hideRecord()
      }
      if (res.status === 200) {
        setRecord((ps) => ({
          ...ps,
          ...res.data.record,
          newAssignedUser: res?.data?.record?.assignedUser || null,
        }))
        setRecordVisible(true)
        await fetchWalletLogs()
      }
    }
  }, [id])

  useEffect(() => fetchRecord(), [fetchRecord])

  const fetchRecords = useCallback(
    async (myQueryFilters) => {
      setTableLoading(true)

      const res = await getCustomers({ limit, queryFilters: myQueryFilters || queryFilters })
      if (res) {
        setTableLoading(false)
        if (res.status === 200) {
          setRecords(res.data)
        }
      }
    },
    [limit]
  )

  useEffect(() => fetchRecords(), [fetchRecords])

  const updateRecord = async () => {
    setRecord((ps) => ({ ...ps, loading: true }))
    const res = await updateCustomerById(record.id, {
      assignedUser: record.newAssignedUser,
    })
    if (res) {
      const updatedRecord = res.data.record
      setRecord((ps) => ({ ...ps, loading: false }))
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Record updated.',
          life: 3000,
        })
        // update the record in the records array
        setRecords((ps) => {
          const index = ps.findIndex((r) => r.id === record.id)
          if (index > -1) {
            const newRecords = [...ps]
            newRecords[index].assignedUsername = updatedRecord?.assignedUser?.username
            newRecords[index].assignedUserId = updatedRecord?.assignedUser?.id
            return newRecords
          }
          return ps
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Record update failed.',
          life: 3000,
        })
      }
    }
    setRecord((ps) => ({ ...ps, loading: false }))
  }

  const [bulkAssignedUser, setBulkAssignedUser] = useState(null)
  const [bulkAssignedUserLoading, setBulkAssignedUserLoading] = useState(false)
  const bulkAssignUserHandler = async () => {
    setBulkAssignedUserLoading(true)
    const ids = selectedRecords.map((r) => r.id)
    const res = await bulkActionCustomer({
      ids,
      action: 'assign-user',
      assignedUser: bulkAssignedUser,
    })
    if (res) {
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Records updated.',
          life: 3000,
        })
        // update the record in the records array
        const user = agents.find((a) => a.id === bulkAssignedUser)
        setRecords((ps) => {
          const newRecords = [...ps]
          ids.forEach((id) => {
            const index = newRecords.findIndex((r) => r.id === id)
            if (index > -1) {
              newRecords[index].assignedUsername = user?.username
              newRecords[index].assignedUserId = user?.id
            }
          })
          return newRecords
        })
        setSelectedRecords([])
        setBulkAssignedUser(undefined)
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Records update failed.',
          life: 3000,
        })
      }
    }
    setBulkAssignedUserLoading(false)
  }

  const fetchWalletLogs = async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getCustomerWalletLogs(id)
    if (res) {
      setTableLoading(false)
      setWalletLogs(res.data.walletLogsRes)
      let credits = 0
      let debits = 0
      res.data.walletLogsRes.forEach((log) => {
        if (log.transactionType === 'credit') {
          credits += Number(log.amount)
        } else {
          debits += Number(log.amount)
        }
      })
      setWalletDetails({
        balance: credits - debits,
        totalCredit: credits,
        totalDebit: debits,
      })
    }
  }

  const fetchRedeemableCoupons = async () => {
    const res = await getRedeemableCoupons()
    if (res) {
      if (res.status === 200) {
        setRedeemableCoupons(res.data)
      }
    }
  }

  const handleRedeemCoupon = async () => {
    if (!addCreditRecord.refId) {
      toast.current.show({
        severity: 'error',
        summary: 'Invalid',
        detail: 'Please select a coupon.',
        life: 3000,
      })
      return
    }
    handleObjChange(setAddCreditRecord, 'loading', true)
    const { amount, customerId, transactionType, refId, refName, walletCouponCode, codeId } =
      addCreditRecord
    const res = await postRedeemableCoupon({
      amount,
      customerId,
      transactionType,
      refId,
      refName,
      walletCouponCode,
      codeId,
    })
    if (res) {
      handleObjChange(setAddCreditRecord, 'loading', false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Credit added successfully.',
          life: 3000,
        })
        fetchWalletLogs()
        hideAddCreditRecord()
      }
    }
  }

  const validateFilterSelection = () => {
    if (
      !queryFilters.dateRange.length ||
      !queryFilters.dateRange[0] ||
      !queryFilters.dateRange[1] ||
      !queryFilters.orderCount
    ) {
      return false
    }
    return true
  }

  useEffect(() => {
    const isValid = validateFilterSelection()
    isValid && fetchRecords(queryFilters)
  }, [queryFilters])

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'agent', 'teamlead']}>
      <div className="card">
        <Toast ref={toast} />
        <Toolbar
          className="p-toolbar p-flex-wrap gap-1"
          left={
            <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
              <h4 className="p-m-0">Customers</h4>
            </div>
          }
          right={
            <div className="p-d-flex p-flex-wrap gap-1">
              <Dropdown
                icon="pi pi-refresh"
                label="Assign User"
                disabled={
                  !['superadmin', 'admin', 'manager'].includes(user.role) || !selectedRecords.length
                }
                placeholder="Select Assign User"
                options={agents.filter((a) => ['agent', 'teamlead'].includes(a.role))}
                optionLabel="username"
                optionValue="id"
                value={bulkAssignedUser}
                onChange={(e) => setBulkAssignedUser(e.value)}
              />
              <Button
                icon="pi pi-check"
                onClick={bulkAssignUserHandler}
                loading={bulkAssignedUserLoading}
                disabled={!bulkAssignedUser || bulkAssignedUserLoading}
                tooltip="Assign User Save"
              />
              <Button label="Refresh" icon="pi pi-refresh" onClick={fetchRecords} />
              <ExportButton datatable_ref={dt} />
            </div>
          }
        ></Toolbar>
        <DataTable
          exportFilename={`Customer_${dtFilenameDate}`}
          ref={dt}
          loading={tableLoading}
          value={records}
          filters={filters}
          showGridlines
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          className="datatable-responsive"
          columnResizeMode="fit"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          rowHover
          emptyMessage="No record found."
          breakpoint="1200px"
          responsiveLayout="stack"
          removableSort
          selectionMode="multiple"
          selection={selectedRecords}
          onSelectionChange={(e) => {
            setSelectedRecords(e.value)
          }}
          header={
            <div className="p-d-flex p-flex-column gap-1">
              <div className="p-d-flex p-ai-center p-flex-wrap gap-1by2 p-jc-end">
                <h5 className="p-mb-0">Basic Filters: </h5>
                <div className=" p-d-flex p-ai-center">
                  <MultiSelect
                    placeholder="Assigned User"
                    options={agents}
                    optionLabel="username"
                    optionValue="id"
                    filter
                    filterBy="username"
                    filterPlaceholder="Search..."
                    value={filters.assignedUserId.value}
                    onChange={(e) => {
                      onChangeFilter(setFilters, e.target.value, 'assignedUserId')
                    }}
                    showClear
                    maxSelectedLabels={2}
                  />
                </div>
                <div className="p-d-flex p-ai-center">
                  <Dropdown
                    placeholder="Email Verified"
                    options={[
                      {
                        label: 'Yes',
                        value: 1,
                      },
                      {
                        label: 'No',
                        value: 0,
                      },
                    ]}
                    optionLabel="label"
                    optionValue="value"
                    value={filters.isEmailVerified.value}
                    onChange={(e) => {
                      onChangeFilter(setFilters, e.target.value, 'isEmailVerified')
                    }}
                    showClear
                  />
                </div>
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    type="search"
                    value={filters.global.value}
                    onChange={(e) => {
                      onChangeFilter(setFilters, e.target.value, 'global')
                    }}
                    placeholder="Search..."
                  />
                </span>
              </div>
              <div className="p-d-flex p-ai-center p-flex-wrap gap-1by2 p-jc-end">
                <h5 className="p-mb-0">Advanced Filters: </h5>
                <div className=" p-d-flex p-ai-center">
                  <Calendar
                    selectionMode="range"
                    value={queryFilters.dateRange}
                    placeholder="Select Date Range"
                    onChange={(e) => {
                      handleObjChange(setQueryFilters, 'dateRange', e.value)
                    }}
                  ></Calendar>
                </div>
                <div className=" p-d-flex p-ai-center">
                  <div className="p-inputgroup">
                    <ToggleButton
                      onLabel="More Than"
                      offLabel="Less Than"
                      checked={queryFilters.flag}
                      style={{ borderRadius: '4px 0px 0px 4px' }}
                      onChange={(e) => handleObjChange(setQueryFilters, 'flag', e.value)}
                    ></ToggleButton>
                    <InputNumber
                      placeholder="Order count"
                      value={queryFilters.orderCount}
                      onValueChange={(e) => handleObjChange(setQueryFilters, 'orderCount', e.value)}
                    />
                  </div>
                </div>
                <div className=" p-d-flex p-ai-center">
                  <Dropdown
                    placeholder="Limit"
                    options={[
                      {
                        label: '10',
                        value: 10,
                      },
                      {
                        label: '50',
                        value: 50,
                      },
                      {
                        label: '100',
                        value: 100,
                      },
                      {
                        label: '500',
                        value: 500,
                      },
                      {
                        label: 'All',
                        value: 'All',
                      },
                    ]}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => {
                      setLimit(e.value)
                    }}
                    value={limit}
                  />
                </div>
              </div>
            </div>
          }
        >
          <Column style={{ width: '0%' }} selectionMode="multiple" />
          <Column style={{ width: '0%' }} header="ID" field="id" sortable />
          <Column
            style={{ width: '3%' }}
            header="Name"
            field="fullName"
            tooltip="Click to view details"
            body={(rd) => (
              <div className="p-d-flex p-ai-center gap-1by2 p-jc-start">
                <p className="p-mb-0">{rd.fullName}</p>
                {rd.isEmailVerified ? <MdVerified size={'20px'} color="#1DA1F2" /> : ''}
              </div>
            )}
          />

          <Column
            style={{ width: '0%' }}
            header="Email"
            field="email"
            body={(rd) => (
              <HideDetails
                value={rd.email}
                style={{ background: 'none', border: 0 }}
                type={'input'}
              />
            )}
          />
          {/* <Column
            style={{ width: '1%' }}
            header="Email Verified"
            field="isEmailVerified"
            body={(rd) => (rd.isEmailVerified ? 'Yes' : 'No')}
            sortable
          /> */}
          <Column header="AgentId" field="assignedUserId" hidden />
          <Column style={{ width: '1%' }} header="User" field="assignedUsername" sortable />
          <Column
            style={{ width: '1%' }}
            header="Last Order"
            field="lastOrderId"
            body={(rd) =>
              rd.lastOrderId ? (
                <Link to={`/orders?invId=${rd.lastOrderId}`} target="_blank">
                  {rd.lastOrderId}
                </Link>
              ) : (
                'N/A'
              )
            }
          />
          <Column
            style={{ width: '1%' }}
            header="Next Refill"
            field="lastOrderRefillDate"
            body={(rd) =>
              rd.lastOrderRefillDate ? format(new Date(rd.lastOrderRefillDate), 'P') : 'N/A'
            }
          />
          <Column
            style={{ width: '1%' }}
            header="Avg Order"
            field="avgOrderValue"
            body={(rd) => '$' + rd.avgOrderValue}
            sortable
          />
          <Column style={{ width: '1%' }} header="T. Orders" field="totalOrders" sortable />
          <Column
            style={{ width: '1%' }}
            header="T. Purchase"
            field="totalPurchase"
            body={(rd) => '$' + rd.totalPurchase}
            sortable
          />
          <Column
            style={{ width: '1%' }}
            header="T. Discount"
            field="totalDiscount"
            body={(rd) => '$' + rd.totalDiscount}
            sortable
          />
          <Column
            style={{ width: '1%' }}
            header="Action"
            body={(rd) => (
              <div className="p-d-flex gap-1by2 p-jc-start">
                <Link to={`?id=${rd?.id}`}>
                  <Button
                    icon="pi pi-eye"
                    tooltip="Open Record"
                    tooltipOptions={{
                      position: 'bottom',
                    }}
                  />
                </Link>
                <Link to={`/customers?history=${rd.id}`}>
                  <Button
                    tooltip="History"
                    tooltipOptions={{ position: 'bottom' }}
                    icon="pi pi-clock"
                    className="p-button p-button-help"
                  />
                </Link>
              </div>
            )}
          />
        </DataTable>
        <Dialog
          autoFocus
          style={{ width: '1200px' }}
          header={`Customer | ${record.fullName} | ${record.id}`}
          modal
          className=""
          visible={recordVisible}
          footer={
            <>
              <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                disabled={record.loading}
                onClick={() => hideRecord()}
              />
            </>
          }
          onHide={() => hideRecord()}
        >
          <TabView>
            <TabPanel header="Customer Details">
              <DataTable
                value={[
                  {
                    key: 'Id',
                    value: record?.id,
                  },
                  {
                    key: 'Name',
                    value: record?.fullName,
                  },
                  {
                    key: 'Email',
                    value: <HideDetails value={record?.email} type="text" />,
                  },
                  {
                    key: 'Email Verified',
                    value: record?.isEmailVerified ? 'Yes' : 'No',
                  },
                  {
                    key: 'Last Paid Order',
                    value: record?.lastPaidOrder?.orderid ? (
                      <Link to={`/orders?invId=${record?.lastPaidOrder?.orderid}`} target="_blank">
                        {record?.lastPaidOrder?.orderid}
                      </Link>
                    ) : (
                      'N/A'
                    ),
                  },
                  {
                    key: 'Next Refill',
                    value: record?.lastPaidOrder?.refillDate
                      ? format(new Date(record?.lastPaidOrder?.refillDate), 'Pp')
                      : 'N/A',
                  },
                  {
                    key: 'Assigned Agent',
                    value: (
                      <div className="p-d-flex gap-1 p-ai-center">
                        <Dropdown
                          className="min-w-15"
                          options={agents.filter((a) => ['agent', 'teamlead'].includes(a.role))}
                          optionLabel="username"
                          optionValue="id"
                          disabled={!['superadmin', 'admin', 'manager'].includes(user.role)}
                          placeholder="Select Agent"
                          value={record?.newAssignedUser}
                          onChange={(e) => {
                            setRecord((ps) => ({
                              ...ps,
                              newAssignedUser: e.value,
                            }))
                          }}
                        />
                        <Button
                          label="Save"
                          icon={'pi pi-save'}
                          disabled={record.assignedUser === record.newAssignedUser}
                          loading={record.loading}
                          onClick={updateRecord}
                        />
                      </div>
                    ),
                  },
                  {
                    key: ' Paid Total Orders',
                    value: record?.paidOrderStats?.totalOrders + ' Orders',
                  },
                  {
                    key: 'Paid Total Purchase',
                    value: moneyFormatter(record?.paidOrderStats?.totalPurchase, '$'),
                  },
                  {
                    key: ' Paid Avg Order Value',
                    value: moneyFormatter(record?.paidOrderStats?.avgOrderValue, '$'),
                  },
                  {
                    key: 'Paid Total Discount',
                    value: moneyFormatter(record?.paidOrderStats?.totalDiscount, '$'),
                  },
                ]}
              >
                <Column field="key" header="Key"></Column>
                <Column field="value" header="Value" body={(rd) => rd.value}></Column>
              </DataTable>
            </TabPanel>
            <TabPanel header="Paid Order History">
              <OrderTable value={record?.paidOrders} />
            </TabPanel>
            <TabPanel header="Unpaid Order History">
              <OrderTable value={record?.unpaidOrders} />
            </TabPanel>
            <TabPanel header="Wallet Details">
              <div className="p-d-flex p-flex-column gap-2">
                <DataTable
                  value={walletLogs}
                  dataKey="id"
                  showGridlines={true}
                  removableSort={true}
                  filters={walletFilters}
                  className="datatable-responsive"
                  loading={tableLoading}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 25, 50]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                  header={
                    <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                      <Button
                        onClick={() => {
                          setAddCreditRecord({
                            ...addCreditRecord,
                            visible: true,
                            customerId: record.id,
                          })
                          fetchRedeemableCoupons()
                        }}
                      >
                        Add Credit
                      </Button>
                      <div className=" p-d-flex p-ai-center">
                        <Dropdown
                          placeholder="Transaction Type"
                          options={[
                            {
                              label: 'Credit',
                              value: 'credit',
                            },
                            {
                              label: 'Debit',
                              value: 'debit',
                            },
                          ]}
                          optionLabel="label"
                          optionValue="value"
                          value={walletFilters.transactionType.value}
                          onChange={(e) => {
                            onChangeFilter(setWalletFilters, e.target.value, 'transactionType')
                          }}
                          showClear
                        />
                      </div>
                    </div>
                  }
                >
                  <Column
                    field="id"
                    header="ID"
                    body={(rd, idx) => idx.rowIndex + 1}
                    sortable={true}
                  />
                  <Column
                    field="amount"
                    header="Amount"
                    body={(rd) => moneyFormatter(rd.amount, '$')}
                  />
                  <Column
                    field="transactionType"
                    header="Type"
                    body={(rd) => (rd.transactionType === 'credit' ? 'Credit' : 'Debit')}
                  />
                  <Column field="refName" header="Statement" />
                  <Column
                    field="createdAt"
                    header="Date"
                    body={(rd) => format(new Date(rd.createdAt), 'PPp')}
                  />
                </DataTable>
                <div className="p-d-flex p-flex-column">
                  <div className=" p-d-flex p-ai-center p-jc-between">
                    <h5 className="p-m-0">Total Credit </h5>
                    <h5 className="p-m-0">${walletDetails.totalCredit}</h5>
                  </div>
                  <br />
                  <div className=" p-d-flex p-ai-center p-jc-between">
                    <h5 className="p-m-0">Total Debit </h5>
                    <h5 className="p-m-0">-${walletDetails.totalDebit}</h5>
                  </div>
                  <Divider />
                  <div className=" p-d-flex p-ai-center p-jc-between">
                    <h5 className="p-m-0">Balance </h5>
                    <h5 className="p-m-0">${walletDetails.balance}</h5>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </Dialog>
        <Dialog
          autoFocus
          header={`Add Credit For ${record.fullName}`}
          modal
          className=""
          visible={addCreditRecord.visible}
          footer={
            <>
              <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                disabled={addCreditRecord.loading}
                onClick={() => hideAddCreditRecord()}
              />
              <Button
                label="Add"
                icon="pi pi-plus"
                className=""
                disabled={addCreditRecord.loading}
                loading={addCreditRecord.loading}
                onClick={() => handleRedeemCoupon()}
              />
            </>
          }
          onHide={() => hideAddCreditRecord()}
          style={{ width: '450px' }}
        >
          <div className="p-d-flex p-dir-col gap-2">
            <div className=" p-d-flex p-ai-center w-full">
              <Dropdown
                placeholder="Select Coupon"
                options={redeemableCoupons.map((couponDetail) => ({
                  ...couponDetail,
                  label: `${couponDetail.name} - $${couponDetail.amount}`,
                }))}
                optionLabel="label"
                optionValue="couponId"
                className="w-full"
                value={addCreditRecord?.refId}
                onChange={(e) => {
                  const record = redeemableCoupons.find(
                    (coupon) => coupon.couponId === e.target.value
                  )
                  setAddCreditRecord((ps) => ({
                    ...ps,
                    refId: record.couponId,
                    refName: 'Credit Added By Crm User',
                    amount: record.amount,
                    walletCouponCode: record.code,
                    codeId: record.codeId,
                  }))
                }}
              />
            </div>
            {addCreditRecord.refId && (
              <div className="w-full text-center">
                <p>The code below will be redeemed:</p>
                <div className="p-d-flex p-ai-center p-jc-center">
                  <InputText
                    readOnly={true}
                    placeholder="Coupon Code"
                    value={addCreditRecord?.walletCouponCode}
                  />
                </div>
              </div>
            )}
          </div>
        </Dialog>
        <ChangeHistorySidebar
          setTableLoading={setTableLoading}
          header="Customer Change History"
          historyType="customer"
        />
      </div>
    </PageAllowedToRoles>
  )
}

const OrderTable = ({ value }) => {
  return (
    <DataTable
      value={value}
      rowHover
      breakpoint="1000px"
      responsiveLayout="stack"
      removableSort
      dataKey="orderid"
      showGridlines
      paginator
      rows={10}
      rowsPerPageOptions={[10, 25, 50]}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
      className="datatable-responsive"
      columnResizeMode="fit"
      emptyMessage="No orders found."
    >
      <Column
        field="orderid"
        header="Order ID"
        body={(rd) => (
          <Link to={`/orders?invId=${rd?.orderid}`} target="_blank">
            {rd?.orderid}
          </Link>
        )}
      />
      <Column field="orderStatus" header="Status" />
      <Column field="total" header="Total" body={(rd) => moneyFormatter(rd?.total, '$')} />
      <Column field="source" header="Source" />
      <Column field="shippingCompany" header="Ship" />
      <Column
        field="orderDate"
        header="Order Date"
        body={(rd) => format(new Date(rd.orderDate), 'Pp')}
      />
    </DataTable>
  )
}
