import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useQuery from '../../../../hooks/useQuery'
import {
  deleteAwsS3Files,
  getPaymentPartnerById,
  postPaymentPartner,
  putPaymentPartner,
  uploadFileToS3,
} from '../../../../api'
import { InputText } from 'primereact/inputtext'
import { handleObjChange } from '../../../../functions/setter'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { nanoid } from 'nanoid'
import { useGlobalContext } from '../../../../context'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { data } from 'autoprefixer'
import { format } from 'date-fns'
import { Checkbox } from 'primereact/checkbox'
import { findArrayChanges } from '../../../../functions/seprateOldNewFiles'
import { Message } from 'primereact/message'
import UploadImage from '../../../mini/UploadImage'
import ReactQuill from 'react-quill'
import MyDatesPicker from '../../../mini/MyDatesPicker'

const empty_ImageState = {
  url: '',
  file: null,
}

const emptyErr = {
  state: false,
  errors: [],
}

const emptyPaymentPartner = {
  name: '',
  phone: '',
  email: '',
  address: '',
  photo: '',
  active: 0,
  dob: '',
  ssn: '',
  documents: [],
  notes: [],
  additionalDetails: '',
}
export default function AddPaymentPartnerDialog({
  setActiveIndex,
  setPaymentPartnerTableLoading,
  setPaymentPartners,
  addPartnerDialog,
  setAddPartnerDialog,
}) {
  const { toast, user } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [paymentPartner, setPaymentPartner] = useState(emptyPaymentPartner)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [attachments, setAttachments] = useState([])
  const documentRef = useRef(null)
  const [photo, setPhoto] = useState(empty_ImageState)
  // handle file upload function
  const handleFilesUpload = (e, setter) => {
    if (!e.target.files.length || typeof setter !== 'function') {
      return
    }
    const files = e.target.files
    const myFiles = []
    let largeFiles = 0
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (file.size < 1024 * 1024 * 5) {
        const path = URL.createObjectURL(file)
        myFiles.push({
          uid: nanoid(),
          name: file.name,
          file,
          path,
        })
      } else {
        largeFiles++
      }
    }
    if (largeFiles) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: `${largeFiles} files are larger than 5 MB and will not be uploaded`,
      })
    }
    setter((ps) => [...ps, ...myFiles])
  }

  // remove attachments files function
  const removeFiles = (uid, state, setter) => {
    if (typeof setter !== 'function') return
    let filValue = state.filter((ele) => ele.uid !== uid)
    setter(filValue)
  }

  const validatePaymentPartner = (paymentPartner) => {
    const err = []
    if (paymentPartner?.name === '') err.push('Name cannot be empty')
    if (paymentPartner?.phone === '') err.push('Phone cannot be empty')
    if (paymentPartner?.email === '') err.push('Email cannot be empty')
    if (paymentPartner?.address === '') err.push('Address cannot be empty')
    if (paymentPartner?.ssn === '') err.push('SSN cannot be empty')
    if (paymentPartner?.dob === '') err.push('Date of Birth cannot be empty')
    return err
  }

  const addPaymentPartner = async () => {
    const errs = validatePaymentPartner(paymentPartner)
    if (errs.length > 0) {
      setNewRecordError({ state: true, errors: errs })
      return
    }

    setLoading(true)
    try {
      let arrayOfFiles = []
      if (attachments.length) {
        await Promise.all(
          attachments.map(async (attachment, index) => {
            const res = await uploadFileToS3({ file: attachment.file })
            // save
            arrayOfFiles.push({
              uid: attachment.uid,
              name: attachment.name,
              path: res.data.filePath,
              // New additions based on orders reference
              createdAt: new Date().toISOString(),
              meta: {
                createdBy: user?.username,
              },
            })
          })
        )
      }

      // Upload photo if exists
      if (photo?.file) {
        const res = await uploadFileToS3({ file: photo.file })
        paymentPartner.photo = res.data.filePath
      }

      // Send data to backend
      const res = await postPaymentPartner(paymentPartner, arrayOfFiles)

      if (res?.status === 201) {
        setPaymentPartners((ps) => [res.data.record, ...ps])
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
          life: 3000,
        })
        onHide()
      }
    } catch (error) {
      console.error('Error adding payment partner:', error)
    } finally {
      setPaymentPartnerTableLoading(false)
      setLoading(false)
    }
  }

  const onHide = () => {
    setAddPartnerDialog(false)
    setAttachments([])
    setPhoto(empty_ImageState)
    setPaymentPartner(emptyPaymentPartner)
    setNewRecordError(emptyErr)
  }

  const footer = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-secondary"
        />
        <Button
          label="Submit"
          icon="pi pi-save"
          className="p-button-primary"
          onClick={addPaymentPartner}
          loading={loading}
        />
      </div>
    )
  }

  return (
    <Dialog
      header="Add Payment Partner"
      visible={addPartnerDialog}
      style={{ width: '50vw' }}
      footer={footer}
      onHide={onHide}
    >
      <div className="p-grid p-formgrid">
        <div className="p-field p-fluid p-col-12 p-xl-6">
          <div className="p-fluid p-field p-col-12">
            <label htmlFor="" className="p-col-fixed">
              Photo
            </label>
            <UploadImage imageState={photo} setImageState={setPhoto} />
          </div>
        </div>
        <div className="p-field p-fluid p-col-12 p-xl-6">
          <div className="p-field p-fluid">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              placeholder="Enter Name"
              value={paymentPartner.name}
              onChange={(e) => {
                handleObjChange(setPaymentPartner, 'name', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid">
            <label htmlFor="phone">Phone</label>
            <InputText
              id="phone"
              type="tel"
              placeholder="Enter Phone"
              value={paymentPartner.phone}
              onChange={(e) => {
                handleObjChange(setPaymentPartner, 'phone', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              placeholder="Enter Email"
              value={paymentPartner.email}
              onChange={(e) => {
                handleObjChange(setPaymentPartner, 'email', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid">
            <label htmlFor="ssn">SSN</label>
            <InputText
              id="ssn"
              placeholder="Enter SSN"
              value={paymentPartner.ssn}
              onChange={(e) => handleObjChange(setPaymentPartner, 'ssn', e.target.value)}
            />
          </div>
        </div>
        <div className="p-field p-fluid p-col-12 p-xl-6">
          <label htmlFor="dateOfBirth">Date of Birth</label>
          <Calendar
            id="dateOfBirth"
            value={paymentPartner.dob}
            placeholder="Enter Date of Birth (dd/mm/yyyy)"
            onChange={(e) => handleObjChange(setPaymentPartner, 'dob', e.value)}
            dateFormat="dd/mm/yy"
          />
        </div>
        <div className="p-field p-fluid p-col-12 p-xl-6">
          <label htmlFor="address">Address</label>
          <InputTextarea
            id="address"
            placeholder="Enter Address"
            value={paymentPartner.address}
            onChange={(e) => handleObjChange(setPaymentPartner, 'address', e.target.value)}
          />
        </div>
        <div className="p-fluid p-field p-col-12">
          <label htmlFor="attachments">Documents</label>
          <div className="p-mt-3">
            {attachments?.map((item, idx) => {
              let { name, path } = item
              if (name.length > 40) {
                name = name.substring(0, 15) + '...' + name.substring(name.length - 20)
              }
              return (
                <div key={idx} className="relative p-grid p-formgrid">
                  <div className=" p-d-flex p-jc-center p-field p-col-10 p-xl-10">
                    <a
                      className={`p-button p-button-outlined p-button-secondary w-100`}
                      key={name}
                      href={path}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {name}
                    </a>
                  </div>
                  <div className=" p-d-flex p-jc-end p-field p-col-2 p-xl-2">
                    <Button
                      className="p-button-danger"
                      style={{ width: '100%' }}
                      onClick={() => removeFiles(item.uid, attachments, setAttachments)}
                      icon="pi pi-trash"
                    />
                  </div>
                  <span
                    className={`${item.new ? 'blob bottom-15-px left-0-px p-mx-0' : ''}`}
                  ></span>
                </div>
              )
            })}
          </div>
          <input
            type="file"
            className="p-hidden"
            accept="*"
            ref={documentRef}
            multiple
            onChange={(e) => {
              handleFilesUpload(e, setAttachments)
            }}
          />
          <Button
            icon="pi pi-plus"
            label="Upload New"
            className="p-button-info"
            onClick={() => {
              documentRef.current.click()
            }}
          />
        </div>
        <div className="p-field p-fluid p-col-12">
          <label htmlFor="additionalDetails">Additional Details</label>
          <ReactQuill
            id="additionalDetails"
            value={paymentPartner.additionalDetails}
            onChange={(e) => handleObjChange(setPaymentPartner, 'additionalDetails', e)}
          />
        </div>
        <div className="p-field p-fluid p-col-12">
          <label
            className="p-d-none p-d-lg-inline"
            htmlFor="active"
            style={{ color: 'transparent' }}
          >
            Active
          </label>
          <div className="p-d-flex p-ai-center">
            <label className="p-d-block p-mr-2" htmlFor="active">
              Active :
            </label>
            <Checkbox
              id="active"
              checked={paymentPartner.active}
              onChange={(e) => handleObjChange(setPaymentPartner, 'active', e.checked)}
            />
          </div>
        </div>
      </div>
      {newRecordError.state && (
        <>
          {newRecordError.errors.map((err, idx) => {
            return (
              <div key={idx} className="p-fluid p-filed p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            )
          })}
          <div className="p-fluid p-field p-col-12">
            <Button
              type="button"
              onClick={() => {
                setNewRecordError(emptyErr)
              }}
              icon="pi pi-times"
              label="Clear Warnings"
              className="p-button-secondary"
            ></Button>
          </div>
        </>
      )}
    </Dialog>
  )
}
