import axios from 'axios'
import endpoints from './endpoints'

export const getTest = async (params) => {
  try {
    return await axios.get(endpoints.test.index, { params })
  } catch (error) {
    return error.response
  }
}

export const getState = async () => {
  try {
    return await axios.get(endpoints.test.getState)
  } catch (error) {
    return error.response
  }
}
