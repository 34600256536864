import { InputText } from 'primereact/inputtext'
import React, { useCallback, useEffect, useState } from 'react'
import { handleObjChange } from '../../functions/setter'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Column } from 'primereact/column'
import { format } from 'date-fns'
import { useGlobalContext } from '../../context'
import {
  deletePaymentPartnerMethodQuota,
  getPaymentPartnerMethodById,
  getPaymentPartnerMethodQuotas,
  getPaymentPartnerMethods,
  postPaymentPartnerMethodQuota,
} from '../../api'
import { Message } from 'primereact/message'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Toast } from 'primereact/toast'
import useQuery from '../../hooks/useQuery'
import EditPaymentPartnerMethodQuotaDialog from '../../components/mycomponents/PaymentManagement/PaymentPartnerMethodQuota/EditPaymentPartnerMethodQuotaDialog'
import moneyFormatter from '../../functions/moneyFormatter'
import AddPaymentPartnerMethodQuotaDialog from '../../components/mycomponents/PaymentManagement/PaymentPartnerMethodQuota/AddPaymentPartnerMethodQuotaDialog'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import { Chip } from 'primereact/chip'

export default function PaymentPartnerMethodQuotaPage() {
  const { toast, user } = useGlobalContext()
  const [paymentPartnerMethodQuotas, setPaymentPartnerMethodQuotas] = useState([])
  const [addPaymentPartnerMethodQuotasDialog, setAddPaymentPartnerMethodQuotasDialog] =
    useState(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [paymentPartnerMethods, setPaymentPartnerMethods] = useState([])

  const fetchPaymentPartnerMethodUsers = async (id, setter) => {
    const res = await getPaymentPartnerMethodById(id)
    if (res) {
      if (res.status === 200) {
        let users = res.data?.users?.map((u) => parseInt(u))
        setter((prev) => ({ ...prev, users }))
      }
    }
  }

  const fetchPaymentPartnerMethods = useCallback(async () => {
    const res = await getPaymentPartnerMethods()
    if (res) {
      if (res.status === 200) {
        setPaymentPartnerMethods(res.data)
      }
    }
  }, [])

  const fetchPaymentPartnerMethodQuotas = useCallback(async () => {
    setTableLoading(true)
    const res = await getPaymentPartnerMethodQuotas()
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setPaymentPartnerMethodQuotas(res.data)
      }
    }
  }, [])

  useEffect(() => {
    fetchPaymentPartnerMethodQuotas()
    fetchPaymentPartnerMethods()
  }, [fetchPaymentPartnerMethodQuotas, fetchPaymentPartnerMethods])

  const deletePaymentPartnerMethodQuotaFn = async (id) => {
    if (!id) return
    setTableLoading(true)
    const res = await deletePaymentPartnerMethodQuota(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setPaymentPartnerMethodQuotas((ps) => ps.filter((p) => p.id !== id))
      }
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-toolbar p-mb-3 p-flex-wrap gap-1mb-4"
          left={
            <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
              <h4 className="p-m-0">Payment Partner Method Quotas</h4>
              {['superadmin', 'admin'].includes(user?.role) && (
                <Button
                  label="Add"
                  icon="pi pi-plus"
                  className="p-button-primary"
                  onClick={() => {
                    setAddPaymentPartnerMethodQuotasDialog(true)
                    fetchPaymentPartnerMethods()
                  }}
                />
              )}
            </div>
          }
          right={
            <div>
              <div className="p-d-flex p-flex-wrap gap-1">
                <Button
                  label="Refresh"
                  icon="pi pi-refresh"
                  onClick={() => {
                    fetchPaymentPartnerMethodQuotas()
                  }}
                />
              </div>
            </div>
          }
        />
        <DataTable
          loading={tableLoading}
          value={paymentPartnerMethodQuotas}
          removableSort
          showGridlines
          // filters={paymentMethodTableFilter}
          // header={paymentMethodsDataTableHeader}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
        >
          <Column field="id" header="ID" sortable />
          <Column field="name" header="Name" sortable />
          <Column field="paymentPartnerMethodName" header="Payment Partner Method" />
          <Column
            field="startDate"
            header="Start Date"
            body={(rd) => format(new Date(rd.startDate), 'PPp')}
          />
          <Column
            field="endDate"
            header="End Date"
            body={(rd) => format(new Date(rd.endDate), 'PPp')}
          />
          <Column
            field="quotaAmount"
            header="Quota Amount"
            body={(rd) => {
              return moneyFormatter(rd.quotaAmount, '$')
            }}
          />
          <Column
            field="totalUsed"
            header="Total Used"
            body={(rd) => {
              return moneyFormatter(rd.totalUsed, '$')
            }}
          />
          <Column
            field="remainingBalance"
            header="Remaining Balance"
            body={(rd) => {
              return moneyFormatter(rd.remainingBalance, '$')
            }}
          />
          <Column
            field="showOnEcom"
            header="Show On Website"
            body={(rd) => {
              return rd.showOnEcom ? 'Yes' : 'No'
            }}
          />
          <Column
            field="showOnCrm"
            header="Show On CRM"
            body={(rd) => {
              return rd.showOnCrm ? 'Yes' : 'No'
            }}
          />
          <Column
            header="Quota Status"
            body={(rd) => {
              return (
                <Chip
                  label={rd.quotaStatus}
                  className={`${
                    rd.quotaStatus === 'Ongoing'
                      ? 'tm-status-Achieved'
                      : rd.quotaStatus === 'Upcoming'
                      ? 'tm-status-Expired'
                      : 'tm-status-Unachieved'
                  } p-text-bold bg-opacity-50`}
                />
              )
            }}
          />
          <Column
            field="active"
            header="status"
            body={(rd) => {
              return rd.active ? 'Active' : 'Inactive'
            }}
          />
          {['superadmin', 'admin'].includes(user?.role) && (
            <Column
              field="Action"
              header="Action"
              style={{
                width: '1%',
              }}
              body={(rd) => {
                return (
                  <div className="p-d-flex gap-1by2 p-jc-center">
                    <Link to={`?quota_id=${rd?.id}`}>
                      <Button
                        className="p-button p-button-info"
                        icon="pi pi-pencil"
                        tooltip="Edit"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    <Button
                      icon="pi pi-trash"
                      className="p-button p-button-danger"
                      tooltip="Delete"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                      onClick={() => {
                        deletePaymentPartnerMethodQuotaFn(rd.id)
                      }}
                    />
                    <Link to={`?history=${rd.id}`}>
                      <Button
                        tooltip="History"
                        tooltipOptions={{ position: 'bottom' }}
                        icon="pi pi-clock"
                        className="p-button p-button-help"
                      />
                    </Link>
                  </div>
                )
              }}
            />
          )}
        </DataTable>
        <AddPaymentPartnerMethodQuotaDialog
          addPaymentPartnerMethodQuotasDialog={addPaymentPartnerMethodQuotasDialog}
          setAddPaymentPartnerMethodQuotasDialog={setAddPaymentPartnerMethodQuotasDialog}
          paymentPartnerMethods={paymentPartnerMethods}
          setPaymentPartnerMethodQuotas={setPaymentPartnerMethodQuotas}
          setTableLoading={setTableLoading}
          fetchPaymentPartnerMethodUsers={fetchPaymentPartnerMethodUsers}
        />
        {/* Edit Payment Partner Method Quota  */}
        <EditPaymentPartnerMethodQuotaDialog
          setTableLoading={setTableLoading}
          fetchPaymentPartnerMethodUsers={fetchPaymentPartnerMethodUsers}
          paymentPartnerMethods={paymentPartnerMethods}
          setPaymentPartnerMethodQuotas={setPaymentPartnerMethodQuotas}
        />
        <ChangeHistorySidebar
          header="Payment Partner Method Quota Change History"
          historyType="im_paymentPartnerMethodQuota"
        />
      </div>
    </>
  )
}
