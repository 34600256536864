import { format } from 'date-fns'
import { Column } from 'jspdf-autotable'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Password } from 'primereact/password'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { deleteWebsiteBanner, getWebsiteBanners } from '../../api'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import AddBannerDialog from '../../components/mycomponents/Banner/AddBannerDialog'
import EditBannerDialog from '../../components/mycomponents/Banner/EditBannerDialog'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import ConfirmationDialogWithInput from '../../components/mycomponents/Dialog/ConfirmationDialogWithInput'
import { useGlobalContext } from '../../context'
import generateS3Path from '../../functions/generateS3Path'

const statusOptions = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 0,
  },
]

export default function BannersPage() {
  const { toast } = useGlobalContext()
  const [newBannerDialogVisible, setNewBannerDialogVisible] = useState(false)
  const [bannerRecords, setBannerRecords] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [bannerConfirmationDialogVisible, setBannerConfirmationDialogVisible] = useState(false)
  const [deleteBannerId, setDeleteBannerId] = useState(null)

  // fetch all banners methods
  const fetchWebsiteBanners = useCallback(async () => {
    setTableLoading(true)
    const res = await getWebsiteBanners()
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setBannerRecords(res.data)
      }
    }
  }, [])
  useEffect(() => fetchWebsiteBanners(), [fetchWebsiteBanners])

  const handleDeleteWebsiteBanner = async () => {
    if (!deleteBannerId) return
    setLoading(true)
    setBannerConfirmationDialogVisible(true)
    const res = await deleteWebsiteBanner(deleteBannerId, password)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
          life: 3000,
        })
        let temp = [...bannerRecords]
        const updatedData = temp.filter((banner) => banner.id !== deleteBannerId)
        setBannerRecords(updatedData)
        onConfirmDialogHide()
      }
    }
  }

  const onConfirmDialogHide = () => {
    setBannerConfirmationDialogVisible(false)
    setPassword('')
  }

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-toolbar p-mb-3 p-flex-wrap gap-1mb-4"
          left={
            <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
              <h4 className="p-m-0">Banners</h4>
              <Button
                label="Add Banner"
                icon="pi pi-plus"
                className="p-button-primary"
                onClick={() => setNewBannerDialogVisible(true)}
              />
            </div>
          }
          right={
            <div>
              <div className="p-d-flex p-flex-wrap gap-1">
                <Button
                  label="Refresh"
                  icon="pi pi-refresh"
                  onClick={() => {
                    fetchWebsiteBanners()
                  }}
                />
              </div>
            </div>
          }
        />
        <DataTable
          removableSort
          value={bannerRecords}
          showGridlines
          // filters={paymentMethodTableFilter}
          // header={paymentMethodsDataTableHeader}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          loading={tableLoading}
        >
          <Column
            style={{
              width: '1%',
            }}
            field="id"
            header="Id"
            sortable
          />
          <Column
            style={{
              width: '2%',
            }}
            field="appImage"
            header="Icon Image"
            body={(rd) => {
              return (
                <div className="p-d-flex p-jc-center p-ai-center">
                  <img
                    src={generateS3Path(rd.bannerImage)}
                    alt={rd.bannerName}
                    className="p-mr-2"
                    style={{
                      width: '40px',
                    }}
                  />
                </div>
              )
            }}
          />
          <Column
            style={{
              width: '10%',
            }}
            field="bannerName"
            header="Name"
            sortable
          />
          <Column
            style={{
              width: '10%',
            }}
            field="createdBy"
            header="Created By"
            sortable
          />
          <Column
            style={{
              width: '10%',
            }}
            field="createdAt"
            header="Created At"
            body={(rd) => format(new Date(rd.createdAt), 'PPp')}
            sortable
          />
          <Column
            style={{
              width: '2%',
              textAlign: 'center',
            }}
            field="status"
            header="Status"
            sortable
            body={(rd) => <div>{rd.active ? 'Active' : 'Inactive'}</div>}
          />
          <Column
            field="Action"
            header="Action"
            style={{
              width: '1%',
            }}
            body={(rd) => {
              return (
                <div className="p-d-flex gap-1by2 p-jc-center">
                  <Link to={`?id=${rd?.id}`}>
                    <Button
                      className="p-button p-button-info"
                      icon="pi pi-pencil"
                      tooltip="Edit"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                    />
                  </Link>
                  <Button
                    icon="pi pi-trash"
                    className="p-button p-button-danger"
                    tooltip="Delete"
                    tooltipOptions={{
                      position: 'bottom',
                    }}
                    onClick={() => {
                      setDeleteBannerId(rd.id)
                      setBannerConfirmationDialogVisible(true)
                    }}
                  />
                  <Link to={`?history=${rd.id}`}>
                    <Button
                      tooltip="History"
                      tooltipOptions={{ position: 'bottom' }}
                      icon="pi pi-clock"
                      className="p-button p-button-help"
                    />
                  </Link>
                </div>
              )
            }}
          />
        </DataTable>
        <AddBannerDialog
          newBannerDialogVisible={newBannerDialogVisible}
          setNewBannerDialogVisible={setNewBannerDialogVisible}
          setBannerRecords={setBannerRecords}
          setTableLoading={setTableLoading}
          statusOptions={statusOptions}
        />
        <EditBannerDialog
          bannerRecords={bannerRecords}
          setBannerRecords={setBannerRecords}
          setTableLoading={setTableLoading}
          statusOptions={statusOptions}
        />
        {/* Confirmation dialog to ask password start*/}
        <ConfirmationDialogWithInput
          onHide={onConfirmDialogHide}
          header={'Confirm password to delete'}
          onSubmit={handleDeleteWebsiteBanner}
          visible={bannerConfirmationDialogVisible}
          inputLabel={'Password'}
          customInputs={true}
          loading={loading}
        >
          <div className="">
            <label htmlFor="password" className="">
              Password
            </label>
            <Password
              autoComplete="nope"
              toggleMask
              feedback={false}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-mt-1"
              placeholder="Enter your password"
            />
          </div>
        </ConfirmationDialogWithInput>
        {/* Confirmation dialog to ask password end*/}
        <ChangeHistorySidebar header="Banner History" historyType="wm_websiteBanner" />
      </div>
    </PageAllowedToRoles>
  )
}
