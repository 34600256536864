import axios from 'axios'
import endpoints from './endpoints'

export const getOrderRequests = async (params) => {
  try {
    return await axios.get(endpoints.order.request, { params })
  } catch (error) {
    return error.response
  }
}

export const orderRequestReviewed = async (
  id,
  paymentPartnerMethodId,
  gatewayName,
  paymentPartner,
  gatewayCustomerName,
  status,
  gatewayAmountReceived,
  gatewayAmountPaid,
  additionalNote,
  gatewayProcessingFee,
  note
) => {
  try {
    return await axios.patch(endpoints.order.request + id, {
      paymentPartnerMethodId,
      gatewayName,
      paymentPartner,
      gatewayCustomerName,
      status,
      gatewayAmountReceived,
      gatewayAmountPaid,
      additionalNote,
      gatewayProcessingFee,
      note,
    })
  } catch (error) {
    return error.response
  }
}
