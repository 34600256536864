import classNames from 'classnames'
import { startOfMonth } from 'date-fns'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { MultiSelect } from 'primereact/multiselect'
import React, { useCallback, useEffect, useState } from 'react'
import { getConversionRateRankings, getSalesRankings } from '../../../api'
import { useGlobalContext } from '../../../context'
import moneyFormatter from '../../../functions/moneyFormatter'
import { dateFilters } from '../../../functions/myDateFns'
import aceCardImage from '../../../images/aceCard.svg'
import jokerCardImage from '../../../images/jokerCard.svg'
import kingCardImage from '../../../images/kingCard.svg'
import MyDatesPicker from '../../mini/MyDatesPicker'
const date = new Date()
export default function LeaderboardDashboard() {
  const { allStatusGroups: ctx_allStatusGroups } = useGlobalContext()
  const [lbDateCodeCommon, setLbDateCodeCommon] = useState(dateFilters[2].code)
  const [lbRangeDatesCommon, setLbRangeDatesCommon] = useState(null)
  const [lbFilterDatesCommon, setLbFilterDatesCommon] = useState({
    dateTo: date,
    dateFrom: startOfMonth(date),
  })
  const [lbShowCustom, setLbShowCustom] = useState(false)
  const [lbDateCodeCustom, setLbDateCodeCustom] = useState(null)
  const [lbRangeDatesCustom, setLbRangeDatesCustom] = useState(null)
  const [lbFilterDatesCustom, setLbFilterDatesCustom] = useState({
    dateTo: null,
    dateFrom: null,
  })
  const [lbCustomStatus, setLbCustomStatus] = useState([])
  const [rankingCustom, setRankingCustom] = useState([])
  const [lbSortCustom, setLbSortCustom] = useState(false)
  // Leaderboard
  const leaderBoardSalesDataSetter = async ({
    dateFrom,
    dateTo,
    status,
    setter,
    sort,
    rankSort,
  }) => {
    const res = await getSalesRankings({
      dateFrom,
      dateTo,
      status,
      sort,
      rankSort,
    })
    if (res && res.status === 200) {
      setter(res.data || [])
    }
  }

  const fetchCustomSalesRanking = useCallback(async () => {
    if (
      !lbCustomStatus ||
      lbCustomStatus.length === 0 ||
      !lbFilterDatesCustom ||
      !lbFilterDatesCustom.dateFrom ||
      !lbFilterDatesCustom.dateTo
    )
      return
    await leaderBoardSalesDataSetter({
      ...lbFilterDatesCustom,
      status: lbCustomStatus.join(','),
      setter: setRankingCustom,
      sort: lbSortCustom ? 'ASC' : 'DESC',
      rankSort: 'DESC',
    })
  }, [lbFilterDatesCustom, lbCustomStatus, lbSortCustom])
  useEffect(() => fetchCustomSalesRanking(), [fetchCustomSalesRanking])
  //  Unpaid Sales Leaderboard
  const [rankingUnpaid, setRankingUnpaid] = useState([])
  const [lbSortUnpaid, setLbSortUnpaid] = useState(true)
  const fetchSalesRankingUnpaid = useCallback(async () => {
    if (!lbFilterDatesCommon || !lbFilterDatesCommon.dateFrom || !lbFilterDatesCommon.dateTo) return
    await leaderBoardSalesDataSetter({
      ...lbFilterDatesCommon,
      status: 'Draft,Pending,Cancelled',
      setter: setRankingUnpaid,
      sort: lbSortUnpaid ? 'ASC' : 'DESC',
      rankSort: 'ASC',
    })
  }, [lbFilterDatesCommon, lbSortUnpaid])
  useEffect(() => fetchSalesRankingUnpaid(), [fetchSalesRankingUnpaid])
  //  Paid Sales Leaderboard
  const [rankingPaid, setRankingPaid] = useState([])
  const [lbSortPaid, setLbSortPaid] = useState(false)
  const fetchSalesRankingPaid = useCallback(async () => {
    if (!lbFilterDatesCommon || !lbFilterDatesCommon.dateFrom || !lbFilterDatesCommon.dateTo) return
    await leaderBoardSalesDataSetter({
      ...lbFilterDatesCommon,
      status: `Processed,Shipped,Tracking,Tracking-Live,Delivered,Undelivered,Partial,Reshipment-Initiated,Reshipment-Completed,Refunded`,
      setter: setRankingPaid,
      sort: lbSortPaid ? 'ASC' : 'DESC',
      rankSort: 'DESC',
    })
  }, [lbFilterDatesCommon, lbSortPaid])
  useEffect(() => fetchSalesRankingPaid(), [fetchSalesRankingPaid])

  // Conversion Rate Leaderboard
  const [rankingConversion, setRankingConversion] = useState([])
  const [lbSortConversion, setLbSortConversion] = useState(true)
  const fetchConversionRateRanking = useCallback(async () => {
    if (!lbFilterDatesCommon || !lbFilterDatesCommon.dateFrom || !lbFilterDatesCommon.dateTo) return
    const res = await getConversionRateRankings(
      lbFilterDatesCommon.dateFrom,
      lbFilterDatesCommon.dateTo,
      lbSortConversion ? 'ASC' : 'DESC'
    )
    if (res && res.status === 200) {
      setRankingConversion(res.data.data || [])
    }
  }, [lbFilterDatesCommon, lbSortConversion])
  useEffect(() => fetchConversionRateRanking(), [fetchConversionRateRanking])

  return (
    <div id="leaderboard">
      <div className="w-full p-my-2 p-d-flex gap-1 p-ai-center">
        <h3 className="p-my-0">Leader Board</h3>
        <div>
          <MyDatesPicker
            code={lbDateCodeCommon}
            setCode={setLbDateCodeCommon}
            rangeDates={lbRangeDatesCommon}
            setRangeDates={setLbRangeDatesCommon}
            setFilterDates={setLbFilterDatesCommon}
          />
        </div>
        <div className="">
          <Checkbox
            inputId="lbShowCustom"
            className="p-mr-2"
            checked={lbShowCustom}
            onChange={(e) => setLbShowCustom(e.checked)}
          />
          <label htmlFor="lbShowCustom">Custom</label>
        </div>
      </div>
      <div className="p-grid p-my-3">
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="p-d-flex p-flex-column gap-1">
            <div className="leaderboard-card">
              <div className="leaderboard-paid p-mb-2">
                <h5 className="p-m-0 header-bg">Paid Sale</h5>
              </div>
              <LeaderboardTable
                data={rankingPaid}
                key="rankingPaid"
                sort={lbSortPaid}
                setSort={setLbSortPaid}
              />
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="p-d-flex p-flex-column gap-1">
            <div className="leaderboard-card">
              <div className="leaderboard-paid p-mb-2">
                <h5 className="p-m-0 header-bg">Conversion Rate</h5>
              </div>
              <LeaderboardTable
                data={rankingConversion}
                key="rankingConversion"
                isValueAmount={false}
                sort={lbSortConversion}
                setSort={setLbSortConversion}
              />
            </div>
          </div>
        </div>

        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="p-d-flex p-flex-column gap-1">
            <div className="leaderboard-card">
              <div className="leaderboard-paid p-mb-2">
                <h5 className="p-m-0 header-bg">Unpaid Sale</h5>
              </div>
              <LeaderboardTable
                data={rankingUnpaid}
                key="rankingUnpaid"
                sort={lbSortUnpaid}
                setSort={setLbSortUnpaid}
              />
            </div>
          </div>
        </div>
        {lbShowCustom && (
          <div className="p-col-12 p-md-6 p-lg-4">
            <div className="p-d-flex p-flex-column gap-1">
              <div className="leaderboard-card">
                <div className="leaderboard-paid">
                  <h5 className="p-m-0 header-bg">Custom Sale</h5>
                </div>
                <div className="w-full p-my-2">
                  <MyDatesPicker
                    code={lbDateCodeCustom}
                    setCode={setLbDateCodeCustom}
                    rangeDates={lbRangeDatesCustom}
                    setRangeDates={setLbRangeDatesCustom}
                    setFilterDates={setLbFilterDatesCustom}
                  />
                </div>
                <div className="p-my-2 w-full">
                  <MultiSelect
                    maxSelectedLabels={3}
                    options={ctx_allStatusGroups}
                    optionLabel="name"
                    optionGroupLabel="name"
                    optionValue="value"
                    optionGroupChildren="items"
                    filter
                    filterBy="name"
                    filterPlaceholder="Search"
                    placeholder="Select statues"
                    showClear
                    value={lbCustomStatus}
                    onChange={(e) => setLbCustomStatus(e.value)}
                    className="w-full"
                  />
                </div>
                {lbCustomStatus && lbCustomStatus.length > 0 ? (
                  <LeaderboardTable
                    data={rankingCustom}
                    key="rankingCustom"
                    sort={lbSortCustom}
                    setSort={setLbSortCustom}
                  />
                ) : (
                  <div className="card lb-card">
                    <p className="text-center text-blue">Please select a status</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const LeaderboardTable = ({ isValueAmount = true, data, sort, setSort }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <div className="card lb-card">
      <div className="p-grid">
        <p className="p-col-2 p-m-0 lb-header text-center">Rank</p>
        <p className="p-col-6 p-m-0 lb-header">Name</p>
        <p className="p-col-4 p-m-0 lb-header">
          {isValueAmount ? 'Amount' : 'Precent'}{' '}
          <i
            className={classNames('pointer pi', {
              'pi-sort-amount-down': sort,
              'pi-sort-amount-up': !sort,
            })}
            onClick={() => setSort(!sort)}
          />
        </p>
        {Array.isArray(data) &&
          data.slice(0, isCollapsed ? 3 : data.length).map((item) => (
            <>
              <p className="p-col-2 p-m-0 lb-record p-d-inline-flex p-ai-center p-jc-center">
                {item?.leader_rank === 1 && (
                  <img alt="Not Found" src={aceCardImage} className="lb-rank-image" />
                )}
                {item?.leader_rank === 2 && (
                  <img alt="Not Found" src={kingCardImage} className="lb-rank-image" />
                )}
                {item?.leader_rank === 3 && (
                  <img alt="Not Found" src={jokerCardImage} className="lb-rank-image" />
                )}
                {item?.leader_rank > 3 && <span>{item?.leader_rank}</span>}
              </p>
              <p className="p-col-6 p-m-0 lb-record p-d-inline-flex p-ai-center">{item.username}</p>
              <p className="p-col-4 p-m-0 lb-record text-blue p-d-inline-flex p-ai-center">
                {isValueAmount && '$' + moneyFormatter(item.sales)}
                {!isValueAmount && item.rate + '%'}
              </p>
            </>
          ))}
      </div>

      <div className="p-m-4" />
      <div className="collapsed-btn w-full p-d-flex p-jc-center">
        <Button
          label={isCollapsed ? 'View More' : 'View Less'}
          onClick={() => setIsCollapsed((ps) => !ps)}
        />
      </div>
    </div>
  )
}
