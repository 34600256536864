import { format, formatDistanceToNow, subDays } from 'date-fns'
import { Button } from 'primereact/button'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Timeline } from 'primereact/timeline'

import classNames from 'classnames'
import { TabPanel, TabView } from 'primereact/tabview'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Message } from 'primereact/message'
import useQuery from '../../../hooks/useQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  addOrderNote,
  deleteOrderNote,
  editOrderNote,
  getOrderFollowup,
  getOrderNotes,
  postOrderFollowup,
} from '../../../api'
import { useGlobalContext } from '../../../context'
import { handleObjChange } from '../../../functions/setter'

// UndeliveredProductNoteDialog component to manage the order note dialog
function UndeliveredProductNoteDialog({ setTableLoading, pathname }) {
  const toast = useRef(null)
  const history = useHistory()
  const query = useQuery()
  const orderId = query.get('orderId')
  const productId = query.get('productId')
  const { user } = useGlobalContext()
  //* Empty objects
  const emptyErr = {
    state: false,
    errors: [],
  }
  const empty_addUndeliveredProductFollowupRecord = {
    followupMedium: '',
    issueAndResolution: '',
    loading: false,
    estimatedTime: '',
    nextFollowupTime: '',
    clientFeedback: '',
  }

  const empty_deleteUndeliveredProductNoteData = {
    note: {},
    visible: false,
  }

  const empty_editUndeliveredProductNoteData = {
    noteId: '',
    visible: false,
    text: '',
  }

  //* State start
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [notesDialogVisible, setNotesDialogVisible] = useState(false)
  const [undeliveredProductNotes, setUndeliveredProductNotes] = useState([])
  const [note, setNote] = useState('')
  const [addUndeliveredProductFollowupRecord, setAddUndeliveredProductFollowupRecord] = useState(
    empty_addUndeliveredProductFollowupRecord
  )
  const [undeliveredProductFollowupRecords, setUndeliveredProductFollowupRecords] = useState([])
  const [deleteUndeliveredProductNoteData, setDeleteUndeliveredProductNoteData] = useState(
    empty_deleteUndeliveredProductNoteData
  )
  const [editUndeliveredProductNoteData, setEditUndeliveredProductNoteData] = useState(
    empty_editUndeliveredProductNoteData
  )
  const [loading, setLoading] = useState(false)
  //* State end

  //* Function to fetch all notes for a undelivered product using it's id
  const fetchUndeliveredProductNotesAndFollowupById = useCallback(async () => {
    if (!orderId) return
    if (!productId) return
    setTableLoading(true)
    const noteRes = await getOrderNotes(orderId, productId, 'UndeliveredProduct')
    if (noteRes) {
      setTableLoading(false)

      if (noteRes.status === 400) {
        hideUndeliveredProductNotes()
      }
      if (noteRes.status === 200) {
        setUndeliveredProductNotes(noteRes.data || [])
      }
    }
    const followupRes = await getOrderFollowup(orderId, productId, 'UndeliveredProduct')
    if (followupRes) {
      setNotesDialogVisible(true)
      if (followupRes.status === 200) {
        setUndeliveredProductFollowupRecords(followupRes.data.records || [])
      }
    }
  }, [orderId])
  useEffect(() => {
    fetchUndeliveredProductNotesAndFollowupById()
  }, [fetchUndeliveredProductNotesAndFollowupById])

  //* Function to hide the section to add a new note
  const hideUndeliveredProductNotes = () => {
    setLoading(false)
    setNotesDialogVisible(false)
    setNote('')
    setAddUndeliveredProductFollowupRecord(empty_addUndeliveredProductFollowupRecord)
    setUndeliveredProductNotes([])
    history.push({
      pathname: pathname,
    })
  }

  const validateUndeliveredProductFollowup = () => {
    const err = []
    const { issueAndResolution, followupMedium, estimatedTime, nextFollowupTime } =
      addUndeliveredProductFollowupRecord

    if (!issueAndResolution) {
      err.push('Adding an Issue & Resolution is mandatory')
    }
    if (!followupMedium.length) {
      err.push('Add a medium of communication')
    }
    if (!estimatedTime) {
      err.push('Please select estimated time of resolution')
    }
    if (!nextFollowupTime) {
      err.push('Please select next followup interval')
    }
    return err
  }

  const handleUndeliveredProductFollowup = async () => {
    const errs = validateUndeliveredProductFollowup()
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    handleObjChange(setAddUndeliveredProductFollowupRecord, 'loading', true)
    const { followupMedium, issueAndResolution, estimatedTime, nextFollowupTime, clientFeedback } =
      addUndeliveredProductFollowupRecord

    const res = await postOrderFollowup({
      orderId,
      productId,
      topic: 'undeliveredProduct',
      status: 'product',
      followupMedium,
      note: issueAndResolution,
      estimatedTime,
      nextFollowupTime,
      clientFeedback,
    })
    if (res) {
      handleObjChange(setAddUndeliveredProductFollowupRecord, 'loading', false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        let _undeliveredProductFollowupRecords = undeliveredProductFollowupRecords
        _undeliveredProductFollowupRecords = [
          ...res.data.followupRecord,
          ..._undeliveredProductFollowupRecords,
        ]
        setUndeliveredProductFollowupRecords(_undeliveredProductFollowupRecords)
        let _undeliveredProductNotes = undeliveredProductNotes
        _undeliveredProductNotes = [...res.data.noteRecord, ..._undeliveredProductNotes]
        setUndeliveredProductNotes(_undeliveredProductNotes)
        setAddUndeliveredProductFollowupRecord(empty_addUndeliveredProductFollowupRecord)
      }
    }
  }

  //* Function to add a new note to complaint
  const handleNewUndeliveredProductNote = async () => {
    if (!note.trim()) {
      setNewRecordError({
        state: true,
        errors: ['Note is invalid'],
      })
      return
    }
    setLoading(true)
    const res = await addOrderNote({
      orderId: orderId,
      note: note,
      productId: productId,
      topic: 'undeliveredProduct',
    })
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        setLoading(false)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setUndeliveredProductNotes((ps) => [res.data.note, ...ps])
        setNote('')
      }
    }
  }

  const content = (item, index) => {
    let parseData = new Date(item?.createdAt)
    let date = formatDistanceToNow(parseData, { addSuffix: true })
    let date1 = format(parseData, 'PPPPp')
    let expectedTAT = format(new Date(item?.estimatedTime), 'PPp')
    let nextFollowup = format(new Date(item?.nextFollowupTime), 'PPp')

    return (
      <div key={item.id} className="p-mb-2">
        <h6> Followup recorded via {item.followupMedium}</h6>
        <p className="">
          <span>Note: </span>
          <label className="p-text-lowercase" style={{ wordBreak: 'break-all' }}>
            {item?.note}
          </label>
        </p>
        <div>
          <p>
            <span>Performed By: </span>
            <label className="p-text-lowercase">{item?.createdBy}</label>
          </p>
        </div>
        <div>
          <p>
            <span>E.Time: </span>
            <label>{expectedTAT}</label>
          </p>
          <p>
            <span>N.Time: </span>
            <label>{nextFollowup}</label>
          </p>
          <span>
            {date} - {date1}
          </span>
        </div>
      </div>
    )
  }

  const hideDeleteUndeliveredProductNote = () => {
    setDeleteUndeliveredProductNoteData(empty_deleteUndeliveredProductNoteData)
  }

  const handleDeleteUndeliveredProductNote = async () => {
    setLoading(true)
    const res = await deleteOrderNote(deleteUndeliveredProductNoteData.note?.id)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        let _undeliveredProductNotes = undeliveredProductNotes
        _undeliveredProductNotes = _undeliveredProductNotes.filter(
          (n) => n.id !== deleteUndeliveredProductNoteData.note.id
        )
        setUndeliveredProductNotes(_undeliveredProductNotes)
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
      }
      hideDeleteUndeliveredProductNote()
    }
  }

  const hideEditUndeliveredProductNote = () => {
    setEditUndeliveredProductNoteData(empty_editUndeliveredProductNoteData)
  }

  const handleEditUndeliveredProductNote = async () => {
    setLoading(true)
    const res = await editOrderNote(
      editUndeliveredProductNoteData.noteId,
      editUndeliveredProductNoteData.text
    )
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        let _undeliveredProductNotes = undeliveredProductNotes
        _undeliveredProductNotes = _undeliveredProductNotes.map((n) =>
          n.id === editUndeliveredProductNoteData.noteId ? res.data.note : n
        )
        setUndeliveredProductNotes(_undeliveredProductNotes)
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
      }
      hideEditUndeliveredProductNote()
    }
  }

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={notesDialogVisible}
        header={`Notes for product #${productId} in order #${orderId}`}
        style={{
          width: '60%',
          minWidth: '400px',
        }}
        onHide={() => {
          hideUndeliveredProductNotes()
        }}
        footer={
          <Button
            label="Cancel"
            className="p-button-text"
            icon="pi pi-times"
            onClick={() => {
              hideUndeliveredProductNotes()
            }}
          />
        }
      >
        <div className="p-grid">
          <div className="p-col-7">
            <TabView>
              <TabPanel header="Add Notes">
                <div className="card p-fluid">
                  <div className="p-field">
                    <InputTextarea
                      autoResize
                      rows={3}
                      placeholder="Enter note"
                      value={note}
                      onChange={(e) => setNote(e.target.value || '')}
                    />
                  </div>
                  <div className="p-field">
                    <Button
                      label={loading ? 'Adding' : 'Add'}
                      loading={loading}
                      disabled={loading}
                      onClick={handleNewUndeliveredProductNote}
                    />
                  </div>
                  {newRecordError.state && (
                    <>
                      {newRecordError.errors.map((err, idx) => {
                        return (
                          <div key={idx} className="p-fluid p-filed p-col-12">
                            <Message text={err} severity="warn" sticky={true} />
                          </div>
                        )
                      })}
                      <div className="p-fluid p-field p-col-12">
                        <Button
                          type="button"
                          onClick={() => {
                            setNewRecordError(emptyErr)
                          }}
                          icon="pi pi-times"
                          label="Clear Warnings"
                          className="p-button-secondary"
                        ></Button>
                      </div>
                    </>
                  )}
                </div>
              </TabPanel>
              <TabPanel header="Add Followup">
                <div className="card">
                  <div className="p-field ">
                    <label htmlFor="reason" className="">
                      Add Issue and Resolution <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <InputTextarea
                      id="reason"
                      rows={3}
                      cols={30}
                      value={addUndeliveredProductFollowupRecord.issueAndResolution}
                      onChange={(e) =>
                        handleObjChange(
                          setAddUndeliveredProductFollowupRecord,
                          'issueAndResolution',
                          e.target.value || ''
                        )
                      }
                      className="w-full p-mt-1"
                      placeholder="Start typing here..."
                    />
                  </div>
                  <div className="p-field">
                    <label htmlFor="medium" className="">
                      Enter your medium of communication <sup style={{ color: 'red' }}>*</sup>
                    </label>

                    <InputText
                      id="medium"
                      value={addUndeliveredProductFollowupRecord.followupMedium}
                      onChange={(e) =>
                        handleObjChange(
                          setAddUndeliveredProductFollowupRecord,
                          'followupMedium',
                          e.target.value || ''
                        )
                      }
                      className="w-full p-mt-1"
                      placeholder="Call, Sms, Email"
                    />
                  </div>
                  <div className="p-field">
                    <label htmlFor="client-feedback" className="">
                      Add client feedback
                    </label>
                    <InputTextarea
                      id="client-feedback"
                      rows={3}
                      cols={30}
                      value={addUndeliveredProductFollowupRecord.clientFeedback}
                      onChange={(e) =>
                        handleObjChange(
                          setAddUndeliveredProductFollowupRecord,
                          'clientFeedback',
                          e.target.value || ''
                        )
                      }
                      className="w-full p-mt-1"
                      placeholder="Start typing here..."
                    />
                  </div>
                  <div className="p-field p-fluid ">
                    <label className="p-d-block" htmlFor="expectedTAT">
                      Select Estimated Resolution Time <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <Calendar
                      id="ERT"
                      placeholder="Select ERT"
                      showTime
                      hourFormat="12"
                      minDate={subDays(new Date(), 0)}
                      value={addUndeliveredProductFollowupRecord.estimatedTime}
                      onChange={(e) => {
                        handleObjChange(
                          setAddUndeliveredProductFollowupRecord,
                          'estimatedTime',
                          e.value || ''
                        )
                        if (addUndeliveredProductFollowupRecord.nextFollowupTime === '') {
                          handleObjChange(
                            setAddUndeliveredProductFollowupRecord,
                            'nextFollowupTime',
                            e.value || ''
                          )
                        }
                      }}
                    />
                  </div>
                  <div className="p-field p-fluid ">
                    <label className="p-d-block" htmlFor="expectedTAT">
                      Next Followup <sup style={{ color: 'red' }}>*</sup>
                    </label>
                    <Calendar
                      id="next-followup"
                      placeholder="Select Next Followup Interval"
                      showTime
                      hourFormat="12"
                      minDate={subDays(new Date(), 0)}
                      value={addUndeliveredProductFollowupRecord.nextFollowupTime}
                      onChange={(e) => {
                        handleObjChange(
                          setAddUndeliveredProductFollowupRecord,
                          'nextFollowupTime',
                          e.value || ''
                        )
                      }}
                    />
                  </div>
                  <Button
                    className="w-full"
                    label="Submit"
                    disabled={addUndeliveredProductFollowupRecord.loading}
                    loading={addUndeliveredProductFollowupRecord.loading}
                    onClick={handleUndeliveredProductFollowup}
                  />
                  {newRecordError.state && (
                    <>
                      {newRecordError.errors.map((err, idx) => {
                        return (
                          <div key={idx} className="p-fluid p-filed p-col-12">
                            <Message text={err} severity="warn" sticky={true} />
                          </div>
                        )
                      })}
                      <div className="p-fluid p-field p-col-12">
                        <Button
                          type="button"
                          onClick={() => {
                            setNewRecordError(emptyErr)
                          }}
                          icon="pi pi-times"
                          label="Clear Warnings"
                          className="p-button-secondary"
                        ></Button>
                      </div>
                    </>
                  )}
                </div>
              </TabPanel>
            </TabView>

            <>
              <div className="card">
                <h5 className="p-m-0">Notes</h5>
              </div>
              <div className="p-grid p-formgrid">
                {undeliveredProductNotes?.map((note, idx) => {
                  return (
                    <div className="p-col-12" key={idx}>
                      <div className="card">
                        <h6 style={{ wordBreak: 'break-word' }}>
                          {note?.note?.trim()?.startsWith('Issue') ? 'Followup' : 'Note'}
                        </h6>
                        <p style={{ wordBreak: 'break-word' }}>{note?.note}</p>
                        <hr />
                        <div className="p-d-flex gap-1by2 p-ai-center p-jc-between">
                          <div className="p-d-flex gap-1by2 p-ai-center opacity-50">
                            {note?.id && <small>#{note?.id}</small>}
                            {note?.createdBy && <small>By: {note.createdBy}</small>}

                            {note?.updatedAt && (
                              <small>Updated At: {format(new Date(note.updatedAt), 'PPp')}</small>
                            )}
                          </div>
                          {user.username === note.createdBy && (
                            <>
                              <div className="p-d-flex gap-1">
                                <Button
                                  icon="pi pi-pencil"
                                  className="p-button-text"
                                  onClick={() => {
                                    setEditUndeliveredProductNoteData({
                                      noteId: note.id,
                                      visible: true,
                                      text: note.note,
                                    })
                                  }}
                                />

                                <Button
                                  id={`deleteOrderNote-${note.id}`}
                                  className="p-button-danger p-button-text"
                                  icon="pi pi-trash"
                                  onClick={() => {
                                    setDeleteUndeliveredProductNoteData({
                                      note,
                                      visible: true,
                                    })
                                  }}
                                />
                                <ConfirmPopup
                                  target={document.getElementById(`deleteOrderNote-${note.id}`)}
                                  visible={
                                    deleteUndeliveredProductNoteData.visible &&
                                    deleteUndeliveredProductNoteData.note?.id === note?.id
                                  }
                                  onHide={hideDeleteUndeliveredProductNote}
                                  message="Are you sure you want to delete this note?"
                                  icon="pi pi-exclamation-triangle"
                                  acceptClassName="p-button-danger"
                                  accept={handleDeleteUndeliveredProductNote}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          </div>

          <div className="p-col-12 p-lg-5">
            <div className="card">
              <h5 className="p-d-flex p-jc-between p-ai-center">
                <span>Complaint Followup Timeline</span>
              </h5>
              <hr />
              {undeliveredProductFollowupRecords.length > 0 ? (
                <div className="widget-timeline">
                  <div className="timeline-header p-d-flex p-jc-between p-ai-center"></div>
                  <div className="timeline-content">
                    <Timeline
                      value={undeliveredProductFollowupRecords}
                      content={content}
                      className="custimized-timeline"
                    />
                  </div>
                </div>
              ) : (
                <h5 className="p-text-center">
                  <i className="pi pi-exclamation-circle p-mx-2" />
                  No Followup History
                </h5>
              )}
            </div>
          </div>
        </div>
        <Dialog
          visible={editUndeliveredProductNoteData.visible}
          onHide={hideEditUndeliveredProductNote}
          className="w-full"
          style={{ maxWidth: '600px' }}
          header="Edit Order Note"
          footer={
            <div className="p-d-flex p-jc-end">
              <Button
                label="Cancel"
                className="p-button-text"
                icon="pi pi-times"
                disabled={loading}
                onClick={hideEditUndeliveredProductNote}
              />
              <Button
                label={loading ? 'Saving' : 'Save'}
                loading={loading}
                disabled={loading}
                onClick={handleEditUndeliveredProductNote}
                icon="pi pi-save"
              />
            </div>
          }
        >
          <InputTextarea
            autoResize
            rows={5}
            placeholder="Enter the note"
            className="w-full"
            value={editUndeliveredProductNoteData.text}
            onChange={(e) =>
              setEditUndeliveredProductNoteData({
                ...editUndeliveredProductNoteData,
                text: e.target.value,
              })
            }
          />
        </Dialog>
      </Dialog>
    </div>
  )
}

export default UndeliveredProductNoteDialog
