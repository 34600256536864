import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toast } from 'primereact/toast'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getBlacklistCustomer } from '../../api/customer'
import { FilterMatchMode } from 'primereact/api'
import { addDays, format } from 'date-fns'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { Toolbar } from 'primereact/toolbar'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { deleteWalletCoupon, getWalletCoupons, postWalletCoupon } from '../../api'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import ExportButton from '../../components/mini/ExportButton'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import EditWalletCoupon from '../../components/mycomponents/CouponWallet/EditWalletCoupon'
import ViewWalletCoupon from '../../components/mycomponents/CouponWallet/ViewWalletCoupon'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import { useGlobalContext } from '../../context'
import moneyFormatter from '../../functions/moneyFormatter'
import { dtFilenameDate } from '../../functions/myDateFns'
import { handleObjChange, onChangeFilter } from '../../functions/setter'

export default function WalletCouponPage() {
  const { user, agents } = useGlobalContext()

  const toast = useRef(null)
  const dt = useRef(null)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = parseInt(searchParams.get('id'))
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdBy: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const emptyRecord = {
    name: '',
    description: '',
    amount: 0,
    validityStartDate: undefined,
    validityEndDate: undefined,
    issueCodeCount: 0,
    loading: false,
    visible: false,
    isActive: 0,
  }
  const emptyRecordRequest = {
    id: null,
    removeReason: null,
    loading: false,
    visible: false,
  }
  const [password, setPassword] = useState('')
  const [filters, setFilters] = useState(emptyFilterObject)
  const [records, setRecords] = useState([])
  const [record, setRecord] = useState(emptyRecord)
  const [recordRequest, setRecordRequest] = useState(emptyRecordRequest)
  const [recordVisible, setRecordVisible] = useState(false)
  const [newRecord, setNewRecord] = useState(emptyRecord)
  const [deleteRecord, setDeleteRecord] = useState(emptyRecord)
  const [tableLoading, setTableLoading] = useState(false)
  const emptyErr = {
    state: false,
    errors: [],
  }
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const validateNewRecord = (item) => {
    const { name, description, amount, validityStartDate, validityEndDate, issueCodeCount } = item
    const err = []
    if (!name || name.length < 5 || name.length > 50) {
      err.push('Name is invalid or shorter than 5 characters or longer than 50 characters')
    }
    if (!description || description.length < 5 || description.length > 250) {
      err.push('Description is invalid or shorter than 5 characters or longer than 250 characters')
    }
    if (!amount) {
      err.push('Amount is required')
    }
    if (!validityStartDate) {
      err.push('Validity start date is required')
    }
    if (!validityEndDate) {
      err.push('Validity end date is required')
    }
    if (!issueCodeCount || parseInt(issueCodeCount) < 1) {
      err.push('Issue code count is invalid or less than 1')
    }
    return err
  }
  const resetNewRecord = () => {
    setNewRecord(emptyRecord)
    setPassword('')
    setNewRecordError(emptyErr)
  }
  const handleValidateNewRecord = () => {
    const errs = validateNewRecord(newRecord)
    if (errs.length > 0) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return false
    }
    setNewRecordError(emptyErr)
    return true
  }
  const handleSubmitNewRecord = async () => {
    handleObjChange(setNewRecord, 'loading', true)
    handleValidateNewRecord()

    const {
      name,
      description,
      amount,
      validityStartDate,
      validityEndDate,
      issueCodeCount,
      isActive,
    } = newRecord
    const res = await postWalletCoupon({
      password,
      name,
      description,
      amount,
      validityStartDate,
      validityEndDate,
      issueCodeCount,
      isActive,
    })
    if (res) {
      handleObjChange(setNewRecord, 'loading', false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setNewRecord(emptyRecord)
        setPassword('')
        setRecords((ps) => [res.data.record, ...ps])
      }
    }
    handleObjChange(setNewRecord, 'loading', false)
    handleObjChange(setNewRecord, 'visible', false)
  }
  const handleSubmitDeleteRecord = async () => {
    handleObjChange(setDeleteRecord, 'loading', true)
    const res = await deleteWalletCoupon(deleteRecord.id, password)
    if (res) {
      handleObjChange(setDeleteRecord, 'loading', false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setDeleteRecord(emptyRecord)
        setPassword('')
        setRecords((ps) => [...ps].filter((item) => item.id !== deleteRecord.id))
      }
    }
    handleObjChange(setDeleteRecord, 'loading', false)
    handleObjChange(setDeleteRecord, 'visible', false)
  }
  // const hideRecord = () => {
  //   setRecord(emptyRecord)
  //   setRecordVisible(false)
  //   history.push({
  //     pathname: '/website/wallet-coupons',
  //   })
  // }

  // const fetchRecord = useCallback(async () => {
  //   if (!id) return
  //   setTableLoading(true)
  //   const res = await getBlacklistCustomer(id)
  //   if (res) {
  //     setTableLoading(false)
  //     if (res.status === 404) {
  //       hideRecord()
  //     }
  //     if (res.status === 200) {
  //       setRecord((ps) => ({
  //         ...ps,
  //         ...res.data.record,
  //       }))
  //       setRecordVisible(true)
  //     }
  //   }
  // }, [id])
  // useEffect(() => fetchRecord(), [fetchRecord])

  const fetchRecords = useCallback(async () => {
    setTableLoading(true)
    const res = await getWalletCoupons()
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setRecords(res.data)
      }
    }
  }, [])
  useEffect(() => fetchRecords(), [fetchRecords])

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager']}>
      <div className="card">
        <Toast ref={toast} />
        <TabView>
          <TabPanel header="Wallet Coupons">
            <Toolbar
              className="p-toolbar p-flex-wrap gap-1"
              left={
                <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                  <h4 className="p-m-0">Wallet Coupons</h4>
                </div>
              }
              right={
                <div className="p-d-flex p-flex-wrap gap-1">
                  <Button label="Refresh" icon="pi pi-refresh" onClick={fetchRecords} />
                  <ExportButton datatable_ref={dt} />
                </div>
              }
            ></Toolbar>
            <DataTable
              exportFilename={`Wallet_Coupon_${dtFilenameDate}`}
              ref={dt}
              loading={tableLoading}
              value={records}
              filters={filters}
              showGridlines
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
              rowHover
              emptyMessage="No record found."
              breakpoint="1200px"
              responsiveLayout="stack"
              header={
                <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                  <div className=" p-d-flex p-ai-center">
                    <p className="p-m-0 p-pr-2">Created By</p>
                    <Dropdown
                      placeholder="User"
                      options={agents}
                      optionLabel="username"
                      optionValue="username"
                      filter
                      filterBy="username"
                      filterPlaceholder="Search..."
                      value={filters.createdBy.value}
                      onChange={(e) => {
                        onChangeFilter(setFilters, e.target.value, 'createdBy')
                      }}
                      showClear
                    />
                  </div>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      value={filters.global.value}
                      onChange={(e) => {
                        onChangeFilter(setFilters, e.target.value, 'global')
                      }}
                      placeholder="Search..."
                    />
                  </span>
                </div>
              }
            >
              <Column style={{ width: '0%' }} header="ID" field="id" sortable />
              <Column style={{ width: '3%' }} header="Name" field="name" />
              <Column
                header="Vali Start Date"
                field="validityStartDate"
                sortable
                body={(rd) => format(new Date(rd.validityStartDate), 'PP')}
              />
              <Column
                header="Vali End Date"
                field="validityEndDate"
                sortable
                body={(rd) => format(new Date(rd.validityEndDate), 'PP')}
              />
              <Column
                header="Amount"
                field="amount"
                sortable
                body={(rd) => moneyFormatter(rd.amount, '$')}
              />
              <Column header="Total Codes" field="totalCodes" sortable />
              <Column header="Used Codes" field="usedCodes" sortable />
              <Column header="Unused Codes" field="unusedCodes" sortable />
              <Column header="Created By" field="createdBy" sortable />
              <Column
                style={{ width: '1%' }}
                header="Action"
                body={(rd) => (
                  <div className="p-d-flex gap-1by2 p-jc-start">
                    <Link to={`?viewId=${rd?.id}`}>
                      <Button
                        icon="pi pi-eye"
                        tooltip="Open Record"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    <Link to={`?editId=${rd?.id}`}>
                      <Button
                        icon="pi pi-pencil"
                        tooltip="Edit Record"
                        className="p-button-info"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    {['superadmin', 'admin', 'manager'].includes(user.role) ? (
                      <Button
                        icon="pi pi-trash"
                        className="p-button-danger"
                        tooltip="Delete Record"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                        onClick={() => {
                          setDeleteRecord({ ...rd, visible: true })
                        }}
                      />
                    ) : (
                      <Button
                        icon="pi pi-plus"
                        className=""
                        tooltip="Add Remove Record"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                        onClick={async () => {
                          setTableLoading(true)
                          const res = await getBlacklistCustomer(rd.id)
                          if (res) {
                            setTableLoading(false)
                            if (res.status === 200) {
                              const { removeReason, removeRequestBy, removeRequestDate } =
                                res.data.record
                              setRecordRequest((ps) => ({
                                ...ps,
                                id: rd.id,
                                removeReason,
                                removeRequestBy,
                                removeRequestDate,
                                visible: true,
                              }))
                            }
                          }
                        }}
                      />
                    )}
                    <Link to={`/website/wallet-coupons?history=${rd.id}`}>
                      <Button
                        tooltip="History"
                        tooltipOptions={{ position: 'bottom' }}
                        icon="pi pi-clock"
                        className="p-button p-button-help"
                      />
                    </Link>
                  </div>
                )}
              />
            </DataTable>
          </TabPanel>
          <TabPanel header="Add Wallet Coupon">
            <div className="p-fluid p-grid p-formgrid">
              <div className="p-col-12 p-lg-6 p-fluid p-field">
                <label className="p-col-fixed" htmlFor="">
                  Name
                </label>
                <InputText
                  placeholder="Enter name"
                  value={newRecord.name}
                  onChange={(e) => handleObjChange(setNewRecord, 'name', e.target.value)}
                />
              </div>
              <div className="p-col-12 p-lg-6 p-fluid p-field">
                <label className="p-col-fixed" htmlFor="">
                  Description
                </label>
                <InputTextarea
                  placeholder="Enter description"
                  value={newRecord.description}
                  onChange={(e) => handleObjChange(setNewRecord, 'description', e.target.value)}
                />
              </div>
              <div className="p-col-12 p-lg-6 p-fluid p-field">
                <label className="p-col-fixed" htmlFor="">
                  Amount
                </label>
                <InputNumber
                  placeholder="Enter amount"
                  mode="currency"
                  currency="USD"
                  value={newRecord.amount}
                  onChange={(e) => handleObjChange(setNewRecord, 'amount', e.value)}
                />
              </div>
              <div className="p-col-12 p-lg-6 p-fluid p-field">
                <label className="p-col-fixed" htmlFor="">
                  Issue Code Count
                </label>
                <InputText
                  placeholder="Enter issue code count"
                  value={newRecord.issueCodeCount}
                  onChange={(e) => handleObjChange(setNewRecord, 'issueCodeCount', e.target.value)}
                />
              </div>
              <div className="p-col-12 p-lg-6 p-fluid p-field">
                <label className="p-col-fixed" htmlFor="">
                  Validity Start Date
                </label>
                <Calendar
                  placeholder="Validity start date"
                  minDate={new Date()}
                  value={newRecord.validityStartDate}
                  onChange={(e) => handleObjChange(setNewRecord, 'validityStartDate', e.value)}
                />
              </div>
              <div className="p-col-12 p-lg-6 p-fluid p-field">
                <label className="p-col-fixed" htmlFor="">
                  Validity End Date
                </label>
                <Calendar
                  placeholder="Validity end date"
                  value={newRecord.validityEndDate}
                  minDate={addDays(new Date(), 1)}
                  onChange={(e) => handleObjChange(setNewRecord, 'validityEndDate', e.value)}
                />
              </div>
              <div className="p-col-12 p-fluid p-field p-d-flex p-ai-center gap-1by2">
                <Checkbox
                  inputId="active"
                  onChange={(e) => handleObjChange(setNewRecord, 'isActive', e.checked ? 1 : 0)}
                  checked={newRecord.isActive ? true : false}
                ></Checkbox>
                <label htmlFor="active" className="p-mb-0">
                  {newRecord.isActive ? 'Active' : 'Inactive'}
                </label>
              </div>
              {newRecordError.state && (
                <>
                  {newRecordError.errors.map((err, idx) => (
                    <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                      <Message text={err} severity="warn" sticky={true} />
                    </div>
                  ))}
                  <div className="p-fluid p-field p-col-12">
                    <Button
                      type="button"
                      onClick={() => setNewRecordError(emptyErr)}
                      icon="pi pi-times"
                      label="Clear Warnings"
                      className="p-button-secondary"
                    />
                  </div>
                </>
              )}
              <div className="p-col-12">
                <Button
                  label={newRecord.loading ? 'Submitting' : 'Submit'}
                  icon="pi pi-save"
                  loading={newRecord.loading}
                  disabled={newRecord.loading}
                  onClick={() => {
                    const isVali = handleValidateNewRecord()
                    isVali && handleObjChange(setNewRecord, 'visible', true)
                  }}
                />
              </div>
            </div>
          </TabPanel>
        </TabView>
        <ViewWalletCoupon />
        <EditWalletCoupon fetchRecords={fetchRecords} />
        <ConfirmDialogWithPassword
          headerText={`Confim Adding Wallet Coupon`}
          loading={newRecord.loading}
          visible={newRecord.visible}
          setPassword={setPassword}
          password={password}
          onHide={() => handleObjChange(setNewRecord, 'visible', false)}
          mainText={`Are you sure you want to issue ${newRecord.issueCodeCount} coupon code of amount ${newRecord.amount} each?`}
          yesText="Submit"
          loadingText={`Submitting`}
          onSubmit={handleSubmitNewRecord}
        />
        <ConfirmDialogWithPassword
          headerText={`Confim Deleteting Wallet Coupon`}
          loading={deleteRecord.loading}
          visible={deleteRecord.visible}
          setPassword={setPassword}
          password={password}
          onHide={() => handleObjChange(setDeleteRecord, 'visible', false)}
          mainText={`Are you sure you want to delete ${deleteRecord.name}?`}
          yesText="Submit"
          loadingText={`Submitting`}
          onSubmit={handleSubmitDeleteRecord}
        />
        <ConfirmDialog />
        <ChangeHistorySidebar
          setTableLoading={setTableLoading}
          header="Wallet Coupon History"
          historyType="walletCoupon"
        />
      </div>
    </PageAllowedToRoles>
  )
}
