import { Checkbox } from 'primereact/checkbox'
import React, { useState } from 'react'
import { handleObjChange } from '../../../../functions/setter'
import { MultiSelect } from 'primereact/multiselect'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useGlobalContext } from '../../../../context'
import { postPaymentPartnerMethodQuota } from '../../../../api'
import { Message } from 'primereact/message'
import classNames from 'classnames'
import { InputNumber } from 'primereact/inputnumber'

const emptyErr = {
  state: false,
  errors: [],
}

const emptyPaymentPartnerMethodQuota = {
  paymentPartnerMethodId: '',
  name: '',
  startDate: '',
  endDate: '',
  quotaAmount: 0,
  active: false,
  showOnEcom: false,
  showOnCrm: false,
}
export default function AddPaymentPartnerMethodQuotaDialog({
  addPaymentPartnerMethodQuotasDialog,
  setAddPaymentPartnerMethodQuotasDialog,
  paymentPartnerMethods,
  setPaymentPartnerMethodQuotas,
  setTableLoading,
  fetchPaymentPartnerMethodUsers,
}) {
  const { agents, toast } = useGlobalContext()

  useState(false)
  const [paymentPartnerMethodQuota, setPaymentPartnerMethodQuota] = useState(
    emptyPaymentPartnerMethodQuota
  )
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [loading, setLoading] = useState(false)
  const validatePaymentMethodQuota = (paymentPartnerMethodQuota) => {
    const err = []
    if (paymentPartnerMethodQuota?.name === '') err.push('Name cannot be empty')
    if (paymentPartnerMethodQuota?.paymentPartnerMethodId === '')
      err.push('Payment Partner M?ethod cannot be empty')
    if (paymentPartnerMethodQuota?.startDate === '') err.push('Start Date cannot be empty')
    if (paymentPartnerMethodQuota?.endDate === '') err.push('End Date cannot be empty')
    if (paymentPartnerMethodQuota?.quotaAmount === 0) err.push('Quota Amount cannot be empty')
    if (paymentPartnerMethodQuota?.users?.length === 0) err.push('Users cannot be empty')
    return err
  }
  const addPaymentPartnerMethodQuota = async () => {
    const errs = validatePaymentMethodQuota(paymentPartnerMethodQuota)
    if (errs.length > 0) {
      setLoading(false)
      setNewRecordError({ state: true, errors: errs })
      return
    }
    setLoading(true)
    setTableLoading(true)
    const res = await postPaymentPartnerMethodQuota(paymentPartnerMethodQuota)
    if (res) {
      setTableLoading(false)
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
          life: 3000,
        })
        setPaymentPartnerMethodQuotas((ps) => [res.data.record, ...ps])
        onHide()
      }
    }
  }

  const onHide = () => {
    setAddPaymentPartnerMethodQuotasDialog(false)
    setPaymentPartnerMethodQuota(emptyPaymentPartnerMethodQuota)
    setNewRecordError(emptyErr)
  }

  const footer = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-secondary"
        />
        <Button
          label="Submit"
          icon="pi pi-save"
          className="p-button-primary"
          onClick={addPaymentPartnerMethodQuota}
          loading={loading}
        />
      </div>
    )
  }

  return (
    <div>
      <Dialog
        header="Add Payment Partner Method Quota"
        visible={addPaymentPartnerMethodQuotasDialog}
        style={{ width: '50vw' }}
        footer={footer}
        onHide={onHide}
      >
        <div className="p-grid p-formgrid p-ai-center p-col-12">
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              placeholder="Enter Name"
              value={paymentPartnerMethodQuota.name}
              onChange={(e) => {
                handleObjChange(setPaymentPartnerMethodQuota, 'name', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="paymentPartnerMethodId">Payment Partner Method</label>
            <Dropdown
              placeholder="Select Payment Partner Method"
              options={paymentPartnerMethods ?? []}
              optionLabel="paymentPartnerMethod"
              optionValue="id"
              value={paymentPartnerMethodQuota?.paymentPartnerMethodId}
              onChange={(e) => {
                const item = paymentPartnerMethods?.find((i) => i.id === e.value)
                if (!item || !item.active) return
                handleObjChange(setPaymentPartnerMethodQuota, 'paymentPartnerMethodId', e.value)
                fetchPaymentPartnerMethodUsers(e.value, setPaymentPartnerMethodQuota)
              }}
              itemTemplate={(option) => (
                <span
                  className={classNames({
                    'opacity-50': !option.active,
                  })}
                >
                  {option.paymentPartnerMethod}
                </span>
              )}
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="startDate">Start Date</label>
            <Calendar
              id="startDate"
              placeholder="Select Start Date"
              value={paymentPartnerMethodQuota.startDate}
              onChange={(e) => handleObjChange(setPaymentPartnerMethodQuota, 'startDate', e.value)}
              minDate={new Date()}
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="endDate">End Date</label>
            <Calendar
              id="endDate"
              placeholder="Select End Date"
              value={paymentPartnerMethodQuota.endDate}
              onChange={(e) => handleObjChange(setPaymentPartnerMethodQuota, 'endDate', e.value)}
              minDate={new Date()}
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="quotaAmount">Quota Amount</label>
            <InputNumber
              id="quotaAmount"
              placeholder="Enter Quota Amount"
              value={paymentPartnerMethodQuota.quotaAmount}
              onChange={(e) => {
                handleObjChange(setPaymentPartnerMethodQuota, 'quotaAmount', e.value)
              }}
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="users">Users</label>
            <MultiSelect
              id="users"
              placeholder="Select Users"
              optionLabel="username"
              optionValue="id"
              value={paymentPartnerMethodQuota.users}
              options={agents}
              filter={'username,id'}
              onChange={(e) => handleObjChange(setPaymentPartnerMethodQuota, 'users', e.value)}
            />
          </div>
          <div className="p-field p-fluid p-col-4">
            <label
              className="p-d-none p-d-lg-inline"
              htmlFor="showOnEcom"
              style={{ color: 'transparent' }}
            >
              Show On Website
            </label>
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="showOnEcom">
                Show On Website :
              </label>
              <Checkbox
                id="showOnEcom"
                checked={paymentPartnerMethodQuota.showOnEcom}
                onChange={(e) =>
                  handleObjChange(setPaymentPartnerMethodQuota, 'showOnEcom', e.checked)
                }
              />
            </div>
          </div>
          <div className="p-field p-fluid p-col-4">
            <label
              className="p-d-none p-d-lg-inline"
              htmlFor="showOnCrm"
              style={{ color: 'transparent' }}
            >
              Show On CRM
            </label>
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="showOnCrm">
                Show On CRM :
              </label>
              <Checkbox
                id="showOnCrm"
                checked={paymentPartnerMethodQuota.showOnCrm}
                onChange={(e) =>
                  handleObjChange(setPaymentPartnerMethodQuota, 'showOnCrm', e.checked)
                }
              />
            </div>
          </div>
          <div className="p-field p-fluid p-col-4">
            <label
              className="p-d-none p-d-lg-inline"
              htmlFor="active"
              style={{ color: 'transparent' }}
            >
              Active
            </label>
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="active">
                Active :
              </label>
              <Checkbox
                id="active"
                checked={paymentPartnerMethodQuota.active}
                onChange={(e) => handleObjChange(setPaymentPartnerMethodQuota, 'active', e.checked)}
              />
            </div>
          </div>
        </div>
        {newRecordError.state && (
          <>
            {newRecordError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setNewRecordError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </Dialog>
    </div>
  )
}
