import axios from 'axios'
import endpoints from './endpoints'

const postCoupon = async (couponData) => {
  try {
    const res = await axios.post(endpoints.coupon.index, couponData)
    return res
  } catch (error) {
    return error.response
  }
}

const getCoupons = async (records) => {
  try {
    const res = await axios.get(endpoints.coupon.index)
    return res
  } catch (error) {
    return error.response
  }
}

const getCouponAnalytics = async () => {
  try {
    const res = await axios.get(endpoints.coupon.analytics)
    return res
  } catch (error) {
    return error.response
  }
}

const getAvailableCoupons = async (records) => {
  try {
    const res = await axios.get(endpoints.coupon.available, { params: { records } })
    return res
  } catch (error) {
    return error.response
  }
}

const getCouponById = async (id) => {
  try {
    const res = await axios.get(endpoints.coupon.index + id)
    return res
  } catch (error) {
    return error.response
  }
}

const patchCoupon = async (id, couponData) => {
  try {
    const res = await axios.patch(endpoints.coupon.index + id, couponData)
    return res
  } catch (error) {
    return error.response
  }
}

const deleteCoupon = async (id, password) => {
  try {
    const res = await axios.delete(endpoints.coupon.index + id, { data: { password } })
    return res
  } catch (error) {
    return error.response
  }
}

const getWalletCoupons = async (limit) => {
  try {
    const res = await axios.get(endpoints.coupon.wallet, { limit })
    return res
  } catch (error) {
    return error.response
  }
}

const getWalletCoupon = async (id) => {
  try {
    const res = await axios.get(endpoints.coupon.wallet + id)
    return res
  } catch (error) {
    return error.response
  }
}

const postWalletCoupon = async (payload) => {
  try {
    const res = await axios.post(endpoints.coupon.wallet, payload)
    return res
  } catch (error) {
    return error.response
  }
}

const deleteWalletCoupon = async (id, password) => {
  try {
    const res = await axios.delete(endpoints.coupon.wallet + id, { data: { password } })
    return res
  } catch (error) {
    return error.response
  }
}

const patchWalletCoupon = async (id, payload) => {
  try {
    const res = await axios.patch(endpoints.coupon.wallet + id, payload)
    return res
  } catch (error) {
    return error.response
  }
}

const getWalletCouponCodes = async (id) => {
try {
    const res = await axios.get(endpoints.coupon.wallet + id + '/code')
    return res
  } catch (error) {
    return error.response
  }
}

const postWalletCouponCode = async (id, payload) => {
  try {
    const res = await axios.post(endpoints.coupon.wallet + id + '/code', payload)
    return res
  } catch (error) {
    return error.response
  }
}

const putWalletCouponCode = async (id, payload) => {
  try {
    const res = await axios.put(endpoints.coupon.wallet + id + '/code', payload)
    return res
  } catch (error) {
    return error.response
  }
}

const getRedeemableCoupons = async () => {
  try {
    const res = await axios.get(endpoints.coupon.redeemable)
    return res
  } catch (error) {
    return error.response
  }
}

const postRedeemableCoupon = async (payload) => {
  try {
    const res = await axios.post(endpoints.coupon.redeemable, payload)
    return res
  } catch (error) {
    return error.response
  }
}

export {
  postCoupon,
  getCoupons,
  getCouponById,
  getCouponAnalytics,
  getAvailableCoupons,
  getRedeemableCoupons,
  patchCoupon,
  deleteCoupon,
  getWalletCoupons,
  getWalletCoupon,
  postWalletCoupon,
  deleteWalletCoupon,
  patchWalletCoupon,
  getWalletCouponCodes,
  postWalletCouponCode,
  putWalletCouponCode,
  postRedeemableCoupon,
}
