import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '../../../context'
import useQuery from '../../../hooks/useQuery'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import {
  getWalletCoupon,
  patchWalletCoupon,
  postWalletCouponCode,
  putWalletCouponCode,
} from '../../../api'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { TabPanel, TabView } from 'primereact/tabview'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { format } from 'date-fns'
import { handleObjChange } from '../../../functions/setter'
import ConfirmDialogWithPassword from '../Dialog/ConfirmDialogWithPassword'
import { Message } from 'primereact/message'
import { Checkbox } from 'primereact/checkbox'
import ExportButton from '../../mini/ExportButton'

const emptyErr = {
  state: false,
  errors: [],
}

const emptyRecord = {
  id: null,
  name: '',
  description: '',
  amount: 0,
  validityStartDate: undefined,
  validityEndDate: undefined,
  loading: false,
  visible: false,
  isActive: false,
}

const emptyDeleteCodesRecord = {
  id: null,
  loading: false,
  visible: false,
  selectedCodes: [],
}

const emptyIssueCodesRecord = {
  id: null,
  loading: false,
  visible: false,
  issueCodeCount: 0,
}

export default function EditWalletCoupon({ fetchRecords }) {
  const dt = useRef(null)
  const history = useHistory()
  const toast = useRef(null)
  const query = useQuery()
  const id = parseInt(query.get('editId'))
  const [walletCoupon, setWalletCoupon] = useState(emptyRecord)
  const [password, setPassword] = useState('')
  const [walletCouponCodes, setWalletCouponCodes] = useState([])
  const [EditWalletCouponDialogVisible, setEditWalletCouponDialogVisible] = useState(false)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [activeIndex, setActiveIndex] = useState(0)
  const [deleteCodes, setDeleteCodes] = useState(emptyDeleteCodesRecord)
  const [issueCodes, setIssueCodes] = useState(emptyIssueCodesRecord)

  const fetchWalletCouponById = useCallback(async () => {
    if (!id) return
    const res = await getWalletCoupon(id)
    if (res) {
      if (res.status === 200) {
        setEditWalletCouponDialogVisible(true)
        setWalletCoupon((ps) => ({ ...ps, ...res.data.record }))
        setWalletCouponCodes(res.data.codes)
      }
    }
  }, [id])
  useEffect(() => {
    fetchWalletCouponById()
  }, [fetchWalletCouponById])

  const hideWalletCouponDialog = () => {
    setEditWalletCouponDialogVisible(false)
    history.push('/website/wallet-coupons')
  }

  const validateUpdateRecord = (item) => {
    const { name, description, amount, validityStartDate, validityEndDate } = item
    const err = []
    if (!name || name.length < 5 || name.length > 50) {
      err.push('Name is invalid or shorter than 5 characters or longer than 50 characters')
    }
    if (!description || description.length < 5 || description.length > 250) {
      err.push('Description is invalid or shorter than 5 characters or longer than 250 characters')
    }
    if (!amount) {
      err.push('Amount is required')
    }
    if (!validityStartDate) {
      err.push('Validity start date is required')
    }
    if (!validityEndDate) {
      err.push('Validity end date is required')
    }
    return err
  }

  const handleValidateUpdateRecord = () => {
    const errs = validateUpdateRecord(walletCoupon)
    if (errs.length > 0) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return false
    }
    setNewRecordError(emptyErr)
    return true
  }

  const handleCouponUpdate = async () => {
    handleObjChange(setWalletCoupon, 'loading', true)
    const {
      id,
      name,
      description,
      amount,
      validityStartDate,
      validityEndDate,
      issueCodeCount,
      isActive,
    } = walletCoupon
    const res = await patchWalletCoupon(id, {
      password,
      name,
      description,
      amount,
      validityStartDate,
      validityEndDate,
      issueCodeCount,
      isActive,
    })
    if (res) {
      handleObjChange(setWalletCoupon, 'loading', false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        // setWalletCoupon((ps) => [res.data.record, ...ps])
        fetchRecords()
        setPassword('')
      }
    }
    handleObjChange(setWalletCoupon, 'loading', false)
    handleObjChange(setWalletCoupon, 'visible', false)
  }

  const handleCouponCodesdelete = async () => {
    handleObjChange(setDeleteCodes, 'loading', true)
    const { selectedCodes } = deleteCodes
    const selectedCouponCodesArr = selectedCodes.map((code) => code.id)
    const res = await putWalletCouponCode(walletCoupon.id, {
      password,
      action: 'delete',
      codes: selectedCouponCodesArr,
    })
    if (res) {
      handleObjChange(setDeleteCodes, 'loading', false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        const updatedCouponCodes = walletCouponCodes.filter(
          (code) => !selectedCouponCodesArr.includes(code.id)
        )
        setWalletCouponCodes(updatedCouponCodes)
        setDeleteCodes(emptyDeleteCodesRecord)
        setPassword('')
      }
    }
    handleObjChange(setDeleteCodes, 'loading', false)
    handleObjChange(setDeleteCodes, 'visible', false)
  }

  const handleIssueCouponCodes = async () => {
    handleObjChange(setIssueCodes, 'loading', true)
    const { issueCodeCount } = issueCodes
    const res = await postWalletCouponCode(walletCoupon.id, {
      password,
      issueCodeCount,
    })
    if (res) {
      handleObjChange(setIssueCodes, 'loading', false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setWalletCouponCodes([...res.data.data])
        setIssueCodes(emptyIssueCodesRecord)
        setPassword('')
        setActiveIndex(1)
      }
    }
    handleObjChange(setIssueCodes, 'loading', false)
    handleObjChange(setIssueCodes, 'visible', false)
  }

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        style={{ width: '90%', maxWidth: '800px' }}
        visible={EditWalletCouponDialogVisible}
        footer={
          <div>
            <Button
              label="Close"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideWalletCouponDialog}
            />
            {!activeIndex && (
              <Button
                label="Update"
                icon="pi pi-save"
                className="p-button-primary"
                onClick={() => {
                  const isValid = handleValidateUpdateRecord()
                  if (isValid) handleObjChange(setWalletCoupon, 'visible', true)
                }}
              />
            )}
          </div>
        }
        onHide={hideWalletCouponDialog}
        header={
          <div className="p-text-capitalize">
            {/* <Badge
                className="p-text-bold"
                value={issueData.status === 'close' ? issueData.status + 'd' : issueData.status}
                style={issueData.status === 'close' ? badgeGreen : badgeRed}
              ></Badge> */}
            <span className="p-ml-2">Wallet Coupon #{walletCoupon?.id}</span>
          </div>
        }
      >
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Details">
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="name">Name</label>
                  <InputText
                    id="name"
                    type="text"
                    value={walletCoupon?.name}
                    onChange={(e) => handleObjChange(setWalletCoupon, 'name', e.target.value)}
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="amount">Amount</label>
                  <InputNumber
                    id="amount"
                    value={walletCoupon?.amount}
                    onChange={(e) => handleObjChange(setWalletCoupon, 'amount', e.value)}
                  />
                </div>
              </div>
              <div className="p-col-12">
                <div className="p-field">
                  <label htmlFor="description">Description</label>
                  <InputTextarea
                    id="description"
                    type="text"
                    value={walletCoupon?.description}
                    onChange={(e) =>
                      handleObjChange(setWalletCoupon, 'description', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="startDate">Validity Start Date</label>
                  <Calendar
                    id="startDate"
                    value={new Date(walletCoupon?.validityStartDate)}
                    onChange={(e) =>
                      handleObjChange(setWalletCoupon, 'validityStartDate', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="endDate">Validity End Date</label>
                  <Calendar
                    id="endDate"
                    value={new Date(walletCoupon?.validityEndDate)}
                    onChange={(e) =>
                      handleObjChange(setWalletCoupon, 'validityEndDate', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="p-col-12 p-fluid p-field p-d-flex p-ai-center gap-1by2">
                <Checkbox
                  inputId="active"
                  onChange={(e) => handleObjChange(setWalletCoupon, 'isActive', e.checked ? 1 : 0)}
                  checked={walletCoupon?.isActive ? true : false}
                ></Checkbox>
                <label htmlFor="active" className="p-mb-0">
                  {walletCoupon?.isActive ? 'Active' : 'Inactive'}
                </label>
              </div>
            </div>
            {newRecordError.state && (
              <>
                {newRecordError.errors.map((err, idx) => (
                  <div key={idx} className="p-fluid p-field p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                ))}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    type="button"
                    onClick={() => setNewRecordError(emptyErr)}
                    icon="pi pi-times"
                    label="Clear Warnings"
                    className="p-button-secondary"
                  />
                </div>
              </>
            )}
          </TabPanel>
          <TabPanel header="Codes">
            <div className="p-d-flex p-flex-column gap-1">
              <div className="p-d-flex p-jc-end gap-1">
                <div className="p-as-end">
                  <Button
                    className="p-button-danger"
                    label="Delete Selected Codes"
                    onClick={() => {
                      const isValid = deleteCodes.selectedCodes.length > 0
                      if (isValid) {
                        handleObjChange(setDeleteCodes, 'visible', true)
                      } else {
                        toast.current.show({
                          severity: 'warn',
                          summary: 'No Selection',
                          detail: 'Please select code(s) to delete',
                        })
                      }
                    }}
                  ></Button>
                </div>
                <div className="">
                  <ExportButton
                    datatable_ref={dt}
                    bypassOtp={true}
                    showTo={['superadmin', 'admin', 'manager']}
                  />
                </div>
              </div>
              <DataTable
                showGridlines
                selectionMode="checkbox"
                value={walletCouponCodes}
                exportFilename={`Coupon-${new Date().toLocaleDateString()}`}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 50, 100, 500]}
                className="datatable-responsive"
                columnResizeMode="fit"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} charges"
                emptyMessage="No Codes Found."
                rowHover
                removableSort
                responsiveLayout="stack"
                ref={dt}
                selection={deleteCodes.selectedCodes}
                onSelectionChange={(e) => {
                  const { value } = e
                  handleObjChange(setDeleteCodes, 'selectedCodes', value)
                }}
                dataKey="id"
              >
                <Column selectionMode="multiple" style={{ width: '0%' }}></Column>
                <Column style={{ width: '1%' }} header="ID" field="id" sortable={true} />
                <Column style={{ width: '10%' }} header="Code" field="code" />
                <Column
                  style={{ width: '10%' }}
                  header="Is Used"
                  field="isUsed"
                  body={(rd) =>
                    rd.customerId && rd.fname ? (
                      <Link to={`/customers?id=${rd.customerId}`} target="_blank">
                        {rd.fname} {rd.lname}
                      </Link>
                    ) : (
                      'No'
                    )
                  }
                />
                <Column
                  style={{ width: '10%' }}
                  header="Created At"
                  field="createdAt"
                  body={(rd) => format(new Date(rd.createdAt), 'PP')}
                />
                <Column
                  style={{ width: '0%' }}
                  header="Action"
                  body={(rd) => (
                    <Button
                      aria-label="Copy button"
                      icon="pi pi-copy"
                      className=""
                      onClick={() => {
                        navigator.clipboard.writeText(rd.code)
                        toast.current.show({
                          severity: 'success',
                          summary: 'Copied',
                          detail: 'Copied to clipboard',
                          life: 3000,
                        })
                      }}
                    />
                  )}
                />
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel header="Issue Codes">
            <div className="p-grid p-fluid p-ai-center">
              <div className="p-col-2">
                <label htmlFor="issueCodeCount">Issue Code Count: </label>
              </div>
              <div className="p-col p-field p-mb-0">
                <InputNumber
                  id="issueCodeCount"
                  value={issueCodes.issueCodeCount}
                  onChange={(e) => handleObjChange(setIssueCodes, 'issueCodeCount', e.value)}
                />
              </div>
              <div className="p-col-12">
                <Button
                  label="Issue Codes"
                  onClick={() => {
                    const isValid = issueCodes.issueCodeCount > 0
                    if (isValid) {
                      handleObjChange(setIssueCodes, 'visible', true)
                    } else {
                      toast.current.show({
                        severity: 'warn',
                        summary: 'Invalid Issue Code Count',
                        detail: 'Please enter a valid issue code count',
                      })
                    }
                  }}
                ></Button>
              </div>
            </div>
          </TabPanel>
        </TabView>
        <ConfirmDialogWithPassword
          headerText={`Confirm Update Wallet Coupon`}
          loading={walletCoupon.loading}
          visible={walletCoupon.visible}
          setPassword={setPassword}
          password={password}
          onHide={() => handleObjChange(setWalletCoupon, 'visible', false)}
          mainText={`Are you sure you want to update the coupon?`}
          yesText="Submit"
          loadingText={`Submitting`}
          onSubmit={handleCouponUpdate}
        />
        <ConfirmDialogWithPassword
          headerText={`Confirm Deletion of Codes`}
          loading={deleteCodes.loading}
          visible={deleteCodes.visible}
          setPassword={setPassword}
          password={password}
          onHide={() => handleObjChange(setDeleteCodes, 'visible', false)}
          mainText={`Are you sure you want to delete the selected codes?`}
          yesText="Submit"
          loadingText={`Submitting`}
          onSubmit={handleCouponCodesdelete}
        />
        <ConfirmDialogWithPassword
          headerText={`Confirm Code Issue`}
          loading={issueCodes.loading}
          visible={issueCodes.visible}
          setPassword={setPassword}
          password={password}
          onHide={() => handleObjChange(setIssueCodes, 'visible', false)}
          mainText={`Are you sure you want to generate ${issueCodes.issueCodeCount} code(s) for the coupon with ID: ${walletCoupon.id}?`}
          yesText="Submit"
          loadingText={`Submitting`}
          onSubmit={handleIssueCouponCodes}
        />
      </Dialog>
    </div>
  )
}
