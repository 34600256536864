const agentMenu = [
  {
    label: 'Dashboard',
    icon: 'pi pi-home',
    to: '/',
  },
  {
    label: 'Targets',
    icon: 'pi pi-calendar-times',
    to: '/targets',
  },
  {
    label: 'Order Management',
    icon: 'pi pi-inbox',
    items: [
      {
        label: 'My Orders',
        icon: 'pi pi-book',
        to: '/orders/my',
      },
      {
        label: 'All Orders',
        icon: 'pi pi-book',
        to: '/orders',
      },
      {
        label: 'Manual Orders',
        icon: 'pi pi-plus',
        to: '/orders/manual',
      },
      {
        label: 'Bin Orders',
        icon: 'pi pi-trash',
        to: '/orders/bin',
      },
      {
        label: 'Product Price',
        icon: 'pi pi-dollar',
        to: '/orders/website-products/price',
      },
    ],
  },
  {
    label: 'Payment Management',
    icon: 'pi pi-money-bill',
    items: [
      {
        label: 'Partner Method Quotas',
        icon: 'pi pi-calendar-plus',
        to: '/payment/partner-method-quotas',
      },
    ],
  },
  {
    label: 'Issue Management',
    icon: 'pi pi-list',
    items: [
      {
        label: 'Shipment Delays',
        icon: 'pi pi-search-plus',
        to: '/issues/shipment-delays',
      },
      {
        label: 'Complaints & Cases',
        icon: 'pi pi-comments',
        to: '/issues/cac',
      },
      {
        label: 'Dispute & Chargeback',
        icon: 'pi pi-flag',
        to: '/issues/dac',
      },
      {
        label: 'Tickets',
        icon: 'pi pi-ticket',
        to: '/issues/ticket-management',
      },
      {
        label: 'Undelivered Products',
        icon: 'pi pi-exclamation-triangle',
        to: '/issues/products/undelivered',
      },
    ],
  },
  {
    label: 'Customer Management',
    icon: 'pi pi-user',
    items: [
      {
        label: 'Customers',
        icon: 'pi pi-users',
        to: '/customers',
      },
      {
        label: 'Customer Enquiries',
        icon: 'pi pi-question',
        to: '/customers/enquiries',
      },
      {
        label: 'DND Customer',
        icon: 'pi pi-minus-circle',
        to: '/customers/dnd',
      },
      {
        label: 'Customer Blacklist',
        icon: 'pi pi-ban',
        to: '/customers/blacklist',
      },
      {
        label: 'Auto Cart',
        icon: 'pi pi-share-alt',
        to: '/customers/auto-cart',
      },
      {
        label: 'Customer Cart',
        icon: 'pi pi-shopping-cart',
        to: '/customers/cart',
      },
    ],
  },
  {
    label: 'User Management',
    icon: 'pi pi-id-card',
    items: [
      {
        label: 'Teams',
        icon: 'pi pi-users',
        to: '/users/teams',
      },
      {
        label: 'Activities',
        icon: 'pi pi-list',
        to: '/users/activities',
      },
      {
        label: 'Affiliate',
        icon: 'pi pi-link',
        to: '/users/affiliate',
      },
    ],
  },
]

export default agentMenu
