import { format } from 'date-fns'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'primereact/toolbar'
import React, { useRef, useState, useCallback, useEffect } from 'react'
import { handleObjChange } from '../../../../functions/setter'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { useGlobalContext } from '../../../../context'
import {
  deletePaymentPartner,
  getPaymentPartnerById,
  postPaymentPartner,
  uploadFileToS3,
} from '../../../../api'
import { Message } from 'primereact/message'
import { nanoid } from 'nanoid'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Toast } from 'primereact/toast'
import useQuery from '../../../../hooks/useQuery'
import EditPaymentPartnerDialog from './EditPaymentPartnerDialog'
import UploadImage from '../../../mini/UploadImage'
import AddPaymentMethodDialog from '../PaymentMethod/AddPaymentMethodDialog'
import AddPaymentPartnerDialog from './AddPaymentPartnerDialog'
import ChangeHistorySidebar from '../../ChangeHistorySidebar'

export default function PaymentPartnersTable({
  setActiveIndex,
  paymentPartners,
  setPaymentPartners,
  fetchPaymentPartners,
  paymentPartnerTableLoading,
  setPaymentPartnerTableLoading,
}) {
  const [addPartnerDialog, setAddPartnerDialog] = useState(false)
  const { toast } = useGlobalContext()
  //Delete Payment Partner
  const deletePaymentPartnerFn = async (id) => {
    if (id) {
      setPaymentPartnerTableLoading(true)
      const res = await deletePaymentPartner(id)
      if (res) {
        setPaymentPartnerTableLoading(false)
        if (res.status === 200) {
          setPaymentPartners((ps) => ps.filter((p) => p.id !== id))
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.data.message,
            life: 3000,
          })
        }
      }
    }
  }
  return (
    <div>
      <Toast ref={toast} />
      <Toolbar
        className="p-toolbar p-mb-3 p-flex-wrap gap-1mb-4"
        left={
          <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
            <h4 className="p-m-0">Payment Partners</h4>
            <Button
              label="Add"
              icon="pi pi-plus"
              className="p-button-primary"
              onClick={() => setAddPartnerDialog(true)}
            />
          </div>
        }
        right={
          <div>
            <div className="p-d-flex p-flex-wrap gap-1">
              <Button
                label="Refresh"
                icon="pi pi-refresh"
                onClick={() => {
                  fetchPaymentPartners()
                }}
              />
            </div>
          </div>
        }
      />
      <DataTable
        loading={paymentPartnerTableLoading}
        value={paymentPartners}
        removableSort
        showGridlines
        // filters={paymentMethodTableFilter}
        // header={paymentMethodsDataTableHeader}
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
      >
        <Column field="id" header="ID" sortable />
        <Column field="name" header="Name" sortable />
        <Column field="phone" header="Phone" />
        <Column field="email" header="Email" />
        <Column
          field="dob"
          header="Date of Birth"
          body={(rowData) => {
            return rowData?.dob ? format(new Date(rowData.dob), 'dd/MM/yyyy') : ''
          }}
        />
        <Column
          field="active"
          header="Status"
          body={(rowData) => {
            return rowData?.active ? 'Active' : 'Inactive'
          }}
        />
        <Column
          field="Action"
          header="Action"
          style={{
            width: '5%',
          }}
          body={(rd) => {
            return (
              <div className="p-d-flex gap-1by2 p-jc-center">
                <Link to={`?pid=${rd?.id}`}>
                  <Button
                    className="p-button p-button-info"
                    icon="pi pi-pencil"
                    tooltip="Edit"
                    tooltipOptions={{
                      position: 'bottom',
                    }}
                  />
                </Link>
                <Button
                  icon="pi pi-trash"
                  className="p-button p-button-danger"
                  tooltip="Delete"
                  tooltipOptions={{
                    position: 'bottom',
                  }}
                  onClick={() => {
                    // setDeletePartnerId(rd.id)
                    deletePaymentPartnerFn(rd.id)
                    // setPasswordConfirmationDialog(true)
                  }}
                />
                <Link to={`?history=${rd.id}`}>
                  <Button
                    tooltip="History"
                    tooltipOptions={{ position: 'bottom' }}
                    icon="pi pi-clock"
                    className="p-button p-button-help"
                  />
                </Link>
              </div>
            )
          }}
        />
      </DataTable>
      {/* PAYMENT PARTNER DIALOG  */}
      <AddPaymentPartnerDialog
        addPartnerDialog={addPartnerDialog}
        setAddPartnerDialog={setAddPartnerDialog}
        setPaymentPartnerTableLoading={setPaymentPartnerTableLoading}
        setPaymentPartners={setPaymentPartners}
      />
      {/* PAYMENT PARTNER DIALOG  */}
      {/* Edit Payment Partner Dialog  */}
      <EditPaymentPartnerDialog
        setActiveIndex={setActiveIndex}
        setPaymentPartnerTableLoading={setPaymentPartnerTableLoading}
        setPaymentPartners={setPaymentPartners}
      />
      <ChangeHistorySidebar
        header="Payment Partner Change History"
        historyType="im_paymentPartner"
      />
    </div>
  )
}
