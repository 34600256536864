import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '../../../context'
import useQuery from '../../../hooks/useQuery'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { getWalletCoupon } from '../../../api'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { TabPanel, TabView } from 'primereact/tabview'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { format } from 'date-fns'
import { Checkbox } from 'primereact/checkbox'
import ExportButton from '../../mini/ExportButton'
import { Toolbar } from 'primereact/toolbar'

const emptyRecord = {
  id: null,
  name: '',
  description: '',
  amount: 0,
  validityStartDate: undefined,
  validityEndDate: undefined,
  loading: false,
  visible: false,
  isActive: false,
}

export default function ViewWalletCoupon() {
  const dt = useRef(null)
  const history = useHistory()
  const toast = useRef(null)
  const query = useQuery()
  const id = parseInt(query.get('viewId'))
  const [walletCoupon, setWalletCoupon] = useState(emptyRecord)
  const [walletCouponCodes, setWalletCouponCodes] = useState([])
  const [viewWalletCouponDialogVisible, setViewWalletCouponDialogVisible] = useState(false)
  const fetchWalletCouponById = useCallback(async () => {
    if (!id) return
    const res = await getWalletCoupon(id)
    if (res) {
      if (res.status === 200) {
        setViewWalletCouponDialogVisible(true)
        setWalletCoupon(res.data.record)
        setWalletCouponCodes(res.data.codes)
      }
    }
  }, [id])
  useEffect(() => {
    fetchWalletCouponById()
  }, [fetchWalletCouponById])

  const hideWalletCouponDialog = () => {
    setViewWalletCouponDialogVisible(false)
    history.push('/website/wallet-coupons')
  }

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        style={{ width: '90%', maxWidth: '800px' }}
        visible={viewWalletCouponDialogVisible}
        footer={
          <div>
            <Button
              label="Close"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideWalletCouponDialog}
            />
          </div>
        }
        onHide={hideWalletCouponDialog}
        header={
          <div className="p-text-capitalize">
            {/* <Badge
                className="p-text-bold"
                value={issueData.status === 'close' ? issueData.status + 'd' : issueData.status}
                style={issueData.status === 'close' ? badgeGreen : badgeRed}
              ></Badge> */}
            <span className="p-ml-2">Wallet Coupon #{walletCoupon?.id}</span>
          </div>
        }
      >
        <TabView>
          <TabPanel header="Details">
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="name">Name</label>
                  <InputText id="name" type="text" value={walletCoupon?.name} disabled />
                </div>
              </div>
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="amount">Amount</label>
                  <InputNumber id="amount" type="number" value={walletCoupon?.amount} disabled />
                </div>
              </div>
              <div className="p-col-12">
                <div className="p-field">
                  <label htmlFor="description">Description</label>
                  <InputTextarea
                    id="description"
                    type="text"
                    value={walletCoupon?.description}
                    disabled
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="startDate">Validity Start Date</label>
                  <Calendar
                    id="startDate"
                    value={new Date(walletCoupon?.validityStartDate)}
                    disabled
                  />
                </div>
              </div>
              <div className="p-col-12 p-md-6">
                <div className="p-field">
                  <label htmlFor="endDate">Validity End Date</label>
                  <Calendar id="endDate" value={new Date(walletCoupon?.validityEndDate)} disabled />
                </div>
              </div>
              <div className="p-col-12 p-fluid p-field p-d-flex p-ai-center gap-1by2">
                <Checkbox
                  inputId="active"
                  // onChange={(e) => handleObjChange(setNewRecord, 'isActive', e.checked ? 1 : 0)}
                  disabled={true}
                  checked={walletCoupon.isActive ? true : false}
                ></Checkbox>
                <label htmlFor="active" className="p-mb-0">
                  {walletCoupon.isActive ? 'Active' : 'Inactive'}
                </label>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Codes">
            <div className="p-d-flex p-jc-end p-mb-3">
              <ExportButton
                datatable_ref={dt}
                bypassOtp={true}
                showTo={['superadmin', 'admin', 'manager', 'operations']}
              />
            </div>
            <DataTable
              ref={dt}
              exportFilename={`Coupon-${new Date().toLocaleDateString()}`}
              dataKey="id"
              showGridlines
              value={walletCouponCodes}
            >
              <Column style={{ width: '10%' }} header="ID" field="id" sortable={true} />
              <Column style={{ width: '20%' }} header="Code" field="code" />
              <Column
                style={{ width: '30%' }}
                header="Is Used"
                field="isUsed"
                body={(rd) =>
                  rd.customerId && rd.fname ? (
                    <Link to={`/customers?id=${rd.customerId}`} target="_blank">
                      {rd.fname} {rd.lname}
                    </Link>
                  ) : (
                    'No'
                  )
                }
              />
              <Column
                style={{ width: '40%' }}
                header="Created At"
                field="createdAt"
                body={(rd) => format(new Date(rd.createdAt), 'PP')}
              />
              <Column
                style={{ width: '0%' }}
                header="Action"
                body={(rd) => (
                  <Button
                    aria-label="Copy button"
                    icon="pi pi-copy"
                    className=""
                    onClick={() => {
                      navigator.clipboard.writeText(rd.code)
                      toast.current.show({
                        severity: 'success',
                        summary: 'Copied',
                        detail: 'Copied to clipboard',
                        life: 3000,
                      })
                    }}
                  />
                )}
              />
            </DataTable>
          </TabPanel>
        </TabView>
      </Dialog>
    </div>
  )
}
