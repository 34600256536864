import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import validator from 'validator'
import {
  deleteBlacklistCustomer,
  getBlacklistCustomer,
  getBlacklistCustomers,
  patchBlacklistCustomerStatus,
  postBlacklistCustomer,
  putBlacklistCustomerRemoveRequest,
} from '../../api/customer'
import { FilterMatchMode } from 'primereact/api'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Toolbar } from 'primereact/toolbar'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import { format } from 'date-fns'
import classNames from 'classnames'
import { useGlobalContext } from '../../context'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import HideDetails from '../../components/mini/HideDetails'
import ExportButton from '../../components/mini/ExportButton'
import { dtFilenameDate } from '../../functions/myDateFns'
import validatePhoneNumber from '../../functions/validatePhoneNumber'

export default function CustomerBlacklist() {
  const { user, agents } = useGlobalContext()

  const toast = useRef(null)
  const dt = useRef(null)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = parseInt(searchParams.get('id'))
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    removeRequest: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdBy: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const emptyRecord = {
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    streetAddress: null,
    addReason: null,
    conditionForRemoval: null,
  }
  const emptyRecordRequest = {
    id: null,
    removeReason: null,
    loading: false,
    visible: false,
  }
  const [filters, setFilters] = useState(emptyFilterObject)
  const [records, setRecords] = useState([])
  const [record, setRecord] = useState(emptyRecord)
  const [recordRequest, setRecordRequest] = useState(emptyRecordRequest)

  const [recordVisible, setRecordVisible] = useState(false)

  const emptyNewRecord = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    streetAddress: '',
    addReason: '',
    loading: false,
  }
  const [newRecord, setNewRecord] = useState(emptyNewRecord)
  const [tableLoading, setTableLoading] = useState(false)
  const [limit, setLimit] = useState(10)

  const handleNewRecordChanges = (target, value) => {
    setNewRecord((ps) => ({ ...ps, [target]: value }))
  }
  const handleRecordRequestChanges = (target, value) => {
    setRecordRequest((ps) => ({ ...ps, [target]: value }))
  }
  const emptyErr = {
    state: false,
    errors: [],
  }
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const validateNewRecord = (item) => {
    const { firstname, lastname, email, phone, streetAddress, addReason, conditionForRemoval } =
      item
    const { valid } = validatePhoneNumber(phone)
    const err = []

    if (!firstname) {
      err.push('Firstname is invalid')
    }
    if (!lastname) {
      err.push('Lastname is invalid')
    }
    if (!email || !validator.isEmail(email)) {
      err.push('Email is invalid')
    }
    if (!phone || !valid) {
      err.push('Phone is invalid')
    }
    if (!streetAddress) {
      err.push('Address is invalid')
    }
    if (!addReason) {
      err.push('Reason is invalid')
    }
    if (!conditionForRemoval) {
      err.push('Condition for removal is invalid')
    }
    return err
  }
  const resetNewRecord = () => {
    setNewRecord(emptyNewRecord)
  }
  const handleSubmitNewRecord = async () => {
    handleNewRecordChanges('loading', true)
    const errs = validateNewRecord(newRecord)
    if (errs.length > 0) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      handleNewRecordChanges('loading', false)
    } else {
      setNewRecordError(emptyErr)
      const { firstname, lastname, email, phone, streetAddress, addReason, conditionForRemoval } =
        newRecord
      const res = await postBlacklistCustomer({
        firstname,
        lastname,
        email,
        phone,
        streetAddress,
        addReason,
        conditionForRemoval,
      })
      if (res) {
        handleNewRecordChanges('loading', false)
        if (res.status === 201) {
          toast.current.show({
            severity: 'success',
            summary: 'Operation Successful',
            detail: res.data.message,
          })
          resetNewRecord()
          setRecords((ps) => [res.data.record, ...ps])
        }
      }
    }
    handleNewRecordChanges('loading', false)
  }
  const onChangeFilter = (value, target) => {
    let _filters = { ...filters }
    _filters[target].value = value
    setFilters(_filters)
  }
  const hideRecord = () => {
    setRecord(emptyRecord)
    setRecordVisible(false)
    history.push({
      pathname: '/customers/blacklist',
    })
  }
  const hideRecordRequest = () => {
    setRecordRequest(emptyRecordRequest)
  }
  const fetchRecord = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getBlacklistCustomer(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 404) {
        hideRecord()
      }
      if (res.status === 200) {
        setRecord((ps) => ({
          ...ps,
          ...res.data.record,
        }))
        setRecordVisible(true)
      }
    }
  }, [id])
  useEffect(() => fetchRecord(), [fetchRecord])

  const fetchRecords = useCallback(async () => {
    setTableLoading(true)
    const res = await getBlacklistCustomers(limit)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setRecords(res.data)
      }
    }
  }, [limit])
  useEffect(() => fetchRecords(), [fetchRecords])

  const removeConfirmDialogHandler = (id, message, header, icon, accept, reject) => {
    confirmDialog({
      message,
      header,
      icon,
      accept: () => accept(id),
      reject,
    })
  }

  const handlePatchBlacklistCustomerStatus = async (id) => {
    setTableLoading(true)
    const res = await patchBlacklistCustomerStatus(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setRecord((ps) => ({
          ...ps,
          ...res.data.record,
        }))

        setRecords((ps) =>
          [...ps].map((item) => {
            if (item.id === id) {
              return res.data.record
            }
            return item
          })
        )
      }
    }
  }
  const handleDeleteBlacklistCustomer = async (id) => {
    setTableLoading(true)
    const res = await deleteBlacklistCustomer(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setRecords((ps) => [...ps].filter((item) => item.id !== id))
        hideRecord()
      }
    }
  }
  const handleRecordRequestSubmit = async () => {
    handleRecordRequestChanges('loading', true)
    const id = recordRequest.id
    const res = await putBlacklistCustomerRemoveRequest(id, {
      removeReason: recordRequest.removeReason,
    })
    if (res) {
      handleRecordRequestChanges('loading', false)
      if (res.status === 200) {
        setRecords((ps) =>
          [...ps].map((item) => {
            if (item.id === id) {
              return res.data.record
            }
            return item
          })
        )
        hideRecordRequest()
      }
    }
  }
  return (
    <div className="card">
      <Toast ref={toast} />
      <TabView>
        <TabPanel header="Customers">
          <Toolbar
            className="p-toolbar p-flex-wrap gap-1"
            left={
              <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                <h4 className="p-m-0">Customer Blacklist</h4>
                <div>
                  <Link to="/orders">
                    <Button label="Orders" icon="pi pi-external-link" />
                  </Link>
                </div>
              </div>
            }
            right={
              <div className="p-d-flex p-flex-wrap gap-1">
                <Button label="Refresh" icon="pi pi-refresh" onClick={fetchRecords} />
                <ExportButton datatable_ref={dt} />
              </div>
            }
          ></Toolbar>
          <DataTable
            exportFilename={`Customer_Blacklist_${dtFilenameDate}`}
            ref={dt}
            loading={tableLoading}
            value={records}
            filters={filters}
            showGridlines
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
            className="datatable-responsive"
            columnResizeMode="fit"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
            rowHover
            emptyMessage="No record found."
            breakpoint="1200px"
            responsiveLayout="stack"
            header={
              <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                <div className=" p-d-flex p-ai-center">
                  <p className="p-m-0 p-pr-2">Created By</p>
                  <Dropdown
                    placeholder="User"
                    options={agents}
                    optionLabel="username"
                    optionValue="username"
                    filter
                    filterBy="username"
                    filterPlaceholder="Search..."
                    value={filters.createdBy.value}
                    onChange={(e) => {
                      onChangeFilter(e.target.value, 'createdBy')
                    }}
                    showClear
                  />
                </div>
                <div className=" p-d-flex p-ai-center">
                  <p className="p-m-0 p-pr-2">Request</p>
                  <Dropdown
                    placeholder="Request"
                    options={['Yes', 'No']}
                    value={filters.removeRequest.value}
                    onChange={(e) => {
                      onChangeFilter(e.target.value, 'removeRequest')
                    }}
                    showClear
                  />
                </div>
                <div className=" p-d-flex p-ai-center">
                  <p className="p-m-0 p-pr-2">Limit</p>
                  <Dropdown
                    placeholder="Limit"
                    options={[10, 50, 100, 500, 'All']}
                    onChange={(e) => {
                      setLimit(e.value)
                    }}
                    value={limit}
                  />
                </div>

                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    type="search"
                    value={filters.global.value}
                    onChange={(e) => {
                      onChangeFilter(e.target.value, 'global')
                    }}
                    placeholder="Search..."
                  />
                </span>
              </div>
            }
          >
            <Column style={{ width: '0%' }} header="ID" field="id" sortable />
            <Column style={{ width: '3%' }} header="Name" field="name" />
            <Column
              style={{ width: '1%' }}
              header="Email"
              field="email"
              body={(rd) => (
                <HideDetails value={rd.email} style={{ background: 'none', border: 0 }} />
              )}
            />
            <Column
              style={{ width: '1%' }}
              header="Phone"
              field="phone"
              body={(rd) => (
                <HideDetails value={rd.phone} style={{ background: 'none', border: 0 }} />
              )}
            />
            <Column style={{ width: '10%' }} header="Add Reason" field="addReason" />
            <Column style={{ width: '1%' }} header="Created By" field="createdBy" />
            <Column style={{ width: '1%' }} header="Remove Request" field="removeRequest" />
            <Column
              style={{ width: '1%' }}
              header="Action"
              body={(rd) => (
                <div className="p-d-flex gap-1by2 p-jc-start">
                  <Link to={`?id=${rd?.id}`}>
                    <Button
                      icon="pi pi-eye"
                      tooltip="Open Record"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                    />
                  </Link>
                  {['superadmin', 'admin', 'manager'].includes(user.role) ? (
                    <Button
                      icon="pi pi-trash"
                      className="p-button-danger"
                      tooltip="Delete Record"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                      onClick={() => {
                        setRecord(rd)
                        removeConfirmDialogHandler(
                          rd.id,
                          'Are you sure to want to delete this record',
                          `Delete Confirmation #${rd.id}`,
                          'pi pi-exclamation-triangle',
                          handleDeleteBlacklistCustomer
                        )
                      }}
                    />
                  ) : (
                    <Button
                      icon="pi pi-plus"
                      className=""
                      tooltip="Add Remove Record"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                      onClick={async () => {
                        setTableLoading(true)
                        const res = await getBlacklistCustomer(rd.id)
                        if (res) {
                          setTableLoading(false)
                          if (res.status === 200) {
                            const { removeReason, removeRequestBy, removeRequestDate } =
                              res.data.record
                            setRecordRequest((ps) => ({
                              ...ps,
                              id: rd.id,
                              removeReason,
                              removeRequestBy,
                              removeRequestDate,
                              visible: true,
                            }))
                          }
                        }
                      }}
                    />
                  )}
                  <Link to={`/customers/blacklist?history=${rd.id}`}>
                    <Button
                      tooltip="History"
                      tooltipOptions={{ position: 'bottom' }}
                      icon="pi pi-clock"
                      className="p-button p-button-help"
                    />
                  </Link>
                </div>
              )}
            />
          </DataTable>
        </TabPanel>
        <TabPanel header="Add Customer">
          <div className="p-fluid p-grid p-formgrid">
            <div className="p-col-12 p-lg-6 p-fluid p-field">
              <label className="p-col-fixed" htmlFor="">
                Firstname
              </label>
              <InputText
                placeholder="Enter customer firstname"
                value={newRecord.firstname}
                onChange={(e) => handleNewRecordChanges('firstname', e.target.value)}
              />
            </div>
            <div className="p-col-12 p-lg-6 p-fluid p-field">
              <label className="p-col-fixed" htmlFor="">
                Lastname
              </label>
              <InputText
                placeholder="Enter customer lastname"
                value={newRecord.lastname}
                onChange={(e) => handleNewRecordChanges('lastname', e.target.value)}
              />
            </div>
            <div className="p-col-12 p-lg-6 p-fluid p-field">
              <label className="p-col-fixed" htmlFor="">
                Email
              </label>
              <InputText
                placeholder="Enter customer email"
                value={newRecord.email}
                onChange={(e) => handleNewRecordChanges('email', e.target.value)}
              />
            </div>
            <div className="p-col-12 p-lg-6 p-fluid p-field">
              <label className="p-col-fixed" htmlFor="">
                Phone (with country code)
              </label>
              <InputText
                placeholder="Enter customer phone"
                value={newRecord.phone}
                onChange={(e) => handleNewRecordChanges('phone', e.target.value)}
              />
            </div>
            <div className="p-col-12 p-fluid p-field">
              <label className="p-col-fixed" htmlFor="">
                Street Address
              </label>
              <InputTextarea
                placeholder="Enter customer street streetAddress"
                value={newRecord.streetAddress}
                onChange={(e) => handleNewRecordChanges('streetAddress', e.target.value)}
              />
            </div>
            <div className="p-col-12 p-fluid p-field">
              <label className="p-col-fixed" htmlFor="">
                Reason To Blacklist
              </label>
              <InputTextarea
                placeholder="Enter reason why customer is been blacklisted"
                value={newRecord.addReason}
                onChange={(e) => handleNewRecordChanges('addReason', e.target.value)}
              />
            </div>
            <div className="p-col-12 p-fluid p-field">
              <label className="p-col-fixed" htmlFor="">
                Condition For Removal
              </label>
              <InputTextarea
                placeholder="Enter condition when customer can be removed from blacklist"
                value={newRecord.conditionForRemoval}
                onChange={(e) => handleNewRecordChanges('conditionForRemoval', e.target.value)}
              />
            </div>

            {newRecordError.state && (
              <>
                {newRecordError.errors.map((err, idx) => (
                  <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                ))}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    type="button"
                    onClick={() => setNewRecordError(emptyErr)}
                    icon="pi pi-times"
                    label="Clear Warnings"
                    className="p-button-secondary"
                  />
                </div>
              </>
            )}
            <div className="p-col-12">
              <Button
                label={newRecord.loading ? 'Submitting' : 'Submit'}
                icon="pi pi-save"
                loading={newRecord.loading}
                disabled={newRecord.loading}
                onClick={handleSubmitNewRecord}
              />
            </div>
          </div>
        </TabPanel>
      </TabView>

      <Dialog
        visible={recordVisible}
        header={`Blacklisted Customer #${id}`}
        className="w-full"
        style={{ width: '90%', maxWidth: '900px' }}
        onHide={hideRecord}
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => hideRecord()}
            />
          </div>
        }
      >
        <div className="p-grid">
          <div
            className={classNames('p-col-12', {
              'p-lg-6':
                record.removeRequest === 'No' ||
                (record.removeRequest === 'Yes' &&
                  !['superadmin', 'admin', 'manager'].includes(user.role)),
              'p-lg-4':
                record.removeRequest === 'Yes' &&
                ['superadmin', 'admin', 'manager'].includes(user.role),
            })}
          >
            <div className="card">
              <h5>Customer Details</h5>
              <hr />
              <p>Firstname: {record?.firstname}</p>
              <p>Lastname: {record?.lastname}</p>
              {/* <p>Email: {record?.email}</p> */}
              <p>
                Email:
                <HideDetails value={record?.email} style={{ maxWidth: '100px' }} type={'text'} />
              </p>
              <p>
                Phone:
                <HideDetails value={record?.phone} style={{ maxWidth: '100px' }} type={'text'} />
              </p>
              <p>Street Address: {record?.streetAddress}</p>
            </div>
          </div>
          <div
            className={classNames('p-col-12', {
              'p-lg-6':
                record.removeRequest === 'No' ||
                (record.removeRequest === 'Yes' &&
                  !['superadmin', 'admin', 'manager'].includes(user.role)),
              'p-lg-4':
                record.removeRequest === 'Yes' &&
                ['superadmin', 'admin', 'manager'].includes(user.role),
            })}
          >
            <div className="card">
              <h5>Other Details</h5>
              <hr />
              <p>Add Reason: {record?.addReason}</p>
              <p>Condition For Removal: {record?.conditionForRemoval}</p>

              <p>Created By: {record?.createdBy}</p>
              <p>Date: {record?.date && format(new Date(record.date), 'PPp')}</p>
            </div>
          </div>
          {record.removeRequest === 'Yes' &&
            ['superadmin', 'admin', 'manager'].includes(user.role) && (
              <div className="p-col-12 p-lg-4">
                <div className="card">
                  <h5>Remove Record Request</h5>
                  <hr />
                  <p>Request Reason: {record?.removeReason}</p>
                  <p>Request By: {record?.removeRequestBy}</p>
                  <p>
                    Request Date:{' '}
                    {record?.removeRequestDate && format(new Date(record.removeRequestDate), 'PPp')}
                  </p>
                  {['superadmin', 'admin', 'manager', 'teamlead'].includes(user.role) && (
                    <div className="p-d-flex p-flex-column gap-1">
                      <div className="flex-1">
                        <Button
                          label="Accept Request"
                          className="w-full"
                          icon="pi pi-check"
                          onClick={() => {
                            removeConfirmDialogHandler(
                              id,
                              'Are you sure tou want to accept this request and delete this record',
                              `Delete Confirmation #${id}`,
                              'pi pi-exclamation-triangle',
                              handleDeleteBlacklistCustomer
                            )
                          }}
                        />
                      </div>
                      <div className="flex-1">
                        <Button
                          label="Reject Request"
                          className="w-full p-button-danger"
                          icon="pi pi-times"
                          onClick={() => {
                            removeConfirmDialogHandler(
                              id,
                              'Are you sure to want to revoke this request',
                              `Reject Request Confirmation #${id}`,
                              'pi pi-exclamation-triangle',
                              handlePatchBlacklistCustomerStatus
                            )
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      </Dialog>
      <Dialog
        visible={recordRequest.visible}
        onHide={hideRecordRequest}
        header={`Add Remove Request For Record #${recordRequest.id}`}
        className="w-full"
        style={{ width: '90%', maxWidth: '400px' }}
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => hideRecordRequest()}
            />
            <Button
              label={recordRequest.loading ? 'Submitting' : 'Submit'}
              loading={recordRequest.loading}
              disabled={recordRequest.loading}
              icon="pi pi-save"
              onClick={() => handleRecordRequestSubmit()}
            />
          </div>
        }
      >
        <div className="p-grid p-formgrid">
          <div className="p-fluid p-field w-full">
            <label className="p-col-fixed">Reason</label>
            <InputTextarea
              placeholder="Remove Reason"
              autoResize
              value={recordRequest.removeReason}
              onChange={(e) => {
                handleRecordRequestChanges('removeReason', e.target.value)
              }}
            />
          </div>
          {recordRequest.removeRequestBy && (
            <div className="">
              <Message
                className="w-full p-d-flex p-jc-start p-mb-3"
                text={`${recordRequest.removeRequestBy} has already a remove request on ${
                  recordRequest.removeRequestDate &&
                  format(new Date(recordRequest.removeRequestDate), 'PP')
                }`}
              />
              <Message
                className="w-full p-d-flex p-jc-start"
                text="Existing request will be overwritten"
              />
            </div>
          )}
        </div>
      </Dialog>
      <ConfirmDialog />
      <ChangeHistorySidebar
        setTableLoading={setTableLoading}
        header="Customer Blacklist Change History"
        historyType="customer-blacklist"
      />
    </div>
  )
}
