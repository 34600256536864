import classNames from 'classnames'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Chips } from 'primereact/chips'
import { Column } from 'primereact/column'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { MultiSelect } from 'primereact/multiselect'
import { Password } from 'primereact/password'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  addProductQna,
  addStoreProductImages,
  addStoreProductStrengthAndQuantity,
  deleteProductQna,
  deleteStoreProductImage,
  deleteStoreProductStrengthAndQuantity,
  getProduct,
  getProductQuantities,
  getProductStrengths,
  getShippingCharges,
  patchProductQna,
  updateStoreProduct,
  updateStoreProductAltNames,
  updateStoreProductImage,
  updateStoreProductIngredients,
  updateStoreProductManufactureNames,
  updateStoreProductMce,
  updateStoreProductStrengthAndQuantity,
} from '../../../api'
import { useGlobalContext } from '../../../context'
import ConfirmationDialogWithInput from './ConfirmationDialogWithInput'
import WarningDialog from './WarningDialog'
// ProductDetailsDialog component to show product details dialog
export default function ProductDetailsDialog({
  setTableProdLoading,
  setTableLoading,
  handelSyncProduct,
}) {
  /**
   * setTableProdLoading @type {function} - function to set table product loading
   * setTableLoading @type {function} - function to set table loading
   * handelSyncProduct @type {function} - function to handle sync product in product table
   **/
  const history = useHistory()
  const { search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const productCode = searchParams.get('view')

  const {
    productTypes,
    shippingTags,
    toast,
    productCategoriesGroups: ctx_productCategoriesGroups,
    colorScheme,
  } = useGlobalContext()
  //
  const emptyMce = {
    ogproductuse: {
      // Done
      id: null,
      data: '',
    },
    ogproductinteraction: {
      // Done
      id: null,
      data: '',
    },
    ogproductsideeffect: {
      // Done
      id: null,
      data: '',
    },
    ogproductwarning: {
      // Done
      id: null,
      data: '',
    },
    ogproductstorage: {
      // Done
      id: null,
      data: '',
    },
    ogproductdata: {
      id: null,
      data: '',
    },
    ogproductWithdrawalSymptoms: {
      // Done
      id: null,
      data: '',
    },
    ogproductuseDrugAbuse: {
      // Done
      id: null,
      data: '',
    },
  }
  const empty_mce = {
    id: null,
    data: '',
  }
  const handleObjectChange = (setter, key, value) => {
    // check if setter is a function
    if (typeof setter !== 'function' || !key) return
    setter((prev) => ({ ...prev, [key]: value }))
  }

  /* PRODUCT */
  // const [productMce, setProductMce] = useState(emptyMce)
  const [withDrawalMce, setWithDrawalMce] = useState(empty_mce)
  const [sideEffectMce, setSideEffectMce] = useState(empty_mce)
  const [warningsAndPrecMce, setWarningsAndPrecMce] = useState(empty_mce)
  const [howToUseMce, setHowToUseMce] = useState(empty_mce)
  const [storageMce, setStorageMce] = useState(empty_mce)
  const [drugAbuseMce, setDrugAbuseMce] = useState(empty_mce)
  const [drugInteractionMce, setDrugInteractionMce] = useState(empty_mce)
  const [additionalInfoMce, setAdditionalInfoMce] = useState(empty_mce)
  const [editProductDialog, setEditProductDialog] = useState(false)
  const [product, setProduct] = useState({})
  const [productImage, setProductImage] = useState([])
  const [productImages, setProductImages] = useState([])
  const [productAlt, setProductAlt] = useState([])
  const [productStrength, setProductStrength] = useState([])
  const [productQuantity, setProductQuantity] = useState([])
  const [productQna, setProductQna] = useState([])
  const [productIngredients, setProductIngredients] = useState([])
  const [productManufactureNames, setProductManufactureNames] = useState([])
  const [productStrengthActiveIndex, setProductStrengthActiveIndex] = useState(0)
  const [files, setFiles] = useState([])
  const [chargesByAll, setChargesByAll] = useState([])
  const [chargesByShipper, setChargesByShipper] = useState([])
  const [chargesByProduct, setChargesByProduct] = useState([])
  const [deleteCloudImage, setDeleteCloudImage] = useState({
    imageId: '',
    loading: false,
    visible: false,
  })
  const newStrength_empty = {
    visible: false,
    loading: false,
    reason: '',
    strengthName: '',
    strengthCode: '',
    strengthRank: '',
    isStockOut: false,
    strengthStatus: 'active',
    quantities: [
      {
        quantity: 0,
        price: 0,
        isStockOut: false,
        qtyStatus: 'active',
      },
    ],
  }
  const [newStrength, setNewStrength] = useState(newStrength_empty)
  const newStrengthHide = () => {
    setNewStrength(newStrength_empty)
  }
  const deleteStrength_empty = {
    visible: false,
    loading: false,
    reason: '',
    password: '',
    strengthName: '',
    strengthCode: '',
    strengthIndex: null,
  }

  const [deleteStrength, setDeleteStrength] = useState(deleteStrength_empty)
  const hideDeleteStrength = () => {
    setDeleteStrength(deleteStrength_empty)
  }
  const handleDeleteStrength = async () => {
    handleObjectChange(setDeleteStrength, 'loading', true)
    const res = await deleteStoreProductStrengthAndQuantity(
      productCode,
      deleteStrength.strengthCode,
      deleteStrength.reason,
      deleteStrength.password
    )
    if (res) {
      handleObjectChange(setDeleteStrength, 'loading', false)
      if (res.status === 200) {
        // remove strength from productStrength
        const newProductStrength = [...productStrength]
        newProductStrength.splice(deleteStrength.strengthIndex, 1)
        setProductStrength(newProductStrength)
        // set the active index to last index if the current index not exist
        if (productStrengthActiveIndex > newProductStrength.length - 1) {
          setProductStrengthActiveIndex(newProductStrength.length - 1)
        }
        // hide dialog
        hideDeleteStrength()
      }
      toggleEdit('productStrengthAndQty')
    }
  }
  // diabaled states
  const defaultDisabled = {
    product: true,
    productImage: true,
    productImages: true,
    productAlt: true,
    productStrengthAndQty: true,
    productIngredients: true,
    productManufactureNames: true,
    howToUse: true,
    drugInteractions: true,
    sideEffects: true,
    warningsAndPrecautions: true,
    storage: true,
    withdrawSymptoms: true,
    drugAbuse: true,
    custom: true,
  }
  const [disabled, setDisabled] = useState(defaultDisabled)
  const fetchProduct = useCallback(async () => {
    if (productCode) {
      setTableProdLoading(true)
      const res = await getProduct(productCode)
      if (res) {
        setTableProdLoading(false)
        if (res.status === 200) {
          setEditProductDialog(true)
          setProduct(res.data.product)
          setProductImage(res.data.productImage)
          setProductImages(res.data.productImages)
          setProductAlt(res.data.productAlt)
          setProductStrength(res.data.productStrength)
          setProductQna(res.data.productQna)
          setProductQuantity(res.data.productQuantity)

          setWithDrawalMce(res.data.productMce.ogproductWithdrawalSymptoms)
          setSideEffectMce(res.data.productMce.ogproductsideeffect)
          setWarningsAndPrecMce(res.data.productMce.ogproductwarning)
          setStorageMce(res.data.productMce.ogproductstorage)
          setHowToUseMce(res.data.productMce.ogproductuse)
          setDrugAbuseMce(res.data.productMce.ogproductuseDrugAbuse)
          setDrugInteractionMce(res.data.productMce.ogproductinteraction)
          setAdditionalInfoMce(res.data.productMce.ogproductdata)
          setProductIngredients(res.data.productIngredients)
          setProductManufactureNames(res.data.productManufactureNames)
          const [resAllCharges, resShipperCharges, resProductCharges] = await Promise.all([
            await getShippingCharges('all'),
            await getShippingCharges(
              'shipper',
              res.data?.product?.shippingCompany,
              res.data?.product?.productType
            ),
            await getShippingCharges('product', productCode),
          ])
          if (resAllCharges && resProductCharges && resShipperCharges) {
            setChargesByAll(resAllCharges.data[0]?.charges || [])
            setChargesByProduct(resProductCharges.data[0]?.charges || [])
            setChargesByShipper(resShipperCharges.data[0]?.charges || [])
          }
        }
      }
    }
  }, [productCode])

  useEffect(() => fetchProduct(), [fetchProduct])

  const hideDialog = async () => {
    await handelSyncProduct(productCode)
    history.push({
      pathname: '/website/products',
    })
    setDisabled(defaultDisabled)
    setEditProductDialog(false)
    setTableLoading(false)
    setTableProdLoading(false)
    setProduct({})
    setProductAlt([])
    setProductStrength([])
    setProductQuantity([])
    // setProductMce({ ...emptyMce })
    setWithDrawalMce(empty_mce)
    setSideEffectMce(empty_mce)
    setWarningsAndPrecMce(empty_mce)
    setStorageMce(empty_mce)
    setHowToUseMce(empty_mce)
    setDrugAbuseMce(empty_mce)
    setDrugInteractionMce(empty_mce)
    setAdditionalInfoMce(empty_mce)
    setProductIngredients([])
    setProductManufactureNames([])
    disableEditProdLoading()
    setFiles([])
    setProductStrengthActiveIndex(0)
  }

  //
  const [newProductImages, setNewProductImages] = useState([])

  const [changeReason, setChangeReason] = useState('')
  const multipleImages = useRef(null)
  const emptyEditProdLoading = {
    target: null,
    loading: false,
  }
  const [editProdLoading, setEditProdLoading] = useState(emptyEditProdLoading)
  const disableEditProdLoading = () => {
    setEditProdLoading(emptyEditProdLoading)
  }
  const enableEditProdLoading = (target, loading) => {
    setEditProdLoading({
      target,
      loading,
    })
  }

  const footer = () => {
    return (
      <div>
        {/* <Button
          label="Save"
          icon="pi pi-plus"
          className="p-button-primary"
        /> */}
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      </div>
    )
  }

  const handleProdImagesUpload = (e) => {
    const files = e.target.files
    const imgs = []
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i)
      imgs.push({
        file: file,
        url: URL.createObjectURL(file),
      })
    }
    let temp = [...newProductImages].concat(imgs)
    setNewProductImages(temp)
  }

  /* Edit Handlers */
  const onInputChange = (target, value) => {
    let temp = { ...product }
    temp[target] = value
    if (target === 'productName') {
      temp.productSeoTitle = value
      temp.productSlug = value.replaceAll(' ', '-').toLowerCase()
    }
    setProduct(temp)
  }

  const saveProduct = async () => {
    enableEditProdLoading('product', true)
    const {
      shippingCompany,
      productName,
      productDescription,
      productMainCategory,
      productCategory,
      bestseller,
      productType,
      productSlug,
      productSeoTitle,
      productSeoDescription,
      productStatus,
      productDiscount,
      isStockOut,
    } = product
    const res = await updateStoreProduct(productCode, {
      shippingCompany,
      productName,
      productDescription,
      productMainCategory: productMainCategory.filter((i) => i).join(','),
      productCategory: productCategory.filter((i) => i).join(','),
      bestseller,
      productType,
      productSlug,
      productSeoTitle,
      productSeoDescription,
      productStatus,
      productDiscount,
      isStockOut,
      changeReason,
    })
    if (res) {
      toggleEdit('product')
      disableEditProdLoading()
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message || 'Operation was successfull',
        })
      }
    }
  }
  const confirmDelete = (accept, reject) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept,
      reject,
    })
  }
  const handleDeleteProductQna = async (id) => {
    const res = await deleteProductQna(id)
    if (res.status === 200) {
      toast?.current?.show({
        severity: 'success',
        summary: 'Operation Successful',
        detail: res.data.message || 'Operation was successfull',
      })
      setProductQna((ps) => ps.filter((item) => item.id !== id))
    }
  }
  const newProductQna_empty = {
    loading: false,
    visible: false,
    id: '',
    answer: '',
    mode: '',
  }
  const [tempProductQna, setTempProductQna] = useState(newProductQna_empty)
  const [productQnaQuestion, setProductQnaQuestion] = useState('')

  const handleTempProductQnaChange = (target, value) => {
    setTempProductQna((ps) => ({ ...ps, [target]: value }))
  }
  const tempProductQnaHide = () => {
    setTempProductQna(newProductQna_empty)
    setProductQnaQuestion('')
  }
  const saveEditProductQna = async () => {
    handleTempProductQnaChange('loading', true)
    const res = await patchProductQna(
      tempProductQna.id,
      tempProductQna.question,
      productQnaQuestion
    )
    if (res) {
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message || 'Operation was successfull',
        })
        setProductQna((ps) =>
          ps.map((item) => {
            if (item.id === res.data.data.id) {
              return res.data.data
            }
            return item
          })
        )
        tempProductQnaHide()
      } else {
        handleTempProductQnaChange('loading', false)
      }
    }
  }

  const saveNewProductQna = async () => {
    handleTempProductQnaChange('loading', true)
    const res = await addProductQna(productCode, tempProductQna.question, productQnaQuestion)
    if (res) {
      if (res.status === 201) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setProductQna((ps) => [...ps, res.data.data])
        tempProductQnaHide()
      } else {
        handleTempProductQnaChange('loading', false)
      }
    }
  }
  const saveFeaturedImage = async () => {
    enableEditProdLoading('productImage', true)

    let _files = files.map((file) => file.file)

    const res = await updateStoreProductImage(
      product.productCode,
      _files,
      productImage,
      changeReason
    )
    if (res) {
      toggleEdit('productImage')
      disableEditProdLoading()
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message || 'Operation was successfull',
        })
      }
    }
  }

  const saveProductImages = async () => {
    if (newProductImages.length === 0) {
      toggleEdit('productImages')
      disableEditProdLoading()
      return
    }
    enableEditProdLoading('productImages', true)

    let _files = newProductImages.map((file) => file.file)

    const res = await addStoreProductImages(product.productCode, _files, changeReason)
    if (res) {
      toggleEdit('productImages')
      disableEditProdLoading()

      if (res.status === 200) {
        setProductImages(res.data.productImages)
        setNewProductImages([])
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message || 'Operation was successfull',
        })
      }
    }
  }

  const saveNewProductStrength = async () => {
    setNewStrength((ps) => ({ ...ps, loading: true }))

    const res = await addStoreProductStrengthAndQuantity({
      productCode: product.productCode,
      strengthName: newStrength.strengthName,
      strengthRank: newStrength.strengthRank,
      isStockOut: newStrength.isStockOut,
      quantities: newStrength.quantities,
      changeReason: newStrength.reason,
      strengthStatus: newStrength.strengthStatus,
    })
    if (res) {
      setNewStrength({ ...newStrength, loading: false })
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message || 'Operation was successfull',
        })
        const updatedStnRes = await getProductStrengths({
          productCode: product.productCode,
        })
        if (updatedStnRes) {
          if (updatedStnRes.status === 200) {
            setProductStrength(updatedStnRes.data)
            // find the index of the new strength
            const newStrengthIndex = updatedStnRes.data.findIndex(
              (strength) => strength.strengthCode === res.data.strength.strengthCode
            )
            // set the new strength as active
            setProductStrengthActiveIndex(newStrengthIndex)
            const quantitiesRes = await getProductQuantities({
              productCode: product.productCode,
              strengthCode: res.data.strength.strengthCode,
            })
            if (quantitiesRes && quantitiesRes.status === 200) {
              setProductQuantity(quantitiesRes.data)
            }
          }
        }
        setNewStrength(newStrength_empty)
      }
      toggleEdit('productStrengthAndQty')
    }
  }

  const saveStrengthAndQuantity = async () => {
    enableEditProdLoading('productStrengthAndQty', true)
    const { strengthCode, strengthName, strengthRank, isStockOut, strengthStatus } =
      productStrength[productStrengthActiveIndex]
    const res = await updateStoreProductStrengthAndQuantity({
      productCode: product.productCode,
      strengthCode,
      strengthName,
      strengthRank,
      isStockOut,
      quantities: productQuantity,
      strengthStatus,
      changeReason,
    })
    if (res) {
      toggleEdit('productStrengthAndQty')
      disableEditProdLoading()
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })

        setProductQuantity(res.data.updatedQuantitiesRes)
        const updatedStnRes = await getProductStrengths({
          productCode: product.productCode,
        })
        if (updatedStnRes) {
          if (updatedStnRes.status === 200) {
            setProductStrength(updatedStnRes.data)
            // find the index of the new strength
            const newStrengthIndex = updatedStnRes.data.findIndex(
              (strength) => strength.strengthCode === strengthCode
            )
            // set the new strength as active
            setProductStrengthActiveIndex(newStrengthIndex)
          }
        }
      }
    }
  }

  const saveIngredients = async () => {
    enableEditProdLoading('productIngredients', true)
    const res = await updateStoreProductIngredients(
      product.productCode,
      productIngredients,
      changeReason
    )
    if (res) {
      toggleEdit('productIngredients')
      disableEditProdLoading()
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Operation was successfull',
        })
      }
    }
  }

  const saveManufactureNames = async () => {
    enableEditProdLoading('productManufactureNames', true)
    const res = await updateStoreProductManufactureNames(
      product.productCode,
      productManufactureNames,
      changeReason
    )
    if (res) {
      toggleEdit('productManufactureNames')
      disableEditProdLoading()
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Operation was successfull',
        })
      }
    }
  }

  const saveAltNames = async () => {
    enableEditProdLoading('productAlt', true)

    const res = await updateStoreProductAltNames(product.productCode, productAlt, changeReason)
    if (res) {
      toggleEdit('productAlt')
      disableEditProdLoading()

      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Operation was successfull',
        })
      }
    }
  }

  const saveMce = async (mce) => {
    enableEditProdLoading(mce, true)
    const mceAry = [
      'howToUse',
      'drugInteractions',
      'sideEffects',
      'warningsAndPrecautions',
      'storage',
      'withdrawSymptoms',
      'drugAbuse',
      'custom',
    ]
    if (!mceAry.includes(mce)) {
      return
    }
    let table = ''
    let data = null
    switch (mce) {
      case 'howToUse':
        table = 'ogproductuse'
        data = howToUseMce?.data
        break
      case 'drugInteractions':
        table = 'ogproductinteraction'
        data = drugInteractionMce?.data
        break
      case 'sideEffects':
        table = 'ogproductsideeffect'
        data = sideEffectMce.data
        break
      case 'warningsAndPrecautions':
        table = 'ogproductwarning'
        data = warningsAndPrecMce.data
        break
      case 'storage':
        table = 'ogproductstorage'
        data = storageMce.data
        break
      case 'withdrawSymptoms':
        table = 'ogproductWithdrawalSymptoms'
        data = withDrawalMce?.data
        break
      case 'drugAbuse':
        table = 'ogproductuseDrugAbuse'
        data = drugAbuseMce?.data
        break
      case 'custom':
        table = 'ogproductdata'
        data = additionalInfoMce?.data
        break
      default:
        break
    }
    const res = await updateStoreProductMce(product.productCode, table, data, changeReason)
    if (res) {
      toggleEdit(mce)
      disableEditProdLoading()
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Operation was successfull',
        })
      }
    }
  }

  const handleDeleteProductImage = async () => {
    if (!deleteCloudImage.imageId) return
    const res = await deleteStoreProductImage(deleteCloudImage.imageId)
    if (res) {
      if (res.status === 200) {
        setProductImages((ps) => [...ps.filter((i) => i.id !== deleteCloudImage.imageId)])
        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message || 'Operation was successfull',
        })
      }
      hideDeleteCloudImageDialog()
    }
  }

  const hideDeleteCloudImageDialog = () => {
    setDeleteCloudImage({ imageId: '', loading: false, visible: false })
  }

  /* Toggle Edit*/
  const toggleEdit = (target) => {
    setDisabled((ps) => ({ ...ps, [target]: !ps[target] }))
  }
  const changeTarget_empty = {
    visible: false,
    target: '',
    targetName: '',
  }
  const [changeTarget, setChangeTarget] = useState(changeTarget_empty)
  const hideReasonDialog = () => {
    setChangeTarget(changeTarget_empty)
    setChangeReason('')
  }
  const handleSaveChanges = async () => {
    const { target } = changeTarget
    hideReasonDialog()
    switch (target) {
      case 'product':
        await saveProduct()
        break
      case 'productImage':
        await saveFeaturedImage()
        break
      case 'productImages':
        await saveProductImages()
        break
      case 'productStrengthAndQty':
        await saveStrengthAndQuantity()
        break
      case 'productIngredients':
        await saveIngredients()
        break
      case 'productManufactureNames':
        await saveManufactureNames()
        break
      case 'productAlt':
        await saveAltNames()
        break
      case 'howToUse':
        await saveMce('howToUse')
        break
      case 'drugInteractions':
        await saveMce('drugInteractions')
        break
      case 'sideEffects':
        await saveMce('sideEffects')
        break
      case 'warningsAndPrecautions':
        await saveMce('warningsAndPrecautions')
        break
      case 'storage':
        await saveMce('storage')
        break
      case 'withdrawSymptoms':
        await saveMce('withdrawSymptoms')
        break
      case 'drugAbuse':
        await saveMce('drugAbuse')
        break
      case 'custom':
        await saveMce('custom')
        break
      default:
        return
    }
  }

  const EditAndSave = ({ target, targetName }) => {
    return (
      <>
        <div>
          {disabled[target] ? (
            <div className="p-d-flex p-jc-end">
              <Button
                onClick={() => toggleEdit(target, disabled)}
                label="Edit"
                icon="pi pi-pencil"
                style={{ width: '100px' }}
              />
            </div>
          ) : (
            <div className="p-d-flex p-jc-end">
              <Button
                label="Cancel"
                onClick={() => {
                  toggleEdit(target, disabled)
                  disableEditProdLoading()
                }}
                style={{ width: '100px' }}
                icon="pi pi-times"
                className="p-button-text"
              />
              <Button
                label={
                  target === editProdLoading.target && editProdLoading.loading
                    ? 'Saving...'
                    : `Save`
                }
                onClick={() => {
                  setChangeTarget({
                    visible: true,
                    target,
                    targetName: targetName,
                  })
                }}
                loading={target === editProdLoading.target && editProdLoading.loading}
                style={{ width: '100px' }}
                icon="pi pi-check"
              />
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div>
      <Dialog
        onHide={hideDialog}
        visible={editProductDialog}
        header={product?.productName}
        footer={footer}
        style={{ width: '90vw' }}
        modal
        className="p-fluid"
      >
        <div className="p-formgrid">
          <div className="p-grid p-mt-1">
            <div className=" p-col-4">
              {/* PRODUCT IMAGE */}

              <div className="card p-shadow-3">
                <h4 className="p-text-center">{product?.productName}</h4>

                <div className="p-grid">
                  <div className="p-col-12">
                    <img
                      className="product-image"
                      src={
                        files?.length > 0
                          ? files[0]?.url
                          : process.env.REACT_APP_IMAGE_BASE + product?.productImage
                      }
                      alt={product?.productImageAlt + ' Image might take sometime to load'}
                    />
                  </div>
                  <div className="p-col-12">
                    <div className="p-d-flex" style={{ gap: '1rem' }}>
                      <div style={{ flex: 2 }}>
                        <input
                          disabled={disabled.productImage}
                          type="file"
                          name="uploadFile"
                          id="uploadFile"
                          accept="image/*"
                          className="p-button"
                          onChange={(e) => {
                            setFiles([
                              {
                                file: e.target.files[0],
                                url: URL.createObjectURL(new Blob(e.target.files), {
                                  type: 'image/png',
                                }),
                              },
                            ])
                          }}
                          style={{ opacity: '0', display: 'none' }}
                        />
                        <label
                          disabled={disabled.productImage}
                          htmlFor="uploadFile"
                          className={classNames('p-button p-p-2', {
                            'p-button-secondary cursor-not-allowed': disabled.productImage,
                          })}
                        >
                          <i className="pi pi-pencil"></i>
                          <span className="p-ml-2">Change Image</span>
                        </label>
                      </div>
                      {files?.length > 0 && (
                        <Button
                          disabled={disabled.productImage}
                          style={{ flex: 1, minWidth: '100px' }}
                          label="Clear"
                          className="p-button-danger"
                          onClick={() => setFiles([])}
                        />
                      )}
                    </div>
                  </div>
                  <div className="p-col-12 p-my-0">
                    <EditAndSave targetName="Featured Image" target="productImage" />
                  </div>
                </div>
              </div>
              {/* product images */}
              <div className="card p-shadow-3 ">
                <input
                  multiple
                  type="file"
                  name=""
                  className="p-hidden"
                  accept="image/*"
                  ref={multipleImages}
                  onChange={handleProdImagesUpload}
                />
                <div className="p-grid">
                  <h6 className="p-col-12 p-m-0">Cloud Images</h6>
                  {productImages?.map((prod_img, idx) => (
                    <div key={idx} className="p-col-12 p-lg-6">
                      <img
                        className="product-image"
                        src={process.env.REACT_APP_IMAGE_BASE + prod_img?.path}
                        alt={product?.productImageAlt + 'Image might take sometime to load'}
                      />
                      {!disabled.productImages && (
                        <Button
                          disabled={deleteCloudImage.imageId === prod_img.id}
                          loading={deleteCloudImage.imageId === prod_img.id}
                          label={
                            deleteCloudImage.imageId === prod_img.id ? 'Deleting...' : 'Delete'
                          }
                          onClick={() => {
                            setDeleteCloudImage({
                              imageId: prod_img.id,
                              visible: true,
                            })
                          }}
                          className="p-button-danger"
                          icon="pi pi-trash"
                        />
                      )}
                    </div>
                  ))}
                  {newProductImages.length > 0 && (
                    <h6 className="p-col-12 p-m-0">
                      {'productImages' === editProdLoading.target && editProdLoading.loading ? (
                        <>
                          <i className="pi pi-spin pi-spinner p-mr-1"></i>
                          Uploading Images...
                        </>
                      ) : (
                        <>Local Images</>
                      )}
                    </h6>
                  )}
                  {newProductImages.map((prodImage, idx) => {
                    return (
                      <div className="p-col-12 p-lg-6" key={idx}>
                        <img
                          className="product-image"
                          src={prodImage.url}
                          alt={prodImage.file.name}
                        />
                        <Button
                          icon="pi pi-minus"
                          label="Remove"
                          className="p-button-danger "
                          onClick={() => {
                            setNewProductImages((ps) => ps.filter((_, i) => i !== idx))
                          }}
                        />
                      </div>
                    )
                  })}
                </div>

                {!disabled.productImages && (
                  <div className="p-col-12">
                    <Button
                      label="Add Product Images"
                      icon="pi pi-plus"
                      onClick={() => multipleImages.current.click()}
                    />
                  </div>
                )}
                <div className="p-col-12 p-my-0">
                  <EditAndSave targetName="Product Images" target="productImages" />
                </div>
              </div>

              {/* INGREDIENTS */}
              <div className="card p-shadow-3">
                <div className="p-formgrid p-grid">
                  <div className="p-field p-col-12">
                    <label htmlFor="price" className="p-col-12">
                      Ingredients
                    </label>
                    <Chips
                      placeholder="Enter ingredients"
                      disabled={disabled.productIngredients}
                      value={productIngredients}
                      onChange={(e) => setProductIngredients(e.value)}
                      separator=","
                    />
                  </div>
                  <div className=" p-col-12">
                    <EditAndSave targetName="Ingredients" target="productIngredients" />
                  </div>
                </div>
              </div>
              {/* MANUFACTURE*/}
              <div className="card p-shadow-3">
                <div className="p-formgrid p-grid">
                  <div className="p-field p-col-12">
                    <label htmlFor="price" className="p-col-12">
                      Manufactured By
                    </label>
                    <Chips
                      placeholder="Enter manufacture names"
                      disabled={disabled.productManufactureNames}
                      value={productManufactureNames}
                      onChange={(e) => setProductManufactureNames(e.value)}
                    />
                  </div>
                  <div className=" p-col-12">
                    <EditAndSave targetName="Manufactured By" target="productManufactureNames" />
                  </div>
                </div>
              </div>
              {/* ALT NAMES */}
              <div className="card p-shadow-3">
                <>
                  {!productAlt ? (
                    <h1>No productAlt</h1>
                  ) : (
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col-12">
                        <label htmlFor="price" className="p-col-12">
                          Alternative / Trade Names
                        </label>
                        <Chips
                          placeholder="Enter alternative / trade names"
                          disabled={disabled.productAlt}
                          value={productAlt}
                          onChange={(e) => setProductAlt(e.value)}
                        />
                      </div>
                      <div className=" p-col-12">
                        <EditAndSave targetName="Alternative Names" target="productAlt" />
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>

            {/* PRODUCT */}
            <div className="p-col-8">
              {!product ? (
                <h1>No Product</h1>
              ) : (
                <div>
                  <div className="card p-shadow-3">
                    <div className="p-grid p-formgrid">
                      <div className="p-field p-col-6">
                        <label htmlFor="name">Product Name</label>
                        <InputText
                          id="name"
                          required
                          disabled={disabled.product}
                          value={product?.productName}
                          autoFocus
                          className={classNames({
                            random: false,
                          })}
                          onChange={(e) => {
                            onInputChange('productName', e.target.value)
                          }}
                          placeholder="Enter Product Name"
                        />
                      </div>
                      <div className="p-field p-col-6">
                        <label htmlFor="name">Product SEO Name</label>
                        <InputText
                          id="name"
                          required
                          disabled={disabled.product}
                          value={product?.productSeoTitle}
                          autoFocus
                          onChange={(e) => {
                            onInputChange('productSeoTitle', e.target.value)
                          }}
                          placeholder="Enter Product SEO"
                        />
                      </div>

                      <div className="p-field p-col-12 p-lg-6">
                        <label htmlFor="quantity">Product Slug</label>
                        <InputText
                          placeholder="Enter Product Slug"
                          disabled={disabled.product}
                          value={product?.productSlug}
                          onChange={(e) => onInputChange('productSlug', e.target.value)}
                        />
                      </div>
                      <div className="p-field p-col-12 p-lg-6">
                        <label htmlFor="price">Discount</label>
                        <InputNumber
                          showButtons
                          buttonLayout="horizontal"
                          incrementButtonIcon="pi pi-plus"
                          decrementButtonIcon="pi pi-minus"
                          mode="decimal"
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          suffix="%"
                          step={0.25}
                          placeholder="Enter Discount"
                          disabled={disabled.product}
                          value={product?.productDiscount}
                          onValueChange={(e) => {
                            if (e.value > 100) {
                              e.value = 100
                            }
                            if (e.value < 0) {
                              e.value = 0
                            }
                            onInputChange('productDiscount', e.value)
                          }}
                        />
                      </div>
                      <div className="p-field p-col-12 p-lg-6">
                        <label htmlFor="productDescription">Product Description</label>
                        <InputTextarea
                          id="productDescription"
                          disabled={disabled.product}
                          value={product?.productDescription}
                          onChange={(e) => onInputChange('productDescription', e.target.value)}
                          required
                          rows={3}
                          cols={20}
                          placeholder="Enter Product Description"
                        />
                      </div>
                      <div className="p-field p-col-12 p-lg-6">
                        <label htmlFor="productSeoDescription"> Product SEO Description</label>
                        <InputTextarea
                          id="productSeoDescription"
                          disabled={disabled.product}
                          value={product?.productSeoDescription}
                          onChange={(e) => onInputChange('productSeoDescription', e.target.value)}
                          required
                          rows={3}
                          cols={20}
                          placeholder="Enter Product SEO Description"
                        />
                      </div>

                      <div className="p-field p-col-12">
                        <label htmlFor="price">Main Category</label>
                        <Chips
                          className="p-mb-2"
                          options={ctx_productCategoriesGroups}
                          optionGroupLabel="label"
                          optionGroupChildren="items"
                          optionLabel="name"
                          optionValue="name"
                          filter
                          filterBy="name"
                          filterPlaceholder="Search Categories"
                          disabled={disabled.product}
                          value={product?.productMainCategory}
                          onChange={(e) => {
                            // prevent enter key from adding chip
                            if (e.originalEvent.keyCode === 13) {
                              return
                            }
                            onInputChange('productMainCategory', e.value)
                          }}
                          placeholder="Main Categories"
                        />
                        <MultiSelect
                          options={ctx_productCategoriesGroups}
                          optionGroupLabel="label"
                          optionGroupChildren="items"
                          optionLabel="name"
                          optionValue="name"
                          filter
                          filterBy="name"
                          filterPlaceholder="Search Categories"
                          disabled={disabled.product}
                          value={product?.productMainCategory}
                          onChange={(e) => onInputChange('productMainCategory', e.value)}
                          placeholder="Select Category"
                        />
                      </div>
                      <div className="p-field p-col-12">
                        <label htmlFor="price">Sub Categories</label>
                        <Chips
                          className="p-mb-2"
                          options={ctx_productCategoriesGroups}
                          optionGroupLabel="label"
                          optionGroupChildren="items"
                          optionLabel="name"
                          optionValue="name"
                          filter
                          filterBy="name"
                          filterPlaceholder="Search Categories"
                          disabled={disabled.product}
                          value={product?.productCategory}
                          onChange={(e) => {
                            // prevent enter key from adding chip
                            if (e.originalEvent.keyCode === 13) {
                              return
                            }
                            onInputChange('productCategory', e.value)
                          }}
                          placeholder="Sub Categories"
                        />
                        <MultiSelect
                          options={ctx_productCategoriesGroups}
                          optionGroupLabel="label"
                          optionGroupChildren="items"
                          optionLabel="name"
                          optionValue="name"
                          filter
                          filterBy="name"
                          filterPlaceholder="Search Categories"
                          disabled={disabled.product}
                          value={product?.productCategory}
                          onChange={(e) => onInputChange('productCategory', e.value)}
                          placeholder="Select Categories"
                        />
                      </div>
                      <div className="p-field p-col-12 p-lg-6">
                        <label htmlFor="price">Product Type</label>
                        <Dropdown
                          options={productTypes}
                          disabled={disabled.product}
                          value={product?.productType}
                          onChange={(e) => onInputChange('productType', e.value)}
                          editable
                          placeholder="Select Type"
                          filter
                          className="multiselect-custom"
                        />
                      </div>
                      <div className="p-field p-col-12 p-lg-3">
                        <label htmlFor="price">Status</label>
                        <Dropdown
                          options={['active', 'inactive']}
                          placeholder="Select Status"
                          disabled={disabled.product}
                          value={product?.productStatus}
                          onChange={(e) => onInputChange('productStatus', e.value)}
                        />
                      </div>
                      <div className="p-field p-col-12 p-lg-3">
                        <label htmlFor="price">Stock Out</label>
                        <div>
                          <Checkbox
                            disabled={disabled.product}
                            checked={!!product?.isStockOut}
                            onChange={(e) => onInputChange('isStockOut', e.checked)}
                          />
                        </div>
                      </div>
                      <div className="p-field p-col-12 p-lg-6">
                        <label htmlFor="price">Bestseller</label>
                        <Dropdown
                          options={['Yes', 'No']}
                          placeholder="Select Bestseller Or Not"
                          disabled={disabled.product}
                          value={product?.bestseller}
                          onChange={(e) => onInputChange('bestseller', e.value)}
                        />
                      </div>
                      <div className="p-field p-col-12 p-lg-6">
                        <label htmlFor="price">Shipping Company</label>
                        <Dropdown
                          options={shippingTags}
                          placeholder="Select Shipping Company"
                          disabled={disabled.product}
                          value={product?.shippingCompany}
                          onChange={(e) => onInputChange('shippingCompany', e.value)}
                        />
                      </div>

                      <div className=" p-col-12">
                        <EditAndSave targetName="Product" target="product" />
                      </div>
                    </div>
                  </div>
                  <div className="card p-shadow-3">
                    <TabView
                      activeIndex={productStrengthActiveIndex}
                      onTabChange={async (e) => {
                        setProductStrengthActiveIndex(e.index)
                        setProductQuantity([])
                        const strengthCode = productStrength[e.index].strengthCode
                        const res = await getProductQuantities({
                          productCode,
                          strengthCode,
                        })
                        if (res && res.status === 200) {
                          setProductQuantity(res.data)
                        }
                      }}
                    >
                      {productStrength.map((strength, i) => (
                        <TabPanel key={`tab-stn-${i}`} header={strength.strengthName}>
                          <>
                            <div className="p-d-flex gap-1">
                              <div className="p-field">
                                <label htmlFor="stnName" className="">
                                  Strength Name
                                </label>
                                <InputText
                                  disabled={disabled.productStrengthAndQty}
                                  value={strength.strengthName}
                                  onChange={(e) => {
                                    let temp = [...productStrength]
                                    temp[i].strengthName = e.target.value
                                    setProductStrength(temp)
                                  }}
                                />
                              </div>
                              <div className="p-field">
                                <label htmlFor="stnName" className="">
                                  Strength Rank (greater than zero)
                                </label>
                                <InputNumber
                                  min={1}
                                  disabled={disabled.productStrengthAndQty}
                                  value={strength.strengthRank}
                                  onChange={(e) => {
                                    let temp = [...productStrength]
                                    temp[i].strengthRank = e.value
                                    setProductStrength(temp)
                                  }}
                                />
                              </div>
                              <div className="p-field">
                                <label htmlFor="stnName" className="">
                                  Strength Stock Out
                                </label>
                                <div className="">
                                  <Checkbox
                                    disabled={disabled.productStrengthAndQty}
                                    checked={!!strength.isStockOut}
                                    onChange={(e) => {
                                      let temp = [...productStrength]
                                      temp[i].isStockOut = e.checked ? 1 : 0
                                      setProductStrength(temp)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="p-field">
                                <label htmlFor="stnName" className="">
                                  Strength{' '}
                                  {strength.strengthStatus === 'active' ? 'Active' : 'Inactive'}
                                </label>
                                <div className="">
                                  <Checkbox
                                    disabled={disabled.productStrengthAndQty}
                                    checked={strength.strengthStatus === 'active'}
                                    onChange={(e) => {
                                      let temp = [...productStrength]
                                      temp[i].strengthStatus = e.checked ? 'active' : 'inactive'
                                      setProductStrength(temp)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {productQuantity.length > 0 && (
                              <div className=" p-grid p-my-2">
                                <label className="p-col-3">Quantity</label>
                                <label className="p-col-3">Pill Price</label>
                                <label className="p-col-3">Total Price</label>
                                <label className="p-col-1">Stock Out</label>
                                <label className="p-col-1">Active/Inactive</label>
                                <label className="p-col-1"></label>
                              </div>
                            )}
                            {productQuantity.map((quantity, i) => {
                              return (
                                <div key={i} className="p-formgrid p-grid">
                                  <div className="p-field p-col-3">
                                    <InputText
                                      disabled={disabled.productStrengthAndQty}
                                      value={quantity.quantity}
                                      onChange={(e) => {
                                        let temp = [...productQuantity]
                                        temp[i].quantity = e.target.value
                                        setProductQuantity(temp)
                                      }}
                                      placeholder="Enter Quantity Nos."
                                    />
                                  </div>
                                  <div className="p-field p-col-3">
                                    <InputText
                                      disabled={disabled.productStrengthAndQty}
                                      value={quantity.price}
                                      onChange={(e) => {
                                        let temp = [...productQuantity]
                                        temp[i].price = e.target.value
                                        setProductQuantity(temp)
                                      }}
                                      placeholder="Enter Quantity Price"
                                    />
                                  </div>
                                  <div className="p-field p-col-3">
                                    <InputNumber
                                      disabled
                                      mode="currency"
                                      currency="USD"
                                      locale="en-US"
                                      value={quantity.price * quantity.quantity}
                                    />
                                  </div>
                                  <div className="p-field p-col-1">
                                    <Checkbox
                                      disabled={disabled.productStrengthAndQty}
                                      checked={!!quantity.isStockOut}
                                      onChange={(e) => {
                                        let temp = [...productQuantity]
                                        temp[i].isStockOut = e.checked ? 1 : 0
                                        setProductQuantity(temp)
                                      }}
                                    />
                                  </div>
                                  <div className="p-field p-col-1">
                                    <Checkbox
                                      disabled={disabled.productStrengthAndQty}
                                      checked={quantity.qtyStatus === 'active'}
                                      onChange={(e) => {
                                        let temp = [...productQuantity]
                                        temp[i].qtyStatus = e.checked ? 'active' : 'inactive'
                                        setProductQuantity(temp)
                                      }}
                                    />
                                  </div>
                                  <div className="p-field p-col-1 p-mt-auto">
                                    <div className=" p-d-flex p-jc-center" style={{ gap: '1rem' }}>
                                      <Button
                                        disabled={
                                          disabled.productStrengthAndQty ||
                                          productQuantity.length === 1
                                        }
                                        className="p-button-icon p-button-danger"
                                        icon="pi pi-trash"
                                        onClick={() => {
                                          let temp = [...productQuantity]
                                          if (temp.length === 1) return
                                          temp = temp.filter((_, id) => id !== i)
                                          setProductQuantity(temp)
                                        }}
                                        tooltipOptions={{ position: 'bottom' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                            <div className="p-d-flex gap-1">
                              <Button
                                disabled={disabled.productStrengthAndQty}
                                className="p-button-icon"
                                icon="pi pi-plus"
                                label="Add Quantity To This Strength"
                                onClick={() => {
                                  let temp = [...productQuantity]
                                  temp.push({
                                    quantity: 0,
                                    price: 0,
                                    isStockOut: 0,
                                    qtyStatus: 'active',
                                  })
                                  setProductQuantity(temp)
                                }}
                                tooltipOptions={{ position: 'bottom' }}
                              />
                              <Button
                                disabled={disabled.productStrengthAndQty}
                                label="Delete This Strength And Quantities"
                                icon="pi pi-trash"
                                className="p-button-danger"
                                onClick={() => {
                                  setDeleteStrength((ps) => ({
                                    ...ps,
                                    visible: true,
                                    strengthCode:
                                      productStrength[productStrengthActiveIndex].strengthCode,
                                    strengthName:
                                      productStrength[productStrengthActiveIndex].strengthName,
                                    strengthIndex: productStrengthActiveIndex,
                                  }))
                                }}
                              />
                            </div>
                          </>
                        </TabPanel>
                      ))}
                    </TabView>
                    <div className="p-field">
                      <Button
                        disabled={disabled.productStrengthAndQty}
                        label="Add New Strength And Quantities"
                        icon="pi pi-plus"
                        onClick={() => {
                          setNewStrength((ps) => ({ ...ps, visible: true }))
                        }}
                      />
                    </div>
                    <EditAndSave
                      targetName="Strengths and Quantities"
                      target="productStrengthAndQty"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Charges */}
            <div className="p-col-12">
              <div className="card p-shadow-3">
                <div className="p-col-12">
                  <DataTable
                    value={chargesByAll}
                    header={
                      <div className="p-d-flex p-jc-between">
                        <span>Shipping charges by default</span>
                        {chargesByAll.length > 0 && (
                          <Link to={`/logistics?edit_sc_id=${chargesByAll[0]?.uid}`}>Edit</Link>
                        )}
                      </div>
                    }
                    showGridlines
                    columnResizeMode="fit"
                    emptyMessage="No records found."
                    rowHover
                  >
                    <Column header="Label" field="lable"></Column>
                    <Column header="Min Quantity" field="minQty"></Column>
                    <Column header="Max Quantity" field="maxQty"></Column>
                    <Column header="Delivery Time" field="shippingTime"></Column>
                    <Column header="Price" field="price"></Column>
                  </DataTable>
                  <DataTable
                    value={chargesByShipper}
                    header={
                      <div className="p-d-flex p-jc-between">
                        <span>Shipping charges by shipper</span>
                        {chargesByShipper.length > 0 && (
                          <Link to={`/logistics?edit_sc_id=${chargesByShipper[0]?.uid}`}>Edit</Link>
                        )}
                      </div>
                    }
                    showGridlines
                    columnResizeMode="fit"
                    emptyMessage="No records found."
                    rowHover
                  >
                    <Column header="Label" field="lable"></Column>
                    <Column header="Min Quantity" field="minQty"></Column>
                    <Column header="Max Quantity" field="maxQty"></Column>
                    <Column header="Delivery Time" field="shippingTime"></Column>
                    <Column header="Price" field="price"></Column>
                  </DataTable>
                  <DataTable
                    value={chargesByProduct}
                    header={
                      <div className="p-d-flex p-jc-between">
                        <span>Shipping charges by product</span>
                        {chargesByProduct.length > 0 && (
                          <Link to={`/logistics?edit_sc_id=${chargesByProduct[0]?.uid}`}>Edit</Link>
                        )}
                      </div>
                    }
                    showGridlines
                    columnResizeMode="fit"
                    emptyMessage="No records found."
                    rowHover
                  >
                    <Column header="Label" field="lable"></Column>
                    <Column header="Min Quantity" field="minQty"></Column>
                    <Column header="Max Quantity" field="maxQty"></Column>
                    <Column header="Delivery Time" field="shippingTime"></Column>
                    <Column header="Price" field="price"></Column>
                  </DataTable>
                </div>
              </div>
            </div>

            {/* MCEs */}
            <div className="p-col-12 ">
              <div className="card p-shadow-3">
                {/* Withdrawal Symptoms */}
                <div className="p-col-12">
                  <h3 className="p-text-center">Withdrawal Symptoms</h3>
                  <ReactQuill
                    value={withDrawalMce?.data}
                    onChange={(val) => handleObjectChange(setWithDrawalMce, 'data', val)}
                    readOnly={disabled.withdrawSymptoms}
                  />

                  <div className="p-mt-3">
                    <EditAndSave targetName="Withdrawal Symptoms" target={'withdrawSymptoms'} />
                  </div>
                </div>
                {/* Side Effects */}
                <div className="p-col-12">
                  <h3 className="p-text-center">Side Effects</h3>
                  <ReactQuill
                    value={sideEffectMce?.data}
                    onChange={(val) => handleObjectChange(setSideEffectMce, 'data', val)}
                    readOnly={disabled.sideEffects}
                  />
                  <div className="p-mt-3">
                    <EditAndSave targetName="Side Effects" target={'sideEffects'} />
                  </div>
                </div>
                {/* Warnings and Precautions */}
                <div className="p-col-12">
                  <h3 className="p-text-center">Warnings and Precautions</h3>
                  <ReactQuill
                    value={warningsAndPrecMce?.data}
                    onChange={(val) => handleObjectChange(setWarningsAndPrecMce, 'data', val)}
                    readOnly={disabled.warningsAndPrecautions}
                  />
                  <div className="p-mt-3">
                    <EditAndSave
                      targetName="Warnings and Precautions"
                      target={'warningsAndPrecautions'}
                    />
                  </div>
                </div>
                {/* Storage */}
                <div className="p-col-12">
                  <h3 className="p-text-center">Storage</h3>
                  <ReactQuill
                    value={storageMce?.data}
                    onChange={(val) => handleObjectChange(setStorageMce, 'data', val)}
                    readOnly={disabled.storage}
                  />
                  <div className="p-mt-3">
                    <EditAndSave targetName="Storage" target={'storage'} />
                  </div>
                </div>
                {/* How To Use */}
                <div className="p-col-12">
                  <h3 className="p-text-center">How To Use</h3>
                  <ReactQuill
                    value={howToUseMce?.data}
                    onChange={(val) => handleObjectChange(setHowToUseMce, 'data', val)}
                    readOnly={disabled.howToUse}
                  />
                  <div className="p-mt-3">
                    <EditAndSave targetName="How To Use" target={'howToUse'} />
                  </div>
                </div>
                {/* Drug Abuse */}
                <div className="p-col-12">
                  <h3 className="p-text-center">Drug Abuse</h3>
                  <ReactQuill
                    value={drugAbuseMce?.data}
                    onChange={(val) => handleObjectChange(setDrugAbuseMce, 'data', val)}
                    readOnly={disabled.drugAbuse}
                  />
                  <div className="p-mt-3">
                    <EditAndSave targetName="Drug Abuse" target={'drugAbuse'} />
                  </div>
                </div>
                {/* Drug Interactions */}
                <div className="p-col-12">
                  <h3 className="p-text-center">Drug Interactions (Not in use)</h3>
                  <ReactQuill
                    value={drugInteractionMce?.data}
                    onChange={(val) => handleObjectChange(setDrugInteractionMce, 'data', val)}
                    readOnly={disabled.drugInteractions}
                  />
                  <div className="p-mt-3">
                    <EditAndSave targetName="Drug Interactions" target={'drugInteractions'} />
                  </div>
                </div>
                {/* Custom */}
                <div className="p-col-12">
                  <h3 className="p-text-center">Additional Info</h3>
                  <ReactQuill
                    value={additionalInfoMce?.data}
                    onChange={(val) => handleObjectChange(setAdditionalInfoMce, 'data', val)}
                    readOnly={disabled.custom}
                  />
                  <div className="p-mt-3">
                    <EditAndSave targetName="Custom" target={'custom'} />
                  </div>
                </div>
              </div>
              <div></div>
            </div>

            {/* QNA */}
            <div className="p-col-12">
              <div className="card p-shadow-3">
                <div className="p-col-12">
                  <h3 className="p-text-center">FAQs</h3>
                  <hr />
                </div>
                {productQna.map((item, idx) => {
                  return (
                    <div key={idx} className="p-col-12">
                      <div className="p-field p-mb-1">
                        <label className="p-col-fixed">Question</label>
                        <InputText value={item.question} disabled />
                      </div>
                      <div className="p-field">
                        <label className="p-col-fixed">Answer</label>
                        <ReactQuill value={item.answer} readOnly />
                      </div>
                      <div className="p-d-flex gap-1">
                        <Button
                          icon="pi pi-trash"
                          className="p-button-danger"
                          label="Delete FAQ"
                          onClick={() => {
                            const accept = () => handleDeleteProductQna(item.id)
                            confirmDelete(accept)
                          }}
                        />
                        <Button
                          icon="pi pi-pencil"
                          className=""
                          label="Edit FAQ"
                          onClick={() => {
                            handleTempProductQnaChange('visible', true)
                            handleTempProductQnaChange('mode', 'edit')
                            handleTempProductQnaChange('question', item.question)
                            handleTempProductQnaChange('id', item.id)
                            setProductQnaQuestion(item.answer)
                          }}
                        />
                      </div>
                      <hr className="p-mb-0" />
                    </div>
                  )
                })}
                <div className="p-col-12">
                  <Button
                    icon="pi pi-plus"
                    className="p-button-info"
                    label="Add FAQ"
                    onClick={() => {
                      handleTempProductQnaChange('visible', true)
                      handleTempProductQnaChange('mode', 'add')
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* add FAQ */}
      <Dialog
        visible={tempProductQna.visible}
        onHide={tempProductQnaHide}
        style={{ width: '1100px' }}
        modal
        className="p-fluid"
        header={`Add new product FAQ for ${product?.productName}`}
        footer={
          <div className="">
            <Button
              icon="pi pi-times"
              label="Cancel"
              className="p-button-text"
              onClick={tempProductQnaHide}
            />
            {tempProductQna.mode === 'edit' && (
              <Button
                icon="pi pi-save"
                label="Save FAQ"
                onClick={saveEditProductQna}
                loading={tempProductQna.loading}
                disabled={tempProductQna.loading}
              />
            )}
            {tempProductQna.mode === 'add' && (
              <Button
                icon="pi pi-plus"
                label="Add FAQ"
                onClick={saveNewProductQna}
                loading={tempProductQna.loading}
                disabled={tempProductQna.loading}
              />
            )}
          </div>
        }
      >
        <div className="p-fluid p-grid p-formgrid">
          <div className="p-field p-col-12">
            <label className="p-col-fixed">Question</label>
            <InputText
              placeholder="Enter the question"
              value={tempProductQna.question}
              onChange={(e) => handleTempProductQnaChange('question', e.target.value)}
            />
          </div>
          <div className="p-field p-col-12">
            <label className="p-col-fixed">Answer</label>
            <ReactQuill
              placeholder="Enter the answer"
              value={productQnaQuestion}
              onChange={setProductQnaQuestion}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        onHide={newStrengthHide}
        className="w-full max-w-70"
        visible={newStrength.visible}
        header="Add new product strength"
        footer={
          <div className="p-d-flex p-jc-end">
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={newStrengthHide}
            />
            <Button
              label={newStrength.loading ? 'Saving...' : 'Save'}
              icon="pi pi-check"
              loading={newStrength.loading}
              disabled={
                newStrength.loading ||
                !newStrength.strengthName ||
                !newStrength.strengthRank ||
                !newStrength.quantities.length ||
                !newStrength.reason
              }
              onClick={saveNewProductStrength}
            />
          </div>
        }
      >
        <div className="">
          <div className="p-field p-d-flex p-ai-end">
            <label className="p-mr-3">Strength Name</label>
            <InputText
              className="flex-1"
              placeholder="Enter the strength name"
              value={newStrength.strengthName}
              onChange={(e) => {
                handleObjectChange(setNewStrength, 'strengthName', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-d-flex p-ai-end">
            <label className="p-mr-3">Strength Rank</label>
            <InputNumber
              className="flex-1"
              min={1}
              placeholder="Enter the strength rank"
              value={newStrength.strengthRank}
              onChange={(e) => {
                handleObjectChange(setNewStrength, 'strengthRank', e.value)
              }}
            />
          </div>
          <div className="p-field p-d-flex p-ai-start">
            <label className="p-mr-3">Strength Stock Out</label>
            <Checkbox
              checked={newStrength.isStockOut}
              onChange={(e) => {
                handleObjectChange(setNewStrength, 'isStockOut', e.checked)
              }}
            />
          </div>
          <div className="p-field p-d-flex p-ai-start">
            <label htmlFor="stnName" className="p-mr-3">
              Strength {newStrength.strengthStatus === 'active' ? 'Active' : 'Inactive'}
            </label>
            <div className="">
              <Checkbox
                checked={newStrength.strengthStatus === 'active'}
                onChange={(e) => {
                  let checks = e.checked ? 'active' : 'inactive'
                  handleObjectChange(setNewStrength, 'strengthStatus', checks)
                }}
              />
            </div>
          </div>
          <div className="p-grid p-my-2">
            <label className="p-col-3">Quantity</label>
            <label className="p-col-3">Pill Price</label>
            <label className="p-col-3">Total Price</label>
            <label className="p-col-1">Stock Out</label>
            <label className="p-col-1">Active/Inactive</label>
            <label className="p-col-1"></label>
            {newStrength.quantities.map((quantity, i) => {
              return (
                <>
                  <div className="p-col-3">
                    <InputText
                      value={quantity.quantity}
                      onChange={(e) => {
                        const _quantities = [...newStrength.quantities]
                        _quantities[i].quantity = e.target.value
                        handleObjectChange(setNewStrength, 'quantities', _quantities)
                      }}
                      className="w-full"
                      placeholder="Enter Quantity Nos."
                    />
                  </div>
                  <div className="p-col-3">
                    <InputText
                      value={quantity.price}
                      onChange={(e) => {
                        const _quantities = [...newStrength.quantities]
                        _quantities[i].price = e.target.value
                        handleObjectChange(setNewStrength, 'quantities', _quantities)
                      }}
                      className="w-full"
                      placeholder="Enter Quantity Price"
                    />
                  </div>
                  <div className="p-col-3">
                    <InputNumber
                      disabled
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      className="w-full"
                      value={quantity.price * quantity.quantity}
                    />
                  </div>
                  <div className="p-col-1">
                    <Checkbox
                      checked={!!quantity.isStockOut}
                      onChange={(e) => {
                        const _quantities = [...newStrength.quantities]
                        _quantities[i].isStockOut = e.checked ? 1 : 0
                        handleObjectChange(setNewStrength, 'quantities', _quantities)
                      }}
                    />
                  </div>
                  <div className="p-field p-col-1">
                    <Checkbox
                      checked={quantity.qtyStatus === 'active'}
                      onChange={(e) => {
                        const _quantities = [...newStrength.quantities]
                        _quantities[i].qtyStatus = e.checked ? 'active' : 'inactive'
                        handleObjectChange(setNewStrength, 'quantities', _quantities)
                      }}
                    />
                  </div>
                  <div className="p-col-1 p-mt-auto">
                    <div className=" p-d-flex p-jc-center" style={{ gap: '1rem' }}>
                      <Button
                        className="p-button-icon p-button-danger"
                        icon="pi pi-trash"
                        tooltip="Delete"
                        onClick={() => {
                          const _quantities = [...newStrength.quantities]
                          if (_quantities.length === 1) return
                          _quantities.splice(i, 1)
                          handleObjectChange(setNewStrength, 'quantities', _quantities)
                        }}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>
                  </div>
                </>
              )
            })}
          </div>
          <div className="p-field">
            <Button
              className="p-button-icon"
              icon="pi pi-plus"
              label="Add Quantity"
              onClick={() => {
                const _quantities = [
                  ...newStrength.quantities,
                  {
                    qtyStatus: 'active',
                    quantity: 0,
                    price: 0,
                  },
                ]
                handleObjectChange(setNewStrength, 'quantities', _quantities)
              }}
            />
          </div>
          <div className="p-d-flex p-ai-center">
            <label className="p-mr-3">Reason</label>
            <InputText
              className="flex-1"
              placeholder="Enter reason for adding this strength"
              value={newStrength.reason}
              onChange={(e) => {
                handleObjectChange(setNewStrength, 'reason', e.target.value)
              }}
            />
          </div>
        </div>
      </Dialog>
      <ConfirmationDialogWithInput
        visible={changeTarget?.visible}
        onHide={hideReasonDialog}
        header={`Reason for a change in ${changeTarget?.targetName}`}
        inputLabel={'Reason'}
        inputPlaceholder={'Enter reason'}
        confirmationText={`
Provide a reason for a change in ${changeTarget?.targetName} below and click on submit to proceed.
`}
        onSubmit={handleSaveChanges}
        inputValue={changeReason}
        setInputValue={setChangeReason}
      />
      {/* strength and quantity delete */}
      <ConfirmationDialogWithInput
        customInputs
        visible={deleteStrength.visible}
        onHide={hideDeleteStrength}
        onSubmit={handleDeleteStrength}
        loading={deleteStrength.loading}
        header={`Delete "${deleteStrength?.strengthName}" Strength and its Quantities`}
      >
        <div className="p-d-flex p-ai-center gap-1">
          <i
            className="pi pi-exclamation-triangle p-mr-2"
            style={{
              fontSize: '2rem',
            }}
          />
          <div className="p-fluid">
            <p>
              Are you sure you want to delete <b>{deleteStrength?.strengthName}</b> strength and its
              quantities?
            </p>
            <div className="p-field">
              <label htmlFor="pwd">Your Password</label>
              <Password
                toggleMask
                feedback={false}
                id="pwd"
                placeholder="Enter your password"
                value={deleteStrength.password}
                onChange={(e) => {
                  handleObjectChange(setDeleteStrength, 'password', e.target.value)
                }}
              />
            </div>
            <div className="p-field">
              <label htmlFor="reason">Change Reason</label>
              <InputText
                id="reason"
                type="text"
                placeholder="Enter reason for change"
                value={deleteStrength.reason}
                onChange={(e) => {
                  handleObjectChange(setDeleteStrength, 'reason', e.target.value)
                }}
              />
            </div>
          </div>
        </div>
      </ConfirmationDialogWithInput>
      <ConfirmDialog />
      <WarningDialog
        visible={deleteCloudImage.visible}
        onHide={hideDeleteCloudImageDialog}
        loading={deleteCloudImage.loading}
        onSubmit={() => {
          setDeleteCloudImage({ ...deleteCloudImage, loading: true })
          handleDeleteProductImage()
        }}
      />
    </div>
  )
}
