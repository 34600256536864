import classNames from 'classnames'
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { MultiSelect } from 'primereact/multiselect'
import { ToggleButton } from 'primereact/togglebutton'
import React, { useCallback, useEffect, useState } from 'react'
import { getOrderSales } from '../../../api'
import { useGlobalContext } from '../../../context'
import moneyFormatter from '../../../functions/moneyFormatter'
import { serialize } from '../../../functions/serializeUrl'
import MyDatesPicker from '../../mini/MyDatesPicker'
import { paidStatus, unpaidStatus, unqualifiedStatus } from '../../../data/orderStatusValues'
const salesDateOptions = [
  {
    name: 'Last 4 Weeks',
    value: 'last4weeks',
  },
  {
    name: 'Last 3 Months',
    value: 'last3months',
  },
  {
    name: 'Custom',
    value: 'custom',
  },
]
const emptySales = {
  titel: 'Date Of Sale',
  togglePaidSales: false,
  toggleUnpaidSales: false,
  toggleUnqualifiedSales: false,
  dateFrom: null,
  dateTo: null,
  titleDates: null,
  cancelled_amount: NaN,
  cancelled_orders: NaN,
  delivered_amount: NaN,
  delivered_orders: NaN,
  undelivered_amount: NaN,
  undelivered_orders: NaN,
  draft_amount: NaN,
  draft_orders: NaN,
  // ineligcancel_amount: NaN,
  // ineligcancel_orders: NaN,
  // ineligible_amount: NaN,
  // ineligible_orders: NaN,
  paid_amount: NaN,
  paid_orders: NaN,
  partial_amount: NaN,
  partial_orders: NaN,
  pending_amount: NaN,
  pending_orders: NaN,
  processed_amount: NaN,
  processed_orders: NaN,
  reshipment_initiated_orders: NaN,
  reshipment_initiated_amount: NaN,
  reshipment_completed_orders: NaN,
  reshipment_completed_amount: NaN,
  // refund_initiated_orders: NaN,
  // refund_initiated_amount: NaN,
  // refund_completed_orders: NaN,
  // refund_completed_amount: NaN,
  refunded_orders: NaN,
  refunded_amount: NaN,
  shipped_amount: NaN,
  shipped_orders: NaN,
  total_amount: NaN,
  total_orders: NaN,
  tracking_amount: NaN,
  tracking_orders: NaN,
  trackinglive_amount: NaN,
  trackinglive_orders: NaN,
  unpaid_amount: NaN,
  unpaid_orders: NaN,
  unqualified_amount: NaN,
  unqualified_orders: NaN,
}
const emptyCheckSales = {
  Total: false,
  Paid: false,
  Processed: false,
  Shipped: false,
  Tracking: false,
  'Tracking-Live': false,
  Delivered: false,
  Undelivered: false,
  Partial: false,
  'Reshipment-Initiated': false,
  'Reshipment-Completed': false,
  'Refunded': false,
  Unpaid: false,
  Pending: false,
  Draft: false,
  Cancelled: false,
  Unqualified: false,
}

const dateFormat = 'dd-MM-yyyy'

export default function SalesCardsDashboard() {
  const { user, agents, leadSource: ctx_leadSource, dashWebsites } = useGlobalContext()
  // *** LOADERS ***
  const [fetchSalesLoading, setFetchSalesLoading] = useState(false)
  const [customLoading, setCustomLoading] = useState(false)

  // *** OPTIONS ***
  const [salesLeadsFrom, setSalesLeadsFrom] = useState([])
  const [salesWebsites, setSalesWebsites] = useState([])
  const [salesDates, setSalesDates] = useState([])
  const [salesAgents, setSalesAgents] = useState([])

  // *** SALES ***
  const [thisDaySales, setThisDaySales] = useState({
    ...emptySales,
    titel: "Today's",
  })
  const [thisWeekSales, setThisWeekSales] = useState({
    ...emptySales,
    titel: "This Week's",
  })
  const [thisMonthSales, setThisMonthSales] = useState({
    ...emptySales,
    titel: "This Month's",
  })

  const [week1Sales, setWeek1Sales] = useState({
    ...emptySales,
    titel: 'Week 1',
  })
  const [week2Sales, setWeek2Sales] = useState({
    ...emptySales,
    titel: 'Week 2',
  })
  const [week3Sales, setWeek3Sales] = useState({
    ...emptySales,
    titel: 'Week 3',
  })
  const [week4Sales, setWeek4Sales] = useState({
    ...emptySales,
    titel: 'Week 4',
  })

  const [month1Sales, setMonth1Sales] = useState({
    ...emptySales,
    titel: 'Month 1',
  })
  const [month2Sales, setMonth2Sales] = useState({
    ...emptySales,
    titel: 'Month 2',
  })
  const [month3Sales, setMonth3Sales] = useState({
    ...emptySales,
    titel: 'Month 3',
  })

  const [customSales, setCustomSales] = useState({
    ...emptySales,
    titel: 'Custom',
  })
  // *** CHECKS ***

  const [thisDaySalesChecks, setThisDaySalesChecks] = useState(emptyCheckSales)
  const [thisWeekSalesChecks, setThisWeekSalesChecks] = useState(emptyCheckSales)
  const [thisMonthSalesChecks, setThisMonthSalesChecks] = useState(emptyCheckSales)

  const [week1SalesChecks, setWeek1SalesChecks] = useState(emptyCheckSales)
  const [week2SalesChecks, setWeek2SalesChecks] = useState(emptyCheckSales)
  const [week3SalesChecks, setWeek3SalesChecks] = useState(emptyCheckSales)
  const [week4SalesChecks, setWeek4SalesChecks] = useState(emptyCheckSales)

  const [month1SalesChecks, setMonth1SalesChecks] = useState(emptyCheckSales)
  const [month2SalesChecks, setMonth2SalesChecks] = useState(emptyCheckSales)
  const [month3SalesChecks, setMonth3SalesChecks] = useState(emptyCheckSales)
  const [customSalesChecks, setCustomSalesChecks] = useState(emptyCheckSales)

  // *** DATES ***
  const [salesDateCodeCustom, setSalesDateCodeCustom] = useState(null)
  const [salesRangeDatesCustom, setSalesRangeDatesCustom] = useState(null)
  const [salesFilterDatesCustom, setSalesFilterDatesCustom] = useState({
    dateTo: null,
    dateFrom: null,
  })

  const handleChangeObject = ({ setter, getter, target, value, event }) => {
    const ps = { ...getter }
    if (event === 'saleCheck') {
      if (target === 'Total') {
        paidStatus.forEach((s) => {
          ps[s] = value
        })
        unpaidStatus.forEach((s) => {
          ps[s] = value
        })
      }
      // paid
      if (paidStatus.includes(target)) {
        if (target === 'Paid') {
          paidStatus.forEach((s) => {
            ps[s] = value
          })
        } else {
          ps[target] = value
          if ([...paidStatus].slice(1).every((s) => ps[s] === value)) {
            paidStatus.forEach((s) => {
              ps[s] = value
            })
          } else {
            ps['Paid'] = false
          }
        }
      }
      // unpaid
      if (unpaidStatus.includes(target)) {
        if (target === 'Unpaid') {
          unpaidStatus.forEach((s) => {
            ps[s] = value
          })
        } else {
          ps[target] = value
          if ([...unpaidStatus].slice(1).every((s) => ps[s] === value)) {
            unpaidStatus.forEach((s) => {
              ps[s] = value
            })
          } else {
            ps['Unpaid'] = false
          }
        }
      }
      // unqualified
      if (unqualifiedStatus.includes(target)) {
        if (target === 'Unqualified') {
          unqualifiedStatus.forEach((s) => {
            ps[s] = value
          })
        } else {
          ps[target] = value
          if ([...unqualifiedStatus].slice(1).every((s) => ps[s] === value)) {
            unqualifiedStatus.forEach((s) => {
              ps[s] = value
            })
          } else {
            ps['Unqualified'] = false
          }
        }
      }
      ps[target] = value
      let nps = { ...ps }
      delete nps['Total']
      unqualifiedStatus.forEach((s) => {
        delete nps[s]
      })
      if (Object.values(nps).every((v) => v === true)) {
        ps['Total'] = true
      } else {
        ps['Total'] = false
      }
      setter(ps)
    } else {
      ps[target] = value
      setter(ps)
    }
  }
  const viewSales = ({ dateFrom, dateTo, orderStatus }) => {
    const searchObj = {
      dateFrom,
      dateTo,
      orderStatus,
      masterid: [...salesAgents].join(' '),
    }
    window.open(`/orders?${serialize(searchObj)}`, '_blank')
  }

  const fetchOrderSales = useCallback(
    async (agents, leads, websites) => {
      setFetchSalesLoading(true)
      if (user.role) {
        if (user.role === 'agent') {
          agents = [user.id]
        }
        if (['teamlead'].includes(user.role) && !agents.length) {
          agents = user.members.map((m) => m.id)
        }

        const date = new Date()
        const [
          // current
          thisday_res,
          thisweek_res,
          thismonth_res,
          // weeks
          week1_res,
          week2_res,
          week3_res,
          week4_res,
          // months
          month1_res,
          month2_res,
          month3_res,
        ] = await Promise.all([
          // current
          getOrderSales(startOfDay(date), endOfDay(date), agents, leads, websites),
          getOrderSales(startOfWeek(date), endOfWeek(date), agents, leads, websites),
          getOrderSales(startOfMonth(date), endOfMonth(date), agents, leads, websites),
          // weeks
          getOrderSales(
            startOfWeek(subWeeks(date, 1)),
            endOfWeek(subWeeks(date, 1)),
            agents,
            leads,
            websites
          ),
          getOrderSales(
            startOfWeek(subWeeks(date, 2)),
            endOfWeek(subWeeks(date, 2)),
            agents,
            leads,
            websites
          ),
          getOrderSales(
            startOfWeek(subWeeks(date, 3)),
            endOfWeek(subWeeks(date, 3)),
            agents,
            leads,
            websites
          ),
          getOrderSales(
            startOfWeek(subWeeks(date, 4)),
            endOfWeek(subWeeks(date, 4)),
            agents,
            leads,
            websites
          ),
          // months
          getOrderSales(
            startOfMonth(subMonths(date, 1)),
            endOfMonth(subMonths(date, 1)),
            agents,
            leads,
            websites
          ),
          getOrderSales(
            startOfMonth(subMonths(date, 2)),
            endOfMonth(subMonths(date, 2)),
            agents,
            leads,
            websites
          ),
          getOrderSales(
            startOfMonth(subMonths(date, 3)),
            endOfMonth(subMonths(date, 3)),
            agents,
            leads,
            websites
          ),
        ])

        setThisDaySales((ps) => ({ ...ps, ...thisday_res?.data }))
        setThisWeekSales((ps) => ({ ...ps, ...thisweek_res?.data }))
        setThisMonthSales((ps) => ({ ...ps, ...thismonth_res?.data }))
        setWeek1Sales((ps) => ({ ...ps, ...week1_res?.data }))
        setWeek2Sales((ps) => ({ ...ps, ...week2_res?.data }))
        setWeek3Sales((ps) => ({ ...ps, ...week3_res?.data }))
        setWeek4Sales((ps) => ({ ...ps, ...week4_res?.data }))
        setMonth1Sales((ps) => ({ ...ps, ...month1_res?.data }))
        setMonth2Sales((ps) => ({ ...ps, ...month2_res?.data }))
        setMonth3Sales((ps) => ({ ...ps, ...month3_res?.data }))
      }
      setFetchSalesLoading(false)
    },
    [user]
  )
  useEffect(
    () => fetchOrderSales(salesAgents, salesLeadsFrom, salesWebsites),
    [fetchOrderSales, salesAgents, salesLeadsFrom, salesWebsites]
  )

  const fetchCustomOrderSales = useCallback(
    async (agents, leads, websites) => {
      setFetchSalesLoading(true)
      if (user.role) {
        if (user.role === 'agent') {
          agents = [user.id]
        }
        const { dateFrom, dateTo } = salesFilterDatesCustom
        if (!dateFrom || !dateTo) {
          return
        }
        setCustomLoading(true)
        const res = await getOrderSales(
          startOfDay(dateFrom),
          endOfDay(dateTo),
          agents,
          leads,
          websites
        )
        if (res) {
          setCustomLoading(false)
          if (res.status === 200) {
            setCustomSales((ps) => ({ ...ps, ...res?.data }))
          }
        }
      }
      setFetchSalesLoading(false)
    },
    [user, salesFilterDatesCustom]
  )
  useEffect(
    () => fetchCustomOrderSales(salesAgents, salesLeadsFrom, salesWebsites),
    [fetchCustomOrderSales, salesAgents, salesLeadsFrom, salesWebsites]
  )

  return (
    <div>
      <h3 className="p-my-0">{fetchSalesLoading ? 'Fetching Sales...' : 'Sales Board'}</h3>
      <div className="p-d-flex p-flex-wrap gap-1 p-my-3">
        {['superadmin', 'admin', 'manager'].includes(user.role) && (
          <MultiSelect
            value={salesAgents}
            onChange={(e) => setSalesAgents(e.value)}
            placeholder="Select Agent, Teamlead"
            options={agents.filter((i) => ['agent', 'teamlead'].includes(i.role))}
            optionLabel="username"
            optionValue="id"
            filter
            filterBy="username,role"
            filterPlaceholder="Search..."
            maxSelectedLabels={2}
          />
        )}
        {['teamlead'].includes(user.role) && (
          <MultiSelect
            value={salesAgents}
            onChange={(e) => setSalesAgents(e.value)}
            placeholder="Select Agents"
            options={user.members}
            optionLabel="username"
            optionValue="id"
            filter
            filterBy="username"
            filterPlaceholder={`Search in agents`}
            maxSelectedLabels={2}
          />
        )}
        <MultiSelect
          maxSelectedLabels={3}
          value={salesLeadsFrom}
          onChange={(e) => setSalesLeadsFrom(e.value)}
          placeholder="Lead Source"
          options={ctx_leadSource}
          optionLabel="name"
          optionValue="value"
        />
        <MultiSelect
          value={salesWebsites}
          onChange={(e) => setSalesWebsites(e.value)}
          options={dashWebsites}
          placeholder="Select Websites"
          // optionLabel="name"
          // optionValue="value"
          // filter
          // filterBy="name"
          // filterPlaceholder="Search..."
          maxSelectedLabels={2}
        />
        <MultiSelect
          value={salesDates}
          onChange={(e) => setSalesDates(e.value)}
          options={salesDateOptions}
          placeholder="Select Dates"
          optionLabel="name"
          optionValue="value"
          display="chip"
        />
      </div>
      <div className="p-grid">
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="card sales-card sales-today">
            <h5 className="header-bg">Today's Sale</h5>
            <SalesStatsTable
              data={thisDaySales}
              setData={setThisDaySales}
              checkData={thisDaySalesChecks}
              setCheckData={setThisDaySalesChecks}
              handleChange={handleChangeObject}
              viewSales={viewSales}
            />
          </div>
          <ConversationRateCard data={thisDaySales} />
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="card sales-card sales-week">
            <h5 className="header-bg">This Week's Sale</h5>
            <SalesStatsTable
              data={thisWeekSales}
              setData={setThisWeekSales}
              checkData={thisWeekSalesChecks}
              setCheckData={setThisWeekSalesChecks}
              handleChange={handleChangeObject}
              viewSales={viewSales}
            />
          </div>
          <ConversationRateCard data={thisWeekSales} />
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <div className="card sales-card sales-month">
            <h5 className="header-bg">This Month's Sale</h5>
            <SalesStatsTable
              data={thisMonthSales}
              setData={setThisMonthSales}
              checkData={thisMonthSalesChecks}
              setCheckData={setThisMonthSalesChecks}
              handleChange={handleChangeObject}
              viewSales={viewSales}
            />
          </div>
          <ConversationRateCard data={thisMonthSales} />
        </div>
      </div>
      {salesDates.includes('last4weeks') && (
        <>
          <h3 className="p-my-0">Last 4 Weeks Sale</h3>
          <div className="p-grid p-my-3">
            <div className="p-col-12 p-md-6 p-lg-4">
              <div className="card sales-card sales-week ">
                <h5 className="header-bg">{week1Sales?.titleDates || week1Sales.titel} Sale</h5>
                <SalesStatsTable
                  data={week1Sales}
                  setData={setWeek1Sales}
                  checkData={week1SalesChecks}
                  setCheckData={setWeek1SalesChecks}
                  handleChange={handleChangeObject}
                  viewSales={viewSales}
                />
              </div>
              <ConversationRateCard data={week1Sales} />
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
              <div className="card sales-card sales-week ">
                <h5 className="header-bg">{week2Sales?.titleDates || week2Sales.titel} Sale</h5>
                <SalesStatsTable
                  data={week2Sales}
                  setData={setWeek2Sales}
                  checkData={week2SalesChecks}
                  setCheckData={setWeek2SalesChecks}
                  handleChange={handleChangeObject}
                  viewSales={viewSales}
                />
              </div>
              <ConversationRateCard data={week2Sales} />
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
              <div className="card sales-card sales-week ">
                <h5 className="header-bg">{week3Sales?.titleDates || week3Sales.titel} Sale</h5>
                <SalesStatsTable
                  data={week3Sales}
                  setData={setWeek3Sales}
                  checkData={week3SalesChecks}
                  setCheckData={setWeek3SalesChecks}
                  handleChange={handleChangeObject}
                  viewSales={viewSales}
                />
              </div>
              <ConversationRateCard data={week3Sales} />
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
              <div className="card sales-card sales-week ">
                <h5 className="header-bg">{week4Sales?.titleDates || week4Sales.titel} Sale</h5>
                <SalesStatsTable
                  data={week4Sales}
                  setData={setWeek4Sales}
                  checkData={week4SalesChecks}
                  setCheckData={setWeek4SalesChecks}
                  handleChange={handleChangeObject}
                  viewSales={viewSales}
                />
              </div>
              <ConversationRateCard data={week4Sales} />
            </div>
          </div>
        </>
      )}
      {salesDates.includes('last3months') && (
        <>
          <h3 className="p-my-0">Last 3 Months Sale</h3>
          <div className="p-grid p-my-3">
            <div className="p-col-12 p-md-6 p-lg-4">
              <div className="card sales-card sales-month ">
                <h5 className="header-bg">{month1Sales?.titleDates || month1Sales.titel} Sale</h5>
                <SalesStatsTable
                  data={month1Sales}
                  setData={setMonth1Sales}
                  checkData={month1SalesChecks}
                  setCheckData={setMonth1SalesChecks}
                  handleChange={handleChangeObject}
                  viewSales={viewSales}
                />
              </div>
              <ConversationRateCard data={month1Sales} />
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
              <div className="card sales-card sales-month ">
                <h5 className="header-bg">{month2Sales?.titleDates || month2Sales.titel} Sale</h5>
                <SalesStatsTable
                  data={month2Sales}
                  setData={setMonth2Sales}
                  checkData={month2SalesChecks}
                  setCheckData={setMonth2SalesChecks}
                  handleChange={handleChangeObject}
                  viewSales={viewSales}
                />
              </div>
              <ConversationRateCard data={month2Sales} />
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
              <div className="card sales-card sales-month ">
                <h5 className="header-bg">{month3Sales?.titleDates || month3Sales.titel} Sale</h5>
                <SalesStatsTable
                  data={month3Sales}
                  setData={setMonth3Sales}
                  checkData={month3SalesChecks}
                  setCheckData={setMonth3SalesChecks}
                  handleChange={handleChangeObject}
                  viewSales={viewSales}
                />
              </div>
              <ConversationRateCard data={month3Sales} />
            </div>
          </div>
        </>
      )}
      {salesDates.includes('custom') && (
        <>
          <div className="p-d-flex gap-1">
            <h3 className="p-my-0">Custom Sale</h3>
            <div className="">
              <MyDatesPicker
                code={salesDateCodeCustom}
                setCode={setSalesDateCodeCustom}
                rangeDates={salesRangeDatesCustom}
                setRangeDates={setSalesRangeDatesCustom}
                setFilterDates={setSalesFilterDatesCustom}
              />
            </div>
          </div>
          <div className="p-grid p-my-3">
            <div className="p-col-12 p-md-6 p-lg-4">
              <div className="card sales-card sales-month ">
                <h5 className="header-bg">
                  {customLoading ? (
                    <i className="pi pi-spin pi-spinner"></i>
                  ) : (
                    <span>{customSales?.titleDates || customSales.titel} Sale</span>
                  )}
                </h5>
                <SalesStatsTable
                  data={customSales}
                  setData={setCustomSales}
                  checkData={customSalesChecks}
                  setCheckData={setCustomSalesChecks}
                  handleChange={handleChangeObject}
                  viewSales={viewSales}
                />
              </div>
              <ConversationRateCard data={customSales} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const SalesStatsTable = ({ checkData, setCheckData, handleChange, data, setData, viewSales }) => {
  return (
    <div className="p-d-flex p-flex-column">
      <SalesStatsRow
        name="Total Sale"
        orders={data.total_orders}
        amount={data.total_amount}
        checkData={checkData}
        checkTarget="Total"
        setCheckData={setCheckData}
        handleChange={handleChange}
      />
      <SalesStatsRow
        name="Paid Sale"
        data={data}
        setData={setData}
        orders={data.paid_orders}
        amount={data.paid_amount}
        togglePaidSales={true}
        checkData={checkData}
        checkTarget="Paid"
        setCheckData={setCheckData}
        handleChange={handleChange}
      />
      {data.togglePaidSales && (
        <div className="p-d-flex p-flex-column">
          <SalesStatsRow
            name="Processed"
            orders={data.processed_orders}
            amount={data.processed_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Processed"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Shipped"
            orders={data.shipped_orders}
            amount={data.shipped_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Shipped"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Tracking"
            orders={data.tracking_orders}
            amount={data.tracking_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Tracking"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Tracking-Live"
            orders={data.trackinglive_orders}
            amount={data.trackinglive_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Tracking-Live"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Delivered"
            orders={data.delivered_orders}
            amount={data.delivered_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Delivered"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Undelivered"
            orders={data.undelivered_orders}
            amount={data.undelivered_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Undelivered"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Partial"
            orders={data.partial_orders}
            amount={data.partial_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Partial"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Reshipment-Initiated"
            orders={data.reshipment_initiated_orders}
            amount={data.reshipment_initiated_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Reshipment-Initiated"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Reshipment-Completed"
            orders={data.reshipment_completed_orders}
            amount={data.reshipment_completed_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Reshipment-Completed"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          {/* <SalesStatsRow
            name="Refund-Initiated"
            orders={data.refund_initiated_orders}
            amount={data.refund_initiated_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Refund-Initiated"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Refund-Completed"
            orders={data.refund_completed_orders}
            amount={data.refund_completed_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Refund-Completed"
            setCheckData={setCheckData}
            handleChange={handleChange}
          /> */}
          <SalesStatsRow
            name="Refunded"
            orders={data.refunded_orders}
            amount={data.refunded_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Refund-Completed"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
        </div>
      )}
      <SalesStatsRow
        name="Unpaid Sale"
        data={data}
        setData={setData}
        orders={data.unpaid_orders}
        amount={data.unpaid_amount}
        toggleUnpaidSales={true}
        checkData={checkData}
        checkTarget="Unpaid"
        setCheckData={setCheckData}
        handleChange={handleChange}
      />
      {data.toggleUnpaidSales && (
        <div className="p-d-flex p-flex-column">
          <SalesStatsRow
            name="Pending"
            orders={data.pending_orders}
            amount={data.pending_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Pending"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Draft"
            orders={data.draft_orders}
            amount={data.draft_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Draft"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Cancelled"
            orders={data.cancelled_orders}
            amount={data.cancelled_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Cancelled"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
        </div>
      )}
      <SalesStatsRow
        name="Unqualified Sale"
        data={data}
        setData={setData}
        orders={data.unqualified_orders}
        amount={data.unqualified_amount}
        toggleUnqualifiedSales={true}
        checkData={checkData}
        checkTarget="Unqualified"
        setCheckData={setCheckData}
        handleChange={handleChange}
      />
      {/* {data.toggleUnqualifiedSales && (
        <div className="p-d-flex p-flex-column">
          <SalesStatsRow
            name="Ineligible"
            orders={data.ineligible_orders}
            amount={data.ineligible_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Ineligible"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
          <SalesStatsRow
            name="Ineligi-Cancel"
            orders={data.ineligcancel_orders}
            amount={data.ineligcancel_amount}
            isSubPaid={true}
            checkData={checkData}
            checkTarget="Ineligi-Cancel"
            setCheckData={setCheckData}
            handleChange={handleChange}
          />
        </div>
      )} */}

      <div className="p-d-flex p-jc-center">
        <Button
          label="View"
          className="p-button-rounded p-px-6 sales-view-btn"
          disabled={Object.values(checkData).every((i) => i === false)}
          onClick={() => {
            const os = []
            for (const status in checkData) {
              if (
                checkData[status] &&
                !['Total', 'Paid', 'Unpaid', 'Unqualified'].includes(status)
              ) {
                os.push(status)
              }
            }
            viewSales({
              dateFrom: format(new Date(data.dateFrom), dateFormat),
              dateTo: format(new Date(data.dateTo), dateFormat),
              orderStatus: os.join(' '),
            })
          }}
        />
      </div>
    </div>
  )
}

const SalesStatsRow = ({
  data,
  setData,
  orders,
  amount,
  name,
  togglePaidSales,
  toggleUnpaidSales,
  toggleUnqualifiedSales,
  isSubPaid,
  checkData,
  setCheckData,
  handleChange,
  checkTarget,
}) => {
  let check = false
  if (checkData && checkTarget && checkData[checkTarget]) {
    check = checkData[checkTarget] || false
  }
  return (
    <div className="p-grid p-ai-center">
      <p className="p-col-6 p-m-0 p-d-flex p-ai-start">
        <Checkbox
          className={classNames('p-mr-2', {
            'p-ml-5': isSubPaid,
          })}
          checked={check}
          onChange={(e) =>
            handleChange({
              setter: setCheckData,
              getter: checkData,
              target: checkTarget,
              value: e.checked,
              event: 'saleCheck',
            })
          }
        />
        <span className="p-pr-2">{name}</span>
        {togglePaidSales && (
          <ToggleButton
            className="p-ml-2 paid-sales-toggle-btn p-ml-auto"
            onIcon="pi pi-caret-up"
            offIcon="pi pi-caret-down"
            onLabel=""
            offLabel=""
            checked={data?.togglePaidSales}
            onChange={(e) =>
              handleChange({
                setter: setData,
                getter: data,
                target: 'togglePaidSales',
                value: e.value,
                event: 'togglePaid',
              })
            }
          />
        )}
        {toggleUnpaidSales && (
          <ToggleButton
            className="p-ml-2 paid-sales-toggle-btn p-ml-auto"
            onIcon="pi pi-caret-up"
            offIcon="pi pi-caret-down"
            onLabel=""
            offLabel=""
            checked={data?.toggleUnpaidSales}
            onChange={(e) =>
              handleChange({
                setter: setData,
                getter: data,
                target: 'toggleUnpaidSales',
                value: e.value,
                event: 'togglePaid',
              })
            }
          />
        )}
        {toggleUnqualifiedSales && (
          <ToggleButton
            className="p-ml-2 paid-sales-toggle-btn p-ml-auto"
            onIcon="pi pi-caret-up"
            offIcon="pi pi-caret-down"
            onLabel=""
            offLabel=""
            checked={data?.toggleUnqualifiedSales}
            onChange={(e) =>
              handleChange({
                setter: setData,
                getter: data,
                target: 'toggleUnqualifiedSales',
                value: e.value,
                event: 'togglePaid',
              })
            }
          />
        )}
      </p>
      <p className="p-col-3 large-text p-m-0">{orders}</p>
      <p className="p-col-3 large-text text-blue p-pr-1 p-m-0">${moneyFormatter(amount)}</p>
    </div>
  )
}

const ConversationRateCard = ({ data }) => {
  const { paid_orders, total_orders, paid_amount } = data || {}
  const rate = parseFloat((paid_orders / total_orders) * 100).toFixed(2)
  return (
    <div className="sales-conversion-card card p-py-2">
      <div className="p-grid p-ai-center p-p-0 p-m-0">
        <p className="p-col-6 p-m-0 p-d-flex p-ai-center">Conversion Rate</p>
        <p className="p-col-3 large-text text-blue p-m-0">{rate}%</p>
        <p className="p-col-3 large-text text-blue p-pr-1 p-m-0">${moneyFormatter(paid_amount)}</p>
      </div>
    </div>
  )
}
