import classNames from 'classnames'
import { addDays, differenceInDays, formatDistanceToNow, isValid } from 'date-fns'
import dateFormat from 'date-fns/format'
import { debounce, delay, filter, last, set } from 'lodash'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Chip } from 'primereact/chip'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Menu } from 'primereact/menu'
import { Message } from 'primereact/message'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import { Tooltip } from 'primereact/tooltip'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MdOutlineEmail, MdOutlineSms, MdWhatsapp } from 'react-icons/md'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  addOrderNote,
  deleteOrder,
  deleteOrderNote,
  editOrderNote,
  getOrder,
  getOrderExcel,
  getOrderFollowup,
  getOrderNotes,
  getOrderProductExcel,
  patchBulkOrderStatus,
  patchStatus,
  resendOrderEmail,
  searchOrderByProduct,
  sendOrderMail,
  syncOrder,
} from '../../api'
import { useGlobalContext } from '../../context'
import { scrollIdIntoView } from '../../functions/common'
import moneyFormatter from '../../functions/moneyFormatter'
import { serialize } from '../../functions/serializeUrl'
import { handleObjChange } from '../../functions/setter'
import OrderMessageDialog from '../mini/OrderMessageDialog'
import OrderNoteDialog from '../mini/OrderNoteDialog'
import QueryBasedDatesPicker from '../mini/QueryBasedDatesPicker'
import RequiredMessage from '../mini/RequiredMessage'
import AddManualOrder from './AddManualOrder'
import BasicOrderDetailsDialog from './BasicOrderDetailsDialog'
import CustomTooltip from './CustomTooltip'
import ConfirmDialogWithPassword from './Dialog/ConfirmDialogWithPassword'
import OrderDetailsDialog from './OrderDetailsDialog'
import { FilterMatchMode } from 'primereact/api'
import SendMailDialog from '../mini/SendMailDialog'
import {
  getAvailablePaymentPartnerMethodQuotas,
  getPaymentPartnerMethods,
  getPaymentPartners,
} from '../../api/payment'
import generateS3Path from '../../functions/generateS3Path'
import { formatInTimeZone } from 'date-fns-tz'
import AvailablePaymentMethodsTable from '../mini/AvailablePaymentMethodsTable'
import { generateSlug } from '../../functions/genSlug'
import ChangeOrderStatus from './Dialog/ChangeOrderStatus'
import copy from 'clipboard-copy'
/**
 * @param {object} dateFilter - The object to store the date filter {dateFrom, dateTo}
 * @param {function} setDateFilter - The function to set the date filter {dateFrom, dateTo}
 * @param {array} orders - The array of orders
 * @param {function} setOrders - The function to set the array of orders
 * @param {string} pageUrl - The url of the page
 * @param {function} getOrders - The function to fetch the orders
 * @param {boolean} tableLoading - The boolean to set the table loading state
 * @param {function} setTableLoading - The function to set the table loading state
 * @param {object} orderStatusOptions - The object of order status options
 * @description - The main orders datatable used in the orders page like all orders, manual orders, etc
 **/
export default function OrdersDataTable({
  dateFilter,
  setDateFilter,
  orders,
  setOrders,
  ordersMeta,
  pageUrl,
  getOrders,
  tableLoading,
  setTableLoading,
  orderStatusOptions,
}) {
  const {
    user,
    setCallHippoDialerVisible,
    setCallHippoDialerUrl,
    shippingTags,
    leadSource,
    agents,
    paymentPartnerMethods,
    setSendEmailDialogVisible,
    fetchPaymentPartnerMethods,
  } = useGlobalContext()

  /* STATIC-STATES */
  const [filterStats, setFilterStats] = useState({
    totalOrders: 0,
    totalSales: 0,
  })
  const menu_utilies = useRef(null)
  const menu_communication = useRef(null)
  const history = useHistory()
  const location = useLocation()
  const { search } = location
  const searchParams = new URLSearchParams(search)
  const searchInvId = searchParams.get('invId')
  const searchView = searchParams.get('view')
  const searchOrderStatus = searchParams.get('orderStatus')
  const searchDateFrom = searchParams.get('dateFrom')
  const searchDateTo = searchParams.get('dateTo')
  const searchMasterid = searchParams.get('masterid')

  const resend_status = [
    'Pending',
    'Payment',
    'Processed',
    'Shipped',
    'Tracking',
    'Tracking-Live',
    'Delivered',
    'Cancelled',
    'Reshipment-Initiated',
    'Reshipment-Completed',
    'Refunded',
    'Undelivered',
  ]

  const colorScores = {
    0: '#d3321d',
    1: '#d3321d',
    2: '#dd552d',
    3: '#ff9e00',
    4: '#ffb600',
    5: '#f4762d',
    6: '#a6e311',
    7: '#59d102',
  }

  const bulkOptions = [{ name: 'Move to bin', value: 'Bin' }]

  const resend_status_agent = ['Pending', 'Payment']

  const orderSource = [
    { name: 'GLB', value: 'GLB' },
    { name: 'MAN', value: 'MAN' },
    { name: 'OLD', value: 'OLD' },
  ]

  let emptyOrder = {
    orderid: null,
    trackingid: null,
    fname: '',
    total: 0,
    website: '',
    shippingname: '',
    orderStatus: 0,
  }

  let emptyAddressDetails = {
    fname: null,
    lname: null,
    email: null,
    phone: null,
    addressline1: null,
    city: null,
    state: null,
    postalcode: null,
    country: null,
  }

  let emptyAddTrackingDetails = {
    productName: null,
    productid: null,
    trackinglink: null,
    trackingid: null,
  }

  const emptyCustomer = {
    id: null,
    userid: null,
    email: null,
    fname: null,
    lname: null,
    phone: null,
    city: null,
    state: null,
    country: null,
    address: null,
    zip: null,
  }
  /* Refs */
  const dt = useRef(null)

  const toast = useRef(null)
  const showSuccess = (summary = 'Success Message', detail = 'Message Content') => {
    toast.current.show({
      severity: 'success',
      summary,
      detail,
      life: 3000,
    })
  }
  /* States */
  const [isBulkAction, setIsBulkAction] = useState(false)
  const [orderDateCode, setOrderDateCode] = useState(null)
  const [orderDialogVisible, setOrderDialogVisible] = useState(false)
  const [basicOrderDialogVisible, setBasicOrderDialogVisible] = useState(false)
  const [newManualOrderDialogVisible, setNewManaulDialogVisible] = useState(false)

  const empty_notesData = { visible: false, meta: {}, notes: [], orderId: null }
  const [notesData, setNotesData] = useState(empty_notesData)
  const handleNotesDataChange = (target, value) => {
    setNotesData((ps) => ({ ...ps, [target]: value }))
  }
  const hideNotes = () => {
    setNotesData(empty_notesData)
  }
  const [orderMessageDialogVisible, setOrderMessageDialogVisible] = useState(false)
  let discountOptions = ['Percentage', 'Amount']

  const resentEmailDialogEmpty = {
    visible: false,
    loading: false,
    status: null,
    order: {},
    confirm: false,
  }
  const emptySearchParams = {
    invId: searchInvId,
    view: searchView,
    dateFrom: searchDateFrom,
    dateTo: searchDateTo,
    orderStatus: searchOrderStatus,
    masterid: searchMasterid,
  }
  const [searchParamsState, setSearchParamsState] = useState(emptySearchParams)
  const [resentEmailDialogData, setResentEmailDialogData] = useState(resentEmailDialogEmpty)
  // const [lastViewedId, setLastViewedId] = useState(null)
  const [selectionState, setSelectionState] = useState({
    selectedOrders: [], // Array of selected orders
    lastViewedId: null, // Last viewed ID
  })
  const [deleteProductDialog, setDeleteProductDialog] = useState(false)
  const [moveOrderBackDialog, setMoveOrderBackDialog] = useState(false)
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false)
  const [deleteSelectedOrdersDialog, setDeleteSelectedOrdersDialog] = useState(false)
  const [order, setOrder] = useState(emptyOrder)
  const [selectedProducts, setSelectedProducts] = useState(null)
  const [otpDialog, setOtpDialog] = useState(false)
  const [enableFields, setEnableFields] = useState(false)
  const [enableProductFields, setEnableProductFields] = useState(false)
  const [changeStatus, setChangeStatus] = useState(false)
  const [changeStatusNotification, setChangeStatusNotification] = useState(true)

  const [tempVar, setTempVar] = useState({})

  const [orderDates, setOrderDates] = useState(null)
  // const [messageDialog, setMessageDialog] = useState(false)
  const [orderProductDetails, setOrderProductDetails] = useState([])
  /* Address */
  const [addressDetails, setAddressDetails] = useState(emptyAddressDetails)
  const [orderDetails, setOrderDetails] = useState({})
  const [trackingDetails, setTrackingDetails] = useState([])
  const [addTrackingDetails, setAddTrackingDetails] = useState([emptyAddTrackingDetails])

  /* Reorder AND Manualorder*/
  const [isEditable, setIsEditable] = useState(true)
  const [customProducts, setCustomProducts] = useState([])
  const [manualProducts, setManualProducts] = useState([])

  const [customerData, setCustomerData] = useState(emptyCustomer)

  /* Delete Order */
  const [deleteOrderDialog, setDeleteOrderDialog] = useState(false)
  const [password, setPassword] = useState('')
  const [orderDeleting, setOrderDeleting] = useState(false)
  /* Filter */

  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    source: { value: null, matchMode: FilterMatchMode.EQUALS },
    orderLeadSource: { value: null, matchMode: FilterMatchMode.EQUALS },
    website: { value: null, matchMode: FilterMatchMode.EQUALS },
    orderDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
    total: { value: null, matchMode: FilterMatchMode.EQUALS },
    orderStatus: { value: null, matchMode: FilterMatchMode.IN },
    orderid: { value: null, matchMode: FilterMatchMode.IN },
    shippingCompany: { value: null, matchMode: FilterMatchMode.CONTAINS },
    masterid: { value: null, matchMode: FilterMatchMode.IN },
    teams: { value: null, matchMode: FilterMatchMode.CONTAINS },
    paymentMethod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    paymentPartner: { value: null, matchMode: FilterMatchMode.CONTAINS },
  }
  const [filters, setFilters] = useState(emptyFilterObject)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  // Payment Details
  const emptyPaymentProcessedDetail = {
    masterid: null,
    userId: null,
    userName: null,
    additionalNote: null,
    clientName: null,
    paidAmount: null,
    gatewayAmountReceived: null,
    gatewayProcessingFee: null,
    paymentPartnerMethodId: null,
    paymentPartnerMethodName: null,
    gatewayName: null,
    paymentPartner: null,
  }

  const [paymentProcessedDetail, setPaymentProcessedDetail] = useState(emptyPaymentProcessedDetail)
  const [paymentProcessedDetailErrors, setPaymentProcessedDetailErrors] = useState([])
  // Order Followup
  const emtpy_orderFollowupRecord = {
    visible: false,
    records: [],
  }

  const [orderFollowupRecord, setOrderFollowupRecord] = useState(emtpy_orderFollowupRecord)
  const [orderStatusDialogVisible, setOrderStatusDialogVisible] = useState(false)
  const [orderStatusUpdateData, setOrderStatusUpdateData] = useState({})

  /* Mail/Message */
  // const emptyMailData = {
  //   orderid: '',
  //   subject: '',
  //   body: '',
  //   loading: false,
  //   confirm: false,
  // }

  // const [mailData, setMailData] = useState(emptyMailData)
  // const hideMessageDialog = () => {
  //   setMessageDialog(false)
  //   setMailData(emptyMailData)
  // }
  // const sendMessage = async () => {
  //   setMailData({ ...mailData, loading: true })
  //   const { orderid, subject, body } = mailData
  //   const res = await sendOrderMail(orderid, subject, body, 'order', orderid)
  //   if (res) {
  //     setMailData({ ...mailData, loading: false })
  //     if (res.status === 200) {
  //       showSuccess('Message Sent', `Message Sent Successfully For ${mailData?.orderid}`)
  //       hideMessageDialog()
  //     }
  //   }
  // }

  const empty_sendEmail = {
    email: '',
    subject: '',
    body: '',
    relatedTo: 'order',
    refId: '',
  }
  /* Mail Dialog */
  const [sendEmail, setSendEmail] = useState(empty_sendEmail)

  /* Import CSV */
  const [exporting, setExporting] = useState(false)

  // resend email change handler
  const onResentEmailChange = (target, value) => {
    setResentEmailDialogData((ps) => ({ ...ps, [target]: value }))
  }
  // resend email api call handler
  const onResendOrderEmail = async () => {
    onResentEmailChange('loading', true)
    const res = await resendOrderEmail(
      resentEmailDialogData.order.orderid,
      resentEmailDialogData.status
    )
    if (res && res.status === 200) {
      hideResendEmailDialog()
      toast.current.show({
        severity: 'success',
        summary: 'Operation Success',
        detail: res.data.message || 'Resend Email',
      })
    }
  }
  const hideChangeStatusDialog = () => {
    setChangeStatus(false)
    setChangeStatusNotification(true)
    setPaymentProcessedDetail(emptyPaymentProcessedDetail)
    setPaymentProcessedDetailErrors([])
  }

  const validatePaymentProcessedDetail = () => {
    setPaymentProcessedDetailErrors([])
    const err = []
    if (!paymentProcessedDetail.userName) {
      err.push('Invalid Agent Name')
    }
    if (!paymentProcessedDetail.paymentPartnerMethodId) {
      err.push('Invalid Gateway Name')
    }
    if (!paymentProcessedDetail.clientName) {
      err.push('Invalid Client Name')
    }
    if (!paymentProcessedDetail.paidAmount) {
      err.push('Invalid Paid Amount')
    }
    setPaymentProcessedDetailErrors(err)
    return err
  }
  const onStatusChange = async (e, id, sendNotification) => {
    const {
      userName,
      paymentPartnerMethodId,
      gatewayName,
      paymentPartner,
      clientName,
      paidAmount,
      userId,
      additionalNote,
      gatewayAmountReceived,
      gatewayProcessingFee,
    } = paymentProcessedDetail
    let note = '',
      orderProcessedRequestById = null
    if (tempVar['event']?.value === 'Processed') {
      const ppd_errs = validatePaymentProcessedDetail()
      if (ppd_errs.length > 0) {
        return
      } else {
        note = `Agent Name: ${userName}, Gateway: ${
          gatewayName + ' - ' + paymentPartner
        }, Name on gateway: ${clientName}, Amount on gateway: $${paidAmount}, Amount Received: $${gatewayAmountReceived}, Processing Fee: $${gatewayProcessingFee}`
        if (additionalNote) {
          note += `, Additional Note: ${additionalNote}`
        }
        orderProcessedRequestById = userId
      }
    }
    const newStatus = e.value
    const index = orders.findIndex((item) => item.orderid === id)
    let _orders = [...orders]
    toast.current.show({
      severity: 'success',
      summary: 'Processing',
      detail: 'Please wait',
    })
    sendNotification = resend_status.includes(newStatus) ? sendNotification : false
    const res = await patchStatus(
      _orders[index]['orderid'],
      newStatus,
      sendNotification,
      note,
      orderProcessedRequestById,
      paymentPartnerMethodId,
      paymentPartner,
      gatewayName,
      clientName,
      paidAmount,
      additionalNote
    )
    if (res) {
      setChangeStatus(false)

      if (res.status === 200) {
        setPaymentProcessedDetail(emptyPaymentProcessedDetail)
        _orders[index]['orderStatus'] = newStatus
        toast.current.show({
          severity: 'success',
          summary: res.data.message || 'Operation Successful',
          life: 5000,
        })
        if (Object.keys(res.data.newProductStatusFromOrder).length > 0) {
          _orders[index]['productStatus'] = res.data.newProductStatusFromOrder
        }
        if (['Reshipment-Completed', 'Refunded'].includes(newStatus)) {
          return getOrders()
        }
        setOrders(_orders)
      }
      hideChangeStatusDialog()
    }
  }

  const orderDeleteHandle = async () => {
    setOrderDeleting(true)
    const res = await deleteOrder(tempVar['data']?.orderid, password)
    setPassword('')
    if (res) {
      setOrderDeleting(false)
      if (res.status === 200) {
        hideOrderDeleteDialog()
        deleteOrderToast('success', 'Order Deleted', res.data.message)
        getOrders()
      }
    }
  }

  const hideOrderDeleteDialog = () => {
    setDeleteOrderDialog(false)
    setOrderDeleting(false)
    setPassword('')
  }

  const deleteOrderToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail, life: 3000 })
  }

  const verifyotp = () => {
    toast.current.show({
      severity: 'success',
      summary: 'OTP Successfully Verified !',
      detail: '',
      life: 3000,
    })
    setOtpDialog(false)
  }

  /* Hide fns */
  const resetUrl = () => {
    handleSearchParamsChange({ invId: null, view: null })
    document.title = 'Global CRM'
  }

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false)
  }

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false)
  }

  const hideMoveOrderBackDialogDialog = () => {
    setMoveOrderBackDialog(false)
  }

  const hideResendEmailDialog = () => {
    setResentEmailDialogData(resentEmailDialogEmpty)
  }
  const handleSearchParamsChange = (values) => {
    setSearchParamsState((ps) => ({ ...ps, ...values }))
  }

  const handleSearchParameterUrl = useCallback(() => {
    history.push({
      pathname: pageUrl,
      search: serialize(searchParamsState),
    })
  }, [searchParamsState, pageUrl])

  useEffect(() => handleSearchParameterUrl(), [handleSearchParameterUrl])

  const fetchOrderData = useCallback(async () => {
    if (!searchInvId) return

    const handleEditViewData = (res) => {
      const { order, orderProductData, addressData, trackingData } = res.data

      setOrderProductDetails(
        orderProductData.map((item) => ({
          ...item,
          quantities: [],
          _strength: item.strength,
          _quantity: item.quantity,
        }))
      )
      order.isDiscountPercent = 'Amount'

      // general
      setTrackingDetails(trackingData)
      setAddressDetails(addressData)
      setOrderDetails({
        ...order,
        discountValue: order.discount,
        isPaymentLinkThere: order.paymentlink ? true : false,
        prevPaymentMethod: order.paymentMethod || null,
      })
    }

    const handleAddData = (res) => {
      const { orderProductData, addressData } = res.data
      setCustomerData({
        ...addressData,
        state: null,
        country: null,
        address: addressData.addressline1,
        zip: addressData.postalcode,
        phone: addressData.phone,
        dbState: addressData.state,
        dbCountry: addressData.country,
      })

      setCustomProducts(orderProductData)
      setManualProducts([])
    }
    setTableLoading(true)
    const res = await getOrder(searchInvId, searchView)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        const order = res.data.order
        document.title = `${searchInvId} Order`
        setOrderRd(order)
        setIsEditable(true)
        setSelectionState((ps) => ({ ...ps, lastViewedId: order }))
        setOrder(order)
        if (searchView === 'basic') {
          handleEditViewData(res)
          setBasicOrderDialogVisible(true)
        } else if (searchView === 'reorder') {
          handleAddData(res)
          setNewManaulDialogVisible(true)
        } else {
          handleEditViewData(res)
          setOrderDialogVisible(true)
        }
      }
      if (res.status === 404) {
        if (res.data.error === 'Bin and blocked order can only be viewed in basic view') {
          handleSearchParamsChange({ invId: searchInvId, view: 'basic' })
        } else {
          resetUrl()
        }
      }
    }
  }, [searchInvId, searchView])

  const onChangeFilter = (value, target) => {
    setFilters((ps) => ({ ...ps, [target]: { ...ps[target], value } }))
  }

  useEffect(() => {
    if (searchOrderStatus) {
      const ary = searchOrderStatus.split(' ').filter((val) => orderStatusOptions.includes(val))
      onChangeFilter(ary, 'orderStatus')
    } else {
      onChangeFilter([], 'orderStatus')
    }
  }, [searchOrderStatus])

  useEffect(() => {
    if (searchMasterid) {
      const ary = searchMasterid.split(' ').map((i) => parseInt(i))
      onChangeFilter(ary, 'masterid')
    } else {
      onChangeFilter([], 'masterid')
    }
  }, [searchMasterid])

  useEffect(() => {
    const set = (dateFrom, dateTo) => {
      setDateFilter({
        dateFrom,
        dateTo,
      })
      setOrderDates([dateFrom, dateTo])
      if (!orderDateCode) {
        setOrderDateCode('daterange')
      }
    }
    if (searchDateFrom && searchDateTo) {
      let sdf = searchDateFrom.split('-')
      let sdt = searchDateTo.split('-')

      if (sdf.length === 3 && sdt.length === 3) {
        let dateFrom = new Date(sdf[2], sdf[1] - 1, sdf[0]),
          dateTo = new Date(sdt[2], sdt[1] - 1, sdt[0])

        if (isValid(dateFrom) && isValid(dateTo)) {
          set(dateFrom, dateTo)
        }
      }
    } else if (searchDateFrom && !searchDateTo) {
      let da = searchDateFrom.split('-')
      if (da.length === 3) {
        let dateFrom = new Date(da[2], da[1] - 1, da[0]),
          dateTo = new Date()
        if (isValid(dateFrom)) {
          set(dateFrom, dateTo)
        }
      }
    } else if (searchDateTo && !searchDateFrom) {
      let da = searchDateTo.split('-')
      if (da.length === 3) {
        let dateFrom = new Date(2015, 0, 0),
          dateTo = new Date(da[2], da[1] - 1, da[0])
        if (isValid(dateTo)) {
          set(dateFrom, dateTo)
        }
      }
    }
  }, [searchDateTo, searchDateFrom])

  const orderViewUrl = (invId, view) => {
    const query = serialize({ ...searchParamsState, invId, view })
    return `${pageUrl}?${query}`
  }

  useEffect(() => fetchOrderData(), [fetchOrderData])
  // sync edit-order with order-product and other data
  const handelSyncOrder = async () => {
    const res = await syncOrder(order.orderid)
    if (res && res.status === 200) {
      setOrders(
        orders.map((val) => {
          if (val.orderid === order.orderid) {
            return { ...val, ...res?.data }
          }
          return val
        })
      )
    }
  }
  const addNewOrderToTable = async (oid) => {
    const res = await syncOrder(oid)
    if (res && res.status === 200) {
      setOrders((ps) => [res?.data, ...ps])
    }
  }
  const calculateSubtotal = useCallback(() => {
    let subtotal = 0
    let cost = 0

    for (const product of orderProductDetails) {
      subtotal =
        subtotal +
        parseFloat(product['quantityPrice']) *
          parseFloat(product['quantity']) *
          parseFloat(product['totalQuantity'])

      cost =
        cost +
        parseFloat(product['quantityCost']) *
          parseFloat(product['quantity']) *
          parseFloat(product['totalQuantity'])
    }

    // let discount = parseFloat((subtotal * orderDetails.discount) / 100)

    let total = subtotal - orderDetails.discount - orderDetails.paymentDiscount
    total = parseFloat(total) + (parseFloat(orderDetails.dcharge) || 0)

    let profit = total - cost
    setOrderDetails((prevState) => ({
      ...prevState,
      total: total.toFixed(2),
      subtotal: subtotal.toFixed(2),
      profit: profit.toFixed(2),
    }))
  }, [
    orderDetails.discount,
    orderDetails.dcharge,
    orderDetails.paymentDiscount,
    orderProductDetails,
  ])

  useEffect(() => {
    if (enableProductFields) {
      calculateSubtotal()
    }
  }, [calculateSubtotal, enableProductFields])

  const confirmDeleteProduct = (order) => {
    setOrder(order)
    setDeleteProductDialog(true)
  }

  const confirmMoveOrder = (order) => {
    setOrder(order)
    setMoveOrderBackDialog(true)
  }

  const deleteSelectedProducts = () => {
    let _products = orders.filter((val) => !selectedProducts.includes(val))
    setOrders(_products)
    setDeleteProductsDialog(false)
    setSelectedProducts(null)
    toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Products Deleted',
      life: 3000,
    })
  }

  const deleteSelectedOrders = async () => {
    setTableLoading(true)
    const tempOrders = [...orders]
    const filterOrders = tempOrders?.filter((val) => !selectionState?.selectedOrders?.includes(val))
    const orderIds = selectionState?.selectedOrders?.map((val) => val.orderid)
    const res = await patchBulkOrderStatus(orderIds, 'Bin')
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setOrders(filterOrders)
        setDeleteSelectedOrdersDialog(false)
        setSelectionState((ps) => ({ ...ps, selectedOrders: [] }))
        toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: 'Orders successfully moved to Bin!',
          life: 3000,
        })
      }
    }
  }

  const deleteProduct = async () => {
    setOrders(orders.filter((val) => val.orderid !== order.orderid))
    setDeleteProductDialog(false)
    toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Order successfully moved to Bin!',
      life: 3000,
    })
    await patchStatus(order?.orderid, 'Bin')
  }

  const moveSelectedOrder = async () => {
    const fakeEvent = { value: 'Pending' }
    await onStatusChange(fakeEvent, order.orderid, false)
    setOrders(orders.filter((item) => item.orderid !== order.orderid))
    setMoveOrderBackDialog(false)
  }

  const LeftTextButton = () => {
    if (pageUrl === '/orders/manual') {
      return (
        <>
          <h5 className=" p-mb-0">Manual Orders</h5>
          <Button
            tooltip="Add new manual order"
            tooltipOptions={{ position: 'top' }}
            label="Add Order"
            icon="pi pi-plus"
            onClick={() => setNewManaulDialogVisible(true)}
          />
          <Link to="/orders">
            <Button label="All Orders" icon="pi pi-external-link" />
          </Link>
          <Link to="/orders/bin">
            <Button label="Bin Orders" className="p-button-danger" icon="pi pi-external-link" />
          </Link>
        </>
      )
    } else if (pageUrl === '/orders/undelivered') {
      return (
        <>
          <h5 className=" p-mb-0">Undelivered Orders</h5>
          <Link to="/orders">
            <Button label="All Orders" icon="pi pi-external-link" />
          </Link>
        </>
      )
    } else if (pageUrl === '/orders') {
      return (
        <>
          <h5 className="p-mb-0">All Orders</h5>
          <Link to="/orders/manual">
            <Button label="Manual Orders" icon="pi pi-external-link" />
          </Link>
          <Link to="/orders/bin">
            <Button label="Bin Orders" className="p-button-danger " icon="pi pi-external-link" />
          </Link>
        </>
      )
    } else if (pageUrl === '/orders/bin') {
      return (
        <>
          <h5 className=" p-mb-0">Bin Orders</h5>
          <Link to="/orders">
            <Button label="All Orders" icon="pi pi-external-link" />
          </Link>
          <Link to="/orders/manual">
            <Button label="Manual Orders" icon="pi pi-external-link" />
          </Link>
        </>
      )
    } else if (pageUrl === '/orders/my-team') {
      return (
        <>
          <h5 className=" p-mb-0">My Team Orders</h5>
          <Link to="/orders">
            <Button label="All Orders" icon="pi pi-external-link" />
          </Link>
        </>
      )
    } else {
      return (
        <>
          <h5 className=" p-mb-0">Orders</h5>
        </>
      )
    }
  }

  const leftToolbarTemplate = () => {
    return (
      <div className="p-d-flex gap-1 p-ai-center">
        <LeftTextButton />
      </div>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <p className="p-d-flex p-ai-center gap-1">
          <div className="p-m-0 p-d-flex gap-1">
            <Chip
              label={`Total Orders: ${moneyFormatter(filterStats?.totalOrders)}`}
              icon="pi pi-inbox"
            />
            <Chip
              label={`Total Sales: ${moneyFormatter(filterStats?.totalSales)}`}
              icon="pi pi-money-bill"
            />
          </div>
          <span className="p-d-flex p-ai-center gap-1">
            {['superadmin'].includes(user.role) && (
              <Button
                loading={exporting}
                label={exporting ? 'Exporting' : 'Export'}
                icon="pi pi-download"
                className="p-button-help "
                onClick={async () => {
                  setExporting(true)
                  toast.current.show({
                    severity: 'info',
                    summary: 'Exporting',
                    detail: 'Exporting order csv file',
                  })
                  const res = await getOrderExcel({
                    ...dateFilter,
                    source: filters.source.value,
                    website: filters.website.value,
                    status: pageUrl === '/orders/bin' ? ['Bin'] : filters.orderStatus.value,
                    includeFields: {
                      trackings: false,
                      subtotal: false,
                      discount: false,
                      dcharge: false,
                      website: false,
                      orderStatus: false,
                      customerId: false,
                      total: false,
                      status: false,
                      source: false,
                      orderRep: false,
                    },
                  })
                  if (res === true) {
                    setExporting(false)
                  } else {
                    setExporting(false)
                    toast.current.show({
                      severity: 'error',
                      summary: 'Error',
                      detail: 'Something went wrong!',
                      life: 3000,
                    })
                  }
                }}
              />
            )}
            {!['/orders/undelivered', '/orders/bin', '/orders/my-team', '/orders/manual'].includes(
              pageUrl
            ) && (
              <>
                {['superadmin', 'admin', 'manager', 'operations'].includes(user.role) && (
                  <Button
                    loading={exporting}
                    label={exporting ? 'Exporting' : 'Export P. Orders'}
                    icon="pi pi-download"
                    className="p-button-info"
                    onClick={async () => {
                      setExporting(true)
                      toast.current.show({
                        severity: 'info',
                        summary: 'Exporting',
                        detail: 'Exporting order csv file',
                      })
                      const res = await getOrderProductExcel({
                        ...dateFilter,
                        source: filters.source.value,
                        website: filters.website.value,
                        status: ['Processed'],
                        includeFields: {
                          email: false,
                          phone: false,
                          trackings: false,
                          subtotal: false,
                          discount: false,
                          dcharge: false,
                          website: false,
                          orderStatus: false,
                          customerId: false,
                          total: false,
                          status: false,
                          source: false,
                          orderRep: false,
                        },
                      })
                      if (res === true) {
                        setExporting(false)
                      } else {
                        setExporting(false)
                        toast.current.show({
                          severity: 'error',
                          summary: 'Error',
                          detail: 'Something went wrong!',
                          life: 3000,
                        })
                      }
                    }}
                  />
                )}
                {['superadmin', 'admin'].includes(user.role) && (
                  <Dropdown
                    placeholder="Bulk Actions"
                    disabled={selectionState?.selectedOrders?.length === 0}
                    options={bulkOptions}
                    optionLabel="name"
                    optionValue="value"
                    onChange={(e) => {
                      if (e.value === 'Bin') {
                        setDeleteSelectedOrdersDialog(true)
                      }
                    }}
                  />
                )}
              </>
            )}
            <Button
              label="Refresh"
              icon="pi pi-refresh"
              className="p-button-icon p-button  "
              onClick={getOrders}
            />
          </span>
        </p>
      </React.Fragment>
    )
  }
  const orderIdBodyTemplate = (rd) => {
    return (
      <div id={rd.orderid} className="p-d-flex p-ai-center">
        <span>{rd.orderid}</span>
        {rd.blacklistedCustomer ? (
          <>
            <Tooltip target=".custom-target-icon" />
            <span className="custom-target-icon p-ml-2" data-pr-tooltip="Blacklisted">
              <i className="pi pi-ban" style={{ fontSize: '1em', color: 'red' }} />
            </span>
          </>
        ) : (
          ''
        )}
      </div>
    )
  }
  const notifyViaBodyTemplate = (rd) => {
    return (
      <div className="p-d-flex p-ai-center gap-1by4">
        {rd.notifyViaEmail ? <MdOutlineEmail /> : ''}
        {rd.notifyViaSms ? <MdOutlineSms /> : ''}
        {rd.notifyViaWhatsapp ? <MdWhatsapp /> : ''}
      </div>
    )
  }
  const orderStatusBodyTemplate = (rd) => {
    return (
      <>
        <Dropdown
          style={{ width: '100%', maxWidth: '300px' }}
          valueTemplate={(option) => {
            return (
              <div style={{ color: 'white' }}>
                <strong>{option}</strong>
              </div>
            )
          }}
          className={'os-' + rd.orderStatus}
          value={rd.orderStatus}
          options={orderStatusOptions}
          onChange={async (e) => {
            if (rd.blacklistedCustomer) {
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Customer is blacklisted',
                life: 3000,
              })
              return
            }
            if (user?.role === 'agent' && !['Processed', 'Bin', 'Cancelled'].includes(e.value)) {
              return
            }
            if (e.value === 'Processed') {
              handleObjChange(setPaymentProcessedDetail, 'userId', rd.masterid)
              handleObjChange(setPaymentProcessedDetail, 'masterid', rd.masterid)
              handleObjChange(setPaymentProcessedDetail, 'paidAmount', rd.total)
              handleObjChange(setPaymentProcessedDetail, 'clientName', rd.name)
              const val = agents.find((item) => item.id === rd.masterid)
              handleObjChange(setPaymentProcessedDetail, 'userName', val?.username)
              handleObjChange(setPaymentProcessedDetail, 'gatewayAmountReceived', 0)
              handleObjChange(setPaymentProcessedDetail, 'gatewayProcessingFee', 0)
              // fetchPaymentPartnerMethodQuotas(rd.total) //TODO: check if this is needed
            }
            if (e.value === 'Completed') {
              const order = await fetchOrder(rd.orderid)
              setOrderStatusUpdateData({
                orderid: rd.orderid,
                orderStatus: e.value,
                orderProductDetails: order?.orderProductData,
              })
              setOrderStatusDialogVisible(true)
              return
            }
            setChangeStatus(true)
            setTempVar({ event: e, data: rd })
            fetchPaymentPartnerMethods() //TODO: check if this is needed
          }}
          placeholder="Select Status"
        />
      </>
    )
  }

  const nameBodyTemplate = (rd) => {
    let name = rd.name.length > 15 ? rd.name.substring(0, 12) + '...' : rd.name
    return (
      <>
        <div className="p-d-flex p-ai-center gap-1by2">
          {name}
          {rd.dndId ? <CustomTooltip data={rd} /> : ''}
        </div>
      </>
    )
  }
  const dateBodyTemplate = (rd) => {
    let date = new Date(rd.orderDate)
    return (
      <div>
        <InputText
          value={isValid(date) && dateFormat(date, 'Pp')}
          readOnly
          style={{ background: 'transparent', outline: 'none', border: 'none' }}
        />
      </div>
    )
  }

  const paymentMethodBodyTemplate = (rd) => {
    const normalizedId = generateSlug(rd.orderid)
    return (
      <div className="relative">
        <Tooltip
          target={`.paymentMethodAndPartner${normalizedId}`}
          tooltipOptions={{ position: 'bottom' }}
          className="custom-tooltip"
        >
          <span className="font-medium">Partner:</span> {rd?.paymentPartner || 'NA'}
        </Tooltip>
        <div className={`paymentMethodAndPartner${normalizedId}`}>{rd.paymentMethod}</div>
      </div>
    )
  }

  const clientTimeBodyTemplate = (rd) => {
    let date = new Date(rd.orderDate)
    let clientDate = formatInTimeZone(date, orderDetails?.timezone, 'PPp')
    let elapsedTime = formatDistanceToNow(date, { addSuffix: true })
    const normalizedId = generateSlug(rd.orderid)
    return (
      <div>
        <Tooltip
          target={`.customerAndElapsedTime${normalizedId}`}
          tooltipOptions={{ position: 'bottom' }}
        >
          <div>
            <div>{clientDate}</div>
            <div>{elapsedTime}</div>
          </div>
        </Tooltip>
        <InputText
          value={isValid(date) && formatInTimeZone(date, orderDetails?.timezone, 'p')}
          readOnly
          className={`customerAndElapsedTime${normalizedId}`}
          style={{ background: 'transparent', outline: 'none', border: 'none' }}
        />
      </div>
    )
  }

  const totalpriceBodyTemplate = (rd) => {
    return <div id="orderAmount">{'$' + rd.total}</div>
  }

  const binEntryDateBodyTemplate = (rd) => {
    const result = differenceInDays(addDays(new Date(rd.binEntryDate), 30), new Date())
    return <div className="p-text-center p-error">{result} Days Left</div>
  }

  const productStatusBodyTemplate = (rd) => {
    // PROBLEM HERE: count number of products in each statuses but keep only those which are not zero
    // eg: rd.productStatus={x123:'Pending', x124:'Pending', x125:'Delivered'}
    // opv={Pending:2, Delivered:1}
    // opv2=[['Pending',2], ['Delivered',1]]
    // opv3=['2 Pending', '1 Delivered']
    // opv4='2 Pending, 1 Delivered'
    const orderProductStatus = Object.values(rd?.productStatus)
    const opv = orderProductStatus.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1
      return acc
    }, {})
    const opv2 = Object.entries(opv).filter((item) => item[1] !== 0)
    const opv3 = opv2.map((item) => `${item[1]} ${item[0]}`)
    const opv4 = opv3.join(', ')

    return <div className="p-d-flex p-jc-center">{opv4}</div>
  }
  const [orderRd, setOrderRd] = useState(null)
  const actionBodyTemplate = (rd) => {
    const utilities_items = [
      (['superadmin', 'admin'].includes(user.role) || user.binOrderAccess === 1) && {
        label: 'Move to bin',
        icon: 'pi pi-angle-double-down',
        command: () => {
          confirmDeleteProduct(orderRd)
        },
      },
      {
        label: 'Resend email',
        icon: 'pi pi-send',
        command: () => {
          onResentEmailChange('order', orderRd)
          onResentEmailChange('visible', true)
        },
      },
      {
        label: 'Notes',
        icon: 'pi pi-copy',
        command: async () => {
          const res = await getOrderNotes(orderRd.orderid)
          if (res && res.status === 200) {
            setNotesData({
              visible: true,
              meta: {
                target: 'Order',
                status: orderRd.orderStatus,
              },
              notes: res.data,
              orderId: orderRd.orderid,
            })
          }
          const followupRes = await getOrderFollowup(orderRd.orderid)
          if (followupRes) {
            // setOpenIssuesTableLoading(false)
            if (followupRes.status === 200) {
              setOrderFollowupRecord({
                visible: true,
                records: followupRes.data.records,
              })
            }
          }
        },
      },
      ['superadmin', 'admin', 'agent'].includes(user.role) && {
        label: 'Reoder',
        icon: 'pi pi-replay',
        command: () => {
          history.push(orderViewUrl(orderRd.orderid, 'reorder'))
        },
      },
      {
        label: 'Write Email',
        icon: 'pi pi-envelope',
        command: () => {
          setSendEmail((ps) => ({
            ...ps,
            refId: orderRd.orderid,
            email: orderRd.email,
            relatedTo: 'order',
            subject: `Update regarding order ${orderRd.orderid}`,
          }))
          setSendEmailDialogVisible(true)
        },
      },
      (['superadmin'].includes(user.role) || user.accessToCopy === 1) && {
        label: 'Copy Email',
        icon: 'pi pi-clone',
        command: () => {
          copy(orderRd.email)
          toast.current.show({
            severity: 'success',
            summary: 'Copy action',
            detail: 'Email copied to clipboard',
          })
        },
      },
      (['superadmin'].includes(user.role) || user.accessToCopy === 1) && {
        label: 'Copy Phone',
        icon: 'pi pi-clone',
        command: () => {
          copy(orderRd.phone)
          toast.current.show({
            severity: 'success',
            summary: 'Copy action',
            detail: 'Phone number copied to clipboard',
          })
        },
      },
    ]

    return (
      <div className="actions p-d-flex p-ai-center p-jc-start gap-1by2">
        <Link to={orderViewUrl(rd.orderid)}>
          <Button icon="pi pi-eye" tooltip="View order" tooltipOptions={{ position: 'bottom' }} />
        </Link>
        {/* {['superadmin', 'admin', 'agent'].includes(user.role) && (
          <Link to={orderViewUrl(rd.orderid, 'reorder')}>
            <Button
              icon="pi pi-replay"
              tooltip="Reorder"
              className="p-button-info"
              tooltipOptions={{ position: 'bottom' }}
            />
          </Link>
        )} */}
        <Button
          icon="pi pi-bars"
          tooltip="Menu"
          tooltipOptions={{ position: 'bottom' }}
          onClick={(event) => {
            menu_utilies?.current.toggle(event)
            setOrderRd(rd)
            setSelectionState((ps) => ({ ...ps, lastViewedId: rd }))
          }}
          className="p-button-help"
          aria-controls="popup_menu"
          aria-haspopup
        />
        {/* <Button
          icon="pi pi-envelope"
          tooltip="Write Email"
          tooltipOptions={{ position: 'bottom' }}
          onClick={() => {
            setSendEmail((ps) => ({
              ...ps,
              refId: rd.orderid,
              email: rd.email,
              relatedTo: 'order',
              subject: `Update regarding order ${rd.orderid}`,
            }))
            setSendEmailDialogVisible(true)
          }}
          className="p-button-info"
          aria-controls="popup_menu"
          aria-haspopup
        /> */}
        <Menu
          model={utilities_items.filter((item) => item)}
          popup
          ref={menu_utilies}
          id="popup_menu"
        />
        {/* <Menu
          model={communication_items.filter((item) => item)}
          popup
          ref={menu_communication}
          id="popup_menu"
        /> */}
      </div>
    )
  }

  const actionBinBodyTemplate = (rd) => {
    return (
      <div className="actions p-d-flex p-ai-center p-jc-center">
        {(['superadmin', 'admin'].includes(user.role) || user.binOrderAccess === 1) && (
          <Button
            icon="pi pi-angle-double-up"
            className="p-button p-mr-2"
            value="Bin"
            onClick={() => {
              confirmMoveOrder(rd)
              setSelectionState((ps) => ({ ...ps, lastViewedId: rd }))
            }}
            tooltip="Move order back"
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
        <Link to={orderViewUrl(rd.orderid, 'basic')}>
          <Button
            icon="pi pi-eye"
            className="p-button p-button-info p-mr-2"
            tooltip="View order details"
            tooltipOptions={{ position: 'bottom' }}
          />
        </Link>

        {['superadmin'].includes(user?.role) && (
          <Button
            icon="pi pi-trash"
            className="p-button p-button-danger p-mr-2"
            value="Bin"
            onClick={() => {
              setDeleteOrderDialog(true)
              setTempVar({ event: '', data: rd })
              setSelectionState((ps) => ({ ...ps, lastViewedId: rd }))
            }}
            tooltip="Delete order completely"
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
      </div>
    )
  }

  const handleSearchOrderByProduct = debounce(async (e) => {
    const input = e.target.value.trim()
    if (!input) {
      return onChangeFilter(null, 'orderid')
    }
    const res = await searchOrderByProduct(input)
    if (res) {
      if (res.status === 200) {
        const orderids = res.data.map((i) => i.orderid)
        onChangeFilter(orderids, 'orderid')
      }
    }
  }, 500)

  /* HEADER */
  const header = (
    <div className="p-d-flex p-flex-wrap p-jc-end gap-1by2">
      {[
        '/orders',
        '/orders/manual',
        '/orders/my-team',
        '/orders/bin',
        '/orders/undelivered',
      ].includes(pageUrl) && (
        <>
          <Dropdown
            placeholder="Payment Method"
            value={filters.paymentMethod.value}
            showClear
            filterPlaceholder="Search"
            options={ordersMeta.paymentMethods ?? []}
            onChange={(e) => {
              onChangeFilter(e.value, 'paymentMethod')
            }}
          />
          <Dropdown
            placeholder="Payment Partner"
            value={filters.paymentPartner.value}
            showClear
            filterPlaceholder="Search"
            options={ordersMeta.paymentPartners ?? []}
            onChange={(e) => {
              onChangeFilter(e.value, 'paymentPartner')
            }}
          />
          <Dropdown
            options={user?.teams || []}
            value={filters.teams.value}
            placeholder="Teams"
            filter
            filterBy="teamName"
            filterPlaceholder="Search"
            optionLabel="teamName"
            optionValue="id"
            showClear
            onChange={(e) => {
              onChangeFilter(e.value, 'teams')
            }}
          />
        </>
      )}
      {[
        '/orders',
        '/orders/manual',
        '/orders/my-team',
        '/orders/bin',
        '/orders/undelivered',
      ].includes(pageUrl) && (
        <div className="">
          <MultiSelect
            options={user?.members || []}
            value={filters.masterid.value}
            placeholder="Users"
            filter
            filterBy="username,role"
            filterPlaceholder="Search"
            optionLabel="username"
            optionValue="id"
            showClear
            onChange={(e) => {
              if (e.value === null || e.value.length === 0) {
                // Handle clearing the selection
                handleSearchParamsChange({ masterid: '' })
              } else {
                const val = e.value.join(' ')
                handleSearchParamsChange({ masterid: val })
              }
            }}
            maxSelectedLabels={3}
          />
        </div>
      )}

      <div>
        <Dropdown
          options={orderSource}
          value={filters.source.value}
          optionLabel="name"
          placeholder="Source"
          showClear
          onChange={(e) => {
            onChangeFilter(e.value, 'source')
          }}
          className=""
        ></Dropdown>
      </div>
      <div>
        <Dropdown
          options={leadSource}
          value={filters.orderLeadSource.value}
          optionLabel="name"
          optionValue="value"
          placeholder="Lead"
          showClear
          onChange={(e) => {
            onChangeFilter(e.value, 'orderLeadSource')
          }}
        ></Dropdown>
      </div>
      <div>
        <Dropdown
          options={shippingTags}
          value={filters.shippingCompany.value}
          placeholder="Shipping"
          showClear
          onChange={(e) => {
            onChangeFilter(e.value, 'shippingCompany')
          }}
        ></Dropdown>
      </div>
      {['/orders', '/orders/manual', '/orders/my-team'].includes(pageUrl) && (
        <div>
          <MultiSelect
            value={filters.orderStatus.value}
            options={orderStatusOptions}
            onChange={(e) => {
              const val = e.value.join(' ')
              handleSearchParamsChange({ orderStatus: val })
            }}
            placeholder="Order Status"
            className=""
            maxSelectedLabels={3}
          />
        </div>
      )}
      <div className="p-d-flex gap-1by2 p-ai-center">
        <QueryBasedDatesPicker
          code={orderDateCode}
          setCode={setOrderDateCode}
          rangeDates={orderDates}
          setRangeDates={setOrderDates}
          handleSearchParamsChange={handleSearchParamsChange}
          dateFromParamName="dateFrom"
          dateToParamName="dateTo"
        />
      </div>
      <div className="">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilterValue}
            onChange={(e) => {
              setGlobalFilterValue(e.target.value)
              onChangeFilter(e.target.value, 'global')
            }}
            placeholder="Search Orders"
          />
        </span>
      </div>
      <div className="">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onChange={handleSearchOrderByProduct}
            placeholder="Search Products"
          />
        </span>
      </div>
    </div>
  )

  /* FOOTERS */

  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
        autoFocus
      />
    </>
  )
  const deleteProductsDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
        autoFocus
      />
    </>
  )

  const moveOrderBackDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideMoveOrderBackDialogDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={moveSelectedOrder}
        autoFocus
      />
    </>
  )

  const deleteSelectedOrdersDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteSelectedOrdersDialog(false)}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedOrders}
        autoFocus
      />
    </>
  )

  // const dialogFuncMap = {
  //   otpDialog: setOtpDialog,
  //   messageDialog: setMessageDialog,
  // }

  const onHide = () => {
    setOtpDialog(false)
  }

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-text"
        />
      </div>
    )
  }

  useEffect(() => {
    if (search !== '') return
    if (!setSelectionState.lastViewedId || !setSelectionState.lastViewedId.orderid) return
    const fn = async () => {
      scrollIdIntoView(setSelectionState.lastViewedId.orderid)
    }
    delay(fn, 100)
  }, [search, setSelectionState.lastViewedId])

  const getFilterStats = async ({ orders, filters }) => {
    if (!orders) {
      return
    }
    // get count and sum of order amount from filtered orders
    // 1. create a shallow copy of orders
    // 2. filter orders based on filters
    // 3. get total orders and total sales
    const { orderid, source, website, orderStatus, orderLeadSource, shippingCompany, masterid } = {
      ...filters,
    }
    const filterValues = {
      orderStatus: orderStatus.value || [],
      orderid: orderid.value || [],
      masterid: masterid.value || [],
      source: source.value,
      orderLeadSource: orderLeadSource.value,
      website: website.value,
      shippingCompany: shippingCompany.value,
    }

    let filteredOrders = orders.filter((order) => {
      return (
        (filterValues.orderStatus.length === 0 ||
          filterValues.orderStatus.includes(order.orderStatus)) &&
        (filterValues.orderid.length === 0 || filterValues.orderid.includes(order.orderid)) &&
        (filterValues.masterid.length === 0 || filterValues.masterid.includes(order.masterid)) &&
        (!filterValues.source || filterValues.source === order.source) &&
        (!filterValues.orderLeadSource || filterValues.orderLeadSource === order.orderLeadSource) &&
        (!filterValues.website || filterValues.website === order.website) &&
        (!filterValues.shippingCompany ||
          order.shippingCompany.includes(filterValues.shippingCompany))
      )
    })

    const totalOrders = filteredOrders.length
    const totalSales = filteredOrders
      .reduce((acc, curr) => acc + parseFloat(curr.total), 0)
      .toFixed(2)
    setFilterStats({ totalOrders, totalSales })
  }

  useEffect(() => {
    if (!orders) return
    getFilterStats({
      orders,
      filters,
    })
  }, [orders, filters])

  const fetchOrder = async (searchId) => {
    setTableLoading(true)
    const res = await getOrder(searchId, searchView)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        return res.data
      }
    }
    return {}
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="p-grid crud-demo">
        <div className="p-col-12">
          {/* hello {dateFilter?.dateFrom} {dateFilter?.dateTo} */}
          <div className="card">
            <Toolbar
              className="p-toolbar gap-1"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            {/* ORDER-DATA */}
            <div className="order-datatable">
              <DataTable
                ref={dt}
                exportFilename={`orders-${new Date().toLocaleDateString()}`}
                value={orders}
                showGridlines
                selectionMode={'multiple'}
                selection={selectionState.selectedOrders}
                onSelectionChange={(e) => {
                  const selected = e.value // Array of selected rows
                  const lastId = selected.length > 0 ? selected[selected.length - 1] : null
                  setSelectionState({
                    selectedOrders: selected,
                    lastViewedId: lastId, // Store last viewed ID
                  })
                }}
                filters={filters}
                dataKey="orderid"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
                className="datatable-responsive"
                columnResizeMode="fit"
                emptyMessage="No orders found."
                header={header}
                loading={tableLoading}
                rowHover
                breakpoint="1000px"
                responsiveLayout="stack"
                removableSort
                rowClassName={(rd) => {
                  return {
                    'row-blacklisted': rd.blacklistedCustomer,
                  }
                }}
              >
                {![
                  '/orders/undelivered',
                  '/orders/bin',
                  '/orders/my-team',
                  '/orders/manual',
                ].includes(pageUrl) &&
                  ['superadmin', 'admin'].includes(user.role) && (
                    <Column selectionMode="multiple" />
                  )}
                <Column hidden field="email" />
                <Column hidden field="phone" />
                <Column hidden field="country" />
                <Column hidden field="orderLeadSource" />
                <Column hidden field="teams" />

                <Column
                  body={orderIdBodyTemplate}
                  style={{ width: '12%' }}
                  field="orderid"
                  header="Invoice ID"
                />
                <Column
                  style={{ width: '10%' }}
                  field="name"
                  header="Name"
                  sortable
                  body={nameBodyTemplate}
                />
                <Column style={{ width: '10%' }} field="mastername" header="Order By" />
                <Column
                  style={{ width: '8%' }}
                  field="orderDate"
                  header="Date"
                  body={dateBodyTemplate}
                />
                <Column
                  style={{ width: '1%' }}
                  field="clientTime"
                  header="Client Time"
                  body={clientTimeBodyTemplate}
                />
                <Column
                  style={{ width: '1%' }}
                  field="total"
                  header="Total"
                  body={totalpriceBodyTemplate}
                  sortable
                />

                <Column style={{ width: '1%' }} field="source" header="Source" />
                <Column style={{ width: '1%' }} field="shippingCompany" header="Ship" />

                {/* hides this section in bin page */}
                <Column
                  hidden={['/orders/bin'].includes(pageUrl)}
                  style={{ width: '1%' }}
                  field="paymentMethod"
                  header="Payment Method"
                  body={paymentMethodBodyTemplate}
                />
                <Column style={{ width: '5%' }} header="Notify Via" body={notifyViaBodyTemplate} />
                <Column
                  hidden={['/orders/bin'].includes(pageUrl)}
                  style={{ width: '10%' }}
                  field="orderStatus"
                  header="Order Status"
                  body={orderStatusBodyTemplate}
                />
                <Column
                  hidden={['/orders/bin'].includes(pageUrl)}
                  header="Product Status"
                  body={productStatusBodyTemplate}
                />
                <Column
                  hidden={pageUrl === '/orders/bin'}
                  header="Action"
                  className="p-text-center"
                  body={actionBodyTemplate}
                />
                <Column
                  style={{ width: '4%' }}
                  hidden={['/orders/bin'].includes(pageUrl)}
                  header="QA Score"
                  body={(rd) => (
                    <Badge
                      size="large"
                      value={rd.saleQualityScore}
                      style={{ background: colorScores[rd.saleQualityScore], lineHeight: '2rem' }}
                    />
                  )}
                />
                <Column
                  hidden={pageUrl !== '/orders/bin'}
                  style={{ width: '10%' }}
                  field="binEntryDate"
                  body={binEntryDateBodyTemplate}
                  header="Expires In"
                  className="p-text-center"
                />
                {/* show this section in bin page */}
                <Column
                  hidden={pageUrl !== '/orders/bin'}
                  header="Action"
                  className="p-text-center"
                  body={actionBinBodyTemplate}
                />
                {/* <Column
                style={{ width: '5%' }}
                header="Timestamp"
                className="p-text-center"
                body={timestampBodyTemplate}
              ></Column> */}
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      {/* ORDER DIALOG */}
      <>
        <OrderDetailsDialog
          resetUrl={resetUrl}
          discountOptions={discountOptions}
          handelSyncOrder={handelSyncOrder}
          orderDialogVisible={orderDialogVisible}
          setOrderDialogVisible={setOrderDialogVisible}
          orderProductDetails={orderProductDetails}
          enableProductFields={enableProductFields}
          setOrderProductDetails={setOrderProductDetails}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
          setEnableProductFields={setEnableProductFields}
          isEditable={isEditable}
          setEnableFields={setEnableFields}
          addressDetails={addressDetails}
          enableFields={enableFields}
          setAddressDetails={setAddressDetails}
          addTrackingDetails={addTrackingDetails}
          setAddTrackingDetails={setAddTrackingDetails}
          trackingDetails={trackingDetails}
          setTrackingDetails={setTrackingDetails}
        />
      </>

      {/* Add manual order */}
      {[
        '/orders/manual',
        '/orders',
        '/orders/my-team',
        '/orders/my',
        '/orders/undelivered',
      ].includes(pageUrl) && (
        <AddManualOrder
          resetUrl={resetUrl}
          discountOptions={discountOptions}
          newManualOrderDialogVisible={newManualOrderDialogVisible}
          manualProducts={manualProducts}
          setManualProducts={setManualProducts}
          setNewManaulDialog={setNewManaulDialogVisible}
          addNewOrderToTable={addNewOrderToTable}
          customProducts={customProducts}
          setCustomProducts={setCustomProducts}
          customerData={customerData}
          setCustomerData={setCustomerData}
          emptyCustomer={emptyCustomer}
          showSuccess={showSuccess}
          toast={toast}
        />
      )}

      {/* Basic Order Dialog */}
      <>
        <BasicOrderDetailsDialog
          resetUrl={resetUrl}
          discountOptions={discountOptions}
          orderDialogVisible={basicOrderDialogVisible}
          setBasicOrderDialogVisible={setBasicOrderDialogVisible}
          orderProductDetails={orderProductDetails}
          orderDetails={orderDetails}
          setEnableFields={setEnableFields}
          addressDetails={addressDetails}
          enableFields={enableFields}
        />
      </>
      {/* Keep em close */}
      <>
        <Dialog
          visible={deleteSelectedOrdersDialog}
          style={{ width: '450px' }}
          header="Confirm"
          modal
          footer={deleteSelectedOrdersDialogFooter}
          onHide={() => setDeleteSelectedOrdersDialog(false)}
        >
          <div className="confirmation-content  p-d-flex p-ai-center">
            <i className="pi pi-exclamation-triangle p-mr-4" style={{ fontSize: '2rem' }} />
            {selectionState?.selectedOrders?.length && (
              <span>
                Are you sure you want to delete the <b>selected orders</b>?
              </span>
            )}
          </div>
        </Dialog>
        <Dialog
          visible={deleteProductDialog}
          style={{ width: '450px' }}
          header="Confirm"
          modal
          footer={deleteProductDialogFooter}
          onHide={hideDeleteProductDialog}
        >
          <div className="confirmation-content  p-d-flex p-ai-center">
            <i className="pi pi-exclamation-triangle p-mr-4" style={{ fontSize: '2rem' }} />
            {order && (
              <span>
                {' '}
                Are you sure you want to move order <b>{order.orderid}</b> in bin?
              </span>
            )}
          </div>
        </Dialog>

        <Dialog
          visible={deleteProductsDialog}
          style={{ width: '450px' }}
          header="Confirm"
          modal
          footer={deleteProductsDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="confirmation-content  p-d-flex p-ai-center">
            <i className="pi pi-exclamation-triangle p-mr-4" style={{ fontSize: '2rem' }} />
            {order && <span>Are you sure you want to delete the selected products?</span>}
          </div>
        </Dialog>

        {/* Move order back */}
        <Dialog
          visible={moveOrderBackDialog}
          style={{ width: '450px' }}
          header="Confirm"
          modal
          footer={moveOrderBackDialogFooter}
          onHide={hideMoveOrderBackDialogDialog}
        >
          <div className="confirmation-content  p-d-flex p-ai-center">
            <i className="pi pi-info-circle p-mr-4" style={{ fontSize: '2rem' }} />
            {order && (
              <span>
                Are you sure you want to move order <b>{order.orderid}</b> back to all orders?
              </span>
            )}
          </div>
        </Dialog>

        {/* Message Box Modal */}
        <Dialog
          header="Verify OTP"
          modal
          className="p-fluid"
          visible={otpDialog}
          style={{ width: '450px' }}
          footer={renderFooter()}
          onHide={() => onHide()}
        >
          <div className="login-panel p-fluid">
            <div className=" outter_box p-d-flex p-jc-center">
              <div className="inner_box">
                <input type="number" maxLength="1" className="box" />
                <input type="number" maxLength="1" className="box" />
                <input type="number" maxLength="1" className="box" />
                <input type="number" maxLength="1" className="box" />
              </div>
            </div>
            <div className="p-text-center">
              <Button
                label="Submit"
                className="p-button-primary"
                style={{ width: '8rem' }}
                onClick={() => verifyotp()}
              />
            </div>
          </div>
        </Dialog>

        {/* Message Box Modal */}
        <SendMailDialog mailData={sendEmail} setMailData={setSendEmail} />
        {/* <Dialog
          header={`Compose Mail For ${mailData.orderid}`}
          modal
          className="p-fluid"
          visible={
            messageDialog
            // true
          }
          style={{ width: '1000px' }}
          footer={renderFooter('messageDialog')}
          onHide={() => hideMessageDialog()}
        >
          <div className="p-my-2">
            <div className="p-field">
              <label htmlFor="subject" className="block p-text-bold">
                Subject
              </label>
              <InputText
                id="subject"
                value={mailData.subject}
                onChange={(e) => setMailData({ ...mailData, subject: e.target.value })}
                placeholder="Enter The Subject"
                aria-describedby="subject"
                className="block"
              />
            </div>
            <div className="p-field">
              <label htmlFor="body" className="block p-text-bold">
                Body
              </label>
              <ReactQuill
                value={mailData.body}
                onChange={(e) => setMailData({ ...mailData, body: e })}
              />
            </div>
            <div className="p-col-12">
              <Checkbox
                checked={mailData.confirm}
                onChange={(e) => setMailData({ ...mailData, confirm: e.checked })}
              />
              <label className="p-checkbox-label p-ml-2">
                I confirm sending this mail to the customer
              </label>
            </div>
          </div>
        </Dialog> */}

        {/* Message delete order */}

        <ConfirmDialogWithPassword
          onHide={hideOrderDeleteDialog}
          onSubmit={orderDeleteHandle}
          visible={deleteOrderDialog}
          loading={orderDeleting}
          password={password}
          setPassword={setPassword}
          headerText="Delete Order"
          mainText={`Are you sure you want to delete order ${tempVar['data']?.orderid}?`}
        />

        {/* resend order status email */}
        <Dialog
          autoFocus
          style={{ width: '450px' }}
          visible={resentEmailDialogData.visible}
          header={`Resend Order Email`}
          onHide={hideResendEmailDialog}
          footer={() => (
            <div>
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                  hideResendEmailDialog()
                }}
              />
              <Button
                disabled={
                  !resentEmailDialogData.confirm ||
                  !resentEmailDialogData.status ||
                  resentEmailDialogData.loading
                }
                loading={resentEmailDialogData.loading}
                label={resentEmailDialogData.loading ? 'Sending' : 'Send'}
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => onResendOrderEmail()}
                autoFocus
              />
            </div>
          )}
        >
          <p>
            Please select which email to resend for order id{' '}
            <strong>{resentEmailDialogData?.order?.orderid}</strong>
          </p>
          <div className="p-mb-3">
            <strong className="p-mr-2">Order status</strong>
            <Dropdown
              className=""
              placeholder="Select Order Status"
              options={
                ['superadmin', 'admin', 'manager', 'teamlead'].includes(user.role)
                  ? resend_status
                  : resend_status_agent
              }
              value={resentEmailDialogData.status}
              onChange={(e) => onResentEmailChange('status', e.value)}
            />
          </div>
          <div>
            <Checkbox
              inputId="binary"
              checked={resentEmailDialogData.confirm}
              onChange={(e) => onResentEmailChange('confirm', e.checked)}
            />
            <label htmlFor="binary" className="p-ml-2">
              I confirm resending email to customer{' '}
              <strong>{resentEmailDialogData?.order?.name}</strong>
            </label>
          </div>
        </Dialog>
        {/* Message status change */}
        <Dialog
          autoFocus
          style={{ width: '550px' }}
          header={`Change Order Status For (${tempVar && tempVar['data']?.orderid})`}
          modal
          className=""
          visible={changeStatus}
          footer={
            <>
              <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => hideChangeStatusDialog()}
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                  if (!tempVar) return
                  const event = tempVar['event'],
                    orderid = tempVar['data']?.orderid
                  onStatusChange(event, orderid, changeStatusNotification)
                }}
                autoFocus
              />
            </>
          }
          onHide={() => hideChangeStatusDialog()}
        >
          <div className="confirmation-content p-d-flex p-ai-start">
            <i className="pi pi-exclamation-triangle p-mr-4 p-mt-4" style={{ fontSize: '2rem' }} />
            <div>
              {tempVar && (
                <div>
                  Are you sure you want to change order status from {tempVar['data']?.orderStatus}{' '}
                  to {tempVar['event']?.value}
                  {tempVar &&
                    !tempVar['data']?.paymentlink &&
                    resend_status.includes(tempVar['event']?.value) && (
                      <>
                        <br />
                        <span className="p-text-bold">
                          (Payment link is not been sent) {tempVar.data?.paymentlink}
                        </span>
                      </>
                    )}
                </div>
              )}
              {resend_status.includes(tempVar['event']?.value) && (
                <div className="p-mt-3 p-d-flex gap-1 p-ai-center ">
                  <Checkbox
                    inputId="changeStatusNotification"
                    checked={changeStatusNotification}
                    onChange={(e) => setChangeStatusNotification(e.checked)}
                  />
                  <label htmlFor="changeStatusNotification">
                    Send notification to customer regrading this order progress
                  </label>
                </div>
              )}

              {tempVar['event']?.value === 'Processed' && (
                <div className="p-mt-4">
                  <h6 className="">Payment Details</h6>
                  <hr />
                  <div className="p-grid p-formgrid p-fluid">
                    <div className="p-field p-col-12">
                      <label>
                        Client Name{' '}
                        <RequiredMessage value={paymentProcessedDetail.paymentPartnerMethodId} />
                      </label>
                      <InputText
                        disabled
                        placeholder="Client Name"
                        value={paymentProcessedDetail.clientName}
                        onChange={(e) =>
                          handleObjChange(setPaymentProcessedDetail, 'clientName', e.target.value)
                        }
                      />
                    </div>
                    <div className="p-field p-col-12">
                      <label>
                        Paid Amount{' '}
                        <RequiredMessage value={paymentProcessedDetail.paymentPartnerMethodId} />
                      </label>
                      <InputNumber
                        disabled
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        placeholder="Paid Amount"
                        value={paymentProcessedDetail.paidAmount}
                        onChange={(e) =>
                          handleObjChange(setPaymentProcessedDetail, 'paidAmount', e.value)
                        }
                      />
                    </div>
                    <div className="p-field p-col-12">
                      <label>
                        Payment Partner Method{' '}
                        <RequiredMessage value={paymentProcessedDetail.paymentPartnerMethodId} />
                      </label>
                      <Dropdown
                        options={paymentPartnerMethods ?? []}
                        optionLabel="paymentPartnerMethod"
                        optionValue="id"
                        placeholder="Select Payment Partner Method"
                        value={paymentProcessedDetail.paymentPartnerMethodId}
                        onChange={(e) => {
                          const item = paymentPartnerMethods.find((i) => i.id === e.value)
                          if (!item || !item.active) return
                          handleObjChange(
                            setPaymentProcessedDetail,
                            'paymentPartnerMethodId',
                            e.value
                          )
                          handleObjChange(setPaymentProcessedDetail, 'gatewayName', item.appName)
                          handleObjChange(
                            setPaymentProcessedDetail,
                            'paymentPartner',
                            item.paymentPartner
                          )
                        }}
                        itemTemplate={(option) => (
                          <span
                            className={classNames({
                              'opacity-50': !option?.active,
                            })}
                          >
                            {option?.paymentPartnerMethod}
                          </span>
                        )}
                      />
                    </div>
                    <div className="p-field p-col-12">
                      <label>
                        Agent/Teamlead Name{' '}
                        <RequiredMessage value={paymentProcessedDetail.paymentPartnerMethodId} />
                      </label>
                      <Dropdown
                        disabled={user.role === 'agent' && paymentProcessedDetail.masterid}
                        options={user?.members.filter((i) =>
                          ['agent', 'teamlead'].includes(i.role)
                        )}
                        optionLabel="username"
                        optionValue="id"
                        placeholder="Agent/Teamlead Name"
                        filter
                        filterBy="username,role"
                        filterPlaceholder="Search"
                        value={paymentProcessedDetail.userId}
                        onChange={(e) => {
                          handleObjChange(setPaymentProcessedDetail, 'userId', e.target.value)
                          const val = agents.find((item) => item.id === e.target.value)
                          if (val) {
                            handleObjChange(setPaymentProcessedDetail, 'userName', val.username)
                          } else {
                            handleObjChange(setPaymentProcessedDetail, 'userName', '')
                          }
                        }}
                      />
                    </div>
                    <div className="p-field p-col-12">
                      <label>Additional Note</label>
                      <InputText
                        placeholder="Additional Note"
                        value={paymentProcessedDetail.additionalNote}
                        onChange={(e) =>
                          handleObjChange(
                            setPaymentProcessedDetail,
                            'additionalNote',
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="p-col-12">
                      <span className="">
                        Note: Selected agent/teamlead will be assigned to this order after request
                        approval.
                      </span>
                    </div>
                  </div>
                  <AvailablePaymentMethodsTable totalAmount={paymentProcessedDetail.paidAmount} />
                </div>
              )}

              {paymentProcessedDetailErrors.length > 0 && (
                <div className="">
                  <hr />
                  <div className="p-grid">
                    {paymentProcessedDetailErrors.map((err, idx) => (
                      <div key={idx} className="p-col-12">
                        <Message className="w-full" text={err} severity="warn" sticky={true} />
                      </div>
                    ))}
                    <div className="p-col-12">
                      <Button
                        onClick={() => setPaymentProcessedDetailErrors([])}
                        icon="pi pi-times"
                        label="Clear Warnings"
                        className="p-button-secondary w-full"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Dialog>
        {/* notes */}
        <OrderNoteDialog
          notesData={notesData}
          setNotesData={setNotesData}
          hideNotes={hideNotes}
          handleNotesDataChange={handleNotesDataChange}
          addOrderNote={addOrderNote}
          deleteOrderNote={deleteOrderNote}
          editOrderNote={editOrderNote}
          orderProductFollowupRecord={orderFollowupRecord}
          setOrderProductFollowupRecord={setOrderFollowupRecord}
          isFollowupRecordsVisible={true}
        />
        {/* message */}
        <OrderMessageDialog
          visible={orderMessageDialogVisible}
          setVisible={setOrderMessageDialogVisible}
          order={orderRd}
        />
        <ChangeOrderStatus
          orderData={orderStatusUpdateData}
          setOrderData={setOrderStatusUpdateData}
          orderStatusDialogVisible={orderStatusDialogVisible}
          setOrderStatusDialogVisible={setOrderStatusDialogVisible}
          orders={orders}
          setOrders={setOrders}
        />
      </>
    </div>
  )
}
