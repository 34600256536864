import { Button } from 'primereact/button'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getUsersTickets } from '../../api/userTickets'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import RaiseTicket from '../../components/mycomponents/UserTicket/RaiseTicket'
import TicketNotes from '../../components/mycomponents/UserTicket/TicketNotes'
import UpdateTicket from '../../components/mycomponents/UserTicket/UpdateTicket'
import UserTicketsDataTable from '../../components/mycomponents/UserTicket/UserTicketsDataTable'
import ViewTicket from '../../components/mycomponents/UserTicket/ViewTicket'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { assignDates, dateFilters, dtFilenameDate } from '../../functions/myDateFns'
import ExportButton from '../../components/mini/ExportButton'

export default function UserTicketManagementPage() {
  //* states start
  const all_dt = useRef(null)
  const my_dt = useRef(null)
  const assigned_dt = useRef(null)
  const closed_dt = useRef(null)
  // All table date filter start
  const [allFilters, setAllFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedToTeamIds: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedToUserIds: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [allPanelDateFilter, setAllPanelDateFilter] = useState(assignDates(dateFilters[1].code))
  const [allPanelDateCode, setAllPanelDateCode] = useState(dateFilters[1].code)
  const [allPanelDateRange, setAllPanelDateRange] = useState(null)
  // All table date filter end

  // My table date filter start
  const [myFilters, setMyFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedToTeamIds: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedToUserIds: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [myPanelDateFilter, setMyPanelDateFilter] = useState(assignDates(dateFilters[1].code))
  const [myPanelDateCode, setMyPanelDateCode] = useState(dateFilters[1].code)
  const [myPanelDateRange, setMyPanelDateRange] = useState(null)
  // My table date filter end

  // Assigned table date filter start
  const [assignedFilters, setAssignedFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedToTeamIds: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedToUserIds: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [assignedPanelDateFilter, setAssignedPanelDateFilter] = useState(
    assignDates(dateFilters[1].code)
  )
  const [assignedPanelDateCode, setAssignedPanelDateCode] = useState(dateFilters[1].code)
  const [assignedPanelDateRange, setAssignedPanelDateRange] = useState(null)
  // Assigned table date filter end

  // Closed table date filter start
  const [closedFilters, setClosedFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedToTeamIds: { value: null, matchMode: FilterMatchMode.EQUALS },
    assignedToUserIds: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [closedPanelDateFilter, setClosedPanelDateFilter] = useState(
    assignDates(dateFilters[1].code)
  )
  const [closedPanelDateCode, setClosedPanelDateCode] = useState(dateFilters[1].code)
  const [closedPanelDateRange, setClosedPanelDateRange] = useState(null)
  // Closed table date filter end

  const [newTicketDialogVisible, setNewTicketDialogVisible] = useState(false)
  const [updateTicketDialogVisible, setUpdateTicketDialogVisible] = useState(false)
  const [allTicketsTableLoading, setAllTicketsTableLoading] = useState(false)
  const [myTicketsTableLoading, setMyTicketsTableLoading] = useState(false)
  const [assignedTicketsTableLoading, setAssignedTicketsTableLoading] = useState(false)
  const [closedTicketsTableLoading, setClosedTicketsTableLoading] = useState(false)

  const [allUsersTickets, setAllUsersTickets] = useState([])
  const [myTickets, setMyTickets] = useState([])
  const [assignedUserTickets, setAssignedUserTickets] = useState([])
  const [closedUsersTickets, setClosedUsersTickets] = useState([])
  const [loading, setLoading] = useState(false)
  //* states end

  //* Handler to fetch all open tickets
  const fetchAllUsersTickets = useCallback(async () => {
    setAllTicketsTableLoading(true)
    const res = await getUsersTickets({
      dateFrom: allPanelDateFilter.dateFrom,
      dateTo: allPanelDateFilter.dateTo,
    })
    if (res) {
      setAllTicketsTableLoading(false)
      if (res.status === 200) {
        setAllUsersTickets(res.data)
      }
    }
  }, [allPanelDateFilter.dateFrom, allPanelDateFilter.dateTo])
  useEffect(() => {
    fetchAllUsersTickets()
  }, [fetchAllUsersTickets])

  //* Handler to fetch my open tickets
  const fetchMyTickets = useCallback(async () => {
    setMyTicketsTableLoading(true)
    let status = 'my'
    const res = await getUsersTickets({
      dateFrom: myPanelDateFilter.dateFrom,
      dateTo: myPanelDateFilter.dateTo,
      status,
    })
    if (res) {
      setMyTicketsTableLoading(false)
      if (res.status === 200) {
        setMyTickets(res.data)
      }
    }
  }, [myPanelDateFilter.dateFrom, myPanelDateFilter.dateTo])
  useEffect(() => {
    fetchMyTickets()
  }, [fetchMyTickets])

  //* Handler to fetch assigned tickets
  const fetchAssignedTickets = useCallback(async () => {
    let status = 'assigned'
    setAssignedTicketsTableLoading(true)
    const res = await getUsersTickets({
      dateFrom: assignedPanelDateFilter.dateFrom,
      dateTo: assignedPanelDateFilter.dateTo,
      status,
    })
    if (res) {
      setAssignedTicketsTableLoading(false)
      if (res.status === 200) {
        setAssignedUserTickets(res.data)
      }
    }
  }, [assignedPanelDateFilter.dateFrom, assignedPanelDateFilter.dateTo])
  useEffect(() => {
    fetchAssignedTickets()
  }, [fetchAssignedTickets])

  //* Handler to fetch closed tickets
  const fetchClosedTickets = useCallback(async () => {
    let status = 'closed'
    setClosedTicketsTableLoading(true)
    const res = await getUsersTickets({
      dateFrom: closedPanelDateFilter.dateFrom,
      dateTo: closedPanelDateFilter.dateTo,
      status,
    })
    if (res) {
      setClosedTicketsTableLoading(false)
      if (res.status === 200) {
        setClosedUsersTickets(res.data)
      }
    }
  }, [closedPanelDateFilter.dateFrom, closedPanelDateFilter.dateTo])
  useEffect(() => {
    fetchClosedTickets()
  }, [fetchClosedTickets])

  // Data Table Filters Header

  const ToolBar = ({ heading, fetchUserTickets, dtRef }) => {
    return (
      <Toolbar
        className="p-toolbar p-mb-3 p-flex-wrap gap-1"
        left={
          <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
            <h4 className="p-m-0">{heading}</h4>

            <Button
              icon="pi pi-plus"
              label="Raise Ticket"
              onClick={() => {
                setNewTicketDialogVisible(true)
              }}
            />
          </div>
        }
        right={
          <div>
            <div className="p-d-flex p-flex-wrap gap-1">
              <Button
                label="Refresh"
                icon="pi pi-refresh"
                onClick={() => {
                  fetchUserTickets()
                }}
              />

              <ExportButton datatable_ref={dtRef} />
            </div>
          </div>
        }
      ></Toolbar>
    )
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <TabView>
            <TabPanel header="All Tickets">
              <ToolBar
                heading={'All Tickets'}
                fetchUserTickets={fetchAllUsersTickets}
                dtRef={all_dt}
              />
              <UserTicketsDataTable
                type="all"
                dtRef={all_dt}
                exportFilename={`All_Tickets_${dtFilenameDate}`}
                dateCode={allPanelDateCode}
                setDateCode={setAllPanelDateCode}
                dateRange={allPanelDateRange}
                setDateRange={setAllPanelDateRange}
                dateFilter={allPanelDateFilter}
                setDateFilter={setAllPanelDateFilter}
                userTickets={allUsersTickets}
                dataTableLoading={allTicketsTableLoading}
                filters={allFilters}
                setFilters={setAllFilters}
              />
            </TabPanel>
            <TabPanel header="My Tickets">
              <ToolBar heading={'My Tickets'} fetchUserTickets={fetchMyTickets} dtRef={my_dt} />
              <UserTicketsDataTable
                type="my"
                dtRef={my_dt}
                exportFilename={`My_Tickets_${dtFilenameDate}`}
                dateCode={myPanelDateCode}
                setDateCode={setMyPanelDateCode}
                dateRange={myPanelDateRange}
                setDateRange={setMyPanelDateRange}
                dateFilter={myPanelDateFilter}
                setDateFilter={setMyPanelDateFilter}
                userTickets={myTickets}
                dataTableLoading={myTicketsTableLoading}
                filters={myFilters}
                setFilters={setMyFilters}
              />
            </TabPanel>
            <TabPanel header="Assigned Ticket">
              <ToolBar
                heading={'Assigned Tickets'}
                fetchUserTickets={fetchAssignedTickets}
                dtRef={assigned_dt}
              />
              <UserTicketsDataTable
                type="assigned"
                dtRef={assigned_dt}
                exportFilename={`Assigned_Tickets_${dtFilenameDate}`}
                dateCode={assignedPanelDateCode}
                setDateCode={setAssignedPanelDateCode}
                dateRange={assignedPanelDateRange}
                setDateRange={setAssignedPanelDateRange}
                dateFilter={assignedPanelDateFilter}
                setDateFilter={setAssignedPanelDateFilter}
                userTickets={assignedUserTickets}
                dataTableLoading={assignedTicketsTableLoading}
                filters={assignedFilters}
                setFilters={setAssignedFilters}
              />
            </TabPanel>
            <TabPanel header="Closed Ticket">
              <ToolBar
                heading={'Close Tickets'}
                fetchUserTickets={fetchClosedTickets}
                dtRef={closed_dt}
              />
              <UserTicketsDataTable
                type="closed"
                dtRef={closed_dt}
                exportFilename={`Closed_Tickets_${dtFilenameDate}`}
                dateCode={closedPanelDateCode}
                setDateCode={setClosedPanelDateCode}
                dateRange={closedPanelDateRange}
                setDateRange={setClosedPanelDateRange}
                dateFilter={closedPanelDateFilter}
                setDateFilter={setClosedPanelDateFilter}
                userTickets={closedUsersTickets}
                dataTableLoading={closedTicketsTableLoading}
                filters={closedFilters}
                setFilters={setClosedFilters}
              />
            </TabPanel>
          </TabView>
          <ViewTicket
            setAssignedUserTickets={setAssignedUserTickets}
            myTickets={myTickets}
            setMyTickets={setMyTickets}
            assignedUserTickets={assignedUserTickets}
            closedUsersTickets={closedUsersTickets}
            setClosedUsersTickets={setClosedUsersTickets}
            allUsersTickets={allUsersTickets}
            setAllUsersTickets={setAllUsersTickets}
            setAllTicketsTableLoading={setAllTicketsTableLoading}
            setMyTicketsTableLoading={setMyTicketsTableLoading}
            setAssignedTicketsTableLoading={setAssignedTicketsTableLoading}
            setLoading={setLoading}
            loading={loading}
          />
          <RaiseTicket
            setAllUsersTickets={setAllUsersTickets}
            setMyTickets={setMyTickets}
            newTicketDialogVisible={newTicketDialogVisible}
            setNewTicketDialogVisible={setNewTicketDialogVisible}
          />
          <TicketNotes
            loading={loading}
            setLoading={setLoading}
            allTicketsTableLoading={allTicketsTableLoading}
            setAllTicketsTableLoading={setAllTicketsTableLoading}
          />
          <UpdateTicket
            updateTicketDialogVisible={updateTicketDialogVisible}
            setUpdateTicketDialogVisible={setUpdateTicketDialogVisible}
          />
          <ChangeHistorySidebar header="User Ticket Change History" historyType="userTicket" />
        </div>
      </div>
    </div>
  )
}
