import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useCallback, useEffect, useState } from 'react'
import { handleObjChange } from '../../../../functions/setter'
import { Dropdown } from 'primereact/dropdown'
import { useGlobalContext } from '../../../../context'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import useQuery from '../../../../hooks/useQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  getPaymentPartnerMethodById,
  postPaymentPartnerMethod,
  putPaymentPartnerMethod,
  uploadFileToS3,
} from '../../../../api'
import { Checkbox } from 'primereact/checkbox'
import { MultiSelect } from 'primereact/multiselect'
import UploadImage from '../../../mini/UploadImage'
import { Message } from 'primereact/message'

const emptyErr = {
  state: false,
  errors: [],
}

const empty_ImageState = {
  url: '',
  file: null,
}

const emptyPaymentPartnerMethod = {
  active: false,
  paymentPartnerId: '',
  paymentMethodId: '',
  users: [],
  additionalData: [
    {
      name: '',
      value: '',
    },
  ],
  qrCodeImage: '',
}
export default function AddPaymentPartnerMethodDialog({
  paymentPartners,
  setPaymentPartnerMethods,
  setPaymentPartnerMethodsTableLoading,
  addPaymentPartnerMethodDialog,
  setAddPaymentPartnerMethodDialog,
}) {
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [paymentPartnerMethodQrCodeImage, setPaymentPartnerMethodQrCodeImage] =
    useState(empty_ImageState)
  const [paymentPartnerMethod, setPaymentPartnerMethod] = useState(emptyPaymentPartnerMethod)
  const [loading, setLoading] = useState(false)
  const { toast, paymentMethods, agents } = useGlobalContext()

  const validatePaymentMethod = (payment) => {
    const err = []
    if (payment.paymentPartnerId === '') err.push('Payment Partner cannot be empty')
    if (payment.paymentMethodId === '') err.push('Payment Method cannot be empty')
    payment.additionalData.forEach((detail) => {
      if (detail.name === '' || detail.value === '') {
        err.push('Additional details name and value cannot be empty')
      }
    })
    return err
  }

  const addPaymentPartnerMethod = async () => {
    try {
      const errs = validatePaymentMethod(paymentPartnerMethod)
      if (errs.length > 0) {
        setLoading(false)
        setNewRecordError({ state: true, errors: errs })
        return
      }
      setLoading(true)
      setPaymentPartnerMethodsTableLoading(true)
      const uploadPromises = []
      if (paymentPartnerMethodQrCodeImage.file) {
        uploadPromises.push(
          uploadFileToS3({ file: paymentPartnerMethodQrCodeImage.file }).then(
            (res) => (paymentPartnerMethod.qrCodeImage = res.data.filePath)
          )
        )
      }
      await Promise.all(uploadPromises)
      const res = await postPaymentPartnerMethod(paymentPartnerMethod)
      if (res) {
        setPaymentPartnerMethodsTableLoading(false)
        setLoading(false)
        if (res.status === 201) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.data.message,
            life: 3000,
          })
          setPaymentPartnerMethods((ps) => [res.data.record, ...ps])
          onHide()
        }
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: error.message,
        life: 3000,
      })
    }
  }

  const onHide = () => {
    setAddPaymentPartnerMethodDialog(false)
    setPaymentPartnerMethod(emptyPaymentPartnerMethod)
    setNewRecordError(emptyErr)
    setPaymentPartnerMethodQrCodeImage(empty_ImageState)
  }

  const footer = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-secondary"
        />
        <Button
          label="Submit"
          icon="pi pi-save"
          className="p-button-primary"
          onClick={addPaymentPartnerMethod}
          loading={loading}
        />
      </div>
    )
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Add"
        visible={addPaymentPartnerMethodDialog}
        style={{ width: '30vw' }}
        footer={footer}
        onHide={onHide}
      >
        <div className="p-grid p-formgrid p-ai-center p-col-12">
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="paymentPartnerId">Payment Partner</label>
            <Dropdown
              id="paymentPartnerId"
              placeholder="Select Payment Partner"
              optionLabel="name"
              optionValue="id"
              value={paymentPartnerMethod.paymentPartnerId}
              options={paymentPartners}
              onChange={(e) => {
                handleObjChange(setPaymentPartnerMethod, 'paymentPartnerId', e.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="paymentMethodId">Payment Method</label>
            <Dropdown
              id="paymentMethodId"
              placeholder="Select Payment Method"
              value={paymentPartnerMethod.paymentMethodId}
              optionLabel="appName"
              optionValue="id"
              options={paymentMethods}
              onChange={(e) => {
                handleObjChange(setPaymentPartnerMethod, 'paymentMethodId', e.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="users">Users</label>
            <MultiSelect
              id="users"
              placeholder="Select Users"
              optionLabel="username"
              optionValue="id"
              value={paymentPartnerMethod.users}
              options={agents}
              filter={'username,id'}
              onChange={(e) => handleObjChange(setPaymentPartnerMethod, 'users', e.value)}
            />
          </div>
          <div className="p-field p-fluid p-col-12">
            <p className="p-text-capitalize">
              Additional Details{' '}
              <span className="p-text-sm">(Note : this details will show on website)</span>
            </p>
          </div>
        </div>
        <div className="p-grid p-formgrid">
          <div className="p-col-12 p-lg-12">
            <div className="p-fluid p-field p-col-12">
              <label htmlFor="" className="p-col-fixed">
                Qr Code Image
              </label>
              <UploadImage
                imageState={paymentPartnerMethodQrCodeImage}
                setImageState={setPaymentPartnerMethodQrCodeImage}
              />
            </div>
          </div>
          <div className="p-col-12 p-lg-12">
            {paymentPartnerMethod?.additionalData?.map((item, idx) => (
              <div className="p-field p-fluid" key={idx}>
                <div className="p-grid p-formgrid">
                  <div className="p-field p-fluid p-col-6">
                    <label htmlFor="name">Name</label>
                    <InputText
                      id="name"
                      placeholder="Enter entity name"
                      value={item.name}
                      onChange={(e) => {
                        let temp = { ...paymentPartnerMethod }
                        temp.additionalData[idx].name = e.target.value
                        setPaymentPartnerMethod(temp)
                      }}
                    />
                  </div>
                  <div className="p-field p-fluid p-col-6">
                    <label htmlFor="name">Value</label>
                    <InputText
                      id="name"
                      placeholder="Enter entity value"
                      value={item.value}
                      onChange={(e) => {
                        let temp = { ...paymentPartnerMethod }
                        temp.additionalData[idx].value = e.target.value
                        setPaymentPartnerMethod(temp)
                      }}
                    />
                  </div>
                  <div className="p-field p-fluid p-col-12 p-d-flex gap-1 p-jc-between p-ai-end">
                    <Button
                      icon="pi pi-trash"
                      className="p-button-danger flex-1"
                      disabled={paymentPartnerMethod.additionalData.length === 1}
                      onClick={() => {
                        let temp = [...paymentPartnerMethod.additionalData]
                        temp = temp.filter((item, i) => i !== idx)
                        setPaymentPartnerMethod({ ...paymentPartnerMethod, additionalData: temp })
                      }}
                    />
                    <Button
                      icon="pi pi-plus"
                      className="flex-1"
                      onClick={() => {
                        let temp = [...paymentPartnerMethod.additionalData]
                        temp.push({ name: '', value: '' })
                        setPaymentPartnerMethod({ ...paymentPartnerMethod, additionalData: temp })
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="p-field p-fluid p-col-6">
            <label
              className="p-d-none p-d-lg-inline"
              htmlFor="active"
              style={{ color: 'transparent' }}
            >
              Active
            </label>
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="active">
                Active :
              </label>
              <Checkbox
                id="active"
                checked={paymentPartnerMethod.active}
                onChange={(e) => handleObjChange(setPaymentPartnerMethod, 'active', e.checked)}
              />
            </div>
          </div>
        </div>
        {newRecordError.state && (
          <>
            {newRecordError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setNewRecordError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </Dialog>
    </>
  )
}
