// hide details of a string
function hideDetails(string) {
  if (!string) return
  if (string.length > 3) {
    let start = string.slice(0, 3)
    let mid = '****'
    let end = string.slice(-5)
    return start + mid + end
  }
}

export default hideDetails
