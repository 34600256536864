const defaultMenu = [
  {
    label: 'Dashboard',
    icon: 'pi pi-home',
    to: '/',
  },
  {
    label: 'Order Management',
    icon: 'pi pi-inbox',
    items: [
      {
        label: 'All Orders',
        icon: 'pi pi-book',
        to: '/orders',
      },
      {
        label: 'Manual Orders',
        icon: 'pi pi-plus',
        to: '/orders/manual',
      },
      {
        label: 'Bin Orders',
        icon: 'pi pi-trash',
        to: '/orders/bin',
      },
      {
        label: 'Product Price',
        icon: 'pi pi-dollar',
        to: '/orders/website-products/price',
      },
    ],
  },
  {
    label: 'Issue Management',
    icon: 'pi pi-list',
    items: [
      {
        label: 'Shipment Delays',
        icon: 'pi pi-search-plus',
        to: '/orders/followup',
      },
      {
        label: 'Complaints & Cases',
        icon: 'pi pi-comments',
        to: '/issues/cac',
      },
      {
        label: 'Dispute & Chargeback',
        icon: 'pi pi-flag',
        to: '/issues/dac',
      },
      // Keeping the wrong one
      {
        label: 'Undelivered Products',
        icon: 'pi pi-exclamation-triangle',
        to: '/issues/products/undelivered',
      },
      {
        label: 'Tickets',
        icon: 'pi pi-ticket',
        to: '/ticket-management',
      },
    ],
  },

  {
    label: 'Users',
    icon: 'pi pi-id-card',
    items: [
      { label: 'My Activities', icon: 'pi pi-user', to: '/myactivities' },
      {
        label: 'Affiliate',
        icon: 'pi pi-link',
        to: '/customer/affiliate',
      },
    ],
  },
  {
    label: 'Target Management',
    icon: 'pi pi-calendar-times',
    to: '/target-management-admin',
  },
  {
    label: 'Enquiry Management',
    icon: 'pi pi-calendar-times',
    to: '/target-management-admin',
  },
  {
    label: 'Client Management',
    icon: 'pi pi-users',
    items: [
      {
        label: 'Blacklist',
        icon: 'pi pi-ban',
        to: '/customer/blacklist',
      },
    ],
  },
]

export default defaultMenu
