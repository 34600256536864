import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { SelectButton } from 'primereact/selectbutton'
import React, { useEffect, useState } from 'react'

import useQuery from '../../../hooks/useQuery'
import { useGlobalContext } from '../../../context'

import { InputSwitch } from 'primereact/inputswitch'
import { getUser, masterOperaions } from '../../../api'
import ConfirmDialogWithPassword from '../Dialog/ConfirmDialogWithPassword'
import { useHistory } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { InputText } from 'primereact/inputtext'
const successfullMsg = 'Operation Successfull'

export default function UserDialog({ pathname, roleOptions, setRecords, records }) {
  const { user, toast, orderStatus } = useGlobalContext()
  const history = useHistory()
  const query = useQuery()
  const id = query.get('id')

  const defaultUser = {
    visible: false,
    id: null,
    username: '',
    email: '',
    role: '',
    loading: false,
    operation: '',
    accessToOrderStatus: [],
    aliasName: '',
  }
  const [record, setRecord] = useState({ ...defaultUser })
  useEffect(() => {
    if (!id) return
    const fetchUser = async () => {
      const res = await getUser(id)
      if (res) {
        if (res.status === 200) {
          setDialogVisible(true)
          setRecord((ps) => ({
            ...ps,
            ...res.data,
            visible: true,
          }))
        }
      }
    }
    fetchUser()
  }, [id])

  const [dialogVisible, setDialogVisible] = useState(false)
  const [password, setPassword] = useState('')

  const hideDialog = async () => {
    const res = await getUser(record.id)
    if (res) {
      if (res.status === 200) {
        setRecord((ps) => ({
          ...ps,
          visible: false,
          ...res.data,
        }))
      } else {
        setRecord((ps) => ({
          ...ps,
          visible: false,
        }))
      }
    }
    setPassword('')
  }

  const hideRecordDialog = () => {
    setRecords((ps) =>
      ps.map((i) => {
        if (i.id === record.id) {
          i = record
        }
        return i
      })
    )
    setRecord({ ...defaultUser })
    setDialogVisible(false)
    history.push({
      pathname: pathname,
    })
  }

  const handelBlockUser = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'block', password)
    setRecord({ ...record, loading: false })

    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${record.username} blocked`,
      })
      hideDialog()
    }
  }

  const handelUnblockUser = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'unblock', password)
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${record.username} unblocked`,
      })
      hideDialog()
    }
  }

  const handelDeleteUser = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'delete', password)
    if (res) {
      setRecord({ ...record, loading: false })
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${record.username} account deleted`,
        })
        setRecords(records.filter((item) => item.id !== record.id))
        setRecord({ ...defaultUser })
        setDialogVisible(false)
      }
    }
  }

  const handelRoleChangeUser = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'role-change', password, {
      newRole: record.newRole,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelUserAllowToCopyContacts = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'allow-to-copy-contacts', password, {
      agents: record.agents,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }
  const handelUserDenyToCopyContacts = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'deny-to-copy-contacts', password, {
      agents: record.agents,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelUnblockIpChangeUser = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'unblock-ip', password, {
      newRole: record.newRole,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelBlockIpChangeUser = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'block-ip', password, {
      newRole: record.newRole,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelLogoutUser = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'logout', password)
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${record.username} logged out`,
      })
      hideDialog()
    }
  }

  const handelManagersAssignedUpdateChange = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'update-managers', password, {
      managers: record.managers,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelAgentsAssignedUpdateChange = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'update-agents', password, {
      agents: record.agents,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelUserAllowAllOrdersAccessChange = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'allow-all-order-access', password, {
      agents: record.agents,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelUserDenyAllOrdersAccessChange = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'deny-all-order-access', password, {
      agents: record.agents,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelUserAllowAllUnassignedOrdersAccessChange = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'allow-all-unassigned-order-access', password, {
      agents: record.agents,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelUserDenyAllUnassignedOrdersAccessChange = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'deny-all-unassigned-order-access', password, {
      agents: record.agents,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handleUserAccessToOrderStatusChange = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'order-status-access', password, {
      accessToOrderStatus: record.accessToOrderStatus,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handelUserCRMAccessChange = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'crm-access', password, {
      crmType: record.crmType,
      field: record.field,
      value: record.value,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }

  const handleAllowBinOrderAccess = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'allow-bin-order-access', password, {
      agents: record.agents,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }
  const handleDenyBinOrderAccess = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'deny-bin-order-access', password, {
      agents: record.agents,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }
  const handleAliasName = async () => {
    setRecord({ ...record, loading: true })
    const res = await masterOperaions(record.id, 'alias-name', password, {
      aliasName: record.aliasName,
    })
    setRecord({ ...record, loading: false })
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: res.data.message || successfullMsg,
      })
      hideDialog()
    }
  }
  const clickToCopyBody = (rowData) => {
    return (
      <InputSwitch
        checked={!!rowData.accessToCopy}
        onChange={(e) => {
          setRecord({
            ...record,
            visible: true,
            operation: e.value ? 'allow-to-copy-contacts' : 'deny-to-copy-contacts',
          })
        }}
      />
    )
  }

  const statusBody = (rowData) => {
    return (
      <InputSwitch
        checked={!!rowData.status}
        onChange={(e) => {
          setRecord({
            ...record,
            visible: true,
            operation: e.value ? 'logout' : 'login',
          })
        }}
      />
    )
  }

  const ipRestrictionBody = (rowData) => {
    return (
      <>
        <InputSwitch
          checked={!!rowData.ipRestriction}
          onChange={(e) => {
            setRecord({
              ...record,
              visible: true,
              operation: e.value ? 'block-ip' : 'unblock-ip',
            })
          }}
        />
      </>
    )
  }

  const blockBody = (rowData) => {
    return (
      <InputSwitch
        checked={!!rowData.block}
        onChange={(e) => {
          setRecord({
            ...record,
            visible: true,
            operation: e.value ? 'block' : 'unblock',
          })
        }}
      />
    )
  }
  const allOrdersAccessBody = (rowData) => {
    return (
      <InputSwitch
        checked={!!rowData.allOrderAccess}
        onChange={(e) => {
          setRecord({
            ...record,
            visible: true,
            operation: e.value ? 'allow-all-order-access' : 'deny-all-order-access',
          })
        }}
      />
    )
  }
  const allUnassignedOrdersAccessBody = (rowData) => {
    return (
      <InputSwitch
        checked={!!rowData.allUnassignedOrderAccess}
        onChange={(e) => {
          setRecord({
            ...record,
            visible: true,
            operation: e.value
              ? 'allow-all-unassigned-order-access'
              : 'deny-all-unassigned-order-access',
          })
        }}
      />
    )
  }
  const orderStatusAccessBody = (rowData) => {
    return (
      <div className="p-d-flex p-flex-wrap gap-1by2">
        <MultiSelect
          options={orderStatus}
          tooltip="No selection will allow access to all order status"
          optionLabel="name"
          optionValue="value"
          className="min-w-10"
          placeholder="Select Order Status"
          value={rowData.accessToOrderStatus ?? []}
          onChange={(e) => {
            setRecord({
              ...record,
              accessToOrderStatus: e.value,
            })
          }}
          maxSelectedLabels={1}
        />
        <Button
          // label="Save"
          icon="pi pi-save"
          onClick={() => setRecord({ ...record, visible: true, operation: 'order-status-access' })}
        />
      </div>
    )
  }
  const aliasNameBody = (rowData) => {
    return (
      <div className="p-d-flex p-flex-wrap gap-1by2">
        <InputText
          className="min-w-10"
          placeholder="Add alias name"
          value={rowData.aliasName ?? []}
          onChange={(e) => {
            setRecord({
              ...record,
              aliasName: e.target.value,
            })
          }}
        />
        <Button
          // label="Save"
          icon="pi pi-save"
          onClick={() => setRecord({ ...record, visible: true, operation: 'alias-name' })}
        />
      </div>
    )
  }
  const deleteBody = (rowData) => {
    return (
      // ['superadmin', 'admin'].includes(rowData.role) && (
      <Button
        onClick={() => {
          setRecord({
            ...record,
            visible: true,
            operation: 'delete',
          })
        }}
        label="Delete"
        icon="pi pi-times-circle"
        className=" p-button-danger "
      />
      // )
    )
  }
  const binOrderAccess = (rowData) => {
    return (
      <InputSwitch
        checked={!!rowData.binOrderAccess}
        onChange={(e) => {
          setRecord({
            ...record,
            visible: true,
            operation: e.value ? 'allow-bin-order-access' : 'deny-bin-order-access',
          })
        }}
      />
    )
  }

  const roleChangeBody = (rowData) => {
    return (
      <SelectButton
        value={rowData.role}
        options={roleOptions}
        optionLabel="name"
        optionValue="value"
        onChange={(e) => {
          setRecord({
            ...record,
            username: rowData.username,
            email: rowData.email,
            id: rowData.id,
            role: rowData.role,
            newRole: e.value,
            visible: true,
            operation: 'role-change',
          })
        }}
      />
    )
  }
  return (
    <div>
      <Dialog
        className="w-full"
        onHide={hideRecordDialog}
        style={{ maxWidth: '600px' }}
        visible={dialogVisible}
        header={`User #${record.id}`}
        footer={() => (
          <div>
            <Button
              label="Close"
              icon="pi pi-times"
              className="p-button-text"
              onClick={hideRecordDialog}
            />
          </div>
        )}
      >
        <div className="p-grid">
          <div className="p-col-4 p-d-flex p-ai-center">Name</div>
          <div className="p-col-8">{record?.username}</div>{' '}
          <div className="p-col-4 p-d-flex p-ai-center">Email</div>
          <div className="p-col-8">{record?.email}</div>{' '}
          <div className="p-col-4 p-d-flex p-ai-center">Role</div>
          <div className="p-col-8">{record?.role}</div>
          {['superadmin'].includes(user.role) && (
            <>
              <div className="p-col-4 p-d-flex p-ai-center">Allow Access To Copy Contacts</div>
              <div className="p-col-8">{clickToCopyBody(record)}</div>
              <div className="p-col-4 p-d-flex p-ai-center">IP Restriction</div>
              <div className="p-col-8">{ipRestrictionBody(record)}</div>
              <div className="p-col-4 p-d-flex p-ai-center">All Orders Access</div>
              <div className="p-col-8">{allOrdersAccessBody(record)}</div>
              <div className="p-col-4 p-d-flex p-ai-center">All Unassigned Orders Access</div>
              <div className="p-col-8">{allUnassignedOrdersAccessBody(record)}</div>
              <div className="p-col-4 p-d-flex p-ai-center">Order Status Access</div>
              <div className="p-col-8">{orderStatusAccessBody(record)}</div>
              <div className="p-col-4 p-d-flex p-ai-center">Block Status</div>
              <div className="p-col-8">{blockBody(record)}</div>
            </>
          )}
          {['superadmin', 'admin', 'manager'].includes(user.role) && (
            <>
              <div className="p-col-4 p-d-flex p-ai-center">Logged In</div>
              <div className="p-col-8">{statusBody(record)}</div>
              <div className="p-col-4 p-d-flex p-ai-center">Role</div>
              <div className="p-col-8">{roleChangeBody(record)}</div>
              <div className="p-col-4 p-d-flex p-ai-center">Bin Order Access</div>
              <div className="p-col-8">{binOrderAccess(record)}</div>
              <div className="p-col-4 p-d-flex p-ai-center">Alias Name</div>
              <div className="p-col-8">{aliasNameBody(record)}</div>
            </>
          )}
          {user.role === 'superadmin' && (
            <>
              <div className="p-col-4 p-d-flex p-ai-center">Delete</div>
              <div className="p-col-8">{deleteBody(user)}</div>
            </>
          )}
        </div>
      </Dialog>
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelBlockUser}
        visible={record.visible && record.operation === 'block'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Block ${record.username}`}
        mainText={`Are you sure you want to block ${record.username}?`}
        loadingText="Blocking..."
        yesText="Block"
      />

      {/* Click To Copy  */}
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelUserAllowToCopyContacts}
        visible={record.visible && record.operation === 'allow-to-copy-contacts'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update user copy access`}
        mainText={`Are you sure you want to allow ${record.username} access to copy contacts?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelUserDenyToCopyContacts}
        visible={record.visible && record.operation === 'deny-to-copy-contacts'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update user copy access`}
        mainText={`Are you sure you want to deny ${record.username} access to copy contacts?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      {/* Click To Copy  */}

      {/* unblock */}
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelUnblockUser}
        visible={record.visible && record.operation === 'unblock'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Unlock ${record.username}`}
        mainText={`Are you sure you want to unblock ${record.username}?`}
        loadingText="Unblocking..."
        yesText="Unblock"
      />

      {/* delete */}
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelDeleteUser}
        visible={record.visible && record.operation === 'delete'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Delete ${record.username} Account`}
        mainText={`Are you sure you want to delete ${record.username} account there no coming back from this step?`}
        yesText="Delete"
      />

      {/* logout */}
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelLogoutUser}
        visible={record.visible && record.operation === 'logout'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Logout ${record.username}`}
        mainText={`Are you sure you want to logout ${record.username}?`}
        loadingText="Logging out..."
        yesText="Logout"
      />

      {/* role-change */}
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelRoleChangeUser}
        visible={record.visible && record.operation === 'role-change'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Change ${record.username} role to ${record.newRole}`}
        mainText={`Are you sure you want to change ${record.username} role from ${record.role} to ${record.newRole}?`}
        loadingText="Changing..."
        yesText="Change"
      />
      {/* ip restrcition */}
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelUnblockIpChangeUser}
        visible={record.visible && record.operation === 'unblock-ip'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Unrestrict ip ${record.username}`}
        mainText={`Are you sure you want to unrestrict ip of ${record.username}?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelBlockIpChangeUser}
        visible={record.visible && record.operation === 'block-ip'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Restrict ip ${record.username}`}
        mainText={`Are you sure you want to restrict ip of ${record.username}?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelManagersAssignedUpdateChange}
        visible={record.visible && record.operation === 'update-managers'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update managers`}
        mainText={`Are you sure you want to update managers of ${record.username}?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelAgentsAssignedUpdateChange}
        visible={record.visible && record.operation === 'update-agents'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update agents`}
        mainText={`Are you sure you want to update agents of ${record.username}?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelUserAllowAllOrdersAccessChange}
        visible={record.visible && record.operation === 'allow-all-order-access'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update user all order access`}
        mainText={`Are you sure you want to allow ${record.username} access to all orders?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelUserDenyAllOrdersAccessChange}
        visible={record.visible && record.operation === 'deny-all-order-access'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update user all order access`}
        mainText={`Are you sure you want to deny ${record.username} access to all orders?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelUserAllowAllUnassignedOrdersAccessChange}
        visible={record.visible && record.operation === 'allow-all-unassigned-order-access'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update user all unassigned order access`}
        mainText={`Are you sure you want to allow ${record.username} access to all unassigned orders?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelUserDenyAllUnassignedOrdersAccessChange}
        visible={record.visible && record.operation === 'deny-all-unassigned-order-access'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update user all unassigned order access`}
        mainText={`Are you sure you want to deny ${record.username} access to all unassigned orders?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handleUserAccessToOrderStatusChange}
        visible={record.visible && record.operation === 'order-status-access'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update user order status access`}
        mainText={`Are you sure you want to allow ${record.username} access to ${
          record?.accessToOrderStatus?.length ? record?.accessToOrderStatus?.join(', ') : 'All'
        } order status?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handelUserCRMAccessChange}
        visible={record.visible && record.operation === 'crm-access'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Update user CRM access`}
        mainText={`Are you sure you want to ${record.value ? 'allow' : 'deny'} ${
          record.username
        } access to ${record.crmType} CRM?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handleAllowBinOrderAccess}
        visible={record.visible && record.operation === 'allow-bin-order-access'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Allow bin order access ${record.username}`}
        mainText={`Are you sure you want to allow bin order access to ${record.username}?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handleDenyBinOrderAccess}
        visible={record.visible && record.operation === 'deny-bin-order-access'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Deny bin order access ${record.username}`}
        mainText={`Are you sure you want to deny bin order access to ${record.username}?`}
        loadingText="Applying..."
        yesText="Apply"
      />
      <ConfirmDialogWithPassword
        onHide={hideDialog}
        onSubmit={handleAliasName}
        visible={record.visible && record.operation === 'alias-name'}
        loading={record.loading}
        password={password}
        setPassword={setPassword}
        headerText={`Change alias name ${record.username}`}
        mainText={`Are you sure you want to change alias name of ${record.username} to ${record.aliasName}?`}
        loadingText="Applying..."
        yesText="Apply"
      />
    </div>
  )
}
