import React, { useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { useGlobalContext } from '../../../context'
import classNames from 'classnames'
import { RadioButton } from 'primereact/radiobutton'
import { patchOrderCompleted } from '../../../api'
import { Toast } from 'primereact/toast'
const allowedProductStatus = ['Delivered', 'Undelivered', 'Cancelled']
const status = [
  'Pending',
  'Processed',
  'Shipped',
  'Tracking',
  'Tracking-Live',
  'Delivered',
  'Undelivered',
  'Cancelled',
  'Reshipment-Initiated',
  'Reshipment-Completed',
  'Refunded',
]
function ChangeOrderStatus({
  orderData,
  setOrderData,
  orderStatusDialogVisible,
  setOrderStatusDialogVisible,
  orders,
  setOrders,
}) {
  const { user } = useGlobalContext()
  const toast = useRef(null)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const orderStatusBodyTemplate = (rowData) => {
    return (
      <Dropdown
        disabled={user?.role === 'agent'}
        options={status}
        style={{ border: 'none', width: '100%' }}
        value={rowData?.status}
        itemTemplate={(option) => (
          <span
            className={classNames({
              'opacity-50': !allowedProductStatus.includes(option),
            })}
          >
            {option}
          </span>
        )}
        onChange={(e) => {
          const val = e.target.value
          setBtnDisabled(false)
          if (user?.role === 'agent') return
          if (!allowedProductStatus.includes(val)) return
          const _orderProductData = orderData?.orderProductDetails
          const _index = _orderProductData.findIndex((item) => item.id === rowData.id)
          _orderProductData[_index].status = val
          setOrderData((prev) => ({ ...prev, orderProductDetails: _orderProductData }))
        }}
      />
    )
  }

  const handleOrderProductStatusChange = async () => {
    setLoading(true)
    const allDelivered = orderData?.orderProductDetails.every((item) => item.status === 'Delivered')

    const allCancelled = orderData?.orderProductDetails.every((item) => item.status === 'Cancelled')

    const orderProductData = orderData?.orderProductDetails.map((item) => {
      return {
        id: item.id,
        status: item.status,
        productName: item.productName,
      }
    })

    const res = await patchOrderCompleted({
      orderId: orderData?.orderid,
      orderProductData,
      allDelivered,
      allCancelled,
    })

    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Order status updated successfully',
          life: 5000,
        })
        const { newRecord } = res.data
        const updatedOrders = orders.map((item) => {
          if (item.orderid === newRecord.orderid) return newRecord
          return item
        })
        setOrders(updatedOrders)
        hideDialog()
      }
    }
    setLoading(false)
  }

  const hideDialog = () => {
    setOrderStatusDialogVisible(false)
    setOrderData(null)
    setLoading(false)
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={orderData?.orderid}
        visible={orderStatusDialogVisible}
        style={{ width: '50vw' }}
        footer={() => (
          <>
            <Button
              label="Close"
              icon="pi pi-times"
              disabled={loading}
              className="p-button-danger"
              onClick={hideDialog}
            />
            <Button
              label="Save"
              loading={loading}
              disabled={btnDisabled}
              icon="pi pi-check"
              onClick={() => {
                const status = orderData?.orderProductDetails.every((item) =>
                  allowedProductStatus.includes(item.status)
                )
                if (!status) {
                  toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail:
                      'Products can only be in the Delivered, Undelivered, or Cancelled state ',
                    life: 5000,
                  })
                }
                if (status) handleOrderProductStatusChange()
              }}
            />
          </>
        )}
        onHide={hideDialog}
      >
        <DataTable
          value={orderData?.orderProductDetails}
          editMode="row"
          dataKey="id"
          showGridlines
          breakpoint="1000px"
          responsiveLayout="stack"
          footer={
            <div className="w-full p-d-flex  gap-2 p-jc-end">
              <div className="field-radiobutton p-d-flex gap-1by2">
                <RadioButton
                  inputId="allDelivered"
                  name="status"
                  value="Delivered"
                  onChange={() => {
                    setBtnDisabled(false)
                    let _orderProductData = orderData?.orderProductDetails
                    _orderProductData = _orderProductData.map((item) => {
                      item.status = 'Delivered'
                      return item
                    })
                    setOrderData((prev) => ({ ...prev, orderProductDetails: _orderProductData }))
                  }}
                  checked={orderData?.orderProductDetails?.every(
                    (item) => item.status === 'Delivered'
                  )}
                />
                <label htmlFor="allDelivered">Mark All Delivered</label>
              </div>
              <div className="field-radiobutton p-d-flex gap-1by2">
                <RadioButton
                  inputId="allCancelled"
                  name="status"
                  value="Cancelled"
                  onChange={() => {
                    setBtnDisabled(false)
                    let _orderProductData = orderData?.orderProductDetails
                    _orderProductData = _orderProductData.map((item) => {
                      item.status = 'Cancelled'
                      return item
                    })
                    setOrderData((prev) => ({ ...prev, orderProductDetails: _orderProductData }))
                  }}
                  checked={orderData?.orderProductDetails?.every(
                    (item) => item.status === 'Cancelled'
                  )}
                />
                <label htmlFor="allCancelled">Mark All Cancelled</label>
              </div>
            </div>
          }
        >
          <Column header="ID" field="id" />
          <Column header="Product Name" field="productName" />
          <Column header="Strength" field="strength" />
          <Column header="No. Pills" field="quantity" />
          <Column header="Pill Price" field="quantityPrice" body={(rd) => `$${rd.quantityPrice}`} />
          <Column header="No. Qty" field="quantity" />
          <Column header="Total Price" field="totalPrice" body={(rd) => `$${rd.totalPrice}`} />
          <Column header="Status" body={orderStatusBodyTemplate} />
          <Column header="SC" field="shippingCompany" />
        </DataTable>
      </Dialog>
    </>
  )
}

export default ChangeOrderStatus
