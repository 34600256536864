import { format } from 'date-fns'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import React, { useEffect, useRef, useState } from 'react'
import { updateOrderHistoryReason } from '../../api'
import { useGlobalContext } from '../../context'
import { FilterMatchMode } from 'primereact/api'
import { assignDates, dateFilters, dtFilenameDate } from '../../functions/myDateFns'
import MyDatesPicker from '../mini/MyDatesPicker'
import ExportButton from '../mini/ExportButton'
// ActivitiesDataTable Component to display all activities in a table
export default function ActivitiesDataTable({
  activities,
  setActivities,
  getActivities,
  agentNames,
  agents,
  pageType,
  dateFilter,
  setDateFilter,
  loading,
  setLoading,
}) {
  /**
   * @param {Array} activities - Array of activities
   * @param {Function} setActivities - Function to set activities
   * @param {Function} getActivities - Function to fetch activities
   * @param {Array} agentNames - Array of agent names
   * @param {Array} agents - Array of agents
   * @param {String} pageType - Type of page
   * @param {Function} setLimit - Function to set limit
   * @param {Number} limit - Limit of activities to fetch
   * @param {Boolean} loading - Loading state
   * @param {Function} setLoading - Function to set loading state
   * @returns {JSX.Element} - ActivitiesDataTable Component
   * @description - Component to display all activities in a table
   */

  const { getActionBtn, user } = useGlobalContext()

  const orderfilter = ['25', '100', '500', '2000', '5000', '10000', 'All']

  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    masterid: { value: null, matchMode: FilterMatchMode.EQUALS },
    masterRole: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [dateCode, setDateCode] = useState(dateFilters[1].code)
  const [dateRange, setDateRange] = useState(null)
  const [selectedOrderFilter, setSelectedOrderFilter] = useState(orderfilter[0])
  const [selectedProducts, setSelectedProducts] = useState(null)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [otpDialog, setOtpDialog] = useState(false)
  const [reasonEditId, setReasonEditId] = useState(null)

  /* MINE */
  const [filters, setFilters] = useState(emptyFilterObject)
  const [filterAgentName, setFilterAgentName] = useState(null)

  const toast = useRef(null)
  const dt = useRef(null)

  const verifyotp = () => {
    toast.current.show({
      severity: 'success',
      summary: 'OTP Successfully Verified !',
      detail: '',
      life: 3000,
    })
    setOtpDialog(false)
  }
  const clearFilter = () => {
    setLoading(false)
    setFilters(emptyFilterObject)
    setDateFilter(assignDates(dateFilters[1].code))
    filterAgentName(null)
    setSelectedOrderFilter(orderfilter[0])
    setGlobalFilterValue('')
    setFilterAgentName(null)
    dt.current.filter(null)
  }

  const header = () => {
    return (
      <div
        className="p-my-2 p-d-flex p-ai-center p-jc-between p-jc-space-between"
        style={{ textAlign: 'center' }}
      >
        <h5 className="p-m-0 p-text-bold ">Activities: {activities?.length || 0}</h5>
        <div className="p-d-flex p-ai-center">
          {agents && agents.length > 0 && pageType === 'AllActivities' && (
            <>
              <span className="p-text-bold p-mx-2">Sort By Agent</span>
              <Dropdown
                options={agentNames}
                value={filterAgentName}
                placeholder="Select Agent"
                onChange={(e) => {
                  let obj = {}
                  if (e.value === 'All') {
                    obj.username = null
                    obj.id = null
                  } else {
                    obj = agents.find((item) => item.username === e.value)
                  }
                  if (!obj) return
                  setFilterAgentName(obj.username)
                  onChangeFilter(obj.id, 'masterid')
                }}
                className="p-mr-2"
              ></Dropdown>
            </>
          )}
          {agents && agents.length > 0 && pageType === 'AllActivities' && (
            <>
              <span className="p-text-bold p-mx-2">Sort By Role</span>
              <Dropdown
                options={['All', 'admin', 'agent', 'customer']}
                value={filterAgentName}
                placeholder="Select Agent"
                onChange={(e) => {
                  let val = ''
                  if (e.value === 'All') {
                    val = null
                  } else {
                    val = e.value
                  }
                  setFilterAgentName(val)
                  onChangeFilter(val, 'masterRole')
                }}
                className="p-mr-2"
              ></Dropdown>
            </>
          )}

          {selectedOrderFilter.name !== 'Custom' && (
            <>
              <div className="p-d-flex gap-1by2 p-ai-center">
                <span className="p-text-bold p-mr-2">Date</span>

                <MyDatesPicker
                  code={dateCode}
                  setCode={setDateCode}
                  rangeDates={dateRange}
                  setRangeDates={setDateRange}
                  filterDates={dateFilter}
                  setFilterDates={setDateFilter}
                />
              </div>
            </>
          )}

          <span className="p-input-icon-left p-ml-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilterValue}
              onChange={(e) => {
                setGlobalFilterValue(e.target.value)
                onChangeFilter(e.target.value, 'global')
              }}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
    )
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="p-mr-2 p-mb-0">
          {pageType === 'MyActivities' && 'My Activities'}
          {pageType === 'AllActivities' && 'All Activities'}
        </h5>
        {/* <span><Button icon="pi pi-folder" className="p-button-rounded p-button-danger" onClick={() => moveToBin()} tooltip="Open Bin Table" tooltipOptions={{ position: 'bottom' }} /></span> */}
      </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-jc-end gap-1">
        <Button
          label="Refresh"
          icon="pi pi-refresh"
          className="p-button-icon"
          onClick={getActivities}
        />

        <ExportButton datatable_ref={dt} />
      </div>
    )
  }

  const dialogFuncMap = {
    otpDialog: setOtpDialog,
  }

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false)
  }
  const renderFooter = (name) => {
    return (
      <div>
        {/* <Button label="Submit" icon="pi pi-check" onClick={() => onHide(name)} autoFocus /> */}
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
      </div>
    )
  }

  const onChangeFilter = (value, target) => {
    let _filters = { ...filters }
    _filters[target].value = value
    setFilters(_filters)
  }

  const actionTypeBody = (rowData) => {
    return <div className="p-d-flex p-jc-center">{getActionBtn(rowData.actionType)}</div>
  }

  const actionBody = (rowData) => {
    return <div className="p-text-capitalize">{rowData?.action || ''}</div>
  }

  const actionDateBody = (rowData) => {
    return <div className="p-text-capitalize">{format(new Date(rowData?.timestamp), 'PPp')}</div>
  }

  const reasonBody = (rowData, i) => {
    return (
      <div className="p-text-capitalize p-d-flex p-jc-between p-ai-center">
        {reasonEditId === rowData.id ? (
          <InputTextarea
            style={{
              flex: '1',
            }}
            className="p-mr-2"
            value={rowData?.reason}
            onChange={(e) => {
              let temp = [...activities]
              temp[i.rowIndex].reason = e.target.value
              setActivities(temp)
            }}
          />
        ) : (
          <span
            style={{
              flex: '1',
              width: '10rem',
            }}
            className="p-mr-4 p-text-truncate"
          >
            {rowData?.reason || 'Not Provided'}
          </span>
        )}

        {reasonEditId === rowData.id && (
          <div className="p-d-flex p-flex-column">
            <Button
              className="p-mb-2 p-button-success"
              icon="pi pi-save"
              onClick={async () => {
                setReasonEditId(null)
                let temp = [...activities]
                temp[i.rowIndex].reasonUpdatedAt = new Date()
                setActivities(temp)
                await updateOrderHistoryReason(rowData.id, rowData.reason)
              }}
            />
            <Button
              className="p-button-danger"
              icon="pi pi-times"
              onClick={() => setReasonEditId(null)}
              autoFocus
            />
          </div>
        )}
        {rowData.masterid === user.id && reasonEditId === null && (
          <Button icon="pi pi-pencil" onClick={() => setReasonEditId(rowData?.id)} />
        )}
      </div>
    )
  }

  const reasonDateBody = (rowData) => {
    return (
      <div className="p-text-capitalize">
        {rowData.reasonUpdatedAt ? format(new Date(rowData?.reasonUpdatedAt), 'PPp') : 'NaN'}
      </div>
    )
  }

  const orderBody = (rowData) => {
    return <div className="">{rowData?.orderid || 'NaN'}</div>
  }

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card">
          <Toast ref={toast} position="bottom-center" />
          <Toolbar
            className="p-toolbar"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          <DataTable
            exportFilename={`Activities_${dtFilenameDate}`}
            ref={dt}
            value={activities}
            showGridlines
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            filters={filters}
            dataKey="id"
            paginator
            rows={10}
            header={header}
            rowsPerPageOptions={[10, 25, 50, 100]}
            className="datatable-responsive"
            columnResizeMode="fit"
            responsiveLayout="scroll"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} activities"
            emptyMessage="No activities found."
            loading={loading}
            removableSort
          >
            <Column field="id" header="ID" style={{ width: '0%' }} />
            <Column
              field="actionType"
              header="Type"
              body={actionTypeBody}
              style={{ width: '3%' }}
            />
            <Column field="type" header="Target" sortable style={{ width: '5%' }} />
            <Column
              field="orderid"
              header="Target ID"
              body={orderBody}
              sortable
              style={{ width: '5%' }}
            />

            <Column
              field="action"
              header="Action"
              body={actionBody}
              sortable
              style={{ width: '20%' }}
            />
            <Column
              field="timestamp"
              header="Date"
              body={actionDateBody}
              sortable
              style={{ width: '10%' }}
            />
            <Column
              hidden={pageType !== 'AllActivities'}
              field="master"
              header="Master"
              sortable
              style={{ width: '5%' }}
            />
            <Column
              hidden={pageType !== 'AllActivities'}
              field="masterRole"
              header="Role"
              sortable
              style={{ width: '5%' }}
            />
            <Column
              field="reason"
              header="Reason"
              body={reasonBody}
              sortable
              style={{ width: '20%' }}
            />
            <Column
              field="reasonUpdateAt"
              header="Reason Date"
              body={reasonDateBody}
              sortable
              style={{ width: '10%' }}
            />
          </DataTable>
        </div>
      </div>
      {/* Message Box Modal */}
      <Dialog
        header="Verify OTP"
        modal
        className="p-fluid"
        visible={otpDialog}
        style={{ width: '650px' }}
        footer={renderFooter('otpDialog')}
        onHide={() => onHide('otpDialog')}
      >
        <div className="login-panel p-fluid">
          <div className="outter_box p-d-flex p-jc-center">
            <div className="inner_box">
              <input type="number" maxLength="1" className="box" />
              <input type="number" maxLength="1" className="box" />
              <input type="number" maxLength="1" className="box" />
              <input type="number" maxLength="1" className="box" />
            </div>
          </div>
          <div className="p-text-center">
            <Button
              label="Submit"
              className="p-button-primary"
              style={{ width: '8rem' }}
              onClick={() => verifyotp()}
            />
          </div>
        </div>
      </Dialog>
    </div>
  )
}
