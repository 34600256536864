import React, { useCallback, useEffect, useRef, useState } from 'react'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { Toast } from 'primereact/toast'
import { TabPanel, TabView } from 'primereact/tabview'
import PaymentMethodsTable from '../../components/mycomponents/PaymentManagement/PaymentMethod/PaymentMethodsTable'
import PaymentPartnersTable from '../../components/mycomponents/PaymentManagement/PaymentPartner/PaymentPartnersTable'
import PaymentPartnerMethodsTable from '../../components/mycomponents/PaymentManagement/PaymentPartnerMethod/PaymentPartnerMethodsTable'
import { getPaymentMethods, getPaymentPartnerMethods, getPaymentPartners } from '../../api'
import { useGlobalContext } from '../../context'

export default function PaymentPartnerMethodsPage() {
  const [activeIndex, setActiveIndex] = useState(1)

  const { toast } = useGlobalContext()
  // payment methods states
  const [pmDtLoading, setPmDtLoading] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  //payment partner states
  const [paymentPartnerTableLoading, setPaymentPartnerTableLoading] = useState(false)
  const [paymentPartners, setPaymentPartners] = useState([])

  // payment partner methods states
  // const [paymentPartnerMethods, setPaymentPartnerMethods] = useState([])
  const [pPMDtLoading, setPPMethodsDtLoading] = useState(false)
  const [paymentPartnerMethods, setPaymentPartnerMethods] = useState([])

  // fetch all payment partners
  const fetchPaymentPartners = useCallback(async () => {
    setPaymentPartnerTableLoading(true)
    const res = await getPaymentPartners()
    if (res) {
      setPaymentPartnerTableLoading(false)
      if (res.status === 200) {
        setPaymentPartners(res.data)
      }
    }
  }, [])

  const fetchPaymentPartnerMethods = useCallback(async () => {
    setPPMethodsDtLoading(true)
    const res = await getPaymentPartnerMethods()
    if (res) {
      setPPMethodsDtLoading(false)
      if (res.status === 200) {
        setPaymentPartnerMethods(res.data)
      }
    }
  }, [])

  useEffect(() => {
    const fetchPaymentRelatedFns = async () => {
      Promise.all([fetchPaymentPartners(), fetchPaymentPartnerMethods()])
    }
    fetchPaymentRelatedFns()
  }, [])
  // fetch all payment methods
  const fetchPaymentMethods = useCallback(async () => {
    setPmDtLoading(true)
    const res = await getPaymentMethods()
    if (res) {
      setPmDtLoading(false)
      if (res.status === 200) {
        setPaymentMethods(res.data)
      }
    }
  }, [])
  useEffect(() => fetchPaymentMethods(), [fetchPaymentMethods])

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin']}>
      <Toast ref={toast} />
      <div className="card">
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          {/* PAYMENT METHODS  */}
          <TabPanel header="Payment Methods">
            <PaymentMethodsTable
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
              fetchPaymentMethods={fetchPaymentMethods}
              pmDtLoading={pmDtLoading}
              setPmDtLoading={setPmDtLoading}
            />
          </TabPanel>
          <TabPanel header="Payment Partners">
            <PaymentPartnersTable
              paymentPartners={paymentPartners}
              setActiveIndex={setActiveIndex}
              setPaymentPartners={setPaymentPartners}
              fetchPaymentPartners={fetchPaymentPartners}
              paymentPartnerTableLoading={paymentPartnerTableLoading}
              setPaymentPartnerTableLoading={setPaymentPartnerTableLoading}
            />
          </TabPanel>
          {/* *** PAYMENT PARTNER METHODS *** */}
          <TabPanel header="Payment Partner Methods">
            <PaymentPartnerMethodsTable
              fetchPaymentPartnerMethods={fetchPaymentPartnerMethods}
              paymentPartners={paymentPartners}
              paymentPartnerMethods={paymentPartnerMethods}
              setPaymentPartnerMethods={setPaymentPartnerMethods}
              setActiveIndex={setActiveIndex}
              paymentPartnerMethodsTableLoading={pPMDtLoading}
              setPaymentPartnerMethodsTableLoading={setPPMethodsDtLoading}
            />
          </TabPanel>
        </TabView>
      </div>
    </PageAllowedToRoles>
  )
}
