import { Dialog } from 'primereact/dialog'
import React, { useCallback, useEffect, useState } from 'react'
import useQuery from '../../../../hooks/useQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getPaymentPartnerMethodQuotaById, putPaymentPartnerMethodQuota } from '../../../../api'
import { vi } from 'date-fns/locale'
import { Button } from 'primereact/button'
import { data } from 'autoprefixer'
import { useGlobalContext } from '../../../../context'
import { InputText } from 'primereact/inputtext'
import { handleObjChange } from '../../../../functions/setter'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { MultiSelect } from 'primereact/multiselect'
import { Checkbox } from 'primereact/checkbox'
import { Message } from 'primereact/message'
import { format } from 'date-fns'
import classNames from 'classnames'
import { InputNumber } from 'primereact/inputnumber'

const emptyErr = {
  state: false,
  errors: [],
}

const emptyPaymentPartnerMethodQuota = {
  paymentPartnerMethodId: '',
  name: '',
  startDate: '',
  endDate: '',
  quotaAmount: 0,
  active: false,
  showOnEcom: false,
  showOnCrm: false,
}

export default function EditPaymentPartnerMethodQuotaDialog({
  setTableLoading,
  fetchPaymentPartnerMethodUsers,
  paymentPartnerMethods,
  setPaymentPartnerMethodQuotas,
}) {
  const query = useQuery()
  const history = useHistory()
  const id = parseInt(query.get('quota_id'))
  const [visibleDialog, setVisibleDialog] = useState(false)
  const [paymentPartnerMethodQuota, setPaymentPartnerMethodQuota] = useState(
    emptyPaymentPartnerMethodQuota
  )
  const { toast, agents } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  // fetch payment partner by id
  const fetchPaymentPartnerMethodQuotaByIdFn = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getPaymentPartnerMethodQuotaById(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        let temp = { ...res.data }
        temp.users = res.data?.users?.map((item) => parseFloat(item))
        setPaymentPartnerMethodQuota(temp)
        setVisibleDialog(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchPaymentPartnerMethodQuotaByIdFn()
  }, [fetchPaymentPartnerMethodQuotaByIdFn])

  const putPaymentPartnerMethodQuotaFn = async () => {
    setLoading(true)
    const res = await putPaymentPartnerMethodQuota(id, paymentPartnerMethodQuota)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setPaymentPartnerMethodQuotas((ps) => {
          const idx = ps.findIndex((p) => p.id === id)
          if (idx !== -1) {
            ps[idx] = res.data.record
          }
          return ps
        })
        onHide()
      }
    }
  }

  const onHide = () => {
    setVisibleDialog(false)
    setPaymentPartnerMethodQuota(emptyPaymentPartnerMethodQuota)
    history.push({ pathname: '/payment/partner-method-quotas' })
  }

  const footer = () => {
    return (
      <div className="p-d-flex p-jc-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => onHide()}
        />
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-primary"
          loading={loading}
          onClick={() => {
            putPaymentPartnerMethodQuotaFn()
          }}
          disabled={loading}
        />
      </div>
    )
  }

  return (
    <div>
      <Dialog
        header={`Edit Payment Partner Method Quota #${id}`}
        visible={visibleDialog}
        style={{ width: '50vw' }}
        footer={footer}
        onHide={onHide}
      >
        <div className="p-grid p-formgrid p-ai-center p-col-12">
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              placeholder="Enter Name"
              value={paymentPartnerMethodQuota.name}
              onChange={(e) => {
                handleObjChange(setPaymentPartnerMethodQuota, 'name', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="paymentPartnerMethodId">Payment Partner Method</label>
            <Dropdown
              placeholder="Select Payment Partner Method"
              options={paymentPartnerMethods ?? []}
              optionLabel="paymentPartnerMethod"
              optionValue="id"
              value={paymentPartnerMethodQuota?.paymentPartnerMethodId}
              onChange={(e) => {
                const item = paymentPartnerMethods?.find((i) => i.id === e.value)
                if (!item || !item.active) return
                handleObjChange(setPaymentPartnerMethodQuota, 'paymentPartnerMethodId', e.value)
              }}
              itemTemplate={(option) => (
                <span
                  className={classNames({
                    'opacity-50': !option.active,
                  })}
                >
                  {option.paymentPartnerMethod}
                </span>
              )}
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="startDate">Start Date</label>
            <Calendar
              id="startDate"
              placeholder="Select Start Date"
              value={new Date(paymentPartnerMethodQuota.startDate)}
              onChange={(e) => {
                const date = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : ''
                handleObjChange(setPaymentPartnerMethodQuota, 'startDate', date)
              }}
              minDate={new Date()}
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="endDate">End Date</label>
            <Calendar
              id="endDate"
              placeholder="Select End Date"
              value={new Date(paymentPartnerMethodQuota.endDate)}
              onChange={(e) => {
                const date = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : ''
                handleObjChange(setPaymentPartnerMethodQuota, 'endDate', date)
              }}
              minDate={new Date()}
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="quotaAmount">Quota Amount</label>
            <InputNumber
              id="quotaAmount"
              placeholder="Enter Quota Amount"
              value={paymentPartnerMethodQuota.quotaAmount}
              onChange={(e) => {
                handleObjChange(setPaymentPartnerMethodQuota, 'quotaAmount', e.value)
              }}
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          </div>
          <div className="p-field p-fluid p-col-6">
            <label htmlFor="users">Users</label>
            <MultiSelect
              id="users"
              placeholder="Select Users"
              optionLabel="username"
              optionValue="id"
              value={paymentPartnerMethodQuota.users}
              options={agents}
              filter={'username,id'}
              onChange={(e) => handleObjChange(setPaymentPartnerMethodQuota, 'users', e.value)}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-lg-6">
            <label
              className="p-d-none p-d-lg-inline"
              htmlFor="showOnEcom"
              style={{ color: 'transparent' }}
            >
              Show On Website
            </label>
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="showOnEcom">
                Show On Website :
              </label>
              <Checkbox
                id="showOnEcom"
                checked={paymentPartnerMethodQuota.showOnEcom ? true : false}
                onChange={(e) =>
                  handleObjChange(setPaymentPartnerMethodQuota, 'showOnEcom', e.checked)
                }
              />
            </div>
          </div>
          <div className="p-field p-fluid p-col-12 p-lg-6">
            <label
              className="p-d-none p-d-lg-inline"
              htmlFor="showOnCrm"
              style={{ color: 'transparent' }}
            >
              Show On CRM
            </label>
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="showOnCrm">
                Show On CRM :
              </label>
              <Checkbox
                id="showOnCrm"
                checked={paymentPartnerMethodQuota.showOnCrm ? true : false}
                onChange={(e) =>
                  handleObjChange(setPaymentPartnerMethodQuota, 'showOnCrm', e.checked)
                }
              />
            </div>
          </div>
          <div className="p-field p-fluid p-col-12 p-lg-6">
            <label
              className="p-d-none p-d-lg-inline"
              htmlFor="active"
              style={{ color: 'transparent' }}
            >
              Active
            </label>
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="active">
                Active :
              </label>
              <Checkbox
                id="active"
                checked={paymentPartnerMethodQuota.active ? true : false}
                onChange={(e) => handleObjChange(setPaymentPartnerMethodQuota, 'active', e.checked)}
              />
            </div>
          </div>
        </div>
        {newRecordError.state && (
          <>
            {newRecordError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setNewRecordError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </Dialog>
    </div>
  )
}
