import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import useQuery from '../../../hooks/useQuery'
import { getChargesDetailsById, updateShippingCharge } from '../../../api'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { handleAryChange } from '../../../functions/setter'
import { useGlobalContext } from '../../../context'
import { Message } from 'primereact/message'

export default function EditShippingChargesLogisticDialog({ setChargesTableLoading }) {
  const history = useHistory()
  const query = useQuery()
  const id = query.get('edit_sc_id')
  const { toast } = useGlobalContext()

  const emptyErr = {
    state: false,
    errors: [],
  }
  // states
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [editDialog, setEditDialog] = useState(false)
  const [record, setRecord] = useState([])
  const [loading, setLoading] = useState(false)

  //* Function to fetch all charges using it's id
  const fetchShippingChargesById = useCallback(async () => {
    if (!id) return
    setChargesTableLoading(true)
    const res = await getChargesDetailsById(id)
    if (res) {
      setLoading(false)
      setChargesTableLoading(false)
      if (res.status === 400) {
        onHideEditShippingChargesDialog()
      }
      if (res.status === 200) {
        setRecord(res.data.records[0])
        setEditDialog(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchShippingChargesById()
  }, [fetchShippingChargesById])

  const validateShippingRecord = () => {
    const err = []
    record.forEach((element) => {
      if (element.price === null) {
        err.push(`Price for quantity ${element.quantity} cannot be empty`)
      }
    })
    return err
  }

  const updateShippingCharges = async () => {
    const errs = validateShippingRecord()
    if (errs.length) {
      setNewRecordError({ state: true, errors: errs })
      return
    }
    setLoading(true)
    let { target, targetId } = record.map((item) => ({
      target: item.target,
      targetId: item.targetId,
    }))[0]
    const res = await updateShippingCharge(id, target, targetId, record)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        onHideEditShippingChargesDialog()
      }
    }
  }

  const onHideEditShippingChargesDialog = () => {
    setEditDialog(false)
    setLoading(false)
    setNewRecordError(emptyErr)
    history.push({
      pathname: '/website/logistics',
    })
  }

  return (
    <div>
      <Dialog
        visible={editDialog}
        className="w-full max-w-60"
        onHide={onHideEditShippingChargesDialog}
        header={`Edit Shipping Charges #${id}`}
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => {
                onHideEditShippingChargesDialog()
              }}
            />
            <Button
              className="p-mt-5"
              icon="pi pi-save"
              label={loading ? 'Saving' : 'Save'}
              loading={loading}
              disabled={loading}
              onClick={updateShippingCharges}
            />
          </div>
        }
      >
        <DataTable className="p-mt-4" value={record}>
          <Column header="Quantity" field="quantity"></Column>
          <Column
            field="price"
            header="Shipping Charges"
            body={(rd, { rowIndex }) => {
              return (
                <InputNumber
                  placeholder="Shipping Charge"
                  value={rd.price}
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  onValueChange={(e) => {
                    const { value } = e
                    const _record = { ...rd }
                    _record.price = value
                    handleAryChange(setRecord, rowIndex, _record)
                  }}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                />
              )
            }}
          ></Column>
          <Column
            header="Delivery Time"
            body={(rd) => `${rd.deliveryTimeFrom} To ${rd.deliveryTimeTo} Days`}
          ></Column>
          <Column
            field="Actions"
            body={(rd, { rowIndex }) => {
              return (
                <Button
                  tabIndex="-1"
                  className="p-button-danger"
                  tooltip="Reset"
                  icon="pi pi-replay"
                  onClick={() => {
                    const _record = { ...rd }
                    _record.price = 0
                    handleAryChange(setRecord, rowIndex, _record)
                  }}
                />
              )
            }}
          ></Column>
        </DataTable>
        {newRecordError.state && (
          <>
            {newRecordError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setNewRecordError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </Dialog>
    </div>
  )
}
