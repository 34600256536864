import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Password } from 'primereact/password'
import React from 'react'
// ConfirmDialogWithPassword component to manage the delete dialog for confirmation
export default function ConfirmDialogWithPassword({
  onHide,
  onSubmit,
  visible,
  loading,
  password,
  setPassword,
  headerText,
  mainText,
  loadingText = 'Submiting...',
  yesText = 'Submit',
}) {
  /**
   * onHide @type {function} - function to handle hide
   * onDelete @type {function} - function to handle delete
   * visible @type {boolean} - boolean to check visible
   * loading @type {boolean} - boolean to check loading
   * password @type {string} - string password
   * setPassword @type {function} - function to handle set password
   * headerText @type {string} - string for header text
   * mainText @type {string} - string for main text
   * loadingText @type {string} - string for loading text
   * yesText @type {string} - string for yes text
   * */

  return (
    <div>
      <Dialog
        onHide={onHide}
        visible={visible}
        autoFocus
        style={{ width: '450px' }}
        modal
        className="p-fluid"
        footer={() => (
          <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={onHide} />
            <Button
              loading={loading}
              disabled={!password || loading}
              label={loading ? loadingText : yesText}
              icon="pi pi-check"
              className="p-button"
              onClick={onSubmit}
            />
          </>
        )}
        header={() => {
          return headerText
        }}
      >
        <div className="confirmation-content  p-d-flex p-ai-center">
          <i className="pi pi-exclamation-triangle p-mr-4" style={{ fontSize: '2rem' }} />
          <div className="w-full">
            <p>{mainText}</p>
            <div className="p-mt-3" autoComplete="nope">
              <div className="">
                <label htmlFor="password" className="">
                  Password
                </label>
                <Password
                  toggleMask
                  feedback={false}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-mt-1"
                  placeholder="Enter your password"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
