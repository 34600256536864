import { format } from 'date-fns'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  deleteOrderIssue,
  getOrderIssue,
  getOrderIssues,
  patchOrderIssue,
  postOrderIssue,
} from '../../api'
import { useGlobalContext } from '../../context'
import { FilterMatchMode } from 'primereact/api'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { nanoid } from 'nanoid'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'

export default function OrderIssueFaqsPage() {
  const { orderIssueOptions, toast } = useGlobalContext()
  const dt = useRef(null)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = searchParams.get('id')
  const emptyNewRecord = {
    mainIssue: '',
    subIssue: '',
    solutions: [''],
    loading: false,
  }

  const emptyRecord = {
    mainIssue: '',
    subIssue: '',
    solutions: [''],
  }
  const [newRecordData, setNewRecordData] = useState(emptyNewRecord)
  const [deleteSolutions, setDeleteSolutions] = useState([])

  const handleNewRecordChanges = (target, value) => {
    setNewRecordData((ps) => ({ ...ps, [target]: value }))
  }
  const emptyErr = {
    state: false,
    errors: [],
  }
  const [editRecordError, setEditRecordError] = useState(emptyErr)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    mainIssue: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [filters, setFilters] = useState(emptyFilterObject)
  const emptyDelete = {
    id: null,
    state: false,
  }
  const [deleteRecordLoading, setDeleteRecordLoading] = useState(emptyDelete)
  const [tableLoading, setTableLoading] = useState(false)
  const [recordVisible, setRecordVisible] = useState(false)
  const [recordEditEnable, setRecordEditEnable] = useState(false)

  const [limit, setLimit] = useState(10)

  const [recordData, setRecordData] = useState(emptyRecord)
  const [editRecordData, setEditRecordData] = useState(emptyNewRecord)

  const [records, setRecords] = useState([])

  const validateNewRecord = (item) => {
    const { mainIssue, subIssue, solutions } = item
    const err = []
    if (!mainIssue) {
      err.push('Main issue is invalidate')
    }
    if (!subIssue) {
      err.push('Sub issue is invalidate')
    }
    if (!solutions || solutions.length === 0 || solutions.every((i) => !i)) {
      err.push('Solutions is invalidate')
    }
    return err
  }
  const resetNewRecord = () => {
    setNewRecordData((ps) => ({
      ...ps,
      subIssue: emptyNewRecord.subIssue,
      solutions: emptyNewRecord.solutions,
    }))
  }
  const handleSubmitNewRecord = async () => {
    const errs = validateNewRecord(newRecordData)
    if (errs.length > 0) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
    } else {
      handleNewRecordChanges('loading', true)
      setNewRecordError(emptyErr)
      const { mainIssue, subIssue, solutions } = newRecordData
      const res = await postOrderIssue(mainIssue, subIssue, solutions)
      if (res) {
        handleNewRecordChanges('loading', false)
        if (res.status === 201) {
          toast.current.show({
            severity: 'success',
            summary: 'Operation Successful',
            detail: res.data.message,
          })
          resetNewRecord()
          setRecords((ps) => [res.data.data, ...ps])
        }
      }
    }
  }
  const fetchRecords = useCallback(async () => {
    setTableLoading(true)
    const res = await getOrderIssues(limit)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setRecords(res.data)
      } else {
        setRecords([])
      }
    }
  }, [limit])

  useEffect(() => fetchRecords(), [fetchRecords])
  const onChangeFilter = (value, target) => {
    let _filters = { ...filters }
    _filters[target].value = value
    setFilters(_filters)
  }
  const hideRecord = () => {
    setRecordData(emptyRecord)
    setRecordVisible(false)
    setRecordEditEnable(false)
    history.push({
      pathname: '/website/order-issues-faqs',
    })
  }
  const fetchRecord = useCallback(async () => {
    if (!id) return
    const res = await getOrderIssue(id)
    if (res) {
      if (res.status === 404) {
        hideRecord()
      }
      if (res.status === 200) {
        setRecordData((ps) => ({
          ...ps,
          ...res.data,
        }))
        setRecordVisible(true)
      }
    }
  }, [id])
  useEffect(() => fetchRecord(), [fetchRecord])

  const handleRecordEditEnable = () => {
    setRecordEditEnable(true)
    setEditRecordData({ ...recordData })
  }

  const handleDiscardRecordEdit = () => {
    setRecordEditEnable(false)
    setEditRecordData(emptyNewRecord)
    setDeleteSolutions([])
  }

  const handleSaveRecordEdit = async () => {
    const errs = validateNewRecord(editRecordData)
    if (errs.length > 0) {
      setEditRecordError({
        state: true,
        errors: errs,
      })
    } else {
      handleEditRecordChange('loading', true)
      setEditRecordError(emptyErr)
      const payload = {}
      payload.mainIssue = editRecordData.mainIssue
      payload.subIssue = editRecordData.subIssue
      payload.solutions = editRecordData.solutions.filter((i) => i.status === 'new')
      payload.delete_solutions = deleteSolutions

      const res = await patchOrderIssue(id, payload)
      if (res) {
        handleEditRecordChange('loading', false)
        if (res.status === 200) {
          setRecordData((ps) => ({
            ...ps,
            ...res.data.data,
          }))
          setRecords((ps) =>
            [...ps].map((i) => {
              if (i.id === res.data.data.id) {
                return res.data.data
              }
              return i
            })
          )
          handleDiscardRecordEdit()
          toast.current.show({
            severity: 'success',
            summary: 'Operation Successful',
            detail: res.data.message,
          })
        }
      }
    }
  }
  const handleEditRecordChange = (target, value) => {
    setEditRecordData((ps) => ({ ...ps, [target]: value }))
  }
  const handleRecordDelete = async (id) => {
    setDeleteRecordLoading({
      id,
      state: true,
    })
    const res = await deleteOrderIssue(id)
    if (res) {
      setDeleteRecordLoading(emptyDelete)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successfull',
          detail: res.data.message,
        })
        const _records = [...records].filter((item) => item.id !== id)
        setRecords(_records)
      }
    }
  }
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <div className="card">
        <Toast ref={toast} />
        <TabView>
          <TabPanel header="Order Issues FAQS">
            <Toolbar
              className="p-toolbar p-mb-3 p-flex-wrap gap-1"
              left={
                <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                  <h4 className="p-m-0">Order Issues FAQs</h4>
                </div>
              }
              right={
                <div>
                  <div className="p-d-flex p-flex-wrap gap-1by2">
                    <div className=" p-d-flex p-ai-center">
                      <p className="p-m-0 p-pr-2">Main Issue</p>
                      <Dropdown
                        options={orderIssueOptions}
                        placeholder="Main Issue"
                        value={filters.mainIssue.value}
                        onChange={(e) => {
                          onChangeFilter(e.target.value, 'mainIssue')
                        }}
                        showClear
                      />
                    </div>
                    <div className=" p-d-flex p-ai-center">
                      <p className="p-m-0 p-pr-2">Limit</p>
                      <Dropdown
                        placeholder="Limit"
                        options={[10, 50, 100, 500, 'All']}
                        onChange={(e) => {
                          setLimit(e.value)
                        }}
                        value={limit}
                      />
                    </div>

                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        value={filters.global.value}
                        onChange={(e) => {
                          onChangeFilter(e.target.value, 'global')
                        }}
                        placeholder="Search..."
                      />
                    </span>
                    <div>
                      <Button label="Refresh" icon="pi pi-refresh" onClick={fetchRecords} />
                    </div>
                  </div>
                </div>
              }
            ></Toolbar>
            <DataTable
              loading={tableLoading}
              value={records}
              ref={dt}
              filters={filters}
              showGridlines
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} issues"
              rowHover
              emptyMessage="No issues found."
              breakpoint="1200px"
              responsiveLayout="stack"
            >
              <Column style={{ width: '0%' }} header="Id" field="id" sortable />
              <Column style={{ width: '2%' }} header="Main Issue" field="mainIssue" sortable />
              <Column style={{ width: '1%' }} header="Sub Issue" field="subIssue" />
              <Column
                style={{ width: '2%' }}
                header="Date"
                field="date"
                body={(rd) => <div>{format(new Date(rd.date), 'PPp')}</div>}
              />
              <Column style={{ width: '2%' }} header="Created By" field="createdBy" sortable />
              <Column
                style={{ width: '0%' }}
                header="Action"
                body={(rd) => (
                  <div className="p-d-flex gap-1">
                    <Button
                      icon="pi pi-eye"
                      onClick={() => {
                        history.push({
                          pathname: '/website/order-issues-faqs',
                          search: `?id=${rd?.id}`,
                        })
                      }}
                    />
                    <Button
                      icon="pi pi-trash"
                      className="p-button-danger"
                      loading={rd.id === deleteRecordLoading.id && deleteRecordLoading.state}
                      disabled={rd.id === deleteRecordLoading.id && deleteRecordLoading.state}
                      onClick={() => handleRecordDelete(rd.id)}
                    />
                  </div>
                )}
              />
            </DataTable>
          </TabPanel>

          <TabPanel header="Add FAQ">
            <div className="p-fluid p-grid p-formgrid">
              <div className="p-col-12 p-fluid p-field">
                <label className="p-col-fixed" htmlFor="">
                  Main Issue
                </label>
                <Dropdown
                  options={orderIssueOptions}
                  placeholder="Select a main issue"
                  value={newRecordData.mainIssue}
                  onChange={(e) => handleNewRecordChanges('mainIssue', e.value)}
                />
              </div>
              <div className="p-col-12 p-fluid p-field">
                <label className="p-col-fixed" htmlFor="">
                  Sub Issue
                </label>
                <InputText
                  placeholder="Enter the sub issue"
                  value={newRecordData.subIssue}
                  onChange={(e) => handleNewRecordChanges('subIssue', e.target.value)}
                />
              </div>
              <div className="p-col-12 p-fluid p-field">
                <label className="p-col-fixed">Solutions</label>
                {newRecordData.solutions.map((item, idx) => (
                  <div key={idx} className="p-d-flex gap-1 p-mb-2">
                    <InputTextarea
                      autoResize
                      placeholder={`Enter #${idx + 1} solution`}
                      value={item}
                      onChange={(e) => {
                        let _solutions = [...newRecordData.solutions]
                        _solutions[idx] = e.target.value
                        handleNewRecordChanges('solutions', _solutions)
                      }}
                    />
                    <div className="p-d-flex gap-1">
                      <Button
                        icon="pi pi-plus"
                        onClick={() => {
                          let _solutions = [...newRecordData.solutions]
                          _solutions.push('')
                          handleNewRecordChanges('solutions', _solutions)
                        }}
                      />
                      <Button
                        disabled={newRecordData.solutions.length <= 1}
                        icon="pi pi-trash"
                        className="p-button-danger"
                        onClick={() => {
                          let _solutions = [...newRecordData.solutions].filter((_, i) => i !== idx)
                          handleNewRecordChanges('solutions', _solutions)
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {newRecordError.state && (
                <>
                  {newRecordError.errors.map((err, idx) => (
                    <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                      <Message text={err} severity="warn" sticky={true} />
                    </div>
                  ))}
                  <div className="p-fluid p-field p-col-12">
                    <Button
                      type="button"
                      onClick={() => setNewRecordError(emptyErr)}
                      icon="pi pi-times"
                      label="Clear Warnings"
                      className="p-button-secondary"
                    />
                  </div>
                </>
              )}
              <div className="p-col-12">
                <Button
                  loading={newRecordData.loading}
                  disabled={newRecordData.loading}
                  label={newRecordData.loading ? 'Submitting' : 'Submit'}
                  icon="pi pi-save"
                  onClick={handleSubmitNewRecord}
                />
              </div>
            </div>
          </TabPanel>
        </TabView>
        <Dialog
          visible={recordVisible}
          header={`Issues #${id}`}
          className="w-full"
          style={{
            maxWidth: '800px',
          }}
          onHide={hideRecord}
          footer={
            <div>
              {recordEditEnable ? (
                <>
                  <Button
                    label="Discard Edit"
                    className="p-button-text"
                    icon="pi pi-trash"
                    onClick={() => handleDiscardRecordEdit()}
                  />
                  <Button
                    disabled={editRecordData.loading}
                    loading={editRecordData.loading}
                    label={editRecordData.loading ? 'Saving...' : 'Save'}
                    icon="pi pi-save"
                    onClick={() => handleSaveRecordEdit()}
                  />
                </>
              ) : (
                <>
                  <Button
                    label="Cancel"
                    className="p-button-text"
                    icon="pi pi-times"
                    onClick={() => hideRecord()}
                  />
                  <Button
                    label="Edit"
                    icon="pi pi-pencil"
                    className=""
                    onClick={() => handleRecordEditEnable()}
                  />
                </>
              )}
            </div>
          }
        >
          {recordEditEnable ? (
            <div className="p-grid p-my-2">
              <div className="p-col-12 p-grid">
                <p className="p-col-4">Main Issue</p>
                <p className="p-col-8">
                  <Dropdown
                    className="w-full"
                    placeholder="Select a main issue"
                    value={editRecordData.mainIssue}
                    onChange={(e) => handleEditRecordChange('mainIssue', e.value)}
                    options={orderIssueOptions}
                  />
                </p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Sub Issue</p>
                <p className="p-col-8">
                  <InputText
                    className="w-full"
                    placeholder="Enter the sub issue"
                    value={editRecordData.subIssue}
                    onChange={(e) => handleEditRecordChange('subIssue', e.target.value)}
                  />
                </p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Solutions</p>
                <div className="p-col-8 gap-1">
                  <div className="p-d-flex p-flex-column gap-1">
                    {editRecordData.solutions.map((item, idx) => (
                      <div key={idx} className="p-d-flex gap-1">
                        <InputTextarea
                          className="w-full"
                          placeholder={`Enter #${idx + 1} solution`}
                          value={item.solution}
                          onChange={(e) => {
                            let _solutions = [...editRecordData.solutions]
                            _solutions[idx]['solution'] = e.target.value
                            handleEditRecordChange('solutions', _solutions)
                          }}
                        />
                        <div className="p-d-flex gap-1">
                          <Button
                            icon="pi pi-plus"
                            onClick={() => {
                              let _solutions = [...editRecordData.solutions]
                              _solutions.push({
                                id: nanoid(),
                                status: 'new',
                                solution: '',
                              })
                              handleEditRecordChange('solutions', _solutions)
                            }}
                          />
                          <Button
                            disabled={editRecordData.solutions.length <= 1}
                            icon="pi pi-trash"
                            className="p-button-danger"
                            onClick={async () => {
                              if (item.oi_id) {
                                setDeleteSolutions((ps) => [...ps, item.id])
                              }
                              let _solutions = [...editRecordData.solutions].filter(
                                (i) => i.id !== item.id
                              )
                              handleEditRecordChange('solutions', _solutions)
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="p-col-12">
                {editRecordError.state && (
                  <>
                    {editRecordError.errors.map((err, idx) => (
                      <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                        <Message text={err} severity="warn" sticky={true} />
                      </div>
                    ))}
                    <div className="p-fluid p-field p-col-12">
                      <Button
                        type="button"
                        onClick={() => setEditRecordError(emptyErr)}
                        icon="pi pi-times"
                        label="Clear Warnings"
                        className="p-button-secondary"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="p-grid p-my-2">
              <div className="p-col-12 p-grid">
                <p className="p-col-4">Main Issue</p>
                <p className="p-col-8">{recordData?.mainIssue}</p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Sub Issue</p>
                <p className="p-col-8">{recordData?.subIssue}</p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Solutions</p>
                <p className="p-col-8">
                  {recordData.solutions.map((item, idx) => (
                    <div key={idx} className="card p-py-2 p-px-3">
                      <p className="">{item?.solution}</p>
                    </div>
                  ))}
                </p>
              </div>
            </div>
          )}
        </Dialog>
      </div>
    </PageAllowedToRoles>
  )
}
