import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'jspdf-autotable'
import { Button } from 'primereact/button'
import { deleteOffer, getOffers, patchOffer, postOffer } from '../../api'
import { useGlobalContext } from '../../context'
import { Dropdown } from 'primereact/dropdown'
import RequiredMessage from '../../components/mini/RequiredMessage'
import dateFormat from 'date-fns/format'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import { Dialog } from 'primereact/dialog'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'

const emptyOffer = {
  name: '',
  slug: '',
  status: '',
}
const emptyEditOffer = {
  id: '',
  name: '',
  slug: '',
  status: '',
}
const ogEmptyOffer = {
  name: '',
  slug: '',
  status: '',
  products: '',
  productNames: '',
}

export default function OffersPage() {
  const { productNames, toast } = useGlobalContext()
  const dt = useRef(null)
  const pdt = useRef(null)
  const [tableLoading, setTableLoading] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [productFilterValue, setProductFilterValue] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])
  const [newOffer, setNewOffer] = useState({ ...emptyOffer })
  const [newLoading, setNewLoading] = useState(false)
  const [data, setData] = useState([])
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [editDialogVisible, setEditDialogVisible] = useState(false)

  const [deleteOfferVal, setDeleteOfferVal] = useState({ ...ogEmptyOffer })
  const [editOfferVal, setEditOfferVal] = useState({ ...emptyEditOffer })
  const [editSelectedProducts, setEditSelectedProducts] = useState([])
  const [editLoading, setEditLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [password, setPassword] = useState('')
  const fetchData = useCallback(async () => {
    const res = await getOffers()
    setData(res.data)
  }, [])

  const handelNewOfferChange = (target, value) => {
    if (target === 'slug') {
      setNewOffer((ps) => ({
        ...ps,
        slug: value.replaceAll(' ', '-').toLowerCase(),
      }))
    } else if (target === 'name') {
      setNewOffer((ps) => ({
        ...ps,
        name: value,
        slug: value.replaceAll(' ', '-').toLowerCase(),
      }))
    } else {
      setNewOffer((ps) => ({ ...ps, [target]: value }))
    }
  }

  const onOfferSave = async () => {
    setNewLoading(true)
    let products = []
    let productNames = []
    selectedProducts.forEach((item) => {
      products.push(item.productCode)
      productNames.push(item.productName)
    })
    const res = await postOffer(
      newOffer.name,
      newOffer.slug,
      newOffer.status,
      products.join(','),
      productNames.join(',')
    )
    if (res) {
      setNewLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Offer added',
          detail: 'Operation succesfull',
        })
      }
      await fetchData()
    }
  }
  const onOfferUpdate = async () => {
    setEditLoading(true)
    let products = []
    let productNames = []
    editSelectedProducts.forEach((item) => {
      products.push(item.productCode)
      productNames.push(item.productName)
    })
    const res = await patchOffer(
      editOfferVal.id,
      editOfferVal.name,
      editOfferVal.slug,
      editOfferVal.status,
      products.join(','),
      productNames.join(',')
    )
    if (res) {
      setEditLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Offer updated',
          detail: 'Operation succesfull',
        })
      }
      editOnHide()
      await fetchData()
    }
  }

  useEffect(fetchData, [fetchData])

  const deleteOnHide = () => {
    setPassword('')
    setDeleting(false)
    setDeleteDialogVisible(false)
    setDeleteOfferVal({ ...ogEmptyOffer })
  }
  const deleteOnShow = (rd) => {
    setDeleteDialogVisible(true)
    setDeleteOfferVal({ ...rd })
  }

  const onDelete = async () => {
    setDeleting(true)
    const res = await deleteOffer(deleteOfferVal.id, password)
    if (res) {
      setDeleting(false)
      if (res.status === 200) {
        deleteOnHide()
        toast.current.show({
          severity: 'success',
          summary: 'Offer deleted',
          detail: 'Operation successfull',
        })
        await fetchData()
      }
    }
  }

  const editOnShow = (rd) => {
    setEditDialogVisible(true)
    setEditOfferVal({
      id: rd.id,
      name: rd.name,
      slug: rd.slug,
      status: rd.status,
    })
    let p = rd.products
    setEditSelectedProducts(productNames.filter((item) => p.includes(item.productCode)))
  }
  const editOnHide = () => {
    setEditDialogVisible(false)
    setEditOfferVal({ ...emptyOffer })
  }

  const header = (
    <div
      className="p-my-2 p-d-flex p-ai-center p-jc-between p-flex-column p-flex-md-row"
      style={{ textAlign: 'center' }}
    >
      <h5 className="p-m-0 p-text-bold ">Total Offers: {data?.length || 0}</h5>
      <div className="p-d-flex p-fuild p-ai-center p-flex-column p-flex-md-row gap-1">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilterValue}
            onChange={(e) => {
              setGlobalFilterValue(e.target.value)
            }}
            placeholder="Search..."
          />
        </span>
      </div>
    </div>
  )

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <div className="card">
        <ConfirmDialogWithPassword
          onHide={deleteOnHide}
          onSubmit={onDelete}
          visible={deleteDialogVisible}
          loading={deleting}
          password={password}
          setPassword={setPassword}
          headerText="Delete Offer"
          mainText={`You sure, you want to delete custom category ${deleteOfferVal?.name}?`}
        />
        <EditDialog
          offer={editOfferVal}
          setOffer={setEditOfferVal}
          selectedProducts={editSelectedProducts}
          setSelectedProducts={setEditSelectedProducts}
          visible={editDialogVisible}
          onHide={editOnHide}
          onOfferUpdate={onOfferUpdate}
          loading={editLoading}
        />
        <Toast ref={toast} />
        <TabView>
          <TabPanel header="Offers">
            <DataTable
              ref={dt}
              value={data}
              showGridlines
              globalFilter={globalFilterValue}
              //  selection={selectedProducts}
              //  onSelectionChange={(e) => setSelectedProducts(e.value)}
              // filters={filters}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} offers"
              emptyMessage="No offer found."
              header={header}
              // loading={tableLoading}
              rowHover
            >
              <Column field="id" header="Id" sortable style={{ width: '1%' }} />
              <Column field="name" header="Name" sortable style={{ width: '8%' }} />
              <Column field="slug" header="Slug" sortable style={{ width: '8%' }} />
              <Column field="productNames" header="Products" sortable style={{ width: '8%' }} />
              <Column field="status" header="Status" sortable style={{ width: '2%' }} />
              <Column field="createdBy" header="Created By" sortable style={{ width: '5%' }} />
              <Column
                field="createdAt"
                header="Created At"
                body={(rd) => dateFormat(new Date(rd?.createdAt), 'PP')}
                sortable
                style={{ width: '5%' }}
              />
              <Column
                header="Actions"
                body={(rd) => (
                  <div className="p-d-flex gap-1by2">
                    <Button icon="pi pi-pencil" onClick={() => editOnShow(rd)} />
                    <Button
                      className="p-button-danger"
                      icon="pi pi-trash"
                      onClick={() => deleteOnShow(rd)}
                    />
                    <Link to={`?history=${rd.id}`}>
                      <Button
                        tooltip="History"
                        tooltipOptions={{ position: 'bottom' }}
                        icon="pi pi-clock"
                        className="p-button p-button-help"
                      />
                    </Link>
                  </div>
                )}
                style={{ width: '1%' }}
              />
            </DataTable>
          </TabPanel>
          <TabPanel header="Add Offer">
            <div className="p-grid">
              <div className="p-fluid p-col-12 p-lg-6 p-formgrid ">
                <div className="p-fluid p-field ">
                  <label htmlFor="p-col-fixed">
                    Name <RequiredMessage value={newOffer.name} />
                  </label>
                  <InputText
                    placeholder="Enter Offer Name"
                    value={newOffer.name}
                    onChange={(e) => handelNewOfferChange('name', e.target.value)}
                  />
                </div>
                <div className="p-fluid p-field ">
                  <label htmlFor="p-col-fixed">
                    Slug <RequiredMessage value={newOffer.slug} />
                  </label>
                  <InputText
                    placeholder="Enter Offer Slug"
                    value={newOffer.slug}
                    onChange={(e) => handelNewOfferChange('slug', e.target.value)}
                  />
                </div>

                <div className="p-fluid p-field ">
                  <label htmlFor="p-col-fixed">
                    Status <RequiredMessage value={newOffer.status} />
                  </label>
                  <Dropdown
                    placeholder="Select Status"
                    value={newOffer.status}
                    onChange={(e) => handelNewOfferChange('status', e.value)}
                    options={['Inactive', 'Active']}
                  />
                </div>
                <div className="p-fluid p-field ">
                  <Button
                    onClick={onOfferSave}
                    loading={newLoading}
                    disabled={
                      Object.values(newOffer).includes('') ||
                      newLoading ||
                      selectedProducts.length === 0
                    }
                    label={newLoading ? 'Submitting' : 'Submit'}
                  />
                </div>
              </div>
              <div className="p-col-12 p-lg-6">
                <DataTable
                  ref={pdt}
                  value={productNames}
                  showGridlines
                  globalFilter={productFilterValue}
                  selection={selectedProducts}
                  onSelectionChange={(e) => setSelectedProducts(e.value)}
                  loading={tableLoading}
                  dataKey="productCode"
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 25, 50, 100, 250]}
                  className="datatable-responsive"
                  columnResizeMode="fit"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                  emptyMessage="No products found."
                  header={() => (
                    <div className="p-d-flex p-ai-center p-jc-between">
                      <h4 className="p-text-bold p-mb-0">
                        Total Selection : {selectedProducts.length}
                      </h4>

                      <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                          type="search"
                          value={productFilterValue}
                          onChange={(e) => {
                            setProductFilterValue(e.target.value)
                          }}
                          placeholder="Search..."
                        />
                      </span>
                    </div>
                  )}
                  // loading={tableLoading}
                  rowHover
                >
                  <Column selectionMode="multiple" style={{ width: '1%' }} />
                  <Column field="productName" header="Name" sortable style={{ width: '35%' }} />
                  <Column
                    field="productCategory"
                    header="Category"
                    sortable
                    style={{ width: '35%' }}
                  />
                  <Column
                    field="shippingCompany"
                    header="Shipping Company"
                    sortable
                    style={{ width: '5%' }}
                  />
                </DataTable>
              </div>
            </div>
          </TabPanel>
        </TabView>
        <ChangeHistorySidebar
          setTableLoading={setTableLoading}
          header="Offer Change History"
          historyType="offer"
        />
      </div>
    </PageAllowedToRoles>
  )
}

const EditDialog = ({
  selectedProducts,
  setSelectedProducts,
  offer,
  setOffer,
  visible,
  onHide,
  loading,
  onOfferUpdate,
}) => {
  const pdt = useRef(null)
  const { productNames } = useGlobalContext()
  const [productFilterValue, setProductFilterValue] = useState('')
  const handelOfferChange = (target, value) => {
    if (target === 'slug') {
      setOffer((ps) => ({
        ...ps,
        slug: value.replaceAll(' ', '-').toLowerCase(),
      }))
    } else if (target === 'name') {
      setOffer((ps) => ({
        ...ps,
        name: value,
        slug: value.replaceAll(' ', '-').toLowerCase(),
      }))
    } else {
      setOffer((ps) => ({ ...ps, [target]: value }))
    }
  }
  return (
    <Dialog
      header="Edit Custom Page"
      visible={visible}
      style={{ width: '70vw' }}
      footer="ji"
      onHide={() => onHide()}
    >
      <div className="p-grid">
        <div className="p-fluid p-col-12 p-lg-5 p-formgrid ">
          <div className="p-fluid p-field ">
            <label htmlFor="p-col-fixed">
              Name <RequiredMessage value={offer.name} />
            </label>
            <InputText
              placeholder="Enter Offer Name"
              value={offer.name}
              onChange={(e) => handelOfferChange('name', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field ">
            <label htmlFor="p-col-fixed">
              Slug <RequiredMessage value={offer.slug} />
            </label>
            <InputText
              placeholder="Enter Offer Slug"
              value={offer.slug}
              onChange={(e) => handelOfferChange('slug', e.target.value)}
            />
          </div>

          <div className="p-fluid p-field ">
            <label htmlFor="p-col-fixed">
              Status <RequiredMessage value={offer.status} />
            </label>
            <Dropdown
              placeholder="Select Status"
              value={offer.status}
              onChange={(e) => handelOfferChange('status', e.value)}
              options={['Inactive', 'Active']}
            />
          </div>
          <div className="p-fluid p-field ">
            <Button
              onClick={onOfferUpdate}
              loading={loading}
              disabled={
                Object.values(offer).includes('') || loading || selectedProducts.length === 0
              }
              label={loading ? 'Submitting' : 'Submit'}
            />
          </div>
        </div>
        <div className="p-col-12 p-lg-7">
          <DataTable
            ref={pdt}
            value={productNames}
            showGridlines
            globalFilter={productFilterValue}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            // filters={filters}
            dataKey="productCode"
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
            className="datatable-responsive"
            columnResizeMode="fit"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
            emptyMessage="No products found."
            header={() => (
              <div className="p-d-flex p-ai-center p-jc-between">
                <h4 className="p-text-bold p-mb-0">Total Selection : {selectedProducts.length}</h4>

                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    type="search"
                    value={productFilterValue}
                    onChange={(e) => {
                      setProductFilterValue(e.target.value)
                    }}
                    placeholder="Search..."
                  />
                </span>
              </div>
            )}
            // loading={tableLoading}
            rowHover
          >
            <Column selectionMode="multiple" style={{ width: '1%' }} />
            <Column field="productName" header="Name" sortable style={{ width: '35%' }} />
            <Column field="productCategory" header="Category" sortable style={{ width: '35%' }} />
            <Column
              field="shippingCompany"
              header="Shipping Company"
              sortable
              style={{ width: '5%' }}
            />
          </DataTable>
        </div>
      </div>
    </Dialog>
  )
}
