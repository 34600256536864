import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useState } from 'react'
import PageAllowedToRoles from '../../../../app/wrappers/PageAllowedToRoles'
import { Toast } from 'primereact/toast'
import { useGlobalContext } from '../../../../context'
import { Column } from 'jspdf-autotable'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { format } from 'date-fns'
import ChangeHistorySidebar from '../../ChangeHistorySidebar'
import AddPaymentMethodDialog from './AddPaymentMethodDialog'
import EditPaymentMethodDialog from './EditPaymentMethodDialog'
import ConfirmationDialogWithInput from '../../Dialog/ConfirmationDialogWithInput'
import { Password } from 'primereact/password'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import generateS3Path from '../../../../functions/generateS3Path'
import { deletePaymentMethod, getPaymentMethods } from '../../../../api'

const statusOptions = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 0,
  },
]

export default function PaymentMethodsTable({
  paymentMethods,
  setPaymentMethods,
  fetchPaymentMethods,
  pmDtLoading,
  setPmDtLoading,
}) {
  //* States Start
  const [paymentMethodTableFilter, setPaymentMethodTableFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  })
  const { toast } = useGlobalContext()
  const [visibleNewPaymentDialog, setVisibleNewPaymentDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmationDialog, setPasswordConfirmationDialog] = useState(false)
  const [deleteMethodId, setDeleteMethodId] = useState(null)

  const onChangeFilter = (setter, value, target) => {
    setter((ps) => ({ ...ps, [target]: { ...ps[target], value } }))
  }

  // global filter search onchange
  const onGlobalFilterChange = (event, filter, setFilter) => {
    const value = event.target.value
    let _filters = { ...filter }

    _filters['global'].value = value

    setFilter(_filters)
  }

  const handleDeletePaymentMethod = async () => {
    if (!deleteMethodId) return
    setLoading(true)
    setPasswordConfirmationDialog(true)
    const res = await deletePaymentMethod(deleteMethodId, password)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
          life: 3000,
        })
        let temp = [...paymentMethods]
        const updatedData = temp.filter((payment) => payment.id !== deleteMethodId)
        setPaymentMethods(updatedData)
        onConfirmDialogHide()
      }
    }
  }

  const onConfirmDialogHide = () => {
    setPasswordConfirmationDialog(false)
    setPassword('')
  }

  const paymentMethodsDataTableHeader = () => {
    return (
      <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
        <div>
          <Dropdown
            id="status"
            placeholder="Select Status"
            showClear
            options={statusOptions}
            value={paymentMethodTableFilter.status.value}
            onChange={(e) => onChangeFilter(setPaymentMethodTableFilter, e.value, 'status')}
          />
        </div>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={
                paymentMethodTableFilter['global']
                  ? paymentMethodTableFilter['global'].value
                  : '' || ''
              }
              onChange={(e) =>
                onGlobalFilterChange(e, paymentMethodTableFilter, setPaymentMethodTableFilter)
              }
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    )
  }

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-toolbar p-mb-3 p-flex-wrap gap-1mb-4"
          left={
            <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
              <h4 className="p-m-0">Payment Methods</h4>
              <Button
                label="Add Payment Method"
                icon="pi pi-plus"
                className="p-button-primary"
                onClick={() => setVisibleNewPaymentDialog(true)}
              />
            </div>
          }
          right={
            <div>
              <div className="p-d-flex p-flex-wrap gap-1">
                <Button
                  label="Refresh"
                  icon="pi pi-refresh"
                  onClick={() => {
                    fetchPaymentMethods()
                  }}
                />
              </div>
            </div>
          }
        ></Toolbar>
        <DataTable
          removableSort
          value={paymentMethods}
          showGridlines
          filters={paymentMethodTableFilter}
          header={paymentMethodsDataTableHeader}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          loading={pmDtLoading}
        >
          <Column
            style={{
              width: '1%',
            }}
            field="id"
            header="Id"
            sortable
          />
          <Column
            style={{
              width: '2%',
            }}
            field="appImage"
            header="Icon Image"
            body={(rd) => {
              return (
                <div className="p-d-flex p-jc-center p-ai-center">
                  <img
                    src={generateS3Path(rd.appImage)}
                    alt={rd.appName}
                    className="p-mr-2"
                    style={{
                      width: '40px',
                    }}
                  />
                </div>
              )
            }}
          />
          <Column
            style={{
              width: '10%',
            }}
            field="appName"
            header="Name"
            sortable
          />
          <Column
            style={{
              width: '10%',
            }}
            field="slug"
            header="Slug"
            sortable
          />
          {/* <Column
            style={{
              width: '10%',
            }}
            field="appImage"
            header="Icon Image"
            sortable
          />
          <Column
            style={{
              width: '10%',
            }}
            field="qrCodeImage"
            header="QR Code Image"
            sortable
          /> */}

          <Column
            style={{
              width: '10%',
            }}
            field="createdBy"
            header="Created By"
            sortable
          />
          <Column
            style={{
              width: '10%',
            }}
            field="createdAt"
            header="Created At"
            body={(rd) => format(new Date(rd.createdAt), 'PPp')}
            sortable
          />
          {/* <Column
            style={{
              width: '10%',
            }}
            field="updatedBy"
            header="Updated By"
            sortable
          />
          <Column
            style={{
              width: '10%',
            }}
            field="updatedAt"
            header="Updated At"
            body={(rd) => format(new Date(rd.updatedAt), 'PPp')}
            sortable
          /> */}
          <Column
            style={{
              width: '2%',
              textAlign: 'center',
            }}
            field="status"
            header="Status"
            sortable
            body={(rd) => <div>{rd.status ? 'Active' : 'Inactive'}</div>}
          />
          <Column
            field="Action"
            header="Action"
            style={{
              width: '1%',
            }}
            body={(rd) => {
              return (
                <div className="p-d-flex gap-1by2 p-jc-center">
                  <Link to={`?id=${rd?.id}`}>
                    <Button
                      className="p-button p-button-info"
                      icon="pi pi-pencil"
                      tooltip="Edit"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                    />
                  </Link>
                  <Button
                    icon="pi pi-trash"
                    className="p-button p-button-danger"
                    tooltip="Delete"
                    tooltipOptions={{
                      position: 'bottom',
                    }}
                    onClick={() => {
                      setDeleteMethodId(rd.id)
                      setPasswordConfirmationDialog(true)
                    }}
                  />
                  <Link to={`?history=${rd.id}`}>
                    <Button
                      tooltip="History"
                      tooltipOptions={{ position: 'bottom' }}
                      icon="pi pi-clock"
                      className="p-button p-button-help"
                    />
                  </Link>
                </div>
              )
            }}
          />
        </DataTable>
        <AddPaymentMethodDialog
          visibleNewPaymentDialog={visibleNewPaymentDialog}
          setVisibleNewPaymentDialog={setVisibleNewPaymentDialog}
          setPaymentMethods={setPaymentMethods}
          setTableLoading={setPmDtLoading}
          statusOptions={statusOptions}
        />
        <EditPaymentMethodDialog
          paymentMethods={paymentMethods}
          setPaymentMethods={setPaymentMethods}
          setTableLoading={setPmDtLoading}
          statusOptions={statusOptions}
        />
        {/* Confirmation dialog to ask password start*/}
        <ConfirmationDialogWithInput
          onHide={onConfirmDialogHide}
          header={'Confirm password to delete'}
          onSubmit={handleDeletePaymentMethod}
          visible={passwordConfirmationDialog}
          inputLabel={'Password'}
          customInputs={true}
          loading={loading}
        >
          <div className="">
            <label htmlFor="password" className="">
              Password
            </label>
            <Password
              autoComplete="nope"
              toggleMask
              feedback={false}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-mt-1"
              placeholder="Enter your password"
            />
          </div>
        </ConfirmationDialogWithInput>
        {/* Confirmation dialog to ask password end*/}
        <ChangeHistorySidebar
          header="Payment Method Change History"
          historyType="im_paymentMethod"
        />
      </div>
    </PageAllowedToRoles>
  )
}
