import axios from 'axios'
import endpoints from './endpoints'

export const getJson = async (name) => {
  try {
    return await axios.get(endpoints.json.index, {
      params: {
        name,
      },
    })
  } catch (error) {
    return error.response
  }
}

export const getCountriesList = async () => {
  try {
    return await axios.get(endpoints.json.countries)
  } catch (error) {
    return error.response
  }
}

export const getStatesByCountryId = async (countryId) => {
  try {
    return await axios.get(endpoints.json.states, {
      params: {
        id: countryId,
      },
    })
  } catch (error) {
    return error.response
  }
}
