export const paidStatus = [
  'Paid',
  'Processed',
  'Shipped',
  'Tracking',
  'Tracking-Live',
  'Delivered',
  'Undelivered',
  'Partial',
  'Reshipment-Initiated',
  'Reshipment-Completed',
  'Refunded',
]
export const unpaidStatus = ['Unpaid', 'Pending', 'Draft', 'Cancelled']
export const unqualifiedStatus = ['Unqualified']
export const allStatus = [...paidStatus, ...unpaidStatus, ...unqualifiedStatus]
export const undeliveredOrderStatusOptions = [
  'Undelivered',
  'Reshipment-Initiated',
  'Reshipment-Completed',
  'Refunded',
]
