import React, { useRef, useState } from 'react'
import { postProductRefund, uploadFileToS3 } from '../../../api'
import { nanoid } from 'nanoid'
import { Dialog } from 'primereact/dialog'
import { useGlobalContext } from '../../../context'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { handleObjChange } from '../../../functions/setter'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { Checkbox } from 'primereact/checkbox'

const emptyErr = {
  state: false,
  errors: [],
}

const emptySetChangeStatus = {
  visible: false,
  loading: false,
  sendNotification: true,
  status: null,
  productid: null,
}

function ProductRefundDialog({
  productRefund,
  setProductRefund,
  error,
  setError,
  fetchOrderProducts,
  media,
  setMedia,
  hideProductRefundDialog,
  setTableLoading,
}) {
  const { user, toast } = useGlobalContext()
  const mediaRef = useRef(null)
  const [changeStatus, setChangeStatus] = useState(emptySetChangeStatus)
  // remove attachments files function
  const removeFiles = (uid, state, setter) => {
    if (typeof setter !== 'function') return
    let filValue = state.filter((ele) => ele.uid !== uid)
    setter(filValue)
  }

  const handleProductRefund = async () => {
    setTableLoading(true)
    handleObjChange(setProductRefund, 'loading', true)
    handleObjChange(setChangeStatus, 'loading', true)
    let arrayOfFiles = []
    if (media.length) {
      await Promise.all(
        media.map(async (attachment, index) => {
          const res = await uploadFileToS3({ file: attachment.file })
          // save
          arrayOfFiles.push({
            uid: attachment.uid,
            name: attachment.name,
            path: res.data.filePath,
            // New additions based on orders reference
            createdAt: new Date().toISOString(),
            meta: {
              createdBy: user?.username,
            },
          })
        })
      )
    }
    const res = await postProductRefund({
      productId: productRefund.productid,
      payload: {
        reason: productRefund.refundReason,
        media: arrayOfFiles,
      },
      sendNotification: true,
    })
    if (res) {
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
          life: 3000,
        })

        hideProductRefundDialog()
        setChangeStatus(emptySetChangeStatus)
        fetchOrderProducts()
      }
    }
    setTableLoading(false)
    handleObjChange(setProductRefund, 'loading', false)
    handleObjChange(setChangeStatus, 'loading', false)
  }

  const isValidProductRefund = () => {
    const errors = []
    if (!productRefund.refundReason.trim()) {
      errors.push('Please enter a reason for refund')
    }
    if (!media.length) {
      errors.push('Proof of refund is required')
    }
    return errors
  }

  const handleFilesUpload = (e, setter) => {
    if (!e.target.files.length || typeof setter !== 'function') {
      return
    }
    const files = e.target.files
    const myFiles = []
    let largeFiles = 0
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (file.size < 1024 * 1024 * 5) {
        const path = URL.createObjectURL(file)
        myFiles.push({
          uid: nanoid(),
          name: file.name,
          file,
          path,
        })
      } else {
        largeFiles++
      }
    }
    if (largeFiles) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: `${largeFiles} files are larger than 5 MB and will not be uploaded`,
      })
    }
    setter((ps) => [...ps, ...myFiles])
  }

  return (
    <>
      <Dialog
        header={`Refund Product #${productRefund.productid}`}
        visible={productRefund.visible}
        style={{ width: '600px', paddingBottom: '0px' }}
        contentStyle={{ paddingBottom: '10px' }}
        onHide={hideProductRefundDialog}
        footer={
          <div className="p-d-flex p-jc-end gap-1by2 ">
            <div className="">
              <Button
                label={'Close'}
                disabled={productRefund.loading}
                onClick={hideProductRefundDialog}
                className="p-button-danger"
                style={{ marginRight: 0 }}
                icon="pi pi-times"
              />
            </div>
            <div className="">
              <Button
                loading={productRefund.loading}
                label={productRefund.loading ? 'Submitting' : 'Submit'}
                onClick={() => {
                  const errors = isValidProductRefund()
                  if (errors.length) {
                    setError({ state: true, errors })
                    return
                  }

                  setChangeStatus((ps) => ({
                    ...ps,
                    visible: true,
                    status: 'Refunded',
                    productid: productRefund.productid,
                  }))
                }}
                style={{ marginRight: 0 }}
                icon="pi pi-check"
              />
            </div>
          </div>
        }
      >
        <div>
          <div className=" p-grid p-formgrid">
            <div className="p-field p-col-12">
              <label htmlFor="refundAmount">
                Reason for Refund <sup style={{ color: 'red' }}>*</sup>
              </label>
              <InputTextarea
                className="w-full"
                rows={5}
                value={productRefund.refundReason}
                placeholder="Enter a reason for refund"
                onChange={(e) => handleObjChange(setProductRefund, 'refundReason', e.target.value)}
              />
            </div>
            <p className="p-ml-2">
              Please attach one or more screenshots for reference
              <sup style={{ color: 'red' }}>*</sup>
            </p>
            <div className="p-field p-fluid p-col-12 p-mb-0">
              {media?.map((item, idx) => {
                let { name, path } = item
                if (name.length > 40) {
                  name = name.substring(0, 15) + '...' + name.substring(name.length - 20)
                }
                return (
                  <div key={idx} className="relative p-d-flex p-mb-3 gap-1by2">
                    <div className="w-full">
                      <InputText
                        value={name}
                        readOnly
                        style={{ outline: 'none', border: 'none' }}
                      />
                    </div>
                    <div>
                      <Button
                        className="p-button-danger p-button-text"
                        onClick={() => removeFiles(item.uid, media, setMedia)}
                        icon="pi pi-trash"
                      />
                    </div>
                    <div>
                      <a key={name} href={path} target="_blank" rel="noreferrer">
                        <Button className="p-button-text" icon="pi pi-external-link"></Button>
                      </a>
                    </div>
                    <span
                      className={`${item.new ? 'blob bottom-15-px left-0-px p-mx-0' : ''}`}
                    ></span>
                  </div>
                )
              })}
            </div>
            <div className="p-field p-col-12">
              <input
                id="refundMedia"
                type="file"
                className="p-hidden"
                accept="*"
                ref={mediaRef}
                multiple
                onChange={(e) => {
                  handleFilesUpload(e, setMedia)
                }}
              />

              <Button
                icon="pi pi-paperclip"
                label="Attach"
                className="w-full p-button-primary"
                disabled={productRefund.loading}
                onClick={() => {
                  mediaRef.current.click()
                }}
              />
            </div>
          </div>
        </div>

        {error.state && (
          <div className="p-grid">
            {error.errors.map((err, idx) => (
              <div key={idx} className="p-fluid p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            ))}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => setError(emptyErr)}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              />
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        visible={changeStatus.visible}
        style={{ width: '450px' }}
        header="Status Change Confirmation"
        modal
        footer={
          <>
            <Button
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              disabled={changeStatus.loading}
              onClick={() => handleObjChange(setChangeStatus, 'visible', false)}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              loading={changeStatus.loading}
              onClick={handleProductRefund}
              autoFocus
            />
          </>
        }
        onHide={() => handleObjChange(setChangeStatus, 'visible', false)}
      >
        <div className="confirmation-content  p-d-flex p-ai-center">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          <div>
            <span>
              Are you sure you want change status to {changeStatus?.status} for product (
              {changeStatus?.productid})?
            </span>

            <div className="p-mt-3 p-d-flex gap-1 p-ai-center ">
              <Checkbox
                inputId="changeStatusNotification"
                checked={changeStatus.sendNotification}
                onChange={(e) => handleObjChange(setChangeStatus, 'sendNotification', e.checked)}
              />
              <label htmlFor="changeStatusNotification">
                Send notification to customer regrading this product progress
              </label>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ProductRefundDialog
