import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'primereact/toolbar'
import { handleObjChange } from '../../../functions/setter'
import React, { useRef, useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import classNames from 'classnames'
import { Toast } from 'primereact/toast'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { addShippingCompany, deleteShippingCompany, updateShippingCompany } from '../../../api'
import { useGlobalContext } from '../../../context'
import ConfirmDialogWithPassword from '../Dialog/ConfirmDialogWithPassword'
import { InputNumber } from 'primereact/inputnumber'

export default function ShipperLogistic({
  companies,
  setShippingCompaniesTableLoading,
  tableLoading,
  setShippingCompanies,
  fetchShippingCompanies,
  chargesRecords,
  setChargesRecords,
}) {
  const { setShippingTags } = useGlobalContext()
  const dt = useRef(null)
  const toast = useRef(null)

  const newRecord_empty = {
    loading: false,
    visible: false,
    companyTag: '',
    companyName: '',
    companyProvider: '',
    companyLink: '',
    expectedMinDays: 0,
    expectedMaxDays: 0,
    arrivalMinDays: 0,
    arrivalMaxDays: 0,
  }
  const defaultLoader = {
    add: false,
    update: false,
    delete: false,
  }
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  }

  const [newRecord, setNewRecord] = useState(newRecord_empty)
  const [editRecord, setEditRecord] = useState(null)
  const [companiesFilters, setCompaniesFilters] = useState(emptyFilterObject)
  const [loaders, setLoaders] = useState(defaultLoader)
  const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false)
  const [deleteCompanyData, setDeleteCompanyData] = useState({})
  const [password, setPassword] = useState('')

  const linkBody = (rowData) => {
    return (
      <>
        {editRecord === rowData.id ? (
          <InputText
            // style={{ border: 'none', background: 'none', padding: '0' }}
            value={rowData.companyLink}
            onChange={(e) => handelEditCompanyChange(rowData.id, 'companyLink', e.target.value)}
          />
        ) : (
          <span>{rowData.companyLink}</span>
        )}
      </>
    )
  }
  const nameBody = (rowData) => {
    return (
      <>
        {editRecord === rowData.id ? (
          <InputText
            // style={{ border: 'none', background: 'none', padding: '0' }}
            value={rowData.companyName}
            onChange={(e) => handelEditCompanyChange(rowData.id, 'companyName', e.target.value)}
          />
        ) : (
          <span>{rowData.companyName}</span>
        )}
      </>
    )
  }
  const providerBody = (rowData) => {
    return (
      <>
        {editRecord === rowData.id ? (
          <InputText
            // style={{ border: 'none', background: 'none', padding: '0' }}
            value={rowData.companyProvider}
            onChange={(e) => handelEditCompanyChange(rowData.id, 'companyProvider', e.target.value)}
          />
        ) : (
          <span>{rowData.companyProvider}</span>
        )}
      </>
    )
  }
  const companyActionBody = (rowData) => {
    return (
      <div className="p-d-flex gap-1by2">
        <Link to={`?edit_st_company=${rowData.companyTag}`}>
          <Button
            icon="pi pi-pencil"
            tooltip="Edit"
            // onClick={() => setEditCompany(rowData.id)}
            tooltipOptions={{ position: 'bottom' }}
          />
        </Link>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          tooltip="Delete"
          onClick={() => {
            setDeleteCompanyDialog(true)
            setDeleteCompanyData(rowData)
          }}
          tooltipOptions={{ position: 'bottom' }}
        />
        <Link to={`?edit_st_code=${rowData.companyTag}`}>
          <Button
            tooltip="Timeline"
            tooltipOptions={{ position: 'bottom' }}
            icon="pi pi-calendar-plus"
            className="p-button-info"
          />
        </Link>
        <Link to={`?history_shipper_id=${rowData.companyTag}`}>
          <Button
            tooltip="History"
            tooltipOptions={{ position: 'bottom' }}
            icon="pi pi-clock"
            className="p-button p-button-help"
          />
        </Link>
      </div>
    )
  }
  const handelEditCompanyChange = (id, target, value) => {
    let temp = [...companies]
    temp = temp.map((item) => {
      if (item.id === id) {
        item[target] = value
      }
      return item
    })
    setShippingCompanies(temp)
  }
  const hideNewCompanyRecord = () => {
    setNewRecord(newRecord_empty)
  }
  const updateCompany = async () => {
    setLoaders({ ...loaders, update: true })
    const company = companies.find((item) => item.id === editRecord)
    if (!company) return
    const res = await updateShippingCompany(company.id, company)
    setLoaders(defaultLoader)
    if (res)
      if (res.status === 200) {
        setEditRecord(null)
        toast.current.show({
          severity: 'success',
          summary: 'Company Updated',
          detail: 'Company Updated Successfully',
          life: 3000,
        })
      } else {
        setEditRecord(null)
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: res.data.error,
          life: 3000,
        })
      }
  }

  const handleSaveCompany = async () => {
    handleObjChange(setNewRecord, 'loading', true)
    const res = await addShippingCompany(newRecord)
    if (res) {
      handleObjChange(setNewRecord, 'loading', false)
      if (res.status === 201) {
        setNewRecord({ ...newRecord_empty })
        setShippingCompanies([...companies, res.data])
        setShippingTags((ps) => [...ps, res.data.companyTag])
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Company added successfully',
          life: 3000,
        })
      } else {
        setNewRecord({ ...newRecord_empty })
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: res.data.error,
          life: 3000,
        })
      }
    }
  }
  const deleteCompany = async () => {
    setLoaders({ ...loaders, delete: true })
    const res = await deleteShippingCompany(deleteCompanyData.companyTag, password)
    setLoaders(defaultLoader)
    hideDeleteCompanyDialog()
    if (res && res.status === 200) {
      setShippingCompanies(
        companies.filter((item) => item.companyTag !== deleteCompanyData.companyTag)
      )
      setChargesRecords(
        chargesRecords.filter((item) => item.company !== deleteCompanyData.companyTag)
      )
      setShippingTags((ps) => ps.filter((item) => item !== deleteCompanyData.companyTag))
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Shipping company deleted successfully',
        life: 3000,
      })
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: res.data.error,
        life: 3000,
      })
    }
  }

  const hideDeleteCompanyDialog = () => {
    setPassword('')
    setDeleteCompanyDialog(false)
    setDeleteCompanyData({})
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card p-shadow-3">
            <Toolbar
              className="p-toolbar p-mb-3 p-d-flex p-ai-center"
              left={() => {
                return (
                  <>
                    <div className="p-d-flex p-ai-center p-mr-3">
                      <h4 className="p-m-0">Shipping Companies</h4>
                    </div>
                    <div>
                      <Button
                        label="Add Shipping Companies"
                        icon="pi pi-plus"
                        onClick={() => {
                          handleObjChange(setNewRecord, 'visible', true)
                        }}
                      />
                    </div>
                  </>
                )
              }}
              right={() => {
                return (
                  <div className="p-d-flex p-ai-center gap-1">
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        value={companiesFilters.global.value}
                        onInput={(e) => {
                          let _filters = { ...companiesFilters }
                          _filters.global.value = e.target.value
                          setCompaniesFilters(_filters)
                        }}
                        placeholder="Search Company"
                      />
                    </span>
                    <div>
                      <Button
                        label="Refresh"
                        icon="pi pi-refresh"
                        tooltip="Refresh Companies"
                        tooltipOptions={{ position: 'top' }}
                        onClick={fetchShippingCompanies}
                      />
                    </div>
                  </div>
                )
              }}
            ></Toolbar>
            <DataTable
              ref={dt}
              value={companies}
              filters={companiesFilters}
              showGridlines
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} companies"
              emptyMessage="No company found."
              loading={tableLoading}
              rowHover
            >
              <Column field="id" header="Id" sortable width="5%" />
              <Column
                field="companyTag"
                header="Shippers Tag"
                // body={tagBody}
                sortable
                width="25%"
              />
              <Column
                field="companyName"
                header="Shippers Name"
                body={nameBody}
                sortable
                width="25%"
              />
              <Column
                field="companyProvider"
                header="Shipping Company"
                body={providerBody}
                sortable
                width="10%"
              />
              <Column
                field="companyLink"
                header="Shippers Link"
                body={linkBody}
                sortable
                width="10%"
              />
              <Column field="createdBy" header="Created By" sortable width="10%" />
              <Column header="Actions" body={companyActionBody} width="1%" />
            </DataTable>
            <Dialog
              header="Add New Shipping Company"
              hide={hideNewCompanyRecord}
              visible={newRecord.visible}
              className="p-fluid "
              onHide={hideNewCompanyRecord}
              style={{ width: '40%', maxWidth: '900px' }}
              footer={
                <>
                  {
                    <Button
                      label="Cancel"
                      icon="pi pi-times"
                      className="p-button-text"
                      onClick={hideNewCompanyRecord}
                    />
                  }
                  <Button
                    disabled={
                      newRecord.loading ||
                      !newRecord.companyTag ||
                      !newRecord.companyName ||
                      !newRecord.companyProvider
                    }
                    className="p-mt-3"
                    loading={newRecord.loading}
                    label="Save Shipping Company"
                    onClick={handleSaveCompany}
                  />
                </>
              }
            >
              <div className="p-grid">
                <div className="p-field p-col-12 p-lg-12">
                  <p className="p-mb-2">Shippers Tag</p>
                  <InputText
                    className="p-text-uppercase p-inputtext p-component"
                    placeholder="GN, ED etc"
                    value={newRecord.companyTag}
                    onChange={(e) => {
                      handleObjChange(setNewRecord, 'companyTag', e.target.value)
                    }}
                  />
                </div>
                <div className="p-field p-col-12 p-lg-12">
                  <p className="p-mb-2">Shippers Name</p>{' '}
                  <InputText
                    placeholder="Generic, Ed Meds etc"
                    value={newRecord.companyName}
                    onChange={(e) => handleObjChange(setNewRecord, 'companyName', e.target.value)}
                  />
                </div>
                <div className="p-field p-col-12 p-lg-12">
                  <p className="p-mb-2">Shipping Company</p>{' '}
                  <InputText
                    placeholder="USPS, UPS, FedEx etc"
                    value={newRecord.companyProvider}
                    onChange={(e) =>
                      handleObjChange(setNewRecord, 'companyProvider', e.target.value)
                    }
                  />
                </div>
                <div className="p-field p-col-12 p-lg-12">
                  <p className="p-mb-2">Shippers Tracking Page</p>{' '}
                  <InputText
                    placeholder="www.fedex.com/track"
                    value={newRecord.companyLink}
                    onChange={(e) => handleObjChange(setNewRecord, 'companyLink', e.target.value)}
                  />
                </div>
                <div className="p-field p-col-6">
                  <p className="p-mb-2">Arrival Min Days</p>{' '}
                  <InputNumber
                    placeholder="Enter minimum arrival days"
                    value={newRecord.arrivalMinDays}
                    onChange={(e) => handleObjChange(setNewRecord, 'arrivalMinDays', e.value)}
                  />
                </div>
                <div className="p-field p-col-6">
                  <p className="p-mb-2">Arrival Max Days</p>{' '}
                  <InputNumber
                    placeholder="Enter maximum arrival days"
                    value={newRecord.arrivalMaxDays}
                    onChange={(e) => handleObjChange(setNewRecord, 'arrivalMaxDays', e.value)}
                  />
                </div>

                <div className="p-field p-col-6">
                  <p className="p-mb-2">Expected Min Days</p>{' '}
                  <InputNumber
                    placeholder="Enter expected minimum days"
                    value={newRecord.expectedMinDays}
                    onChange={(e) => handleObjChange(setNewRecord, 'expectedMinDays', e.value)}
                  />
                </div>
                <div className="p-field p-col-6">
                  <p className="p-mb-2">Expected Max Days</p>{' '}
                  <InputNumber
                    placeholder="Enter expected maximum days"
                    value={newRecord.expectedMaxDays}
                    onChange={(e) => handleObjChange(setNewRecord, 'expectedMaxDays', e.value)}
                  />
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
      <ConfirmDialogWithPassword
        onHide={hideDeleteCompanyDialog}
        onSubmit={deleteCompany}
        visible={deleteCompanyDialog}
        loading={loaders.delete}
        password={password}
        setPassword={setPassword}
        headerText="Delete Shipping Company"
        mainText={`Are you sure you want to delete shipping company ${deleteCompanyData.companyName}?`}
      />
    </div>
  )
}
