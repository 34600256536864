import { format } from 'date-fns'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import React, { useEffect, useRef, useState } from 'react'
import { addIp, deleteIp, getIps, patchIp } from '../../api'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { useGlobalContext } from '../../context'
import ExportButton from '../../components/mini/ExportButton'
import { dtFilenameDate } from '../../functions/myDateFns'

function IpAccess() {
  const { toast } = useGlobalContext()
  const dt = useRef(null)

  const empty_ip = {
    visible: false,
    loading: false,
    ip: '',
    name: '',
  }
  const empty_edit_ip = {
    visible: false,
    loading: false,
    ip: '',
    id: 0,
    status: '',
    statusText: '',
  }
  const [lastViewed, setLastViewed] = useState(null)
  const [ip, setIp] = useState(empty_ip)
  const [editIp, setEditIp] = useState(empty_edit_ip)
  const [deleteStateIp, setDeleteStateIp] = useState(empty_edit_ip)
  const [tableLoading, setTableLoading] = useState(false)
  const [ips, setIps] = useState([])
  const [password, setPassword] = useState('')

  const hideIpAdd = () => {
    setIp(empty_ip)
    setPassword('')
  }
  const hideIpEdit = () => {
    setEditIp(empty_edit_ip)
    setPassword('')
  }
  const hideIpDelete = () => {
    setDeleteStateIp(empty_edit_ip)
    setPassword('')
  }
  const handleNewIpChange = (target, value) => {
    setIp((ps) => ({ ...ps, [target]: value }))
  }
  const handleNewIpSave = async () => {
    handleNewIpChange('loading', true)
    const res = await addIp({
      ip: ip.ip,
      name: ip.name,
      password,
    })
    if (res) {
      handleNewIpChange('loading', false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setIps((ps) => [...ps, res.data.data])
        hideIpAdd()
      }
    }
  }
  const handleIpPatch = async () => {
    setEditIp((ps) => ({ ...ps, loading: true }))
    const res = await patchIp(editIp.id, {
      forKey: editIp.forKey,
      forValue: editIp.forValue,
      password,
    })
    if (res) {
      setEditIp((ps) => ({ ...ps, loading: false }))
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        let ps = [...ips].map((i) => {
          if (i.id === res.data.id) {
            return res.data.data
          }
          return i
        })
        setIps(ps)

        hideIpEdit()
      }
    }
  }
  const handleIpDelete = async () => {
    setDeleteStateIp((ps) => ({ ...ps, loading: true }))
    const res = await deleteIp(deleteStateIp.id, password)
    if (res) {
      setDeleteStateIp((ps) => ({ ...ps, loading: false }))
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setIps((ps) => ps.filter((i) => i.id !== res.data.id))
        hideIpDelete()
      }
    }
  }

  const fetchData = async () => {
    setTableLoading(true)
    const res = await getIps()
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setIps(res.data)
      }
    }
  }

  useEffect(() => fetchData(), [])

  return (
    <PageAllowedToRoles allowedRoles={['superadmin']}>
      <div>
        <Toast ref={toast} />
        <Toolbar
          className="p-toolbar p-mb-2"
          left={() => (
            <div className="p-d-flex gap-1 p-ai-center">
              <h4 className="p-m-0">IP Access</h4>
              <Button
                label="Add new ip"
                icon="pi pi-plus"
                onClick={() => {
                  handleNewIpChange('visible', true)
                }}
              />
            </div>
          )}
          right={() => (
            <div className="p-d-flex gap-1">
              <Button icon="pi pi-refresh" className="" onClick={fetchData} />
              <ExportButton datatable_ref={dt} />
            </div>
          )}
        ></Toolbar>
        <DataTable
          exportFilename={`IP_Access_${dtFilenameDate}`}
          value={ips}
          ref={dt}
          showGridlines
          selectionMode="single"
          selection={lastViewed}
          onSelectionChange={(e) => setLastViewed(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          className="datatable-responsive"
          columnResizeMode="fit"
          emptyMessage="No record found."
          rowHover
          breakpoint="1000px"
          responsiveLayout="stack"
          loading={tableLoading}
        >
          <Column header="ID" field="id" />
          <Column header="Ip Address" field="ip" />
          <Column header="Name" field="name" />
          <Column header="Created By" field="createdBy" />
          <Column
            header="Created At"
            field="createdAt"
            body={(rd) => format(new Date(rd.createdAt), 'PPp')}
          />

          <Column
            // style={{ width: '0' }}
            header="Delete"
            body={(rd) => {
              return (
                <div className="p-d-flex gap-1by2">
                  <Button
                    tooltip="Delete this ip access"
                    label="Delete"
                    icon="pi pi-trash"
                    className="p-button-danger"
                    onClick={() => {
                      const ps = { ...deleteStateIp }
                      ps.visible = true
                      ps.ip = rd.ip
                      ps.id = rd.id
                      setDeleteStateIp(ps)
                    }}
                  />
                  <Link to={`/ipaccess?history=${rd.id}`}>
                    <Button
                      tooltip="History"
                      tooltipOptions={{ position: 'bottom' }}
                      icon="pi pi-clock"
                      className="p-button p-button-help"
                    />
                  </Link>
                </div>
              )
            }}
          />
        </DataTable>
        <Dialog
          header="Add IP Access"
          style={{ maxWidth: '500px' }}
          visible={ip.visible}
          onHide={hideIpAdd}
          footer={
            <div>
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideIpAdd}
              />
              <Button
                label={ip.loading ? 'Adding...' : 'Add'}
                icon="pi pi-plus"
                disabled={ip.loading}
                loading={ip.loading}
                onClick={handleNewIpSave}
              />
            </div>
          }
        >
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              IP Address
            </label>
            <InputText
              placeholder="Enter ip address"
              value={ip.ip}
              onChange={(e) => handleNewIpChange('ip', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Ip Name
            </label>
            <InputText
              placeholder="Enter ip name"
              value={ip.name}
              onChange={(e) => handleNewIpChange('name', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Password
            </label>
            <Password
              placeholder="Enter your password"
              toggleMask
              feedback={false}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </Dialog>
        {/* change status */}
        <ConfirmDialogWithPassword
          visible={editIp.visible}
          loading={editIp.loading}
          headerText="Update Ip status"
          mainText={`${editIp.statusText} ip address ${editIp.ip}`}
          loadingText="Submitting..."
          yesText="Submit"
          onHide={hideIpEdit}
          onSubmit={handleIpPatch}
          password={password}
          setPassword={setPassword}
        />
        {/* delete */}
        <ConfirmDialogWithPassword
          visible={deleteStateIp.visible}
          loading={deleteStateIp.loading}
          headerText="Delete Ip"
          mainText={`Delete ip address ${deleteStateIp.ip}`}
          onHide={hideIpDelete}
          onSubmit={handleIpDelete}
          password={password}
          setPassword={setPassword}
        />
        <ChangeHistorySidebar
          setTableLoading={setTableLoading}
          header="Customer Blacklist Change History"
          historyType="ip-access"
        />
      </div>
    </PageAllowedToRoles>
  )
}

export default IpAccess
