import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { PiWarningOctagonBold } from 'react-icons/pi'

function WarningDialog({
  visible,
  loading,
  onSubmit,
  onHide,
  title = 'Are your sure you want to delete this image?',
}) {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      footer={() => (
        <>
          <Button
            disabled={loading}
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={onHide}
          />
          <Button
            disabled={loading}
            loading={loading}
            label={loading ? 'Deleting' : 'Delete'}
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={onSubmit}
          />
        </>
      )}
      style={{ width: '450px', textAlign: 'center' }}
    >
      <div className="p-d-flex gap-1 p-jc-center p-ai-center p-dir-col">
        <PiWarningOctagonBold className="w-3 h-3" />
        <h5 className="p-m-0">{title}</h5>
        <p className="p-m-0">
          This action is irreversible, and the image will be deleted permanently from our servers
        </p>
      </div>
    </Dialog>
  )
}

export default WarningDialog
