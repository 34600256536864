import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useCallback, useEffect, useState } from 'react'
import { CustomerDetails } from './CustomerDetails'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Dropdown } from 'primereact/dropdown'
import { useGlobalContext } from '../../../context'
import { addCustomerEnquireFeedback, getProductNames } from '../../../api'
import { Timeline } from 'primereact/timeline'
import { InputTextarea } from 'primereact/inputtextarea'
import { objectifiedCountryData }  from '../../../data/country_name'
import { InputNumber } from 'primereact/inputnumber'

export const CustomerCaseEditDialog = ({
  id,
  enquiryData,
  customizedMarker,
  customizedContent,
  editable,
  hideEditableDialog,
  updateCustomerEnquiry,
  setEnquiryData,
  acceptabilityOptions,
  catalougeOptions,
  toast,
}) => {
  const { agents, productTypes, productCategoriesGroups } = useGlobalContext()
  const genderOptions = [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
    { name: 'NA', value: 'NA' },
  ]

  const leadType = [
    { name: 'Buy Lead', value: 'Buy Lead' },
    { name: 'Direct Lead', value: 'Direct Lead' },
  ]
  const shiftsOptions = [
    { name: 'First Shift', value: 'First' },
    { name: 'Second Shift', value: 'Second' },
  ]
  const indcatalougeOptions = [{ web: 'Selco' }, { web: 'Supreme' }]

  // convert the above values into an arry
  const disposalOptions = [
    'No Answer',
    'Not Interested',
    'Quote Shared',
    'High Price',
    'Already Purchased',
    'DND',
    'Wrong Contact',
    'Sale Done',
    'Order Cancelled',
    'In Follow up',
    'Trust Issues',
    'Payment Confirmation',
    'Product not available',
    'Others',
  ]

  // Sale Done
  // Duplicate Lead
  // No Response
  // Not Interested
  // Price High
  // DND
  // Already Ordered
  // Shipping time Problem
  // Client Bad Experience
  // Ineligible Options
  // Product out of stock
  // Not Serviceable
  // Others

  const reasonForInEligibilityOptions = [
    'Sale Done',
    'Duplicate Lead',
    'No Response',
    'Not Interested',
    'Price High',
    'DND',
    'Already Ordered',
    'Shipping time Problem',
    'Client Bad Experience',
    'Ineligible Options',
    'Product out of stock',
    'Not Serviceable',
    'Others',
  ]

  // Old Options
  // const disposalOptions = [
  //   'Followup',
  //   'Not Answer',
  //   'Not Interested',
  //   'Quote Shared',
  //   'High Price',
  //   'Venmo Dropped',
  //   'Already Purchased',
  //   'Do not call',
  //   'Wrong Contact',
  //   'Sale Done',
  //   'Order Cancelled',
  //   'No Followup',
  //   'Trust Issues',
  //   'No Notes',
  //   'Payment Confirmation',
  // ]

  const enquiryScoreOptions = [
    {
      name: 'First Followup',
      value: 0.5,
    },
    {
      name: 'Second Followup',
      value: 0.5,
    },
    {
      name: 'Third Followup',
      value: 0.5,
    },
    {
      name: 'Notes on Indiamart',
      value: 0.5,
    },
    {
      name: 'Client First Response',
      value: 1,
    },
    {
      name: 'Client Good Communication',
      value: 2,
    },
  ]

  const ratingOptions = [
    { name: '1 Star', value: 1 },
    { name: '2 Star', value: 2 },
    { name: '3 Star', value: 3 },
    { name: '4 Star', value: 4 },
    { name: '5 Star', value: 5 },
  ]

  const newFeedback_empty = {
    feedback: '',
    visible: false,
    loading: false,
  }

  const [newFeedback, setNewFeedback] = useState(newFeedback_empty)

  const [productNames, setProductNames] = useState([])

  const getProducts = useCallback(async () => {
    const res = await getProductNames()
    if (res && res.status === 200) {
      setProductNames(res.data)
    }
  }, [])

  useEffect(() => {
    getProducts()
  }, [getProducts])

  // add feedback function
  const handleFeedbackSave = async () => {
    onChangeNewFeedback('loading', true)
    const res = await addCustomerEnquireFeedback(id, {
      feedback: newFeedback.feedback,
    })
    if (res) {
      onChangeNewFeedback('loading', false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation succesful',
          detail: res.data.message,
        })
        setNewFeedback(newFeedback_empty)
        setEnquiryData((ps) => ({
          ...ps,
          feedbacks: [res.data.feedback, ...ps.feedbacks],
        }))
      }
    }
  }

  // handle nested object on change
  const handleNestedObject = (setter, key, value) => {
    setter((ps) => ({
      ...ps,
      enquiry: {
        ...ps.enquiry,
        [key]: value,
      },
    }))
  }

  const onChangeNewFeedback = (target, value) => {
    setNewFeedback((ps) => ({ ...ps, [target]: value }))
  }

  return (
    <div>
      <Dialog
        header={`Update Customer Enquiry #${id}`}
        visible={editable}
        onHide={hideEditableDialog}
        style={{ width: '60%' }}
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              onClick={hideEditableDialog}
              icon="pi pi-times"
            />
            <Button
              label="Save"
              className=" p-button-primary"
              onClick={updateCustomerEnquiry}
              icon="pi pi-save"
            />
          </div>
        }
      >
        <div>
          {/* customer details  */}
          <CustomerDetails enquiryData={enquiryData} />
          {/* enter customer details  */}
          <div className="enterCustomerDetails p-mt-4">
            <div className="p-grid p-fluid p-mt-2 p-my-3">
              <div className="p-lg-8 p-col-12 p-mb-3">
                <h5 className="p-mt-5 p-mb-4">Enter Details</h5>
                <div className="p-grid p-fluid p-mt-2 p-my-3">
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Customer Name</p>
                    <InputText
                      value={enquiryData?.enquiry?.name}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'name', e.target.value)}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Acceptability</p>
                    <Dropdown
                      placeholder="Select Acceptability"
                      value={enquiryData?.enquiry?.acceptibility}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'acceptibility', e.target.value)
                      }
                      options={acceptabilityOptions}
                      optionLabel="label"
                      optionValue="value"
                    />
                  </div>
                  {enquiryData?.enquiry?.acceptibility === 'Ineligible' && (
                    <div className="p-lg-3 p-col p-mb-3">
                      <p className="p-mb-2">Reason For InEligibility</p>
                      <Dropdown
                        placeholder="Select Reason For InEligibility"
                        value={enquiryData?.enquiry?.reasonForInEligiblity}
                        onChange={(e) =>
                          handleNestedObject(
                            setEnquiryData,
                            'reasonForInEligiblity',
                            e.target.value
                          )
                        }
                        options={reasonForInEligibilityOptions}
                      />
                    </div>
                  )}
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Catalogue</p>
                    <Dropdown
                      placeholder="Choose Catalogue"
                      value={enquiryData?.enquiry?.catelogue}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'catelogue', e.target.value)
                      }
                      filterBy="web"
                      optionLabel="web"
                      optionValue="web"
                      options={
                        enquiryData?.enquiry.web === 'Indiamart'
                          ? indcatalougeOptions
                          : catalougeOptions?.filter((opt) => opt.web !== 'Indiamart')
                      }
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Shifts</p>
                    <MultiSelect
                      placeholder="First, Second..."
                      value={enquiryData.enquiry.shifts}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'shifts', e.target.value)}
                      options={shiftsOptions}
                      optionLabel="name"
                      optionValue="value"
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Lead Type</p>
                    <Dropdown
                      placeholder="Buy or Direct Lead"
                      optionLabel="name"
                      optionValue="value"
                      value={enquiryData?.enquiry?.leadType}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'leadType', e.target.value)
                      }
                      options={leadType}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Product Type</p>
                    <Dropdown
                      placeholder="Pill, Bottle, Tube, Pouch, Box..."
                      value={enquiryData?.enquiry?.productType}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'productType', e.target.value)
                      }
                      options={productTypes}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Disposals</p>
                    <Dropdown
                      placeholder="Select Disposals"
                      value={enquiryData?.enquiry?.disposal}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'disposal', e.target.value)
                      }
                      options={disposalOptions}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Assign To</p>
                    <Dropdown
                      placeholder="Select Agent"
                      optionValue="id"
                      optionLabel="username"
                      value={enquiryData?.enquiry?.assignedToId}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'assignedToId', e.target.value)
                      }
                      options={agents}
                      filter
                      filterBy="username"
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Product</p>
                    <Dropdown
                      placeholder="Crocin, Dolo, Paracetamol..."
                      value={enquiryData?.enquiry?.product}
                      optionLabel="productName"
                      optionValue="productName"
                      options={productNames}
                      onChange={(e) => {
                        handleNestedObject(setEnquiryData, 'product', e.target.value)
                        const selectedProduct = productNames.find(
                          (product) => product.productName === e.target.value
                        )
                        const mainCategory = selectedProduct?.productMainCategory.split(',') ?? []
                        const subCategory = selectedProduct?.productCategory.split(',') ?? []
                        handleNestedObject(setEnquiryData, 'category', mainCategory)
                        handleNestedObject(setEnquiryData, 'subCategory', subCategory)
                      }}
                      filter
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Category</p>
                    <MultiSelect
                      placeholder="Select Main Category"
                      value={enquiryData?.enquiry?.product && enquiryData?.enquiry?.category}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'category', e.target.value)
                      }
                      disabled={!enquiryData?.enquiry?.product}
                      filter
                      options={productCategoriesGroups[0]?.items}
                      optionValue="name"
                      optionLabel="name"
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Sub category</p>
                    <MultiSelect
                      placeholder="Select Sub Category"
                      value={enquiryData?.enquiry?.product && enquiryData?.enquiry?.subCategory}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'subCategory', e.target.value)
                      }
                      disabled={!enquiryData?.enquiry?.product}
                      options={productCategoriesGroups[1]?.items}
                      optionValue="name"
                      optionLabel="name"
                      id="subCategory"
                      filter
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Country</p>
                    <Dropdown
                      placeholder="USA, UK, India..."
                      value={enquiryData?.enquiry?.country}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'country', e.target.value)
                      }
                      options={objectifiedCountryData}
                      optionLabel="name"
                      optionValue="name"
                      filterBy="name"
                      editable
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Gender</p>
                    <Dropdown
                      placeholder="Male, Female, NA..."
                      optionLabel="name"
                      optionValue="value"
                      value={enquiryData?.enquiry?.gender}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'gender', e.target.value)}
                      options={genderOptions}
                    />
                  </div>
                  {/* <div className="p-lg-3 p-mb-3">
                    <p className="p-mb-2">Age</p>
                    <InputNumber
                      placeholder="26"
                      value={enquiryData?.enquiry?.age}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'age', e.value)}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Budget</p>
                    <InputNumber
                      placeholder="500"
                      value={enquiryData?.enquiry?.budget}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'budget', e.value)}
                    />
                  </div> */}
                  <div className="p-col p-lg-3 p-mb-3">
                    <p className="p-mb-2">Quantity</p>
                    <InputNumber
                      placeholder="5"
                      value={enquiryData?.enquiry?.quantity}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'quantity', e.value)}
                    />
                  </div>
                  {/*  <div className="p-col-3 p-mb-3">
                    <p className="p-mb-2">Rating</p>
                    <Dropdown
                      placeholder="Select Rating"
                      value={enquiryData?.enquiry?.rating}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'rating', e.target.value)}
                      options={ratingOptions}
                      optionLabel="name"
                      optionValue="value"
                    />
                  </div>   */}
                  {enquiryData?.enquiry?.web === 'Indiamart' && (
                    <div className="p-col-3 p-mb-3">
                      <p className="p-mb-2">Enquiry Score : {enquiryData?.enquiry?.enquiryScore}</p>
                      <MultiSelect
                        placeholder="Select Enquiry Values"
                        value={enquiryData?.enquiry?.enquiryScoreLabel}
                        onChange={(e) => {
                          let score = 0
                          e.target.value.forEach((element) => {
                            const elem = enquiryScoreOptions.find((el) => el.name === element)
                            score += elem.value
                          })
                          handleNestedObject(setEnquiryData, 'enquiryScoreLabel', e.target.value)
                          handleNestedObject(setEnquiryData, 'enquiryScore', score)
                        }}
                        optionLabel="name"
                        optionValue="name"
                        options={enquiryScoreOptions}
                      />
                    </div>
                  )}{' '}
                  {/* <div className="p-col-3 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.firstResponse === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'firstResponse', newValue)
                      }}
                      inputId="firstResponse"
                    ></Checkbox>
                    <label htmlFor="firstResponse" className="p-pl-2 p-checkbox-label">
                      First Response
                    </label>
                  </div>
                  <div className="p-col-3 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.followups === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'followups', newValue)
                      }}
                      inputId="followups"
                    ></Checkbox>
                    <label htmlFor="followups" className="p-pl-2 p-checkbox-label">
                      Followups
                    </label>
                  </div>
                  <div className="p-col-3 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.notes === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'notes', newValue)
                      }}
                      inputId="notes"
                    ></Checkbox>
                    <label htmlFor="notes" className="p-pl-2 p-checkbox-label">
                      Notes
                    </label>
                  </div>
                  <div className="p-col-3 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.clientResponse === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'clientResponse', newValue)
                      }}
                      inputId="clientResponse"
                    ></Checkbox>
                    <label htmlFor="clientResponse" className="p-pl-2 p-checkbox-label">
                      Client Response
                    </label>
                  </div>
                  <div className="p-col-6 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.goodConvowithClient === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'goodConvowithClient', newValue)
                      }}
                      inputId="goodConvowithClient"
                    ></Checkbox>
                    <label htmlFor="goodConvowithClient" className="p-pl-2 p-checkbox-label">
                      Good conversation with Client
                    </label>
                  </div> */}
                </div>
              </div>
              {/* feedbacks section  */}
              <div className="p-lg-4 p-col-12 p-mb-3">
                <h5 className="p-mt-5">Feedbacks</h5>
                <InputTextarea
                  className="p-mt-5 p-mb-2"
                  value={newFeedback?.feedback}
                  onChange={(e) => onChangeNewFeedback('feedback', e.target.value)}
                  placeholder="Feedback"
                />
                <Button
                  label={newFeedback?.loading ? 'Adding Feedback' : 'Add Feedback'}
                  icon="pi pi-plus"
                  onClick={handleFeedbackSave}
                  loading={newFeedback?.loading}
                  disabled={newFeedback?.loading || !newFeedback?.feedback}
                />
                <div className="widget-timeline p-mt-5">
                  <div className="timeline-content">
                    <Timeline
                      value={enquiryData?.feedbacks}
                      marker={customizedMarker}
                      content={customizedContent}
                      className="custimized-timeline"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* enter customer details  */}
        </div>
      </Dialog>
    </div>
  )
}
