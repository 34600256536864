import axios from 'axios'
import endpoints from './endpoints'

export const getPaymentMethodById = async (id) => {
  try {
    return await axios.get(endpoints.payment.method + id)
  } catch (error) {
    return error.response
  }
}

export const getPaymentMethods = async () => {
  try {
    return await axios.get(endpoints.payment.method)
  } catch (error) {
    return error.response
  }
}

export const postPaymentMethod = async (paymentData) => {
  try {
    const res = await axios.post(endpoints.payment.method, paymentData)
    return res
  } catch (error) {
    return error.response
  }
}

export const putPaymentMethod = async (paymentData, id) => {
  try {
    const res = await axios.put(endpoints.payment.method + id, paymentData)
    return res
  } catch (error) {
    return error.response
  }
}

export const deletePaymentMethod = async (id, password) => {
  try {
    const res = await axios.delete(endpoints.payment.method + id, {
      data: { password },
    })
    return res
  } catch (error) {
    return error.response
  }
}

// new api

export const getPaymentPartnerMethodQuotas = async () => {
  try {
    return await axios.get(endpoints.payment.partnerMethodQuota)
  } catch (error) {
    return error.response
  }
}

export const getAvailablePaymentPartnerMethodQuotas = async (totalAmount) => {
  try {
    return await axios.get(endpoints.payment.partnerMethodQuotaAvailable, {
      params: { totalAmount },
    })
  } catch (error) {
    return error.response
  }
}

export const postPaymentPartnerMethodQuota = async (paymentPartnerMethodQuotaData) => {
  try {
    const res = await axios.post(
      endpoints.payment.partnerMethodQuota,
      paymentPartnerMethodQuotaData
    )
    return res
  } catch (error) {
    return error.response
  }
}

export const deletePaymentPartnerMethodQuota = async (id) => {
  try {
    const res = await axios.delete(endpoints.payment.partnerMethodQuota + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const getPaymentPartnerMethods = async () => {
  try {
    return await axios.get(endpoints.payment.partnerMethod)
  } catch (error) {
    return error.response
  }
}

export const postPaymentPartnerMethod = async (paymentPartnerMethodData) => {
  try {
    const res = await axios.post(endpoints.payment.partnerMethod, paymentPartnerMethodData)
    return res
  } catch (error) {
    return error.response
  }
}

export const deletePaymentPartnerMethod = async (id) => {
  try {
    const res = await axios.delete(endpoints.payment.partnerMethod + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const getPaymentPartnerById = async (id) => {
  try {
    return await axios.get(endpoints.payment.partner + id)
  } catch (error) {
    return error.response
  }
}

export const getPaymentPartners = async () => {
  try {
    return await axios.get(endpoints.payment.partner)
  } catch (error) {
    return error.response
  }
}

export const postPaymentPartner = async (paymentPartnerData, documents) => {
  try {
    const res = await axios.post(endpoints.payment.partner, {
      ...paymentPartnerData,
      documents,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const putPaymentPartner = async (id, paymentPartnerData, documents) => {
  try {
    const res = await axios.put(endpoints.payment.partner + id, {
      ...paymentPartnerData,
      documents,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const deletePaymentPartner = async (id) => {
  try {
    const res = await axios.delete(endpoints.payment.partner + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const getPaymentPartnerMethodById = async (id) => {
  try {
    return await axios.get(endpoints.payment.partnerMethod + id)
  } catch (error) {
    return error.response
  }
}

export const putPaymentPartnerMethod = async (id, paymentPartnerMethodData) => {
  try {
    const res = await axios.put(endpoints.payment.partnerMethod + id, paymentPartnerMethodData)
    return res
  } catch (error) {
    return error.response
  }
}

export const getPaymentPartnerMethodQuotaById = async (id) => {
  try {
    return await axios.get(endpoints.payment.partnerMethodQuota + id)
  } catch (error) {
    return error.response
  }
}

export const putPaymentPartnerMethodQuota = async (id, paymentPartnerMethodQuotaData) => {
  try {
    const res = await axios.put(
      endpoints.payment.partnerMethodQuota + id,
      paymentPartnerMethodQuotaData
    )
    return res
  } catch (error) {
    return error.response
  }
}
