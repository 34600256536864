import { subDays } from 'date-fns'
import { nanoid } from 'nanoid'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { deleteAwsS3Files, getTicketById, patchUserTicket, uploadFileToS3 } from '../../../api'
import { useGlobalContext } from '../../../context'
import { handleObjChange } from '../../../functions/setter'
import useQuery from '../../../hooks/useQuery'
import { findArrayChanges } from '../../../functions/seprateOldNewFiles'
// import uploadFile from '../../../functions/uploadFile'
// import { postImage } from '../../../api/test'

const UpdateTicket = ({ updateTicketDialogVisible, setUpdateTicketDialogVisible }) => {
  const toast = useRef(null)
  const query = useQuery()
  const id = parseInt(query.get('id'))
  const history = useHistory()

  const { user, agents, teams } = useGlobalContext()
  const featureImage = useRef(null)
  const emptyTicketObject = {
    type: '',
    assignedUserId: '',
    assignToTeamId: '',
    createdBy: '',
    subject: '',
    description: '',
    expectedTAT: '',
    expectedCompletion: '',
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  const [loading, setLoading] = useState(false)
  const [recordError, setRecordError] = useState(emptyErr)
  const [record, setRecord] = useState(emptyTicketObject)
  const [attachmentsCopy, setAttachmentsCopy] = useState([])
  const [attachments, setAttachments] = useState([])
  const editDisabledHandler = (data) => {
    let disabled = false
    if (data.assignedUserId) {
      disabled = data.assignedUserId.includes(user.id)
    }
    if (data.assignToTeamId) {
      const abc = teams.filter((val) => val.teamID === data.assignToTeamId)
      if (!abc.length) {
        return (disabled = true)
      }
      abc[0].teamMembers.map((val) => {
        if (val.userId === user.id) {
          return (disabled = true)
        }
      })
    }
    return disabled
  }
  //* Function to fetch all details for Ticket using it's id
  const fetchAndUpdateTicketById = useCallback(async () => {
    if (!id) return
    const res = await getTicketById({ id, status: 'update' })
    if (res) {
      if (res.status === 404 || res.status === 400) {
        hideUpdateTicketDialog()
      }
      if (res.status === 200) {
        // Logic to disable the fields
        const disabled = editDisabledHandler(res.data.ticket)
        setRecord({ ...res.data.ticket, disabled })
        setAttachments(res.data.ticket.attachments)
        setAttachmentsCopy(res.data.ticket.attachments)
        setUpdateTicketDialogVisible(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchAndUpdateTicketById()
  }, [fetchAndUpdateTicketById])

  const validateNewRecord = () => {
    const errs = []
    const { description } = record
    if (!description) {
      errs.push('Please enter the description')
    }
    return errs
  }

  const patchTicket = async () => {
    const errs = validateNewRecord()
    if (errs.length) {
      setRecordError({ state: true, errors: errs })
      return
    }
    setLoading(true)

    const { newFiles, removedFiles } = findArrayChanges(attachmentsCopy, attachments)
    // Remove from S3
    const deleteFiles = removedFiles.map((item) => item.path)
    // Add from S3
    let arrayOfFiles = [...attachments]
    const addAwsS3Files = async () => {
      await Promise.all(
        newFiles.map(async (attachment, index) => {
          const res = await uploadFileToS3({
            file: attachment?.file,
          })
          // Add new files to unchanged files
          const uidIdx = arrayOfFiles.findIndex((item) => item.uid === attachment.uid)
          arrayOfFiles[uidIdx].path = res.data.path
        })
      )
    }
    // Do the actions in parallel
    await Promise.all([
      newFiles.length > 0 && (await addAwsS3Files()),
      removedFiles.length > 0 && (await deleteAwsS3Files(deleteFiles)),
    ])
    arrayOfFiles = arrayOfFiles.map((item) => ({
      uid: item.uid,
      name: item.name,
      path: item.path,
      // New additions based on orders reference
      createdAt: new Date().toISOString(),
      meta: {
        createdBy: user?.username,
      },
    }))
    const res = await patchUserTicket(record, id, arrayOfFiles)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        hideUpdateTicketDialog()
      }
    }
  }

  const handleFilesUpload = (e) => {
    if (!e.target.files.length) {
      return
    }
    const files = e.target.files
    const myFiles = []
    let largeFiles = 0
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (file.size < 1024 * 1024 * 5) {
        const path = URL.createObjectURL(file)
        myFiles.push({
          uid: nanoid(),
          name: file.name,
          file,
          path,
          new: true,
        })
      } else {
        largeFiles++
      }
    }
    if (largeFiles) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: `${largeFiles} files were not added as they were larger than 5MB`,
      })
    }
    setAttachments((ps) => [...ps, ...myFiles])
  }

  const removeFiles = (uid) => {
    let filValue = attachments.filter((ele) => ele.uid !== uid)
    setAttachments(filValue)
  }

  const hideUpdateTicketDialog = () => {
    setUpdateTicketDialogVisible(false)
    setRecord(emptyTicketObject)
    setLoading(false)
    setRecordError(emptyErr)
    setAttachmentsCopy([])
    setAttachments([])
    history.push({
      pathname: '/issues/ticket-management',
    })
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={
          <div className="p-text-center">
            <h4 style={{ color: '#4B8AF4' }}>Update Ticket</h4>
          </div>
        }
        closable={false}
        visible={updateTicketDialogVisible}
        style={{ width: '100vw', maxWidth: '750px' }}
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => hideUpdateTicketDialog()}
            />
            <Button
              loading={loading}
              label={loading ? 'Updating...' : 'Update'}
              className="p-button-submit"
              icon="pi pi-check"
              onClick={() => {
                patchTicket()
              }}
            />
          </div>
        }
      >
        <div className="p-grid" style={{ rowGap: '1rem' }}>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block" htmlFor="TicketType">
              Ticket Type <sup style={{ color: 'red' }}>*</sup>
            </label>
            <Dropdown
              disabled
              id="TicketType"
              options={[
                {
                  name: 'Technical',
                },
                {
                  name: 'Non Technical',
                },
              ]}
              optionLabel="name"
              optionValue="name"
              placeholder="Select Ticket Type"
              value={record.type}
              onChange={(e) => {
                handleObjChange(setRecord, 'type', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6 p-mb-0">
            <label className="p-d-block" htmlFor="AgentSelection">
              Assigned To <sup style={{ color: 'red' }}>*</sup>
            </label>
            <MultiSelect
              disabled
              filter
              filterPlaceholder="Search..."
              showClear
              maxSelectedLabels={1}
              id="AgentSelection"
              options={agents}
              optionLabel="username"
              optionValue="id"
              placeholder="Individuals"
              value={record.assignedUserId}
              onChange={(e) => {
                handleObjChange(setRecord, 'assignedUserId', e.target.value || [])
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6 p-mb-0">
            <label className="p-d-block" htmlFor="teamSelection">
              Assigned To <sup style={{ color: 'red' }}>*</sup>
            </label>
            <Dropdown
              disabled
              filter
              filterPlaceholder="Search..."
              id="teamSelection"
              options={teams}
              optionLabel="teamName"
              optionValue="teamID"
              placeholder="Team"
              showClear
              value={record.assignToTeamId}
              onChange={(e) => {
                handleObjChange(setRecord, 'assignToTeamId', e.target.value || '')
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block" htmlFor="subject">
              Subject <sup style={{ color: 'red' }}>*</sup>
            </label>
            <InputText
              disabled
              placeholder="Enter Subject"
              id="subject"
              value={record.subject}
              onChange={(e) => {
                handleObjChange(setRecord, 'subject', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block" htmlFor="description">
              Description <sup style={{ color: 'red' }}>*</sup>
            </label>
            <InputTextarea
              disabled={record.disabled}
              id="description"
              rows={5}
              cols={30}
              placeholder="Describe your problem here..."
              value={record.description}
              onChange={(e) => {
                handleObjChange(setRecord, 'description', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6 p-mb-0">
            <label className="p-d-block" htmlFor="expectedTAT">
              Expected TAT
            </label>
            <Calendar
              disabled={record.disabled}
              id="expectedTAT"
              placeholder="Close Date"
              showTime
              hourFormat="12"
              value={record.expectedTAT ? new Date(record?.expectedTAT) : ''}
              onChange={(e) => {
                handleObjChange(setRecord, 'expectedTAT', e.value || '')
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-6 p-mb-0 ">
            <label className="p-d-block">Attachment</label>
            <input
              type="file"
              className="p-hidden"
              accept="*"
              ref={featureImage}
              multiple
              onChange={handleFilesUpload}
            />
            <Button
              disabled={record.disabled}
              label="Choose Files"
              className="p-button-outlined"
              onClick={() => featureImage.current.click()}
            />
          </div>
          <div className="p-field p-fluid p-col-6 p-mb-0">
            <label className="p-d-block" htmlFor="expectedCompletion">
              Expected Completion
            </label>
            <Calendar
              disabled={record.createdBy === user.id}
              id="expectedCompletion"
              placeholder="Close Date"
              showTime
              hourFormat="12"
              minDate={subDays(new Date(), 0)}
              value={record?.expectedCompletion ? new Date(record?.expectedCompletion) : ''}
              onChange={(e) => {
                handleObjChange(setRecord, 'expectedCompletion', e.value || '')
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            {attachments?.map((item, idx) => {
              let { name, path } = item
              if (name.length > 40) {
                name = name.substring(0, 15) + '...' + name.substring(name.length - 20)
              }
              let myPath = process.env.REACT_APP_S3_BUCKET_URL + '/' + path
              if (item.new) {
                myPath = path
              }
              return (
                <div key={idx} className="relative p-grid p-formgrid p-mb-4">
                  <div className=" p-d-flex p-jc-center p-field p-col-8">
                    <a
                      className={`p-button p-button-outlined p-button-secondary`}
                      key={item.name}
                      href={myPath}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.new ? name + ' (Unsaved)' : name}
                    </a>
                  </div>
                  <div className=" p-d-flex p-jc-center p-field p-col-4">
                    <Button
                      disabled={record.disabled}
                      className="p-button-danger"
                      onClick={() => removeFiles(item.uid)}
                      label="Delete"
                    />
                  </div>
                </div>
              )
            })}
          </div>

          {recordError.state && (
            <>
              {recordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default UpdateTicket
