import axios from 'axios'
import endpoints from './endpoints'
import { endOfDay, startOfDay } from 'date-fns'

const categoryNames = async (params) => {
  try {
    const res = await axios.get(endpoints.category.names, { params })
    return res
  } catch (error) {
    return error.response
  }
}
const addCategory = async (data, image) => {
  try {
    let mydata = new FormData()
    mydata.append('data', JSON.stringify(data))
    mydata.append('ogCategoryImage', image)
    const res = await axios.post(endpoints.category.index, mydata)
    return res
  } catch (error) {
    return error.response
  }
}
const patchCategory = async (id, data, image) => {
  try {
    let mydata = new FormData()
    if (image) {
      mydata.append('image', image)
    }
    mydata.append('data', JSON.stringify(data))
    const res = await axios.patch(endpoints.category.index + id, mydata)
    return res
  } catch (error) {
    return error.response
  }
}
const getCategories = async ({ type, dateFrom = null, dateTo = null }) => {
  try {
    const res = await axios.get(endpoints.category.index, {
      params: {
        type,
        dateFrom: dateFrom ? startOfDay(dateFrom).toISOString() : null,
        dateTo: dateTo ? endOfDay(dateTo).toISOString() : null,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}
const deleteCategory = async (id, password) => {
  try {
    const data = JSON.stringify({ password })
    const res = await axios.delete(endpoints.category.index + id, { data })
    return res
  } catch (error) {
    return error.response
  }
}

const getCategory = async (slug) => {
  try {
    const res = await axios.get(endpoints.category.index + slug)
    return res
  } catch (error) {
    return error.response
  }
}
const getArrangeCategoryProducts = async (categoryId) => {
  try {
    const res = await axios.get(endpoints.category.arrange, {
      params: { categoryId },
    })
    return res
  } catch (error) {
    return error.response
  }
}

const postArrangeCategoryProducts = async (groupedProducts, categoryId) => {
  try {
    const res = await axios.post(endpoints.category.arrange, {
      groupedProducts,
      categoryId,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export {
  categoryNames,
  addCategory,
  getCategories,
  patchCategory,
  deleteCategory,
  getCategory,
  getArrangeCategoryProducts,
  postArrangeCategoryProducts,
}
