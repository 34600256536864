import React, { useCallback, useState, useEffect } from 'react'
import { getOrderProducts, patchProductStatus } from '../../api'
import { assignDates, dateFilters } from '../../functions/myDateFns'
import { undeliveredOrderStatusOptions } from '../../data/orderStatusValues'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useGlobalContext } from '../../context'
import { Toolbar } from 'primereact/toolbar'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import MyDatesPicker from '../../components/mini/MyDatesPicker'
import { handleObjChange, onChangeFilter } from '../../functions/setter'
import ProductResphimentInititateDialog from '../../components/mycomponents/UndeliveredProducts/ProductResphimentInititateDialog'
import { Dialog } from 'primereact/dialog'
import { Checkbox } from 'primereact/checkbox'
import copy from 'clipboard-copy'
import ProductRefundDialog from '../../components/mycomponents/UndeliveredProducts/ProductRefundDialog'
import { MultiSelect } from 'primereact/multiselect'
import CustomTooltip from '../../components/mycomponents/CustomTooltip'
import UndeliveredProductNoteDialog from '../../components/mycomponents/UndeliveredProducts/UndeliveredProductNoteDialog'
import { SplitButton } from 'primereact/splitbutton'
import { useNavigate } from 'react-router-dom'
import SendMailDialog from '../../components/mini/SendMailDialog'

// Used in multiple places, so beware while changing.
const resend_status = ['Reshipment-Initiated', 'Reshipment-Completed', 'Undelivered', 'Refunded']

// Resphipment-Initiated Dialog Specific
const emptyAddTrackingDetails = {
  quantity: 0,
  trackinglink: '',
  trackingid: '',
}
const emptyProductResphiment = {
  productName: null,
  orderid: null,
  productid: null,
  productData: {},
  loading: false,
  visible: false,
}

// Send notification dialog
const emptySetChangeStatus = {
  visible: false,
  loading: false,
  sendNotification: true,
  status: null,
  productid: null,
}

// Refund Dialog Specific
const emptyRefundDetails = {
  visible: false,
  loading: false,
  refundReason: null,
  productid: null,
  refundAmount: null,
}

export default function UndeliveredProductsPage() {
  const { user, toast, setSendEmailDialogVisible } = useGlobalContext()
  const history = useHistory()
  //? Commons
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.IN },
    createdById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedToId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    orderBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
    masterId: { value: null, matchMode: FilterMatchMode.IN },
  }
  const emptyErr = {
    state: false,
    errors: [],
  }
  const empty_sendEmail = {
    email: '',
    subject: '',
    body: '',
    relatedTo: 'order',
    refId: '',
  }

  //? Page states
  const [orderProducts, setOrderProducts] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)
  const [dateCode, setDateCode] = useState(dateFilters[1].code)
  const [dateRange, setDateRange] = useState(null)
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))
  const [filters, setFilters] = useState(emptyFilterObject)

  //? Change Status Dialog
  const [changeStatus, setChangeStatus] = useState(emptySetChangeStatus)

  //? Mail Dialog
  const [sendEmail, setSendEmail] = useState(empty_sendEmail)

  //? Common state(s)
  const [error, setError] = useState(emptyErr)

  //? Reshipment-Initiated Dialog Specific states
  const [addTrackingDetails, setAddTrackingDetails] = useState([{ ...emptyAddTrackingDetails }])
  const [productReshipment, setProductReshipment] = useState(emptyProductResphiment)
  const [media, setMedia] = useState([])
  const [productRefund, setProductRefund] = useState(emptyRefundDetails)

  //? Fetch Order Products
  const fetchOrderProducts = useCallback(async () => {
    setTableLoading(true)
    const res = await getOrderProducts({
      status: undeliveredOrderStatusOptions,
      ...dateFilter,
    })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        const data = res.data.orderProducts
        setOrderProducts(data)
      }
    }
  }, [dateFilter])

  useEffect(() => {
    fetchOrderProducts()
  }, [fetchOrderProducts])

  const handleUndeliveredAndResphimentCompletedStatuses = async (
    productId,
    status,
    sendNotification
  ) => {
    handleObjChange(setChangeStatus, 'loading', true)
    const res = await patchProductStatus(productId, status, sendNotification)
    if (res) {
      if (res?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Status Changed Successfully',
          life: 3000,
        })
        fetchOrderProducts()
        setChangeStatus(emptySetChangeStatus)
      }
    }
    handleObjChange(setChangeStatus, 'loading', false)
  }

  const orderStatusBodyTemplate = (rowData) => {
    return (
      <>
        <Dropdown
          disabled={user?.role === 'agent'}
          options={undeliveredOrderStatusOptions}
          style={{ border: 'none', width: '100%' }}
          className={`os-` + rowData?.status}
          value={rowData?.status}
          valueTemplate={(option) => {
            return (
              <div style={{ color: 'white' }}>
                <strong>{option}</strong>
              </div>
            )
          }}
          onChange={(e) => {
            if (e.value === 'Reshipment-Initiated') {
              setProductReshipment({
                ...productReshipment,
                visible: true,
                productData: [rowData],
                productid: rowData.id,
                orderid: rowData.orderid,
                productName: rowData.productName,
              })
            }
            if (e.value === 'Refunded') {
              setProductRefund((ps) => ({
                ...ps,
                visible: true,
                productid: rowData.id,
              }))
            }
            if (['Reshipment-Completed', 'Undelivered'].includes(e.value)) {
              setChangeStatus((ps) => ({
                ...ps,
                visible: true,
                status: e.value,
                productid: rowData.id,
              }))
            }
          }}
        />
      </>
    )
  }

  const leftToolbarTemplate = () => {
    return (
      <div className="p-d-flex gap-1 p-ai-center">
        <h5 className=" p-mb-0">Undelivered Products</h5>
        <Link to="/orders">
          <Button label="All Orders" icon="pi pi-external-link" />
        </Link>
      </div>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Refresh"
        icon="pi pi-refresh"
        onClick={() => fetchOrderProducts()}
        disabled={tableLoading}
      />
    )
  }

  const nameBodyTemplate = (rd) => {
    let name = rd.name.length > 15 ? rd.name.substring(0, 12) + '...' : rd.name
    return (
      <>
        <div className="p-d-flex p-ai-center gap-1by2">
          {name}
          {/* {rd.dndId ? <CustomTooltip data={rd} /> : ''} */}
        </div>
      </>
    )
  }

  // Could have sent it to the dialog component, but it would add additional props to the component
  const hideProductReshipmentDialog = () => {
    setError(emptyErr)
    setProductReshipment(emptyProductResphiment)
    setAddTrackingDetails([{ ...emptyAddTrackingDetails }])
  }

  const hideProductRefundDialog = () => {
    setProductRefund(emptyRefundDetails)
    setError(emptyErr)
    setMedia([])
  }

  const actionDetails = async (rd) => {
    const currentParams = new URLSearchParams(window.location.search)

    // Add or modify query parameters
    currentParams.set('orderId', rd.orderid)
    currentParams.set('productId', rd.id)
    // Construct the new URL with the updated query parameters
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`

    // Use history.push to update the URL and trigger navigation
    history.push(newUrl)
  }

  return (
    <div className="card">
      <Toast ref={toast} />
      <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
      <div className="p-col-12">
        <DataTable
          value={orderProducts}
          editMode="row"
          dataKey="id"
          showGridlines
          breakpoint="1000px"
          loading={tableLoading}
          filters={filters}
          responsiveLayout="stack"
          paginator
          rowHover={true}
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          className="datatable-responsive"
          header={
            <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
              <div className="p-d-flex gap-1">
                <div>
                  <MyDatesPicker
                    code={dateCode}
                    setCode={setDateCode}
                    rangeDates={dateRange}
                    setRangeDates={setDateRange}
                    filterDates={dateFilter}
                    setFilterDates={setDateFilter}
                  />
                </div>
                <div className="">
                  <MultiSelect
                    options={user?.members || []}
                    value={filters.masterId.value}
                    placeholder="Users"
                    filter
                    filterBy="username,role"
                    filterPlaceholder="Search"
                    optionLabel="username"
                    optionValue="id"
                    showClear
                    onChange={(e) => onChangeFilter(setFilters, e.target.value || [], 'masterId')}
                    maxSelectedLabels={3}
                  />
                </div>
                <div>
                  <MultiSelect
                    value={filters.status.value}
                    options={resend_status}
                    onChange={(e) => {
                      onChangeFilter(setFilters, e.target.value || [], 'status')
                    }}
                    placeholder="Product Status"
                    className=""
                    maxSelectedLabels={3}
                  />
                </div>
                <div>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      value={filters['global'] ? filters['global'].value : '' || ''}
                      onChange={(e) => onChangeFilter(setFilters, e.target.value, 'global')}
                      placeholder="Global Search"
                    />
                  </span>
                </div>
              </div>
            </div>
          }
        >
          <Column header="ID" field="id" />
          <Column
            header="Order ID"
            field="orderid"
            body={(rd) => {
              return (
                <Link to={`/orders?invId=${rd.orderid}`} target="_blank">
                  {rd.orderid}
                </Link>
              )
            }}
          />
          <Column style={{ width: '10%' }} field="name" header="Name" body={nameBodyTemplate} />
          <Column header="Order By" field="username" />
          <Column header="Product Name" field="productName" />
          <Column header="Strength" field="strength" />
          <Column header="No. Pills" field="quantity" />
          <Column header="Pill Price" field="quantityPrice" body={(rd) => `$${rd.quantityPrice}`} />
          <Column header="No. Qty" field="quantity" body={(rd) => `${rd.totalQuantity}`} />
          <Column header="Total Price" field="totalPrice" body={(rd) => `$${rd.totalPrice}`} />
          <Column
            header="Product Status"
            field="status"
            body={orderStatusBodyTemplate}
            style={{ width: '1%' }}
          />
          <Column header="SC" field="shippingCompany" />
          <Column
            header="Action"
            field="action"
            style={{ width: '1%' }}
            body={(rd) => {
              console.log(rd)
              const items = [
                {
                  label: 'Write Email',
                  icon: 'pi pi-envelope',
                  command: async () => {
                    setSendEmail((ps) => ({
                      ...ps,
                      refId: rd.orderid,
                      email: rd.email,
                      relatedTo: 'order',
                      subject: `Update regarding order ${rd.orderid}`,
                    }))
                    setSendEmailDialogVisible(true)
                  },
                },
              ]
              if (['superadmin'].includes(user.role) || user.accessToCopy === 1) {
                items.push({
                  label: 'Copy Email',
                  icon: 'pi pi-clone',
                  command: async () => {
                    copy(rd.email)
                    toast.current.show({
                      severity: 'success',
                      summary: 'Copy action',
                      detail: 'Email copied to clipboard',
                    })
                  },
                }),
                  items.push({
                    label: 'Copy Phone',
                    icon: 'pi pi-clone',
                    command: async () => {
                      copy(rd.phone)
                      toast.current.show({
                        severity: 'success',
                        summary: 'Copy action',
                        detail: 'Phone number copied to clipboard',
                      })
                    },
                  })
              }

              return (
                <div>
                  <div className="p-d-flex gap-1by2 p-ai-center">
                    <SplitButton onClick={() => actionDetails(rd)} label="Notes" model={items} />
                  </div>
                </div>
              )
            }}
          />
        </DataTable>
      </div>
      <ProductResphimentInititateDialog
        productReshipment={productReshipment}
        setProductReshipment={setProductReshipment}
        addTrackingDetails={addTrackingDetails}
        setAddTrackingDetails={setAddTrackingDetails}
        error={error}
        setError={setError}
        fetchOrderProducts={fetchOrderProducts}
        setTableLoading={setTableLoading}
        hideProductReshipmentDialog={hideProductReshipmentDialog}
      />

      <Dialog
        visible={changeStatus.visible}
        style={{ width: '450px' }}
        header="Status Change Confirmation"
        modal
        footer={
          <>
            <Button
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              disabled={changeStatus.loading}
              onClick={() => handleObjChange(setChangeStatus, 'visible', false)}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              loading={changeStatus.loading}
              onClick={() =>
                handleUndeliveredAndResphimentCompletedStatuses(
                  changeStatus.productid,
                  changeStatus.status,
                  changeStatus.sendNotification
                )
              }
              autoFocus
            />
          </>
        }
        onHide={() => handleObjChange(setChangeStatus, 'visible', false)}
      >
        <div className="confirmation-content  p-d-flex p-ai-center">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          <div>
            <span>
              Are you sure you want change status to {changeStatus?.status} for product (
              {changeStatus?.productid})?
            </span>

            {resend_status.includes(changeStatus?.status) ? (
              <div className="p-mt-3 p-d-flex gap-1 p-ai-center ">
                <Checkbox
                  inputId="changeStatusNotification"
                  checked={changeStatus.sendNotification}
                  onChange={(e) => handleObjChange(setChangeStatus, 'sendNotification', e.checked)}
                />
                <label htmlFor="changeStatusNotification">
                  Send notification to customer regrading this product progress
                </label>
              </div>
            ) : (
              <div className="p-mt-3">
                <label htmlFor="">This change wont send any notification to customer</label>
              </div>
            )}
          </div>
        </div>
      </Dialog>

      {/* Message Box Modal */}
      <SendMailDialog mailData={sendEmail} setMailData={setSendEmail} />
      <ProductRefundDialog
        productRefund={productRefund}
        setProductRefund={setProductRefund}
        error={error}
        setError={setError}
        fetchOrderProducts={fetchOrderProducts}
        media={media}
        setMedia={setMedia}
        hideProductRefundDialog={hideProductRefundDialog}
        setTableLoading={setTableLoading}
      />
      <UndeliveredProductNoteDialog
        pathname={'/issues/products/undelivered'}
        setTableLoading={setTableLoading}
      />
    </div>
  )
}
