import axios from 'axios'
import { endOfDay, format, startOfDay } from 'date-fns'
import endpoints from './endpoints'

export const getAllOrder = async ({
  dateFrom = null,
  dateTo = null,
  status = [],
  source,
  masterids = [],
}) => {
  try {
    const res = await axios.get(endpoints.order.index, {
      params: {
        dateFrom: dateFrom ? startOfDay(dateFrom).toISOString() : null,
        dateTo: dateTo ? endOfDay(dateTo).toISOString() : null,
        status,
        source,
        masterids,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getAssignOrders = async (params) => {
  try {
    let myParams = { ...params } || {}
    return await axios.get(endpoints.order.assign, { params: myParams })
  } catch (error) {
    return error.response
  }
}

export const assignOrdersTo = async (payload) => {
  try {
    return await axios.post(endpoints.order.assign, payload)
  } catch (error) {
    return error.response
  }
}

export const getOrder = async (orderId, view) => {
  try {
    const res = await axios.get(endpoints.order.index + orderId, {
      params: {
        view,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const syncOrder = async (orderId) => {
  try {
    const res = await axios.get(endpoints.order.sync + orderId)
    return res
  } catch (error) {
    return error.response
  }
}

export const patchOrderForFiles = async (orderId, flagType = '', files) => {
  try {
    const res = await axios.patch(endpoints.order.index + orderId, { flagType, files })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchOrderForPrescriptionDocuments = async (
  orderId,
  flagType = '',
  prescriptionDocuments
) => {
  try {
    const res = await axios.patch(endpoints.order.index + orderId, {
      flagType,
      prescriptionDocuments,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteOrder = async (orderId, password) => {
  try {
    var data = JSON.stringify({
      password,
    })
    const res = await axios.delete(endpoints.order.index + orderId, { data })
    return res
  } catch (error) {
    return error.response
  }
}

export const addManualOrder = async (payload) => {
  try {
    const res = await axios.post(endpoints.order.index, { ...payload })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchOrderProduct = async (orderProductId, payload) => {
  try {
    const res = await axios.patch(endpoints.order.product + orderProductId, {
      ...payload,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchOrderProducts = async (
  orderId,
  shipping,
  isDiscountPercent,
  discount,
  paymentDiscount,
  subtotal,
  total,
  profit,
  payload
) => {
  try {
    const res = await axios.patch(endpoints.order.products + orderId, {
      orderProductData: [...payload],
      shipping,
      isDiscountPercent,
      discount,
      paymentDiscount,
      subtotal,
      total,
      profit,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchStatus = async (
  orderId,
  status,
  sendNotification = false,
  note = '',
  orderProcessedRequestById,
  paymentPartnerMethodId,
  paymentPartner,
  gatewayName,
  clientName,
  paidAmount,
  additionalNote
) => {
  try {
    const res = await axios.patch(endpoints.order.status + orderId, {
      status,
      sendNotification,
      note,
      orderProcessedRequestById,
      paymentPartnerMethodId,
      paymentPartner,
      gatewayName,
      clientName,
      paidAmount,
      additionalNote,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchBulkOrderStatus = async (orderIds, status) => {
  try {
    const res = await axios.patch(endpoints.order.bulkOrderStatus, {
      orderIds,
      status,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchProductStatus = async (orderProductId, status, sendNotification) => {
  try {
    const res = await axios.patch(endpoints.order.productStatus + orderProductId, {
      status,
      sendNotification,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchOrderAddress = async (orderId, payload) => {
  try {
    const res = await axios.patch(endpoints.order.address + orderId, {
      ...payload,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getOrderHistory = async (orderId, { type }) => {
  try {
    const res = await axios.get(endpoints.order.history + orderId, {
      params: {
        type,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const updateOrderHistoryReason = async (historyId, reason) => {
  try {
    let date = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
    const res = await axios.patch(endpoints.order.history + historyId, {
      reason,
      date,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const sendOrderMail = async (email, subject, body, relatedTo, refId) => {
  try {
    const res = await axios.post(endpoints.order.contactMail, {
      email,
      subject,
      body,
      relatedTo,
      refId,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const sendOrderMessage = async (orderid, message) => {
  try {
    const res = await axios.post(endpoints.order.contactMessage + orderid, {
      message,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const resendOrderEmail = async (orderid, status) => {
  try {
    return await axios.post(endpoints.order.emailResend + orderid, { status })
  } catch (error) {
    return error.response
  }
}

export const getOrderNotes = async (orderid, productId, topic) => {
  try {
    return await axios.get(endpoints.order.note + orderid, {
      params: {
        productId,
        topic,
      },
    })
  } catch (error) {
    return error.response
  }
}

export const addOrderNote = async ({ orderId, note, productId, topic }) => {
  try {
    return await axios.post(endpoints.order.note + orderId, { note, productId, topic })
  } catch (error) {
    return error.response
  }
}

export const editOrderNote = async (id, note) => {
  try {
    return await axios.patch(endpoints.order.note + id, { note })
  } catch (error) {
    return error.response
  }
}

export const deleteOrderNote = async (id) => {
  try {
    return await axios.delete(endpoints.order.note + id)
  } catch (error) {
    return error.response
  }
}

export const getContactDetails = async (id) => {
  try {
    return await axios.get(endpoints.order.contact + id)
  } catch (error) {
    return error.response
  }
}

export const getContactsForFix = async () => {
  try {
    return await axios.get(endpoints.order.contactFix)
  } catch (error) {
    return error.response
  }
}

export const saveContactFix = async (id, phone, oldPhone) => {
  try {
    return await axios.patch(endpoints.order.contactFix, {
      id,
      phone,
      oldPhone,
    })
  } catch (error) {
    return error.response
  }
}

export const searchOrderByProduct = async (keyword) => {
  try {
    return await axios.get(endpoints.order.orderProductSearch + keyword)
  } catch (error) {
    return error.response
  }
}

export const postOrderIssue = async (mainIssue, subIssue, solutions) => {
  try {
    return await axios.post(endpoints.order.issue, {
      mainIssue,
      subIssue,
      solutions,
    })
  } catch (error) {
    return error.response
  }
}

export const getOrderIssue = async (id) => {
  try {
    return await axios.get(endpoints.order.issue + id)
  } catch (error) {
    return error.response
  }
}

export const getOrderIssues = async (limit) => {
  try {
    return await axios.get(endpoints.order.issue, {
      params: {
        limit,
      },
    })
  } catch (error) {
    return error.response
  }
}

export const patchOrderIssue = async (id, payload) => {
  try {
    return await axios.patch(endpoints.order.issue + id, payload)
  } catch (error) {
    return error.response
  }
}

export const deleteOrderIssue = async (id) => {
  try {
    return await axios.delete(endpoints.order.issue + id)
  } catch (error) {
    return error.response
  }
}

export const updateOrderProductShipmentDelays = async ({ id, trackingStatus }) => {
  try {
    return await axios.put(endpoints.order.orderProductShipmentDelaySwitch + id, {
      trackingStatus,
    })
  } catch (error) {
    return error.response
  }
}

export const postOrderFollowup = async ({
  orderId: id,
  productId,
  topic,
  followupMedium,
  note,
  status,
  estimatedTime,
  nextFollowupTime,
  clientFeedback,
}) => {
  try {
    return await axios.post(endpoints.order.followups + id, {
      productId,
      topic,
      followupMedium,
      note,
      status,
      estimatedTime,
      nextFollowupTime,
      clientFeedback,
    })
  } catch (error) {
    return error.response
  }
}

export const getOrderFollowup = async (orderId, productId, topic) => {
  try {
    return await axios.get(endpoints.order.followups + orderId, {
      params: {
        productId,
        topic,
      },
    })
  } catch (error) {
    return error.response
  }
}
export const checkoutShippingDetails = async (products) => {
  try {
    return await axios.post(endpoints.order.checkoutShipping, {
      products,
    })
  } catch (error) {
    return error.response
  }
}

export const patchSaleQualityScore = async (orderId, qualityValues, score) => {
  try {
    return await axios.patch(endpoints.order.qualityScore + orderId, {
      qualityValues,
      score,
    })
  } catch (error) {
    return error.response
  }
}

export const addPaymentLink = async (orderId, paymentLink, paymentMethod) => {
  try {
    return await axios.post(endpoints.order.payment + orderId, {
      paymentLink,
      paymentMethod,
    })
  } catch (error) {
    return error.response
  }
}

export const removePaymentLink = async (orderId) => {
  try {
    const res = await axios.delete(endpoints.order.payment + orderId)
    return res
  } catch (error) {
    return error.response
  }
}

export const resendPaymentLink = async (orderId) => {
  try {
    const res = await axios.put(endpoints.order.payment + orderId)
    return res
  } catch (error) {
    return error.response
  }
}

export const getRefileDate = async (email) => {
  try {
    return await axios.get(endpoints.order.refillDate + email)
  } catch (error) {
    return error.response
  }
}

export const patchOrderCompleted = async ({
  orderId,
  orderProductData,
  allDelivered,
  allCancelled,
}) => {
  try {
    return await axios.patch(endpoints.order.markCompleted + orderId, {
      productStatusAry: orderProductData,
      allDelivered,
      allCancelled,
    })
  } catch (error) {
    return error.response
  }
}

export const getOrderProducts = async ({ dateFrom = null, dateTo = null }) => {
  try {
    const res = await axios.get(endpoints.order.products, {
      params: {
        dateFrom: dateFrom ? startOfDay(dateFrom).toISOString() : null,
        dateTo: dateTo ? endOfDay(dateTo).toISOString() : null,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const postProductResphimentInitiate = async ({
  productId,
  trackingDetails,
  sendNotification,
}) => {
  try {
    return await axios.post(endpoints.order.productReshipmentInit + productId, {
      trackingDetails,
      sendNotification,
    })
  } catch (error) {
    return error.response
  }
}

export const postProductRefund = async ({ productId, payload, sendNotification }) => {
  try {
    return await axios.post(endpoints.order.productRefund + productId, {
      payload,
      sendNotification,
    })
  } catch (error) {
    return error.response
  }
}
