import React, { useCallback, useEffect, useState } from 'react'
import { getState } from '../../api/test'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import dateFormat from 'date-fns/format'
import { formatDistanceToNow } from 'date-fns'
import { format, formatInTimeZone, getTimezoneOffset, toZonedTime } from 'date-fns-tz'

export default function EmptyPage() {
  const [state, setState] = useState([])
  const fetchTimeZone = useCallback(async () => {
    const response = await getState()
    setState(response.data)
  }, [])

  useEffect(() => {
    fetchTimeZone()
  }, [])

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <h5>Empty Page</h5>
          <DataTable
            value={state}
            // header={openDataTableFiltersHeader}
            showGridlines
            dataKey="id"
            // paginator
            // rows={10}
            // rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
            // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          >
            <Column
              field="id"
              header="ID"
              sortable
              style={{
                width: '5%',
              }}
              body={(rd, idx) => {
                console.log(idx)
                return (
                  <InputText
                    value={idx.rowIndex + 1}
                    readOnly
                    style={{ background: 'transparent', border: 'none', outline: 'none' }}
                  />
                )
              }}
            />
            <Column
              field="timezone"
              header="Timezone"
              sortable
              style={{
                width: '5%',
              }}
            />
            <Column
              header="Today's Date"
              body={(rd) => {
                const date = new Date()
                return (
                  <InputText
                    value={dateFormat(date, 'Pp')}
                    readOnly
                    style={{ background: 'transparent', border: 'none', outline: 'none' }}
                  />
                )
              }}
            />
            <Column
              header="Elapsed Time"
              body={(rd) => {
                const date = new Date()
                let elapsedTime = formatDistanceToNow(date, { addSuffix: true })

                return (
                  <InputText
                    value={elapsedTime}
                    readOnly
                    style={{ background: 'transparent', border: 'none', outline: 'none' }}
                  />
                )
              }}
            />

            <Column
              header="UTC Time"
              body={(rd) => {
                const date = new Date()
                const utcDate = format(toZonedTime(date, 'UTC'), 'PPp')

                return (
                  <InputText
                    value={utcDate}
                    readOnly
                    style={{ background: 'transparent', border: 'none', outline: 'none' }}
                  />
                )
              }}
            />
            <Column
              header="Timezone Date"
              body={(rd) => {
                const date = new Date()
                let clientDate = formatInTimeZone(date, rd.timezone, 'PPp')
                return (
                  <InputText
                    value={clientDate}
                    readOnly
                    style={{ background: 'transparent', border: 'none', outline: 'none' }}
                  />
                )
              }}
            />
          </DataTable>
        </div>
      </div>
    </div>
  )
}
