import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import {
  getArrangeCategoryProducts,
  getCategory,
  getProducts,
  postArrangeCategoryProducts,
} from '../../api'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'jspdf-autotable'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import { FilterMatchMode } from 'primereact/api'
import { Link } from 'react-router-dom'
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { useGlobalContext } from '../../context'

export default function CategoryArangePage() {
  const { toast } = useGlobalContext()
  const { slug } = useParams()
  const dt = useRef(null)

  const [lastViewed, setLastViewed] = useState(null)
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState({})
  const [products, setProducts] = useState([])
  const [prodGroups, setProdGroups] = useState([])
  const [prodGroupName, setProdGroupName] = useState('')
  const [tableLoading, setTableLoading] = useState(false)

  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  }

  const [filters, setFilters] = useState(emptyFilterObject)

  const addGroup = (name) => {
    setProdGroups((ps) => [...ps, { name: name.trim(), prods: [] }])
    setProdGroupName('')
  }

  const fetchRecords = useCallback(async () => {
    setTableLoading(true)
    const res = await getCategory(slug)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setCategory(res.data[0])
        const categoryName = res.data[0].ogCategoryName
        const categoryId = res.data[0].ogCategoryId

        if (categoryName) {
          const [resProds, resProdGroups] = await Promise.all([
            await getProducts({ status: 'active', category: categoryName }),
            await getArrangeCategoryProducts(categoryId),
          ])

          if (resProds && resProds.status === 200) {
            setProducts(resProds.data)
          }
          if (resProdGroups && resProdGroups.status === 200) {
            setProdGroups(resProdGroups.data)
          }
        }
      }
    }
  }, [slug])
  useEffect(() => fetchRecords(), [fetchRecords])

  const columns = [
    { field: 'productName', header: 'Name' },
    { field: 'productIngredients', header: 'Ingredients' },
  ]

  const dynamicColumns = columns.map((col) => {
    return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} />
  })

  const handleGroupUpdates = async () => {
    setLoading(true)
    let groups = []
    prodGroups.forEach((group) => {
      group.value.forEach((prods, idx) => {
        groups.push({
          productCode: prods.productCode,
          prodRank: idx,
          prodGroup: group.name,
        })
      })
    })

    const res = await postArrangeCategoryProducts(groups, category.ogCategoryId)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Success',
          detail: res.data.message,
        })
      }
    }
  }
  const onChangeFilter = (value, target) => {
    setFilters((ps) => ({ ...ps, [target]: { ...ps[target], value } }))
  }
  const confirmDeleteGroup = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this group?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const idx = parseInt(event.target.value)
        const _prodGroups = [...prodGroups]
        _prodGroups.splice(idx, 1)
        setProdGroups(_prodGroups)
      },
    })
  }

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'operations']}>
      <div>
        <Toast ref={toast} />
        <ConfirmPopup />
        <Toolbar
          className="p-toolbar p-mb-3"
          left={
            <div className="p-d-flex gap-1 p-ai-center">
              <h5 className="p-m-0">Arrange {category?.ogCategoryName} Products</h5>
              <div className="p-d-flex gap-1by2 p-ai-center">
                <InputText
                  placeholder="Enter name of new group"
                  className="flex-1"
                  value={prodGroupName}
                  onChange={(e) => setProdGroupName(e.target.value)}
                />
                <Button
                  label="Add Group"
                  onClick={() => addGroup(prodGroupName)}
                  disabled={!prodGroupName}
                />
              </div>
            </div>
          }
          right={
            <div className="p-d-flex p-fuild p-ai-center p-flex-column p-flex-md-row gap-1">
              <Link to="/category">
                <Button
                  label="Category"
                  icon="pi pi-external-link"
                  className="p-button p-button-primary"
                />
              </Link>
              <Button icon="pi pi-refresh" onClick={() => fetchRecords()} />
            </div>
          }
        ></Toolbar>

        <div className="p-grid">
          <div className="p-col-12 p-lg-8">
            <div className="card">
              <h6 className="p-col-12 p-m-0">Groups and rank in this category</h6>
              <div className="p-grid">
                {prodGroups.map((item, idx) => (
                  <DataTable
                    key={item.name}
                    value={item.value}
                    showGridlines
                    dataKey="productCode"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                    className="datatable-responsive p-col-12 p-lg-6"
                    columnResizeMode="fit"
                    emptyMessage="No records found."
                    header={
                      <div className="p-d-flex p-jc-between p-ai-end">
                        <span>{item.name}</span>
                        <div className="p-d-flex gap-1by2">
                          <MultiSelect
                            options={products}
                            value={item.value}
                            optionLabel="productName"
                            filter
                            filterBy="productName,productIngredients,productCode"
                            filterPlaceholder="Search"
                            placeholder="Select Products"
                            onChange={(e) => {
                              const grp_prods = e.value
                              const _prodGroups = [...prodGroups]
                              _prodGroups[idx]['value'] = grp_prods
                              setProdGroups(_prodGroups)
                            }}
                            maxSelectedLabels={1}
                          />
                          <Button
                            icon="pi pi-trash"
                            value={idx}
                            className="p-button-danger"
                            onClick={confirmDeleteGroup}
                          />
                        </div>
                      </div>
                    }
                    rowHover
                    breakpoint="1000px"
                    responsiveLayout="stack"
                    removableSort
                    reorderableRows
                    loading={tableLoading}
                    onRowReorder={(e) => {
                      const _prodGroups = [...prodGroups]
                      _prodGroups[idx]['value'] = e.value
                      setProdGroups(_prodGroups)
                    }}
                  >
                    <Column
                      rowReorder
                      style={{ width: '0em' }}
                      body={<i className="p-datatable-reorderablerow-handle pi pi-bars"></i>}
                    />
                    <Column
                      header="Index"
                      body={(rd, column) => {
                        return column.rowIndex + 1 // Add 1 to get 1-based index
                      }}
                    />
                    {dynamicColumns}
                  </DataTable>
                ))}
              </div>
              <div className="p-col-12">
                <Button
                  disabled={loading}
                  loading={loading}
                  label={loading ? 'Submitting' : 'Submit'}
                  className="w-full"
                  icon="pi pi-save"
                  onClick={handleGroupUpdates}
                />
              </div>
            </div>
          </div>
          <div className="p-col-12 p-lg-4">
            <div className="card">
              <h6 className="p-m-0">All active products of this category</h6>
              <DataTable
                ref={dt}
                value={products}
                showGridlines
                selectionMode="single"
                selection={lastViewed}
                onSelectionChange={(e) => setLastViewed(e.value)}
                dataKey="productCode"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                className="datatable-responsive"
                columnResizeMode="fit"
                emptyMessage="No records found."
                filters={filters}
                header={
                  <div className="">
                    <span className="p-input-icon-left w-full">
                      <i className="pi pi-search" />
                      <InputText
                        className="w-full"
                        type="search"
                        value={filters.global.value}
                        onChange={(e) => {
                          onChangeFilter(e.target.value, 'global')
                        }}
                        placeholder="Search Records"
                      />
                    </span>
                  </div>
                }
                loading={tableLoading}
                rowHover
                breakpoint="1000px"
                responsiveLayout="stack"
                removableSort
              >
                <Column field="productName" header="Name" sortable={true} />
                <Column field="productIngredients" header="Ingredients" sortable={true} />
                <Column field="productType" hidden />
                <Column field="productCode" hidden />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </PageAllowedToRoles>
  )
}
