import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useCallback, useEffect, useState } from 'react'
import { handleObjChange } from '../../../../functions/setter'
import { Dropdown } from 'primereact/dropdown'
import { useGlobalContext } from '../../../../context'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import useQuery from '../../../../hooks/useQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  deleteAwsS3Files,
  getPaymentPartnerMethodById,
  putPaymentPartnerMethod,
  uploadFileToS3,
} from '../../../../api'
import { Checkbox } from 'primereact/checkbox'
import { MultiSelect } from 'primereact/multiselect'
import UploadImage from '../../../mini/UploadImage'
import { Message } from 'primereact/message'
import generateS3Path from '../../../../functions/generateS3Path'

const emptyErr = {
  state: false,
  errors: [],
}

const empty_ImageState = {
  url: '',
  file: null,
}

const emptyPaymentPartnerMethod = {
  name: '',
  active: false,
  paymentPartnerId: '',
  paymentMethodId: '',
  users: [],
  additionalData: [
    {
      name: '',
      value: '',
    },
  ],
  qrCodeImage: '',
}
export default function EditPaymentPartnerMethodDialog({
  paymentPartnerMethods,
  setPaymentPartnerMethods,
  setPaymentPartnerMethodsTableLoading,
  setActiveIndex,
  paymentPartners,
}) {
  //   const [newRecordError, setNewRecordError] = useState(emptyErr)
  const { paymentMethods, agents, toast } = useGlobalContext()
  const [visibleDialog, setVisibleDialog] = useState(false)
  const [qrCodeImage, setQrCodeImage] = useState(empty_ImageState)
  const [paymentPartnerMethod, setPaymentPartnerMethod] = useState(emptyPaymentPartnerMethod)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [loading, setLoading] = useState(false)
  const query = useQuery()
  const history = useHistory()
  const id = parseInt(query.get('partner_method_id'))
  // const [paymentPartnerMethodQrCodeImage, setPaymentPartnerMethodQrCodeImage] =
  //   useState(empty_ImageState)

  // fetch payment partner by id
  const fetchPaymentPartnerMethodById = useCallback(async () => {
    if (!id) return
    setPaymentPartnerMethodsTableLoading(true)
    const res = await getPaymentPartnerMethodById(id)
    if (res) {
      setPaymentPartnerMethodsTableLoading(false)
      if (res.status === 200) {
        let temp = { ...res.data }
        temp.users = res.data?.users?.map((item) => parseFloat(item))
        setPaymentPartnerMethod(temp)
        setVisibleDialog(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchPaymentPartnerMethodById()
  }, [fetchPaymentPartnerMethodById])

  const validatePaymentMethod = (payment) => {
    const err = []
    if (payment?.name === '') err.push('Name cannot be empty')
    if (payment?.paymentPartnerId === '') err.push('Payment Partner cannot be empty')
    if (payment?.paymentMethodId === '') err.push('Payment Method cannot be empty')
    payment?.additionalData?.forEach((detail) => {
      if (detail.name === '' || detail.value === '') {
        err.push('Additional details name and value cannot be empty')
      }
    })
    return err
  }

  const putPaymentPartnerMethodFn = async () => {
    try {
      if (!id) return
      const errs = validatePaymentMethod(paymentPartnerMethod)
      if (errs.length > 0) {
        setLoading(false)
        setNewRecordError({ state: true, errors: errs })
        return
      }
      setLoading(true)
      if (qrCodeImage.file) {
        await deleteAwsS3Files([paymentPartnerMethod.qrCodeImage])
        await uploadFileToS3({ file: qrCodeImage.file }).then(
          (res) => (paymentPartnerMethod.qrCodeImage = res.data.filePath)
        )
      }

      const res = await putPaymentPartnerMethod(id, paymentPartnerMethod)
      if (res) {
        setLoading(false)
        if (res.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.data.message,
          })
          const updatedData = paymentPartnerMethods.map((paymentPartnerMethod) =>
            paymentPartnerMethod.id === id ? res.data.record : paymentPartnerMethod
          )
          setPaymentPartnerMethods(updatedData)
          onHide()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onHide = () => {
    setVisibleDialog(false)
    setPaymentPartnerMethod(emptyPaymentPartnerMethod)
    setQrCodeImage(empty_ImageState)
    history.push({
      pathname: '/payment/partner-methods',
    })
    setActiveIndex(2)
  }

  const footer = () => {
    return (
      <div className="p-d-flex p-jc-end">
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => onHide()}
        />
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-primary"
          onClick={() => {
            putPaymentPartnerMethodFn()
          }}
          loading={loading}
          disabled={loading}
        />
      </div>
    )
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={`Edit Payment Partner Method #${id}`}
        visible={visibleDialog}
        style={{ width: '30vw' }}
        footer={footer}
        onHide={onHide}
      >
        <div className="p-grid p-formgrid p-ai-center p-col-12">
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="paymentPartnerId">Payment Partner</label>
            <Dropdown
              id="paymentPartnerId"
              placeholder="Select Payment Partner"
              optionLabel="name"
              optionValue="id"
              value={paymentPartnerMethod.paymentPartnerId}
              options={paymentPartners || []}
              onChange={(e) => {
                handleObjChange(setPaymentPartnerMethod, 'paymentPartnerId', e.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="paymentMethodId">Payment Method</label>
            <Dropdown
              id="paymentMethodId"
              placeholder="Select Payment Method"
              value={paymentPartnerMethod.paymentMethodId}
              optionLabel="appName"
              optionValue="id"
              options={paymentMethods}
              onChange={(e) => {
                handleObjChange(setPaymentPartnerMethod, 'paymentMethodId', e.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="users">Users</label>
            <MultiSelect
              id="users"
              placeholder="Select Users"
              optionLabel="username"
              optionValue="id"
              value={paymentPartnerMethod.users}
              options={agents}
              filter={'username,id'}
              onChange={(e) => handleObjChange(setPaymentPartnerMethod, 'users', e.value)}
            />
          </div>
          <div className="p-field p-fluid p-col-12">
            <p className="p-text-capitalize">
              Additional Details{' '}
              <span className="p-text-sm">(Note : this details will show on website)</span>
            </p>
          </div>
        </div>
        <div className="p-grid p-formgrid">
          {paymentPartnerMethod?.qrCodeImage ? (
            <div className="p-fluid p-field p-col-12">
              <label htmlFor="" className="p-col-fixed">
                Qr Code Image
              </label>
              <div className="card p-text-center">
                <img
                  className="w-full"
                  style={{ borderRadius: '10px 10px 10px 10px' }}
                  src={generateS3Path(paymentPartnerMethod.qrCodeImage)}
                  alt=""
                />
                <Button
                  label="Change Image"
                  icon="pi pi-pencil"
                  onClick={() => handleObjChange(setPaymentPartnerMethod, 'qrCodeImage', '')}
                />
              </div>
            </div>
          ) : (
            <div className="p-fluid p-field p-col-12">
              <label htmlFor="" className="p-col-fixed">
                Qr Code Image
              </label>
              <UploadImage imageState={qrCodeImage} setImageState={setQrCodeImage} />
            </div>
          )}
          <div className="p-col-12">
            {paymentPartnerMethod?.additionalData?.map((item, idx) => (
              <div className="p-field p-fluid" key={idx}>
                <div className="p-grid p-formgrid">
                  <div className="p-field p-fluid p-col-6">
                    <label htmlFor="name">Name</label>
                    <InputText
                      id="name"
                      placeholder="Enter entity name"
                      value={item.name}
                      onChange={(e) => {
                        let temp = { ...paymentPartnerMethod }
                        temp.additionalData[idx].name = e.target.value
                        setPaymentPartnerMethod(temp)
                      }}
                    />
                  </div>
                  <div className="p-field p-fluid p-col-6">
                    <label htmlFor="name">Value</label>
                    <InputText
                      id="name"
                      placeholder="Enter entity value"
                      value={item.value}
                      onChange={(e) => {
                        let temp = { ...paymentPartnerMethod }
                        temp.additionalData[idx].value = e.target.value
                        setPaymentPartnerMethod(temp)
                      }}
                    />
                  </div>
                  <div className="p-field p-fluid p-col-12 p-d-flex gap-1 p-jc-between p-ai-end">
                    <Button
                      icon="pi pi-trash"
                      className="p-button-danger flex-1"
                      disabled={paymentPartnerMethod.additionalData.length === 1}
                      onClick={() => {
                        let temp = [...paymentPartnerMethod.additionalData]
                        temp = temp.filter((item, i) => i !== idx)
                        setPaymentPartnerMethod({ ...paymentPartnerMethod, additionalData: temp })
                      }}
                    />
                    <Button
                      icon="pi pi-plus"
                      className="flex-1"
                      onClick={() => {
                        let temp = [...paymentPartnerMethod.additionalData]
                        temp.push({ name: '', value: '' })
                        setPaymentPartnerMethod({ ...paymentPartnerMethod, additionalData: temp })
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="p-field p-fluid p-col-6">
            <label
              className="p-d-none p-d-lg-inline"
              htmlFor="active"
              style={{ color: 'transparent' }}
            >
              Active
            </label>
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="active">
                Active :
              </label>
              <Checkbox
                id="active"
                checked={paymentPartnerMethod.active ? true : false}
                onChange={(e) => handleObjChange(setPaymentPartnerMethod, 'active', e.checked)}
              />
            </div>
          </div>
        </div>
        {newRecordError.state && (
          <>
            {newRecordError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setNewRecordError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </Dialog>
    </>
  )
}
