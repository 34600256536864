import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { use, useEffect, useState } from 'react'
import generateS3Path from '../../functions/generateS3Path'
import { getAvailablePaymentPartnerMethodQuotas } from '../../api/payment'
import { useGlobalContext } from '../../context'

export default function AvailablePaymentMethodsTable({ totalAmount }) {
  //   const [tableLoading, setTableLoading] = useState(false)
  const { user } = useGlobalContext()
  const [paymentPartnerMethodQuotas, setPaymentPartnerMethodQuotas] = useState([])
  const fetchPaymentPartnerMethodQuotas = async (totalAmount) => {
    // setTableLoading(true)
    const res = await getAvailablePaymentPartnerMethodQuotas(totalAmount)
    if (res) {
      //   setTableLoading(false)
      if (res.status === 200) {
        setPaymentPartnerMethodQuotas(res.data)
      }
    }
  }

  useEffect(() => {
    if (totalAmount) {
      fetchPaymentPartnerMethodQuotas(totalAmount)
    }
  }, [totalAmount])

  return (
    <div>
      <DataTable
        // loading={tableLoading}
        value={paymentPartnerMethodQuotas}
        className="p-datatable-sm"
        columnResizeMode="fit"
        responsiveLayout="stack"
        style={{ width: '100%' }}
        header={
          <div className="p-d-flex p-jc-between">
            <h6>Available Payment Partner Methods</h6>
          </div>
        }
        showGridlines
      >
        <Column
          header="ID"
          field="id"
          style={{
            width: '10%',
          }}
        />
        <Column
          style={{
            width: '2%',
          }}
          field="appImage"
          header="App"
          body={(rd) => {
            return (
              <div className="p-d-flex p-jc-center p-ai-center">
                <img
                  src={generateS3Path(rd.appImage)}
                  alt={rd.appName}
                  className="p-mr-2"
                  style={{
                    width: '30px',
                  }}
                />
              </div>
            )
          }}
        />
        <Column header="Name" field="appName" />
        <Column header="Partner Name" field="partnerName" />
        <Column
          header="Remaining Balance"
          body={(rd) => {
            return <span>${rd.remainingBalance}</span>
          }}
        />
      </DataTable>
    </div>
  )
}
