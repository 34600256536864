import React, { useCallback, useState, useEffect } from 'react'
import OrdersDataTable from '../../components/mycomponents/OrdersDataTable'
import { getAllOrder } from '../../api'
import { assignDates, dateFilters } from '../../functions/myDateFns'
import { useGlobalContext } from '../../context'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
const orderStatusOptions = [
  'Pending',
  'Processed',
  'Shipped',
  'Tracking',
  'Tracking-Live',
  'Undelivered',
  'Partial',
  'Draft',
  'Completed',
]
export default function MyTeamOrdersPage() {
  const { user } = useGlobalContext()
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[0].code))
  const [tableLoading, setTableLoading] = useState(false)
  const [source, setSource] = useState(null)
  const [orders, setOrders] = useState(null)
  const [ordersMeta, setOrdersMeta] = useState({})

  const getOrders = useCallback(async () => {
    if (!user || !user?.members || user?.members?.length === 0) return
    const masterids = user.members.map((m) => m.id)

    setTableLoading(true)
    const res = await getAllOrder({ ...dateFilter, source, masterids, status: orderStatusOptions })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        const data = res.data.orders
        setOrders(data)
        setOrdersMeta((ps) => ({
          ...ps,
          totalSales: res.data.totalSales,
          totalOrders: res.data.totalOrders,
          paymentMethods: res.data.paymentMethods,
          paymentPartners: res.data.paymentPartners,
        }))
      }
    }
  }, [dateFilter, source, user])

  useEffect(() => {
    getOrders()
  }, [getOrders])
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'teamlead']}>
      <OrdersDataTable
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        source={source}
        ordersMeta={ordersMeta}
        setSource={setSource}
        orders={orders}
        setOrders={setOrders}
        pageUrl={'/orders/my-team'}
        getOrders={getOrders}
        tableLoading={tableLoading}
        setTableLoading={setTableLoading}
        orderStatusOptions={orderStatusOptions}
      />
    </PageAllowedToRoles>
  )
}
