import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { TabMenu } from 'primereact/tabmenu'
import { Knob } from 'primereact/knob'
import {
  getCustomerEnquireFeedbacks,
  getCustomerEnquires,
  getCustomerEnquiry,
  patchCustomerEnquire,
} from '../../api'

import dateFormat from 'date-fns/format'
import { Link, useHistory, useLocation } from 'react-router-dom'
import HideDetails from '../../components/mini/HideDetails'
import ExportButton from '../../components/mini/ExportButton'
import { assignDates, dateFilters, dtFilenameDate } from '../../functions/myDateFns'
import MyDatesPicker from '../../components/mini/MyDatesPicker'
import { FilterMatchMode } from 'primereact/api'

import { getEnquiryAnalysis } from '../../api/customer'
import { TabPanel, TabView } from 'primereact/tabview'
import capitalizeFirstLetter from '../../functions/capitalizeFirstLetter'
import { format, isValid } from 'date-fns'
import { CustomerCaseViewDialog } from '../../components/mycomponents/CustomerCases/CustomerCaseViewDialog'
import { CustomerCaseEditDialog } from '../../components/mycomponents/CustomerCases/CustomerCaseEditDialog'
import { onChangeFilter } from '../../functions/setter'
import { filter } from 'lodash'
import { Divider } from 'primereact/divider'
import { MultiSelect } from 'primereact/multiselect'
import { useGlobalContext } from '../../context'

export default function CustomerEnquiryPage() {
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    web: { value: null, matchMode: FilterMatchMode.CONTAINS },
    page: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    acceptibility: { value: null, matchMode: FilterMatchMode.EQUAL },
    catelogue: { value: null, matchMode: FilterMatchMode.EQUAL },
  }

  const acceptabilityOptions = [
    {
      label: 'Eligible',
      value: 'Eligible',
    },
    {
      label: 'Ineligible',
      value: 'Ineligible',
    },
  ]
  // const acceptabilityOptions = [
  //   {
  //     label: 'Eligible Options',
  //     code: 'EligibleOptions',
  //     items: [
  //       'Sale',
  //       'No Response',
  //       'Not Interested',
  //       'Price High',
  //       'Do Not Call',
  //       'Already Ordered',
  //       'Shipping time Problem',
  //       'Client Bad Experience',
  //     ],
  //   },
  //   {
  //     label: 'Ineligible Options',
  //     code: 'IneligibleOptions',
  //     items: ['Product out of stock', 'Not Serviceable', 'Others'],
  //   },
  // ]

  const itemsOptions = [
    { label: 'Dashboard', type: 'dash' },
    { label: 'Agents', type: 'agents' },
    { label: 'Products', type: 'product' },
    { label: 'Categories', type: 'category' },
    { label: 'Regions', type: 'region' },
    { label: `Age's`, type: 'age' },
    { label: 'Lead Types', type: 'leadType' },
    { label: 'Teams', type: 'teams' },
    { label: 'Dispositions', type: 'disposal' },
  ]
  const catalougeOptions = ['Selco', 'Supreme']
  const nlcatalougeOptions = ['United Wellness']
  const { user, agents } = useGlobalContext()
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = searchParams.get('id')
  const edit = searchParams.get('edit')
  const dt = useRef(null)
  const toast = useRef(null)
  const [enquires, setEnquires] = useState([])
  const [organicCatalogueOptions, setOrganicCatalogueOptions] = useState([])
  const [pageOptions, setPageOptions] = useState([])

  const [filters, setFilters] = useState(emptyFilterObject)
  const [filters2, setFilters2] = useState(emptyFilterObject)

  const [tableLoading, setTableLoading] = useState(false)

  const [advancedFilterValue, setAdvancedFilteredValue] = useState({
    label: 'Agents',
    type: 'agents',
  })

  const [dateCode, setDateCode] = useState(dateFilters[1].code)
  const [dateCode2, setDateCode2] = useState(dateFilters[1].code)
  const [dateRange, setDateRange] = useState(null)
  const [dateRange2, setDateRange2] = useState(null)
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))
  const [dateFilter2, setDateFilter2] = useState(assignDates(dateFilters[1].code))
  const [editable, setEditable] = useState(false)
  const [analysisData, setAnalysisData] = useState(0)
  const [activeIndex, setActiveIndex] = useState(1)
  const [selectedAgents, setSelectedAgents] = useState([])

  const enquiryData_empty = {
    enquiry: {},
    feedbacks: [],
    visible: false,
  }
  const [enquiryData, setEnquiryData] = useState(enquiryData_empty)

  const fetchEnquiry = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getCustomerEnquiry(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 404) {
        history.push({
          pathname: '/customers/enquiries',
        })
      }
      if (res.status === 200) {
        let modalToggle = true
        const feedbackRes = await getCustomerEnquireFeedbacks(id)
        let feedbacks = []
        if (feedbackRes && feedbackRes.status === 200) {
          feedbacks = feedbackRes.data
        }

        setEnquiryData((ps) => ({
          ...ps,
          feedbacks,
        }))
        if (edit && ['superadmin', 'admin', 'manager', 'operations'].includes(user.role)) {
          modalToggle = false
          setEditable(true)
        } else {
          setEditable(false)
        }
        const enquiry = res.data.enquiry
        enquiry.checks = [
          enquiry?.firstResponse ? 'First Response' : '',
          enquiry?.followups ? 'Followups' : '',
          enquiry?.notes ? 'Notes' : '',
          enquiry?.clientResponse ? 'Client Response' : '',
          enquiry?.goodConvowithClient ? 'Good Convo With Client' : '',
        ]
          .filter((i) => i)
          .join(', ')
        setEnquiryData((ps) => ({
          ...ps,
          enquiry: enquiry,
          visible: modalToggle,
        }))
      }
    }
  }, [id, search])
  useEffect(() => fetchEnquiry(), [fetchEnquiry, search])

  const fetchData = useCallback(async () => {
    setTableLoading(true)
    const res = await getCustomerEnquires({
      dateFrom: dateFilter.dateFrom,
      dateTo: dateFilter.dateTo,
    })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setEnquires(res?.data?.data)
        setOrganicCatalogueOptions(res?.data?.source)
        setPageOptions(res?.data?.page)
      } else {
        setEnquires([])
        toast.current.show({
          severity: 'error',
          summary: 'Error Fetching',
          detail: res.data.error,
        })
      }
    }
  }, [dateFilter.dateFrom, dateFilter.dateTo])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const fetchAnalysisData = useCallback(async () => {
    const { type } = advancedFilterValue
    setTableLoading(true)
    const res = await getEnquiryAnalysis({
      type,
      dateFrom: dateFilter2.dateFrom,
      dateTo: dateFilter2.dateTo,
      selectedAgents,
    })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setAnalysisData(res.data.analysis)
      }
    }
  }, [dateFilter2.dateFrom, dateFilter2.dateTo, selectedAgents, advancedFilterValue])

  useEffect(() => {
    fetchAnalysisData(advancedFilterValue)
  }, [fetchAnalysisData])
  // function for updating customer enquiry
  const updateCustomerEnquiry = async () => {
    setTableLoading(true)
    const res = await patchCustomerEnquire(id, enquiryData.enquiry)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setEnquires((preV) =>
          preV.map((item) => {
            return item.id == id ? res.data.enquiry : item
          })
        )
        hideEditableDialog()
      }
    }
  }

  const hideEnquiry = () => {
    history.push({
      pathname: '/customers/enquiries',
    })
    setEnquiryData(enquiryData_empty)
  }

  const organicHeader = (
    <div className="p-d-flex p-jc-end gap-1">
      <div>
        <span className="p-text-bold p-mr-1">Catalogue</span>
        <Dropdown
          value={filters.web.value}
          placeholder="Catalogue"
          options={organicCatalogueOptions?.filter((opt) => opt.web !== 'Indiamart')}
          showClear
          filterBy="web"
          optionLabel="web"
          optionValue="web"
          onChange={(e) => {
            onChangeFilter(setFilters, e.value, 'web')
          }}
        />
      </div>
      {/* <div>
        <span className="p-text-bold p-mr-1">Source</span>
        <Dropdown
          value={filters.web.value}
          placeholder="Source"
          options={organicCatalogueOptions?.filter((opt) => opt.web !== 'Indiamart')}
          filter
          filterBy="web"
          optionLabel="web"
          optionValue="web"
          showClear
          onChange={(e) => {
            onChangeFilter(setFilters, e.value, 'web')
          }}
        />
      </div> */}
      <div>
        <span className="p-text-bold p-mr-1">Page</span>
        <Dropdown
          value={filters.page.value}
          placeholder="Page"
          options={pageOptions}
          filter
          filterBy="page"
          optionLabel="page"
          optionValue="page"
          showClear
          onChange={(e) => {
            onChangeFilter(setFilters, e.value, 'page')
          }}
        />
      </div>
      <div>
        <span className="p-text-bold p-mr-1">Acceptability</span>
        <Dropdown
          value={filters.acceptibility.value}
          placeholder="Acceptability"
          options={acceptabilityOptions}
          optionLabel="label"
          optionValue="value"
          showClear
          onChange={(e) => {
            if (e.value === 'All') e.value = null
            onChangeFilter(setFilters, e.value, 'acceptibility')
          }}
        />
      </div>

      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={filters.global.value}
          onChange={(e) => {
            // setGlobalFilterValue(e.target.value)
            onChangeFilter(setFilters, e.target.value, 'global')
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  )
  const indiaMartHeader = (
    <div className="p-d-flex p-jc-end gap-1">
      <div>
        <span className="p-text-bold p-mr-1">Catalogue</span>
        <Dropdown
          value={filters2.page.value}
          placeholder="Catalogue"
          options={user?.globalp_name === 'Selco Pharma' ? catalougeOptions : nlcatalougeOptions}
          showClear
          onChange={(e) => {
            onChangeFilter(setFilters2, e.value, 'page')
          }}
        />
      </div>
      {/* <div>
        <span className="p-text-bold p-mr-1">Page</span>
        <Dropdown
          value={filters2.page.value}
          placeholder="Page"
          options={pageOptions}
          filter
          filterBy="page"
          optionLabel="page"
          optionValue="page"
          showClear
          onChange={(e) => {
            onChangeFilter(setFilters2, e.value, 'page')
          }}
        />
      </div> */}
      <div>
        <span className="p-text-bold p-mr-1">Acceptability</span>
        <Dropdown
          value={filters2.acceptibility.value}
          placeholder="Acceptability"
          options={acceptabilityOptions}
          optionLabel="label"
          optionValue="value"
          showClear
          onChange={(e) => {
            if (e.value === 'All') e.value = null
            onChangeFilter(setFilters2, e.value, 'acceptibility')
          }}
        />
      </div>

      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          value={filters2.global.value}
          onChange={(e) => {
            onChangeFilter(setFilters2, e.target.value, 'global')
          }}
          placeholder="Search..."
        />
      </span>
    </div>
  )
  const leftToolbarTemplate = () => {
    return (
      <div>
        <h4 className="p-my-0">Customer Enquiries</h4>
      </div>
    )
  }
  const rightToolbarTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center gap-1">
        <div className="">
          <MyDatesPicker
            code={dateCode}
            setCode={setDateCode}
            rangeDates={dateRange}
            setRangeDates={setDateRange}
            filterDates={dateFilter}
            setFilterDates={setDateFilter}
          />
        </div>
        <ExportButton datatable_ref={dt} />
        <Button label="Refresh" icon="pi pi-refresh" className="p-my-auto" onClick={fetchData} />
      </div>
    )
  }

  // action body template
  const actionBody = (rd) => {
    return (
      <div className="actions p-d-flex p-ai-center p-jc-center gap-1by2">
        <Link to={`?id=${rd?.id}`}>
          <Button icon="pi pi-eye" />
        </Link>
        {['superadmin', 'admin', 'manager', 'operations'].includes(user.role) && (
          <Link to={`?id=${rd?.id}&edit=true`}>
            <Button className="p-button-info" icon="pi pi-pencil" />
          </Link>
        )}
      </div>
    )
  }

  // function to hide editable modal
  const hideEditableDialog = () => {
    history.push({
      pathname: '/customers/enquiries',
    })
    setEditable(false)
  }

  const customizedMarker = () => {
    return (
      <span
        className="custom-marker shadow-1"
        style={{ backgroundColor: 'rgba(68, 72, 109, 0.07)' }}
      >
        <i className="pi pi-comment"></i>
      </span>
    )
  }

  const customizedContent = (item) => {
    return (
      <>
        <h6 className="p-mb-0 p-text-bold">{capitalizeFirstLetter(item?.feedback)}</h6>

        <div>
          <p className="p-mb-0">
            <span>Added By : </span>
            <label className="p-text-lowercase">{item?.createdBy}</label>
          </p>
          <span>
            {isValid(new Date(item?.createdAt)) && format(new Date(item?.createdAt), 'PPp')}
          </span>
        </div>
      </>
    )
  }

  const MyInputTextDisplay = ({ value, maxWidth = '70px' }) => {
    return (
      <InputText
        tooltip={value}
        value={value}
        readOnly
        className="p-text-truncate p-p-0"
        style={{
          background: 'none',
          border: 0,
          maxWidth: maxWidth,
        }}
        tooltipOptions={{
          position: 'bottom',
        }}
      />
    )
  }

  return (
    <div className="card">
      <Toast ref={toast} />
      <TabView>
        <TabPanel header="Enquiries">
          <div className="card">
            <Toolbar
              className="p-toolbar p-mb-2"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            />
            <TabView>
              <TabPanel header="Organic">
                <DataTable
                  exportFilename={`Customer_Enquiries_${dtFilenameDate}`}
                  ref={dt}
                  value={enquires?.filter((enquiry) => enquiry.web !== 'Indiamart')}
                  showGridlines
                  filters={filters}
                  dataKey="id"
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                  className="datatable-responsive"
                  columnResizeMode="fit"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} enquires"
                  emptyMessage="No enquire found."
                  header={organicHeader}
                  loading={tableLoading}
                  rowHover
                  breakpoint="1000px"
                  responsiveLayout="stack"
                >
                  <Column field="id" header="Id" sortable style={{ width: '1%' }} />
                  <Column
                    field="date"
                    header="Date"
                    body={(rowData) => dateFormat(new Date(rowData.date), 'Pp')}
                    sortable
                    style={{ width: '1%' }}
                  />
                  <Column
                    field="orderId"
                    header="Order Id"
                    body={(rd) => {
                      return rd.orderId === 'NA' ? (
                        rd.orderId
                      ) : (
                        <Link to={`/orders?invId=${rd.orderId}`}>{rd.orderId}</Link>
                      )
                    }}
                    sortable
                    style={{ width: '1%' }}
                  />
                  {/* 
                  <Column
                    field="web"
                    header="Source"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => <MyInputTextDisplay value={rd.web} />}
                  /> */}
                  <Column
                    field="web"
                    header="Catalogue"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => <MyInputTextDisplay value={rd.web} />}
                  />

                  <Column
                    field="page"
                    header="Page"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => <MyInputTextDisplay value={rd.page} />}
                  />
                  <Column field="name" header="Name" sortable style={{ width: '1%' }} />
                  <Column
                    field="email"
                    header="Email"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => (
                      <HideDetails
                        id={'email'}
                        value={rd.email}
                        style={{ background: 'none', border: 0, maxWidth: '100px' }}
                        className={'p-text-truncate'}
                      />
                    )}
                  />
                  <Column
                    field="phone"
                    header="Phone"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => (
                      <HideDetails
                        value={rd.phone}
                        style={{ background: 'none', border: 0, maxWidth: '100px' }}
                        className={'p-text-truncate'}
                      />
                    )}
                  />
                  <Column
                    field="message"
                    header="Message"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => <MyInputTextDisplay value={rd.message} maxWidth={'200px'} />}
                  />
                  <Column
                    field="username"
                    header="Agent Name"
                    style={{ width: '1%' }}
                    body={(rd) => <p>{rd.username ? rd.username : 'NA'}</p>}
                  />
                  <Column header="Action" body={actionBody} style={{ width: '1%' }} />
                </DataTable>
              </TabPanel>
              <TabPanel header="Indiamart">
                <DataTable
                  exportFilename={`Customer_Enquires_${dtFilenameDate}`}
                  ref={dt}
                  value={enquires?.filter((enquiry) => enquiry.web === 'Indiamart')}
                  showGridlines
                  filters={filters2}
                  dataKey="id"
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                  className="datatable-responsive"
                  columnResizeMode="fit"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} enquires"
                  emptyMessage="No enquire found."
                  header={indiaMartHeader}
                  loading={tableLoading}
                  rowHover
                  breakpoint="1000px"
                  responsiveLayout="stack"
                >
                  <Column field="id" header="Id" sortable style={{ width: '1%' }} />
                  <Column
                    field="date"
                    header="Date"
                    body={(rowData) => dateFormat(new Date(rowData.date), 'Pp')}
                    sortable
                    style={{ width: '1%' }}
                  />
                  <Column
                    field="orderId"
                    header="Order Id"
                    body={(rd) => {
                      return rd.orderId === 'NA' ? (
                        rd.orderId
                      ) : (
                        <Link to={`/orders?invId=${rd.orderId}`}>{rd.orderId}</Link>
                      )
                    }}
                    sortable
                    style={{ width: '1%' }}
                  />

                  {/* <Column
                    field="web"
                    header="Source"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => <MyInputTextDisplay value={rd.web} />}
                  /> */}
                  <Column
                    field="page"
                    header="Catalogue"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => <MyInputTextDisplay value={rd.page} />}
                  />
                  {/* <Column
                    field="page"
                    header="Page"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => <MyInputTextDisplay value={rd.page} />}
                  /> */}
                  <Column field="name" header="Name" sortable style={{ width: '1%' }} />
                  <Column
                    field="email"
                    header="Email"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => (
                      <HideDetails
                        id={'email'}
                        value={rd.email}
                        style={{ background: 'none', border: 0, maxWidth: '100px' }}
                        className={'p-text-truncate'}
                      />
                    )}
                  />
                  <Column
                    field="phone"
                    header="Phone"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => (
                      <HideDetails
                        value={rd.phone}
                        style={{ background: 'none', border: 0, maxWidth: '100px' }}
                        className={'p-text-truncate'}
                      />
                    )}
                  />
                  <Column
                    field="message"
                    header="Message"
                    sortable
                    style={{ width: '1%' }}
                    body={(rd) => <MyInputTextDisplay value={rd.message} maxWidth={'200px'} />}
                  />
                  <Column
                    field="username"
                    header="Agent Name"
                    style={{ width: '1%' }}
                    body={(rd) => <p>{rd.username ? rd.username : 'NA'}</p>}
                  />
                  <Column header="Action" body={actionBody} style={{ width: '1%' }} />
                </DataTable>
              </TabPanel>
            </TabView>
          </div>
        </TabPanel>
        <TabPanel header="Enquiry Analysis">
          <div className="card">
            <Toolbar
              left={() => {
                return (
                  <div>
                    <h4 className="p-my-0">Enquiries Analysis</h4>
                  </div>
                )
              }}
              right={() => {
                return (
                  <div className="p-d-flex p-ai-center gap-1">
                    <MyDatesPicker
                      code={dateCode2}
                      setCode={setDateCode2}
                      rangeDates={dateRange2}
                      setRangeDates={setDateRange2}
                      filterDates={dateFilter2}
                      setFilterDates={setDateFilter2}
                    />
                    <div>
                      <Button
                        label="Refresh"
                        icon="pi pi-refresh"
                        className="p-my-auto"
                        onClick={fetchAnalysisData}
                      />
                    </div>
                  </div>
                )
              }}
            />
            <div className="card p-mt-3">
              <TabMenu
                className="w-full"
                model={itemsOptions}
                activeIndex={activeIndex}
                onTabChange={(e) => {
                  setAnalysisData([])
                  setActiveIndex(e.index)
                  setAdvancedFilteredValue(e.value)
                }}
              />
            </div>
            {advancedFilterValue.type === 'dash' && (
              <div className="p-d-flex p-jc-end">
                <MultiSelect
                  optionValue="id"
                  optionLabel="username"
                  placeholder="Select Agent(s)"
                  value={selectedAgents}
                  onChange={(e) => setSelectedAgents(e.value || [])}
                  options={agents}
                  filter
                  filterBy="username"
                  maxSelectedLabels={2}
                />
              </div>
            )}
            {advancedFilterValue.type === 'dash' ? (
              <div className="p-d-flex p-jc-center p-text-center gap-2">
                <div className="">
                  <Knob
                    value={
                      analysisData && analysisData.length >= 1
                        ? Number(analysisData[0]?.averageEnquiryScore)
                        : 0
                    }
                    min={0}
                    max={5}
                    size={250}
                  />
                  <h3 className="p-m-0">Enquiry Score</h3>
                </div>
              </div>
            ) : (
              <DataTable
                value={analysisData}
                loading={tableLoading}
                showGridlines
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                className="datatable-responsive"
                columnResizeMode="fit"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} enquiries"
                emptyMessage="No records found."
                rowHover
                breakpoint="1000px"
                responsiveLayout="stack"
              >
                <Column style={{ width: '20%' }} field="name" header={advancedFilterValue.label} />
                <Column style={{ width: '20%' }} field="enquiry" header="Enquiry" />
                <Column style={{ width: '20%' }} field="qualityScore" header="Quality Score" />
                <Column style={{ width: '20%' }} field="sale" header="Sale" />
                <Column style={{ width: '20%' }} field="conversionRate" header="Conversion %" />
              </DataTable>
            )}
          </div>
        </TabPanel>
      </TabView>
      {/* view Dialog  */}
      <CustomerCaseViewDialog
        id={id}
        enquiryData={enquiryData}
        hideEnquiry={hideEnquiry}
        customizedMarker={customizedMarker}
        customizedContent={customizedContent}
      />
      {/* edit Dialog  */}
      <CustomerCaseEditDialog
        id={id}
        enquiryData={enquiryData}
        customizedMarker={customizedMarker}
        customizedContent={customizedContent}
        editable={editable}
        hideEditableDialog={hideEditableDialog}
        updateCustomerEnquiry={updateCustomerEnquiry}
        setEnquiryData={setEnquiryData}
        acceptabilityOptions={acceptabilityOptions}
        catalougeOptions={organicCatalogueOptions}
        toast={toast}
      />
    </div>
  )
}
