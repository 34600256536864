import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useQuery from '../../../../hooks/useQuery'
import {
  deleteAwsS3Files,
  getPaymentPartnerById,
  putPaymentPartner,
  uploadFileToS3,
} from '../../../../api'
import { InputText } from 'primereact/inputtext'
import { handleObjChange } from '../../../../functions/setter'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { nanoid } from 'nanoid'
import { useGlobalContext } from '../../../../context'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { data } from 'autoprefixer'
import { format } from 'date-fns'
import { Checkbox } from 'primereact/checkbox'
import { findArrayChanges } from '../../../../functions/seprateOldNewFiles'
import UploadImage from '../../../mini/UploadImage'
import generateS3Path from '../../../../functions/generateS3Path'
import { Message } from 'primereact/message'
import ReactQuill from 'react-quill'
const empty_ImageState = {
  url: '',
  file: null,
}

const emptyErr = {
  state: false,
  errors: [],
}
const emptyPaymentPartner = {
  name: '',
  phone: '',
  email: '',
  address: '',
  photo: '',
  active: 0,
  dob: '',
  ssn: '', // social security number
  documents: [], // temp omit
  notes: [], // temp omit
  additionalDetails: '',
}
export default function EditPaymentPartnerDialog({
  setActiveIndex,
  setPaymentPartnerTableLoading,
  setPaymentPartners,
}) {
  const { toast, user } = useGlobalContext()
  const documentRef = useRef(null)
  const [attachments, setAttachments] = useState([])
  const [visibleDialog, setVisibleDialog] = useState(false)
  const [paymentPartner, setPaymentPartner] = useState(emptyPaymentPartner)
  const [loading, setLoading] = useState(false)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const query = useQuery()
  const history = useHistory()
  const id = parseInt(query.get('pid'))
  const [attachmentsCopy, setAttachmentsCopy] = useState([])
  const [photo, setPhoto] = useState(empty_ImageState)

  // fetch payment partner by id
  const fetchPaymentPartnerById = useCallback(async () => {
    if (!id) return
    setPaymentPartnerTableLoading(true)
    const res = await getPaymentPartnerById(id)
    if (res) {
      setPaymentPartnerTableLoading(false)
      if (res.status === 200) {
        setPaymentPartner(res.data)
        setAttachments(res.data?.documents)
        setAttachmentsCopy(res.data?.documents)
        setVisibleDialog(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchPaymentPartnerById()
  }, [fetchPaymentPartnerById])

  const validatePaymentPartner = (paymentPartner) => {
    const err = []
    if (paymentPartner?.name === '') err.push('Name cannot be empty')
    if (paymentPartner?.phone === '') err.push('Phone cannot be empty')
    if (paymentPartner?.email === '') err.push('Email cannot be empty')
    if (paymentPartner?.address === '') err.push('Address cannot be empty')
    if (paymentPartner?.ssn === '') err.push('SSN cannot be empty')
    if (paymentPartner?.dob === '') err.push('Date of Birth cannot be empty')
    return err
  }
  const putPaymentPartnerFn = async () => {
    if (!id) return
    const errs = validatePaymentPartner(paymentPartner)
    if (errs.length) {
      setNewRecordError({ state: true, errors: errs })
      return
    }
    try {
      setLoading(true)
      setPaymentPartnerTableLoading(true)

      if (photo.file) {
        await deleteAwsS3Files([paymentPartner.photo])
        await uploadFileToS3({ file: photo.file }).then(
          (res) => (paymentPartner.photo = res.data.filePath)
        )
      }
      const { newFiles, removedFiles } = findArrayChanges(attachmentsCopy, attachments)
      // Remove from S3
      const deleteFiles = removedFiles.map((item) => item.path)
      // Add from S3
      let arrayOfFiles = [...attachments]
      const addAwsS3Files = async () => {
        await Promise.all(
          newFiles.map(async (attachment, index) => {
            const res = await uploadFileToS3({
              file: attachment?.file,
            })
            // Add new files to unchanged files
            const uidIdx = arrayOfFiles.findIndex((item) => item.uid === attachment.uid)
            arrayOfFiles[uidIdx].path = res.data.filePath
          })
        )
      }
      // Do the actions in parallel
      await Promise.all([
        newFiles.length > 0 && (await addAwsS3Files()),
        removedFiles.length > 0 && (await deleteAwsS3Files(deleteFiles)),
      ])
      arrayOfFiles = arrayOfFiles.map((item) => ({
        uid: item.uid,
        name: item.name,
        path: item.path,
        // New additions based on orders reference
        createdAt: new Date().toISOString(),
        meta: {
          createdBy: user?.username,
        },
      }))

      const res = await putPaymentPartner(id, paymentPartner, arrayOfFiles)

      if (res?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res?.data?.message || 'Updated successfully',
        })

        setPaymentPartners((ps) => ps.map((p) => (p.id === id ? { ...p, ...res.data.record } : p)))

        onHide()
      }
    } catch (error) {
      console.error('Error updating payment partner:', error)
      toast.current.show({
        severity: 'error',
        summary: 'Update Failed',
        detail: 'Something went wrong. Please try again.',
      })
    } finally {
      setLoading(false)
      setPaymentPartnerTableLoading(false)
    }
  }

  const handleFilesUpload = (e) => {
    if (!e.target.files.length) {
      return
    }
    const files = e.target.files
    const myFiles = []
    let largeFiles = 0
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      if (file.size < 1024 * 1024 * 5) {
        const path = URL.createObjectURL(file)
        myFiles.push({
          uid: nanoid(),
          name: file.name,
          file,
          path,
          new: true,
        })
      } else {
        largeFiles++
      }
    }
    if (largeFiles) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: `${largeFiles} files were not added as they were larger than 5MB`,
      })
    }
    setAttachments((ps) => [...ps, ...myFiles])
  }

  const removeFiles = (uid) => {
    let filValue = attachments.filter((ele) => ele.uid !== uid)
    setAttachments(filValue)
  }

  const onHide = () => {
    setVisibleDialog(false)
    setPaymentPartner(emptyPaymentPartner)
    setAttachments([])
    setAttachmentsCopy([])
    setPhoto(empty_ImageState)
    history.push({
      pathname: '/payment/partner-methods',
    })
    setActiveIndex(1)
  }

  const footer = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => onHide()}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button"
          loading={loading}
          onClick={() => {
            putPaymentPartnerFn()
          }}
        />
      </div>
    )
  }

  return (
    <Dialog
      header={`Edit Payment Partner #${id}`}
      visible={visibleDialog}
      style={{ width: '50vw' }}
      footer={footer}
      onHide={onHide}
    >
      <div className="p-grid p-formgrid p-ai-center">
        <div className="p-field p-fluid p-col-12 p-xl-6">
          <div className="p-fluid p-field p-col-12">
            {paymentPartner?.photo ? (
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Photo
                </label>
                <div className="card p-text-center">
                  <img
                    className="w-full"
                    style={{ borderRadius: '10px 10px 10px 10px' }}
                    src={generateS3Path(paymentPartner.photo)}
                    alt=""
                  />
                  <Button
                    label="Change Image"
                    icon="pi pi-pencil"
                    onClick={() => handleObjChange(setPaymentPartner, 'photo', '')}
                  />
                </div>
              </div>
            ) : (
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Photo
                </label>
                <UploadImage imageState={photo} setImageState={setPhoto} />
              </div>
            )}
          </div>
        </div>
        <div className="p-field p-fluid p-col-12 p-xl-6">
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              placeholder="Enter Name"
              value={paymentPartner.name}
              onChange={(e) => {
                handleObjChange(setPaymentPartner, 'name', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="phone">Phone</label>
            <InputText
              id="phone"
              type="tel"
              placeholder="Enter Phone"
              value={paymentPartner.phone}
              onChange={(e) => {
                handleObjChange(setPaymentPartner, 'phone', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              placeholder="Enter Email"
              value={paymentPartner.email}
              onChange={(e) => {
                handleObjChange(setPaymentPartner, 'email', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12">
            <label htmlFor="ssn">SSN</label>
            <InputText
              id="ssn"
              placeholder="Enter SSN"
              value={paymentPartner.ssn}
              onChange={(e) => handleObjChange(setPaymentPartner, 'ssn', e.target.value)}
            />
          </div>
        </div>
        <div className="p-field p-fluid p-col-12 p-xl-6">
          <label htmlFor="dateOfBirth">Date of Birth</label>
          <Calendar
            id="dateOfBirth"
            placeholder="Enter Date of Birth (dd/mm/yyyy)"
            value={new Date(paymentPartner.dob)}
            onChange={(e) => {
              const date = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : ''
              handleObjChange(setPaymentPartner, 'dob', date)
            }}
            dateFormat='dd/mm/yy'
          />
        </div>
        <div className="p-field p-fluid p-col-12 p-xl-6">
          <label htmlFor="address">Address</label>
          <InputTextarea
            id="address"
            placeholder="Enter Address"
            value={paymentPartner.address}
            onChange={(e) => handleObjChange(setPaymentPartner, 'address', e.target.value)}
          />
        </div>
        <div className="p-fluid p-field p-col-12">
          <label htmlFor="attachments">Documents</label>
          <div className=" p-mt-3">
            {attachments?.map((item, idx) => {
              let { name, path } = item
              if (name.length > 40) {
                name = name.substring(0, 15) + '...' + name.substring(name.length - 20)
              }
              let myPath = process.env.REACT_APP_S3_BUCKET_URL + '/' + path
              if (item.new) {
                myPath = path
              }
              return (
                <div key={idx} className="relative p-grid p-formgrid">
                  <div className=" p-d-flex p-jc-center p-field p-col-11">
                    <a
                      className={`p-button p-button-outlined p-button-secondary w-100`}
                      key={name}
                      href={myPath}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {name}
                    </a>
                  </div>
                  <div className=" p-d-flex p-jc-center p-field p-col-1">
                    <Button
                      className="p-button-danger"
                      onClick={() => removeFiles(item.uid, attachments, setAttachments)}
                      icon="pi pi-trash"
                    />
                  </div>
                  <span
                    className={`${item.new ? 'blob bottom-15-px left-0-px p-mx-0' : ''}`}
                  ></span>
                </div>
              )
            })}
          </div>
          <input
            type="file"
            className="p-hidden"
            accept="*"
            ref={documentRef}
            multiple
            onChange={(e) => {
              handleFilesUpload(e, setAttachments)
            }}
          />
          <Button
            icon="pi pi-plus"
            label="Upload New"
            className="p-button-info"
            onClick={() => {
              documentRef.current.click()
            }}
          />
        </div>
        <div className="p-field p-fluid p-col-12">
          <label htmlFor="additionalDetails">Additional Details</label>
          <ReactQuill
            id="additionalDetails"
            value={paymentPartner.additionalDetails}
            onChange={(e) => handleObjChange(setPaymentPartner, 'additionalDetails', e)}
          />
        </div>
        <div className="p-field p-fluid p-col-6">
          <label
            className="p-d-none p-d-lg-inline"
            htmlFor="active"
            style={{ color: 'transparent' }}
          >
            Active
          </label>
          <div className="p-d-flex p-ai-center">
            <label className="p-d-block p-mr-2" htmlFor="active">
              Active :
            </label>
            <Checkbox
              id="active"
              checked={paymentPartner.active ? true : false}
              onChange={(e) => handleObjChange(setPaymentPartner, 'active', e.checked)}
            />
          </div>
        </div>
      </div>
      {newRecordError.state && (
        <>
          {newRecordError.errors.map((err, idx) => {
            return (
              <div key={idx} className="p-fluid p-filed p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            )
          })}
          <div className="p-fluid p-field p-col-12">
            <Button
              type="button"
              onClick={() => {
                setNewRecordError(emptyErr)
              }}
              icon="pi pi-times"
              label="Clear Warnings"
              className="p-button-secondary"
            ></Button>
          </div>
        </>
      )}
    </Dialog>
  )
}
