import { Button } from 'primereact/button'
import React, { Fragment, useState } from 'react'
import { handleAryChange, handleObjChange } from '../../../functions/setter'
import { postProductResphimentInitiate } from '../../../api'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { useGlobalContext } from '../../../context'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { InputNumber } from 'primereact/inputnumber'
import { Checkbox } from 'primereact/checkbox'
const emptyErr = {
  state: false,
  errors: [],
}

const emptyAddTrackingDetails = {
  quantity: 0,
  trackinglink: '',
  trackingid: '',
}

const emptySetChangeStatus = {
  visible: false,
  loading: false,
  sendNotification: true,
  status: null,
  productid: null,
}

function ProductResphimentInititateDialog({
  productReshipment,
  setProductReshipment,
  addTrackingDetails,
  setAddTrackingDetails,
  error,
  setError,
  fetchOrderProducts,
  setTableLoading,
  hideProductReshipmentDialog,
}) {
  const { toast } = useGlobalContext()
  const [changeStatus, setChangeStatus] = useState(emptySetChangeStatus)
  const isValidTrackingDetails = () => {
    const errors = []
    if (!addTrackingDetails.length) errors.push('No Tracking Details Added')
    addTrackingDetails.forEach((item, idx) => {
      if (!item.quantity) errors.push(`Quantity is missing for link ${idx + 1}`)
      if (!item.trackingid) errors.push(`Tracking Id is missing for link ${idx + 1}`)
      if (!item.trackinglink) errors.push(`Link is missing for link ${idx + 1}`)
    })
    return errors
  }

  const handleProductResphimentInitiate = async () => {
    setTableLoading(true)
    handleObjChange(setProductReshipment, 'loading', true)
    handleObjChange(setChangeStatus, 'loading', true)
    const res = await postProductResphimentInitiate({
      productId: productReshipment.productid,
      trackingDetails: addTrackingDetails,
      sendNotification: changeStatus.sendNotification,
    })
    if (res) {
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Tracking Details Added Successfully and Reshipment Initiated',
          life: 3000,
        })
        console.log('say hello')
        hideProductReshipmentDialog()
        setChangeStatus(emptySetChangeStatus)
        fetchOrderProducts()
      }
    }
    setTableLoading(false)
    handleObjChange(setProductReshipment, 'loading', false)
    handleObjChange(setChangeStatus, 'loading', false)
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={`Add New Tracking Details For #${productReshipment.productData[0]?.id}`}
        visible={productReshipment.visible}
        style={{ width: '600px', paddingBottom: '0px' }}
        contentStyle={{ paddingBottom: '10px' }}
        onHide={hideProductReshipmentDialog}
        footer={
          <div className="p-d-flex p-jc-end gap-1by2 ">
            <div className="">
              <Button
                label={'Close'}
                disabled={productReshipment.loading}
                onClick={hideProductReshipmentDialog}
                className="p-button-danger"
                style={{ marginRight: 0 }}
                icon="pi pi-times"
              />
            </div>
            <div className="">
              <Button
                loading={productReshipment.loading}
                label={productReshipment.loading ? 'Submitting' : 'Submit'}
                onClick={() => {
                  const errors = isValidTrackingDetails()
                  if (errors.length) {
                    setError({ state: true, errors })
                    return
                  }
                  setChangeStatus((ps) => ({
                    ...ps,
                    visible: true,
                    status: 'Reshipment-Initiated',
                    productid: productReshipment.productid,
                  }))
                }}
                style={{ marginRight: 0 }}
                icon="pi pi-check"
              />
            </div>
          </div>
        }
      >
        <div>
          {addTrackingDetails.map((item, index) => {
            return (
              <Fragment key={index}>
                <div className="p-d-flex p-ai-center p-jc-between p-mb-2">
                  <h6 className="p-text-center p-mb-0">Tracking Link {index + 1}</h6>
                  <div className="">
                    <Button
                      label=""
                      icon="pi pi-trash"
                      className="p-button-danger p-button-text"
                      autoFocus
                      onClick={() => {
                        const _trackingDetails = addTrackingDetails.filter((_, i) => i !== index)
                        setAddTrackingDetails(_trackingDetails)
                      }}
                      tooltip="Remove This Row"
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>
                </div>
                <div key={index} className="p-d-flex p-flex-column">
                  <div className=" p-grid p-formgrid">
                    <div className="p-field p-col-6">
                      <InputText
                        disabled
                        style={{ width: '100%' }}
                        value={productReshipment.productName}
                        placeholder="Select a product"
                      />
                    </div>

                    <div className=" p-field p-col-6">
                      <InputNumber
                        style={{ width: '100%' }}
                        placeholder="Quantity"
                        value={item.quantity}
                        onChange={(e) => {
                          const _trackingDetails = [...addTrackingDetails]
                          _trackingDetails[index].quantity = e.value

                          handleAryChange(setAddTrackingDetails, index, _trackingDetails[index])
                        }}
                      />
                    </div>
                    <div className=" p-field p-col-12">
                      <InputText
                        style={{ width: '100%' }}
                        placeholder="Tracking Id"
                        value={item.trackingid}
                        onChange={(e) => {
                          const _trackingDetails = [...addTrackingDetails]
                          _trackingDetails[index].trackingid = e.target.value
                          handleAryChange(setAddTrackingDetails, index, _trackingDetails[index])
                        }}
                      />
                    </div>

                    <div className="p-field p-col-12">
                      <InputText
                        className=""
                        placeholder="Tracking Link"
                        value={item.trackinglink}
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          const _trackingDetails = [...addTrackingDetails]
                          _trackingDetails[index].trackinglink = e.target.value
                          handleAryChange(setAddTrackingDetails, index, _trackingDetails[index])
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            )
          })}
        </div>

        <Button
          icon="pi pi-plus"
          label="Add Row"
          className="p-button-info"
          style={{ marginTop: '21px', marginBottom: '14px', width: '100%' }}
          onClick={() => {
            setAddTrackingDetails([...addTrackingDetails, { ...emptyAddTrackingDetails }])
          }}
          // disabled={undeliveredOrderStatusOptions.includes(orderDetails?.orderStatus)}
        />

        {error.state && (
          <div className="p-grid">
            {error.errors.map((err, idx) => (
              <div key={idx} className="p-fluid p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            ))}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => setError(emptyErr)}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              />
            </div>
          </div>
        )}
      </Dialog>
      <Dialog
        visible={changeStatus.visible}
        style={{ width: '450px' }}
        header="Status Change Confirmation"
        modal
        footer={
          <>
            <Button
              label="No"
              icon="pi pi-times"
              className="p-button-text"
              disabled={changeStatus.loading}
              onClick={() => handleObjChange(setChangeStatus, 'visible', false)}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              className="p-button-text"
              loading={changeStatus.loading}
              onClick={handleProductResphimentInitiate}
              autoFocus
            />
          </>
        }
        onHide={() => handleObjChange(setChangeStatus, 'visible', false)}
      >
        <div className="confirmation-content  p-d-flex p-ai-center">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          <div>
            <span>
              Are you sure you want change status to {changeStatus?.status} for product (
              {changeStatus?.productid})?
            </span>

            <div className="p-mt-3 p-d-flex gap-1 p-ai-center ">
              <Checkbox
                inputId="changeStatusNotification"
                checked={changeStatus.sendNotification}
                onChange={(e) => handleObjChange(setChangeStatus, 'sendNotification', e.checked)}
              />
              <label htmlFor="changeStatusNotification">
                Send notification to customer regrading this product progress
              </label>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ProductResphimentInititateDialog
